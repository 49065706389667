import { AnswerStatus } from '../middleware/dashboardQueries/types';
import { ThumbStatus } from '../reducers/dashboardQueries/types';

export const DASHBOARD_QUERIES_INITIALIZE = 'DASHBOARD_QUERIES_INITIALIZE';
export const DASHBOARD_QUERIES_SEND_QUERY = 'DASHBOARD_QUERIES_SEND_QUERY';
export const DASHBOARD_QUERIES_STOP_QUERY = 'DASHBOARD_QUERIES_STOP_QUERY';
export const DASHBOARD_QUERIES_RECEIVE_QUERY_UPDATE = 'DASHBOARD_QUERIES_RECEIVE_QUERY_UPDATE';
export const DASHBOARD_QUERIES_RECEIVE_PONG = 'DASHBOARD_QUERIES_RECEIVE_PONG';
export const DASHBOARD_QUERIES_DEINITIALIZE = 'DASHBOARD_QUERIES_DEINITIALIZE';
export const DASHBOARD_QUERIES_THUMBS_TOGGLE = 'DASHBOARD_QUERIES_THUMBS_TOGGLE';
export const DASHBOARD_QUERIES_FEEDBACK_SUBMIT_START = 'DASHBOARD_QUERIES_FEEDBACK_SUBMIT_START';
export const DASHBOARD_QUERIES_FEEDBACK_SUBMIT_SUCCESS = 'DASHBOARD_QUERIES_FEEDBACK_SUBMIT_SUCCESS';
export const DASHBOARD_QUERIES_FEEDBACK_SUBMIT_ERROR = 'DASHBOARD_QUERIES_FEEDBACK_SUBMIT_ERROR';

interface IInitialize {
  companyName: string;
  dashboardID: string;
}

export const initialize = ({ companyName, dashboardID }: IInitialize) => ({
  type: DASHBOARD_QUERIES_INITIALIZE,
  payload: {
    companyName,
    dashboardID,
  },
});

interface ISendQuery {
  query: string;
}

export const sendQuery = ({ query }: ISendQuery) => ({
  type: DASHBOARD_QUERIES_SEND_QUERY,
  payload: {
    query,
  },
});

interface IReceiveQueryUpdate {
  status: AnswerStatus;
  answer?: {
    message: string;
  };
  error?: string;
}

export const receiveQueryUpdate = ({ status, answer, error }: IReceiveQueryUpdate) => ({
  type: DASHBOARD_QUERIES_RECEIVE_QUERY_UPDATE,
  payload: {
    status,
    answer,
    error,
  },
});

interface IReceivePong {
  version: string;
}

export const receivePong = ({ version }: IReceivePong) => ({
  type: DASHBOARD_QUERIES_RECEIVE_PONG,
  payload: {
    version,
  },
});

interface IThumbsToggle {
  id: string;
  thumb: ThumbStatus;
}

export const thumbsToggle = ({ id, thumb }: IThumbsToggle) => ({
  type: DASHBOARD_QUERIES_THUMBS_TOGGLE,
  payload: {
    thumb,
    id,
  },
});

export const stopQuery = () => ({
  type: DASHBOARD_QUERIES_STOP_QUERY,
  payload: {},
});

export const deinitialize = () => ({
  type: DASHBOARD_QUERIES_DEINITIALIZE,
  payload: {},
});

interface IFeedbackSubmit {
  queryID: string;
}

export const feedbackSubmitStart = ({ queryID }: IFeedbackSubmit) => ({
  type: DASHBOARD_QUERIES_FEEDBACK_SUBMIT_START,
  payload: { queryID },
});

export const feedbackSubmitSuccess = ({ queryID }: IFeedbackSubmit) => ({
  type: DASHBOARD_QUERIES_FEEDBACK_SUBMIT_SUCCESS,
  payload: { queryID },
});

export const feedbackSubmitError = ({ queryID }: IFeedbackSubmit) => ({
  type: DASHBOARD_QUERIES_FEEDBACK_SUBMIT_ERROR,
  payload: { queryID },
});
