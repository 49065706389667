import { Box, Tab } from '@material-ui/core';
import React, { useState } from 'react';
import { Link, Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';

import FileUploadDialogContainer from '../../components/FileUploadFlow/components/FileUploadDialog/FileUploadDialogContainer';
import SuccessfulUploadDialog from '../../components/FileUploadFlow/components/FileUploadMappingSuccess/SuccessfulUploadDialogContainer';
import { StyledResultsTabs } from '../../components/dashboard/style';
import DashboardToolbar from '../../components/DashboardToolbar/DashboardToolbarContainer';
import InfoBadgesArea from '../../components/ResultsTab/InfoBadgesArea';
import { Sidebar, SidebarDashboardProvider } from '../../components/Sidebar';
import SidebarItemAddToDashboard from '../../components/Sidebar/SidebarItems/SidebarItemAddToDashboard';
import SidebarItemBreakdown from '../../components/Sidebar/SidebarItems/SidebarItemBreakdown';
import SidebarItemColorPalette from '../../components/Sidebar/SidebarItems/SidebarItemColorPalette';
import SidebarItemControlPanel from '../../components/Sidebar/SidebarItems/SidebarItemControlPanel';
import SidebarItemCreateFilter from '../../components/Sidebar/SidebarItems/SidebarItemCreateFilter';
import SidebarItemExport from '../../components/Sidebar/SidebarItems/SidebarItemExport';
import SidebarItemSavedFilters from '../../components/Sidebar/SidebarItems/SidebarItemSavedFilters';
import SidebarItemShare from '../../components/Sidebar/SidebarItems/SidebarItemShare';
import SidebarItemTranslation from '../../components/Sidebar/SidebarItems/SidebarItemTranslation';
import SidebarItemWeightedMetrics from '../../components/Sidebar/SidebarItems/SidebarItemWeightedMetrics';
import FlexSpacer from '../../components/UI/FlexSpacer';

import GenerationFeedbackContainer from './components/Feedback/GenerationFeedbackContainer';
import KeyQuotesContainer from './components/KeyQuotes/KeyQuotesContainer';
import RefreshAIContainer from './components/RefreshAI/RefreshAIContainer';
import UploadInDashboardContainer from './components/UploadInDashboard/UploadInDashboardContainer';
import { DashboardTab, TAB_ID } from './DashboardContentContainer';
import TabTutorialBlockCompare from './TabTutorialBlockCompare';
import QueriesContainer from './Queries/QueriesContainer';

const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

interface IDashboardContent {
  isDashboardUnavailable: boolean;
  shouldShowUploadInDashboard: boolean;
  isUserAdminOfCompany: boolean;
  hasWeights: boolean;
  dashboardID: string;
  dashboardTitle: string;
  url: string;
  search: string;
  contentTabsToRender: DashboardTab[];
  selectedTabID: string;
  selectedTabIndex: number;
  isPrivate: boolean;
  shouldShowPrivacyMenu: boolean;
  shouldShowChatAI: boolean;
  onStatusDialogClose: () => void;
  onReturnToHivesClick: () => void;
  onControlPanelIconClick: () => void;
  onTabClick: (tab: DashboardTab) => void;
  onQueriesHeightChange: (height: number) => void;
}

function DashboardContent({
  isDashboardUnavailable,
  isUserAdminOfCompany,
  shouldShowUploadInDashboard,
  hasWeights,
  url,
  search,
  dashboardID,
  dashboardTitle,
  selectedTabID,
  selectedTabIndex,
  contentTabsToRender,
  isPrivate,
  shouldShowPrivacyMenu,
  shouldShowChatAI,
  onStatusDialogClose,
  onReturnToHivesClick,
  onControlPanelIconClick,
  onTabClick,
  onQueriesHeightChange,
}: IDashboardContent) {
  const [showCompareTutorialLink, setShowCompareTutorialLink] = useState(true);

  return (
    <Box>
      <DashboardToolbar
        dashboardName={dashboardTitle}
        dashboardID={dashboardID}
        title={dashboardTitle}
        shouldShowInviteLink
        disableInviteLink={!isUserAdminOfCompany}
        exitText
        shouldShowAccountLink
        shouldShowUploadButton
        isPrivate={isPrivate}
        shouldShowPrivacyMenu={shouldShowPrivacyMenu}
      />
      <SidebarDashboardProvider key={selectedTabID}>
        <Box display='flex'>
          <Sidebar>
            {/* @ts-ignore */}
            {selectedTabID !== TAB_ID.SEGMENTS && <SidebarItemCreateFilter />}
            {/* @ts-ignore */}
            {selectedTabID !== TAB_ID.SEGMENTS && <SidebarItemSavedFilters />}
            {/* @ts-ignore */}
            {selectedTabID === TAB_ID.RESPONSES && <SidebarItemBreakdown />}
            {/* @ts-ignore */}
            {selectedTabID === TAB_ID.RESPONSES && <SidebarItemAddToDashboard />}
            <SidebarItemTranslation />
            {hasWeights && <SidebarItemWeightedMetrics />}
            {/* @ts-ignore */}
            {selectedTabID !== TAB_ID.SEGMENTS && <SidebarItemColorPalette />}
            <SidebarItemShare />
            <SidebarItemExport />
            <SidebarItemControlPanel onClick={onControlPanelIconClick} />
          </Sidebar>
          <Box flexGrow={1} style={{ minWidth: 0 }}>
            {selectedTabID !== TAB_ID.COMPARE && <InfoBadgesArea showFilters={selectedTabID !== TAB_ID.SEGMENTS} />}

            <TabsWrapper>
              <StyledResultsTabs value={selectedTabIndex} indicatorColor='primary' textColor='primary'>
                {contentTabsToRender.map((tabData) => (
                  <Tab
                    {...tabData.props}
                    component={Link}
                    data-testid={tabData.id}
                    onClick={onTabClick.bind(null, tabData)}
                    key={tabData.props.label}
                    to={tabData.props.to + search}
                  />
                ))}
              </StyledResultsTabs>
              <FlexSpacer />
              {selectedTabID === TAB_ID.COMPARE && showCompareTutorialLink && (
                <TabTutorialBlockCompare onClose={setShowCompareTutorialLink.bind(null, false)} />
              )}
            </TabsWrapper>

            <Switch>
              {contentTabsToRender.map((tabData) => (
                <Route key={tabData.props.label} path={tabData.props.to} render={tabData.render} />
              ))}
              <Redirect from={url} to={contentTabsToRender[0].props.to + search} />
            </Switch>
          </Box>
        </Box>
      </SidebarDashboardProvider>
      <GenerationFeedbackContainer />
      {shouldShowChatAI && <QueriesContainer onResizeHappened={onQueriesHeightChange} />}
      <KeyQuotesContainer />
      <RefreshAIContainer />
      {shouldShowUploadInDashboard && (
        <UploadInDashboardContainer
          dashboardID={dashboardID}
          dashboardName={dashboardTitle}
          isCloseable={!isDashboardUnavailable}
          onCloseClick={onStatusDialogClose}
          onReturnToHivesClick={onReturnToHivesClick}
          onExploreMyDataClick={onStatusDialogClose}
        />
      )}
    </Box>
  );
}

export default DashboardContent;
