import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useColumnsMapping } from '../../hooks/columnsMapping/useColumnsMapping';
import { DATA_TYPES } from '../../pages/Programs/AddDataDialog';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { dashboardsUploadModalToggle } from '../../store/actions/dashboards';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { dashboardsInitialize } from '../../store/thunks/dashboards';
import { resetMapping } from '../../store/thunks/fileMapping';
import FileUploadFlow from './FileUploadFlow';

interface IFileUploadFlowContainer {
  fileUploadButtonTitle: string;
  dashboardName?: string;
  dashboardID?: string;
  isNewDashboard?: boolean;
  onClose?: () => void;
}
function FileUploadFlowContainer({
  fileUploadButtonTitle,
  dashboardName,
  dashboardID,
  isNewDashboard = true,
  onClose = () => {},
}: IFileUploadFlowContainer) {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const { dateRange } = useAppSelector((state) => state.dashboards);
  const {
    errorMessage: columnsMappingErrorMessage,
    isLoading: isColumnsMappingLoading,
    isSuccess: isColumnsMappingSuccess,
    fileNames: mappedColumnsFileNames,
    resetState,
  } = useColumnsMapping();
  const [isAddDataDialogOpen, setIsAddDataDialogOpen] = useState(false);
  const [isFileUploadDialogOpen, setIsFileUploadDialogOpen] = useState(false);
  function handleFileUploadClose() {
    setIsFileUploadDialogOpen(false);
  }
  function handleCreateNewClick() {
    eventsTracker.track(EVENTS.FiLE_UPLOAD_BUTTON_SELECT, {
      'Button location': 'Hives Page',
    });
    setIsAddDataDialogOpen(true);
  }
  function handleCreateNewClose() {
    setIsAddDataDialogOpen(false);
  }

  function handleFileUploadSuccess() {
    dispatch(dashboardsInitialize(dateRange));
  }

  function handleFileMappingDialogClose() {
    setIsAddDataDialogOpen(false);
    dispatch(resetMapping());
    resetState();
    onClose();
    dispatch(dashboardsInitialize(dateRange));
  }
  function handleConnectDataClick() {
    history.push('./connections');
  }

  function handleAddDataSelect(dataType: DATA_TYPES) {
    switch (dataType) {
      case DATA_TYPES.FILE:
        setIsFileUploadDialogOpen(true);
        break;
      case DATA_TYPES.COLLECTION:
        handleConnectDataClick();
        break;
    }
  }
  return (
    <FileUploadFlow
      isAddDataDialogOpen={isAddDataDialogOpen}
      isFileUploadOpen={isFileUploadDialogOpen}
      isColumnsMappingLoading={isColumnsMappingLoading}
      isSuccessfulUploadDialogOpen={
        !isColumnsMappingLoading && isColumnsMappingSuccess && !!mappedColumnsFileNames.length && !columnsMappingErrorMessage
      }
      dashboardName={dashboardName}
      dashboardID={dashboardID}
      isNewDashboard={isNewDashboard}
      isFailureUploadDialogOpen={!isColumnsMappingLoading && !isColumnsMappingSuccess && !!columnsMappingErrorMessage}
      mappedColumnsFileNames={mappedColumnsFileNames}
      onCreateNewClick={handleCreateNewClick}
      onCreateNewClose={handleCreateNewClose}
      onAddDataSelect={handleAddDataSelect}
      onFileUploadClose={handleFileUploadClose}
      onFileUploadSuccess={handleFileUploadSuccess}
      onFileMappingDialogClose={handleFileMappingDialogClose}
      fileUploadButtonTitle={fileUploadButtonTitle}
    />
  );
}

export default FileUploadFlowContainer;
