import React, { useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { AnswersStartingRowHelpStyled, HyperlinkIconStyled } from '../../styles';

interface IHoverableHelp {
  renderHelpElement: (top: number, left: number) => React.JSX.Element;
}

function HoverableHelp({ renderHelpElement }: IHoverableHelp) {
  const elRef = useRef<SVGSVGElement | null>(null);
  const [isHelpVisible, setIsHelpVisible] = useState(false);

  function handleHelpToggle(shouldShowHelp: boolean) {
    setIsHelpVisible(shouldShowHelp);
  }

  return (
    <>
      <HyperlinkIconStyled ref={elRef} onMouseEnter={handleHelpToggle.bind(null, true)} onMouseLeave={handleHelpToggle.bind(null, false)} />
      {isHelpVisible && renderHelp()}
    </>
  );

  function renderHelp() {
    if (!elRef?.current) {
      return;
    }
    const { top, left } = (elRef.current as SVGSVGElement).getBoundingClientRect();

    return createPortal(renderHelpElement(top, left), document.body);
  }
}

export default HoverableHelp;
