import React, { useEffect } from 'react';
import { DropzoneOptions, ErrorCode, useDropzone } from 'react-dropzone';

import { FileStatus, FileUploadSystemError, IFile } from '../types';

interface IDropzoneContent {
  files: File[];
  handleSelectFile: (e: any) => void;
}

interface IDropzone {
  files: React.MutableRefObject<{ [fileName: string]: IFile }>;
  options?: DropzoneOptions & { className?: string };
  rootContainer: (props: React.PropsWithChildren<object>) => React.ReactElement;
  children?: (props: IDropzoneContent) => React.ReactElement;
  handleUploadErrorSet: (error: FileUploadSystemError | null) => void;
  handleFilesChange: (files: { [fileName: string]: IFile }) => void;
  handleSelectFile?: (e: any) => void;
}

function Dropzone({
  files,
  rootContainer: RootContainer,
  handleSelectFile,
  handleUploadErrorSet,
  handleFilesChange,
  children,
  options = {},
}: IDropzone) {
  const { className, ...restOptions } = options;
  const { acceptedFiles, fileRejections, getRootProps, getInputProps, open } = useDropzone({ ...restOptions });

  useEffect(() => {
    // Check if there are any rejected files
    if (fileRejections.length > 0) {
      const error: ErrorCode = fileRejections[0].errors?.[0].code as ErrorCode;
      if (error === ErrorCode.FileInvalidType) {
        handleUploadErrorSet(FileUploadSystemError.INVALID_FILE_TYPE);
      } else {
        // file is too large, too small, or too many files catchall
        handleUploadErrorSet(FileUploadSystemError.UNKNOWN);
      }
    } else {
      handleUploadErrorSet(null);
    }

    // Update the files list with the new accepted files which aren't present already (Prevent duplicates)
    const newFiles = acceptedFiles.filter((file) => !Object.values(files.current).some((prevFile) => prevFile.file.name === file.name));
    const newFilesArray: IFile[] = newFiles.map((file) => ({
      file: file,
      status: FileStatus.DEFAULT,
      error: '',
      source: '',
    }));
    // add rejected files to the newFilesArray
    fileRejections.forEach((rejection) => {
      if (rejection.errors?.[0].code === ErrorCode.FileInvalidType) return;
      newFilesArray.push({
        file: rejection.file,
        status: FileStatus.ERROR,
        error: 'Upload failed',
        source: '',
      });
    });

    // Create an object with the file names as keys and the files as values
    const newFilesObject = newFilesArray.reduce(
      (obj, file) => {
        obj[file.file.name] = file;
        return obj;
      },
      {} as { [fileName: string]: IFile },
    );

    handleFilesChange({ ...files.current, ...newFilesObject });
  }, [acceptedFiles, fileRejections]);

  return (
    <RootContainer {...getRootProps({ className: className ?? '' })}>
      <input {...getInputProps()} />
      {children &&
        children({
          files: Object.values(files.current).map((file) => file.file),
          handleSelectFile: (e: any) => {
            open();
            handleSelectFile && handleSelectFile(e);
          },
        })}
    </RootContainer>
  );
}

export default Dropzone;
