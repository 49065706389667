import { datadogLogs } from '@datadog/browser-logs';

import {
  getAuthProvidersUrl,
  getAzureFilesUrl,
  getBookmarkResponseUrl,
  getBrandsUrl,
  getBreakdownUrl,
  getConnectionsUrl,
  getConnectionUrl,
  getCreateFilterUrl,
  getCsrfToken,
  getDashboardsUrl,
  getDashboardUrl,
  getDashboardViewsUrl,
  getDashboardViewUrl,
  getExportUrl,
  getFetchSettings,
  getFullRecord,
  getLabelsUrl,
  getLanguagesUrl,
  getProfileUrl,
  getProviderAuthsUrl,
  getProviderAuthUrl,
  getQualtricsSurveysUrl,
  getResponsesUrl,
  getSavedFiltersUrl,
  getSavedFilterUrl,
  getSegmentsUrl,
  getSentimentSummaryUrl,
  getSummaryUrl,
  getSurveysUrl,
  getTotalTrendsUrl,
  getTotalUrl,
  getTrendsBreakDownUrl,
  getTrendsUrl,
  getUpdateFilterUrl,
  getUploadFilesUrl,
  getWidgetGroupUrl,
  getWidgetUrl,
  postAIInsightsFeedbackUrl,
  postAuthUrl,
  putAIInsightsRequestUrl,
  getCustomersUrl,
  getLabelsManagementUrl,
  getRegenerationStatusUrl,
  putInterviewInsightsRequestUrl,
  putRegeneratingUrl,
  getReposnonseLabelsManagementUrl,
  getSignupWorkspaceUrl,
  getUserFinishedUploadUrl,
  getTemplatesUrl,
  getRequestTemplateUrl,
  getFileMappingStateUrl,
  getFileMappingReportIssueUrl,
  getDocumentLoadUrl,
  getDocumentValidateMappingUrl,
  getValidateDateFormatUrl,
  getDashboardQueriesFeedbackUrl,
} from '../../contexts/api-routes';
import { createDateRule, EMPTY_FILTER_QUERY } from '../FilterForm/lib';
import { COMBINATOR, OPERATOR_NAME } from '../FilterForm/constants';
import { API_BASE_URL, LOG_MESSAGE_TYPE } from '../../constants';
import { getTestsLinks } from './index';
import CONFIG from '../../config';
import { InsightsQuery } from '../../store/thunks/aiInsights';
import { InsightsFilter } from '../../store/thunks/interviewAIInsights';
import { Query } from '../../types/Query';
import { BreakdownFilter } from '../../store/reducers/overviewReducer';
import { UserMapping } from '../../store/reducers/fileMapping/types';

const SEGMENTS_FETCH_LIMIT = 10;

class ApiException {
  status: string;
  text: string;
  url: string;
  method: string;

  constructor(status: string, text: string, url: string, method: string) {
    this.status = status;
    this.text = text;
    this.url = url;
    this.method = method;
  }

  toString() {
    return `${this.status}: ${this.text}`;
  }
}

type FetchOptions = RequestInit & {
  withAbortRethrow?: boolean;
};
const apiFetch = <T = any>(
  url: string,
  method = 'GET',
  body: any = null,
  options: FetchOptions | null = {},
  customSettings: FetchOptions | null = null,
) => {
  const settings = customSettings ? customSettings : { ...getFetchSettings(method, body), ...options };
  const startTime = new Date().getTime();
  return fetch(url, settings)
    .then((response) => {
      // TODO: move this logic to the service worker
      if (response.status === 401 || response.status === 403) {
        window.location.href = API_BASE_URL;
      }
      const isJson = response.headers.get('content-type')?.includes('application/json');
      if (response.ok && isJson) {
        return response.json().then((json) => [response, json]);
      }
      return response.text().then((text) => [response, text]);
    })
    .then(([response, value]) => {
      if (response.ok) {
        return value as T;
      }
      throw new ApiException(response.status, value, url, method);
    })
    .catch((error) => {
      if (options?.withAbortRethrow) {
        throw error;
      }
      if (error.name !== 'AbortError') {
        const endTime = new Date().getTime();

        const links = getTestsLinks();
        datadogLogs.logger.error(`[API Error][${error}]`, {
          type: LOG_MESSAGE_TYPE.API_ERROR,
          details: error,
          duration: endTime - startTime,
          unsafeLink: links.unsafe,
          debugLink: links.debug,
        });
        return Promise.resolve();
      }
    });
};

export const createWidget = async (dashboardId: string, config: any) => {
  const widgetUrl = getWidgetUrl(dashboardId);
  const widget = await apiFetch(widgetUrl, 'POST', config);
  return widget.id;
};

export const updateWidget = async (config: any) => {
  const widgetUrl = getWidgetUrl(config.base.dashboardID, config.base.id);
  // eslint-disable-next-line prefer-const
  let { filter, ...base } = config.base;
  if (filter) {
    if (filter.filterID) {
      base = { ...base, filter: { filterID: filter.filterID } };
    } else if (filter.query) {
      base = { ...base, filter: { query: filter.query } };
    }
  }
  await apiFetch(widgetUrl, 'PUT', { base, settings: config.settings });
};

export const deleteWidget = async (dashboardId: string, widgetId: string) => {
  const widgetUrl = getWidgetUrl(dashboardId, widgetId);
  await apiFetch(widgetUrl, 'DELETE');
};

export const putAIInsightsGenerationRequest = async (
  signal: AbortSignal,
  dashboardId: string,
  widgetId: string,
  dataSourceID: string,
  totalAnswersAmount: number,
  filter: InsightsQuery[],
  force: boolean,
  customPrompt?: string,
  selectedConcept?: string,
) => {
  const generateInsightsURL = putAIInsightsRequestUrl(dashboardId, widgetId);
  return await apiFetch(
    generateInsightsURL,
    'PUT',
    {
      dataSourceId: dataSourceID,
      totalAnswersAmount,
      selectedConcept: selectedConcept ? selectedConcept : null,
      customPrompt: customPrompt ? customPrompt : null,
      filter,
      force: force,
    },
    { signal, withAbortRethrow: true },
  );
};

export const putInterviewInsightsGenerationRequest = async (
  signal: AbortSignal,
  dashboardId: string,
  widgetId: string,
  dataSourceID: string,
  totalAnswersAmount: number,
  filter: InsightsFilter[],
  force: boolean,
  selectedConcept?: string,
  customPrompt?: string,
) => {
  const generateInsightsURL = putInterviewInsightsRequestUrl(dashboardId, widgetId);
  return await apiFetch(
    generateInsightsURL,
    'PUT',
    {
      dataSourceId: dataSourceID,
      totalAnswersAmount,
      selectedConcept: selectedConcept ? selectedConcept : null,
      customPrompt: customPrompt ? customPrompt : null,
      filter,
      force: force,
    },
    { signal, withAbortRethrow: true },
  );
};

export const postAIInsightsFeedback = async (dashboardId: string, widgetId: string, requestBody: any) => {
  const generateFeedbackURL = postAIInsightsFeedbackUrl(dashboardId, widgetId);
  return await apiFetch(generateFeedbackURL, 'POST', requestBody);
};

export const createWidgetGroup = async (dashboardId: string, config: any) => {
  const widgetGroupUrl = getWidgetGroupUrl(dashboardId);
  const widgetGroup = await apiFetch(widgetGroupUrl, 'POST', config);
  return widgetGroup.id;
};

export const updateWidgetGroup = async (dashboardId: string, config: any) => {
  const widgetGroupUrl = getWidgetGroupUrl(dashboardId, config.id);
  await apiFetch(widgetGroupUrl, 'PUT', config);
};

export const deleteWidgetGroup = async (dashboardId: string, widgetGroupId?: string) => {
  const url = getWidgetGroupUrl(dashboardId, widgetGroupId);
  await apiFetch(url, 'DELETE');
};

export const fetchDashboards = async (
  dateRange: { startDate: string; endDate: string } | null,
  params: { abortController?: AbortController } = {},
) => {
  const { abortController } = params;
  const filter = dateRange
    ? {
        combinator: COMBINATOR.AND,
        rules: [
          createDateRule(OPERATOR_NAME.GREATER_THAN_OR_EQUAL_TO, dateRange.startDate),
          createDateRule(OPERATOR_NAME.LESS_THAN_OR_EQUAL_TO, dateRange.endDate),
        ],
      }
    : {
        combinator: COMBINATOR.AND,
        rules: [],
      };
  const options: FetchOptions = {};
  if (abortController) {
    options.signal = abortController.signal;
  }
  return await apiFetch(getDashboardsUrl(), 'POST', filter, options);
};

export const fetchDashboardViews = async () => {
  return await apiFetch(getDashboardViewsUrl());
};

export const deleteDashboardView = async (id: string) => {
  const dashboardViewUrl = getDashboardViewUrl(id);
  await apiFetch(dashboardViewUrl, 'DELETE');
};

export const createDashboardsView = async (config: any) => {
  const dashboardView = await apiFetch(getDashboardViewUrl(), 'POST', config);
  return dashboardView.id;
};

export const updateDashboardsView = async (config: any) => {
  const dashboardViewUrl = getDashboardViewUrl(config.id);
  await apiFetch(dashboardViewUrl, 'PUT', config);
};

interface SummaryParams {
  dataSourceIDs: string[];
  filter: Query;
  reduced?: boolean;
}
export const fetchSummaries = async (dashboardId: string, params: SummaryParams[]) => {
  const summaryUrl = getSummaryUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceIDs, filter, reduced }) =>
      apiFetch(summaryUrl, 'POST', {
        dataSourceIDs,
        filter,
        reduced: !!reduced,
      }),
    ),
  );
};

export const fetchSummary = async (dashboardId: string, dataSourceIDs: string[], filter: Query, reduced?: boolean) => {
  const summaries = await fetchSummaries(dashboardId, [{ dataSourceIDs, filter, reduced }]);
  return Promise.resolve(summaries.pop());
};

export const fetchSummariesBreakdown = async (dashboardId: string, breakdown: BreakdownFilter, params: SummaryParams[]) => {
  const summaryUrl = getBreakdownUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceIDs, filter }) =>
      apiFetch(summaryUrl, 'POST', {
        dataSourceIDs,
        breakdown,
        filter,
      }),
    ),
  );
};

interface TrendsParams {
  select: any[];
  filter: Query;
  dataSourceID: any;
  groupBy: string;
}
export const fetchTrends = async (dashboardId: string, params: TrendsParams[]) => {
  const trendsUrl = getTrendsUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceID, select, groupBy, filter }) =>
      apiFetch(trendsUrl, 'POST', {
        dataSourceID,
        select,
        groupBy,
        filter,
      }),
    ),
  );
};

export const fetchTrend = async (dashboardId: string, dataSourceID: string, select: any[], groupBy: string, filter: Query) => {
  const trends = await fetchTrends(dashboardId, [{ dataSourceID, select, groupBy, filter }]);
  return Promise.resolve(trends.pop());
};

export const fetchTrendsBreakdown = async (dashboardId: string, breakdown: BreakdownFilter, params: TrendsParams[]) => {
  const trendsBreakdownUrl = getTrendsBreakDownUrl(dashboardId);
  return await Promise.all(
    params.map(({ dataSourceID, select, groupBy, filter }) =>
      apiFetch(trendsBreakdownUrl, 'POST', {
        breakdown,
        dataSourceID,
        select,
        groupBy,
        filter,
      }),
    ),
  );
};

export const fetchMultipleTotals = async (dashboardId: string, queries: string[]) => {
  const totalUrl = getTotalUrl(dashboardId);
  return await Promise.all(queries.map((query) => apiFetch(totalUrl, 'POST', query)));
};

export const fetchTotals = async (dashboardId: string, query = EMPTY_FILTER_QUERY) => {
  const multipleTotals = await fetchMultipleTotals(dashboardId, [query]);
  return Promise.resolve(multipleTotals.pop());
};

export const fetchTotalTrends = async (dashboardId: string, groupBy: string) => {
  const totalTrendsUrl = getTotalTrendsUrl(dashboardId, groupBy);
  return await apiFetch(totalTrendsUrl, 'GET');
};

export const fetchSentiment = async (dashboardId: string, dataSourceIDs: string[], filter = EMPTY_FILTER_QUERY) => {
  const sentimentUrl = getSentimentSummaryUrl(dashboardId);
  return await apiFetch(sentimentUrl, 'POST', { dataSourceIDs, filter });
};

export const fetchDashboardExport = async (dashboardId: string, isTranslated: boolean, payload: any) => {
  const exportUrl = getExportUrl(dashboardId, isTranslated);
  return await apiFetch(exportUrl, 'POST', payload);
};

export const fetchSegments = async (dashboardId: string, offset: number) => {
  const segmentsUrl = getSegmentsUrl(dashboardId) + `?offset=${offset}&limit=${SEGMENTS_FETCH_LIMIT}`;
  return await apiFetch(segmentsUrl, 'POST');
};

const getAuthorizationOptions = (bearerToken: string) => ({
  headers: {
    Authorization: `Bearer ${bearerToken}`,
  },
});

export const fetchCsrfToken = async (bearerToken: string) => {
  const options = getAuthorizationOptions(bearerToken);
  return await apiFetch(postAuthUrl, 'GET', null, null, options);
};

export const fetchUserInformation = async (bearerToken: string) => {
  const options = getAuthorizationOptions(bearerToken);
  const url = `https://${CONFIG.AUTH0.DOMAIN}/userinfo`;
  return await apiFetch(url, 'GET', null, null, options);
};

export const fetchUser = async () => {
  const url = `${API_BASE_URL}/api/user`;
  return await apiFetch(url);
};

export const fetchDashboard = async (dashboardId: string) => {
  const url = getDashboardUrl(dashboardId);
  return await apiFetch(url);
};

export const patchDashboard = async (dashboardId: string, data: any) => {
  const url = getDashboardUrl(dashboardId);
  return await apiFetch(url, 'PATCH', data);
};

export const fetchSavedFilters = (dashboardId: string) => {
  const url = getSavedFiltersUrl(dashboardId);
  return apiFetch(url);
};

export const createSavedFilter = async (dashboardId: string, name: string, query: Query, description: string) => {
  const url = getCreateFilterUrl(dashboardId, name, description);
  return await apiFetch(url, 'POST', query);
};

export const updateSavedFilter = async (dashboardId: string, savedFilterId: string, name: string, query: Query, description: string) => {
  const url = getUpdateFilterUrl(dashboardId, savedFilterId, name, description);
  return await apiFetch(url, 'PUT', query);
};

export const deleteSavedFilter = async (dashboardId: string, filterId: string) => {
  const url = getSavedFilterUrl(dashboardId, filterId);
  return await apiFetch(url, 'DELETE');
};

export const fetchFullRecord = async (dashboardId: string, answerId: string) => {
  const url = getFullRecord(dashboardId, answerId);
  return await apiFetch(url);
};

export const fetchExtendLabels = async (dashboardId: string, responseId: string) => {
  const url = getLabelsUrl(dashboardId, responseId);
  return await apiFetch(url);
};

export const fetchResponses = async (
  dashboardId: string,
  dataSourceID: string,
  offset: number,
  limit: number,
  filter: Query,
  sorting: any,
  bookmarkedOnly: boolean,
) => {
  const url = getResponsesUrl(dashboardId);
  const data = { dataSourceID, offset, limit, filter, sorting, bookmarkedOnly };
  return await apiFetch(url, 'POST', data);
};

export const bookmarkResponse = async (dashboardId: string, answerID: string) => {
  const url = getBookmarkResponseUrl(dashboardId);
  const data = { answerID };
  return await apiFetch(url, 'POST', data);
};

export const unbookmarkResponse = async (dashboardId: string, answerID: string) => {
  const url = getBookmarkResponseUrl(dashboardId, answerID);
  await apiFetch(url, 'DELETE');
};

export const fetchProfile = async () => {
  const url = getProfileUrl();
  return await apiFetch(url);
};

export const updateProfile = async (profile: any) => {
  const url = getProfileUrl();
  return await apiFetch(url, 'PUT', profile);
};

export const fetchSurveys = async () => {
  const url = getSurveysUrl();
  return await apiFetch(url);
};

export const uploadFiles = async (files: any[], metadata: any) => {
  const url = getUploadFilesUrl();
  const formData = new FormData();
  for (const { arrayBuffer, fileName } of files) {
    const blob = new Blob([arrayBuffer]);
    formData.append('file', blob, fileName);
  }
  formData.append('metadata', JSON.stringify(metadata));

  return await apiFetch(url, 'POST', formData);
};

export const userFinishedUpload = async (dashboardID: string, isNewDashboard: boolean, files: any[]) => {
  const body = {
    files,
    isNewDashboard,
  };
  const url = getUserFinishedUploadUrl(dashboardID);
  return await apiFetch(url, 'POST', body);
};

export const fetchConnections = async () => {
  const url = getConnectionsUrl();
  return await apiFetch(url);
};

export const createConnection = async (operation: string, source: string, connection: any) => {
  const url = getConnectionsUrl() + `?operation=${operation}&source=${source}`;
  return await apiFetch(url, 'POST', connection);
};

export const updateConnection = async (connectionUpdate: any) => {
  const url = getConnectionUrl(connectionUpdate.id);
  return await apiFetch(url, 'PUT', connectionUpdate);
};

export const deleteConnection = async (connectionId: string) => {
  const url = getConnectionUrl(connectionId);
  return await apiFetch(url, 'DELETE');
};

export const fetchAzureFiles = async (authID: string) => {
  const url = getAzureFilesUrl(authID);
  return await apiFetch(url);
};

export const fetchQualtricsSurveys = async (authID: string) => {
  const url = getQualtricsSurveysUrl(authID);
  return await apiFetch(url);
};

export const fetchProviderAuths = async () => {
  const url = getProviderAuthsUrl();
  return await apiFetch(url);
};

export const authenticateProvider = async (source: string, authBody: any) => {
  const url = getProviderAuthsUrl() + `?source=${source}`;
  return await apiFetch(url, 'POST', authBody);
};

export const updateProviderAuth = async (id: string, source: string, authBody: any) => {
  const url = getProviderAuthUrl(id) + `?source=${source}`;
  return await apiFetch(url, 'PUT', authBody);
};

export const deleteProviderAuth = async (id: string) => {
  const url = getProviderAuthUrl(id);
  return await apiFetch(url, 'DELETE');
};

export const cleanProviderAuth = async (source: string) => {
  const url = getProviderAuthsUrl() + `?source=${source}`;
  return await apiFetch(url, 'DELETE');
};

export const fetchBrands = async () => {
  const url = getBrandsUrl();
  return await apiFetch(url);
};

export const fetchLanguages = async () => {
  const url = getLanguagesUrl();
  return await apiFetch(url);
};

export const fetchAuthProviders = async () => {
  const url = getAuthProvidersUrl();
  return await apiFetch(url);
};

export const fetchCustomers = async (dashboardID: string, dataSourceID: string) => {
  const url = getCustomersUrl(dashboardID, dataSourceID);
  return await apiFetch(url);
};

export const createLabel = async (dashboardID: string, dataSourceID: string, body: any) => {
  const url = getLabelsManagementUrl(dashboardID, dataSourceID);
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const renameLabel = async (dashboardID: string, dataSourceID: string, label: string, body: any) => {
  const url = getLabelsManagementUrl(dashboardID, dataSourceID, label);
  return await apiFetch(url, 'PUT', body, { withAbortRethrow: true });
};

export const deleteLabel = async (dashboardID: string, dataSourceID: string, label: string) => {
  const url = getLabelsManagementUrl(dashboardID, dataSourceID, label);
  return await apiFetch(url, 'DELETE', {}, { withAbortRethrow: true });
};

export const getRegenerationStatus = async (dashboardID: string, dataSourcesIDs: string[], signal: AbortSignal) => {
  const url = getRegenerationStatusUrl(dashboardID, dataSourcesIDs.map((ds) => `dataSourceID=${ds}`).join('&'));
  return await apiFetch(url, 'GET', null, { signal, withAbortRethrow: true });
};

export const startRegeneration = async (dashboardID: string, dataSourceID: string) => {
  const url = putRegeneratingUrl(dashboardID, dataSourceID);
  return await apiFetch(url, 'PUT');
};

export const assignTopicToResponse = async (dashboardID: string, datasourceID: string, responseID: string, body: any) => {
  const url = getReposnonseLabelsManagementUrl(dashboardID, datasourceID, responseID);
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const removeTopicFromResponse = async (dashboardID: string, datasourceID: string, responseID: string, body: any) => {
  const url = getReposnonseLabelsManagementUrl(dashboardID, datasourceID, responseID);
  return await apiFetch(url, 'DELETE', body, { withAbortRethrow: true });
};

export const createWorkspace = async (body: any) => {
  const url = getSignupWorkspaceUrl();
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const getTemplates = async (signal: AbortSignal) => {
  const url = getTemplatesUrl();
  return await apiFetch(url, 'GET', null, { withAbortRethrow: true, signal });
};

export const requestTemplate = async (body: any) => {
  const url = getRequestTemplateUrl();
  return await apiFetch(url, 'POST', body);
};

type GetFileMappingStateResponse = {
  userMapping: Record<string, UserMapping>;
  dateFormat: string;
  templateID: string;
  startRow: number;
  numberOfRows: number;
};
export const getFileMappingState = async (body: any, predictTypes: boolean, signal: AbortSignal) => {
  const url = getFileMappingStateUrl(predictTypes);
  return await apiFetch<GetFileMappingStateResponse>(url, 'POST', body, { withAbortRethrow: true, signal });
};

export const fileMappingReportIssue = async (body: any) => {
  const url = getFileMappingReportIssueUrl();
  return await apiFetch(url, 'POST', body);
};

export const fileMappingLoadDocuments = async (body: any) => {
  const url = getDocumentLoadUrl();
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const validateDateFormat = async (body: any) => {
  const url = getValidateDateFormatUrl();
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const documentValidateMapping = async (body: any) => {
  const url = getDocumentValidateMappingUrl();
  return await apiFetch(url, 'POST', body, { withAbortRethrow: true });
};

export const postDashboardQueriesFeedback = async (dashboardId: string, feedbackBody: any) => {
  const generateFeedbackURL = getDashboardQueriesFeedbackUrl(dashboardId);
  return await apiFetch(generateFeedbackURL, 'POST', feedbackBody);
};
