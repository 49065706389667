import {
  DATE_CONTAIN_COLUMNS_TYPES,
  UNIQUE_COLUMNS_TYPES_PER_FILE,
} from '../../../components/FileUploadFlow/components/FileUploadMapping/constants';

export function canExcludeFromDateFormatParsingError(hasColumnTypeChanged: boolean, columnType: string) {
  return hasColumnTypeChanged && !DATE_CONTAIN_COLUMNS_TYPES.includes(columnType);
}

export function isReplacingTheUniqueType(hasColumnTypeChanged: boolean, columnType: string) {
  return hasColumnTypeChanged && UNIQUE_COLUMNS_TYPES_PER_FILE.includes(columnType);
}
