import React from 'react';
import * as MarkdownLib from 'react-markdown';
import remarkGfm from 'remark-gfm';

interface IMarkdown {
  text: string;
}

function Markdown({ text }: IMarkdown) {
  return <MarkdownLib.default remarkPlugins={[remarkGfm]}>{text}</MarkdownLib.default>;
}

export default Markdown;
