import { useEffect, useMemo, useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import { MappingState } from '../../store/reducers/fileMapping/types';

export function useColumnsMapping() {
  const { isLoading: isLoadingState, files, error } = useAppSelector((state) => state.fileMapping);

  const [errorMessage, setErrorMessage] = useState<null | string>(null);
  const [isSuccess, setIsSuccess] = useState(false);
  const isLoading = !!isLoadingState[MappingState.UPLOADING];
  const [prevState, setPrevState] = useState(MappingState.DEFAULT);

  const fileNames = useMemo(() => {
    if (!files) {
      return [];
    }
    return files.map((f) => f.fileName);
  }, [files]);

  useEffect(() => {
    if (!isLoading) {
      if (prevState !== MappingState.UPLOADING) {
        return;
      }
      setIsSuccess(true);
      setErrorMessage(null);
    } else {
      if (error.UPLOADING) {
        setErrorMessage(error.UPLOADING);
      } else {
        setPrevState(MappingState.UPLOADING);
        setIsSuccess(false);
        setErrorMessage(null);
      }
    }
  }, [isLoading, error]);

  function resetState() {
    setPrevState(MappingState.DEFAULT);
    setIsSuccess(false);
    setErrorMessage(null);
  }

  return { errorMessage, isLoading, isSuccess, fileNames, resetState };
}
