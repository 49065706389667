import BaseWidgetContainer from '../BaseWidgetContainer';
import TrendsWidgetSettings from './Settings/TrendsWidgetSettings';
import { WidgetMenuWithBarsOption } from '../../WidgetMenu/WidgetMenu';
import TrendsWidgetContent from './TrendsWidgetContent';

const TrendsWidget = ({ config, height, onDimensionsChange, groupTitle }) => {
  return (
    <BaseWidgetContainer
      config={config}
      height={height}
      groupTitle={groupTitle}
      onDimensionsChange={onDimensionsChange}
      SettingsComponent={TrendsWidgetSettings}
      MenuComponent={WidgetMenuWithBarsOption}
      ContentComponent={TrendsWidgetContent}
    />
  );
};

export default TrendsWidget;
