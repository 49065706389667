import React from 'react';
import styled from 'styled-components';

const SIDEBAR_WIDTH = 68;

const SidebarContainer = styled('aside')`
  background-color: #fff;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding-top: 58px;
  position: fixed;
  top: 0;
  width: ${SIDEBAR_WIDTH}px;
}`;

/**
 * @description This is a spacer for the sidebar. It is used to push the content to the right. We need this because the sidebar is fixed.
 */
const SidebarSpacer = styled('div')`
  min-width: ${SIDEBAR_WIDTH}px;
  width: ${SIDEBAR_WIDTH}px;
`;

const SidebarContent = styled('div')`
  height: calc(100% - 88px); // 88px (intercom button height + padding)
  overflow-y: auto;
`;

const Sidebar = ({ children }: { children: React.ReactNode }) => {
  return (
    <>
      <SidebarSpacer />
      <SidebarContainer>
        <SidebarContent>
          {
            // @ts-ignore
            React.Children.toArray(children).map((child, index) => React.cloneElement(child, { index }))
          }
        </SidebarContent>
      </SidebarContainer>
    </>
  );
};

export default Sidebar;
