import React from 'react';

import backgroundImage from '../../../../assets/images/upload-in-dashboard-background.svg';
import {
  Background,
  CloseButtonContainer,
  CloseIconStyled,
  Container,
  Header,
  HeaderContainer,
  HeaderSubtitle,
} from '../FileUploadMapping/styles';
import { Body, Status } from './styles';
import HorizontalLoader from '../../common/HorizontalLoader/HorizontalLoader';

interface IFileUploadMappingLoader {
  onCloseButtonClick: () => void;
}

function FileUploadMappingLoader({ onCloseButtonClick }: IFileUploadMappingLoader) {
  return (
    <Container>
      <Background src={backgroundImage} />
      <HeaderContainer>
        <Header>File(s) uploading...</Header>
        <HeaderSubtitle>
          Keep this window open until your mapped files are fully uploaded. Closing this window will cancel the upload and your progress
          will be lost.
        </HeaderSubtitle>
        <CloseButtonContainer onClick={onCloseButtonClick}>
          <CloseIconStyled />
        </CloseButtonContainer>
      </HeaderContainer>
      <Body>
        <Status>Uploading...</Status>
        <HorizontalLoader />
      </Body>
    </Container>
  );
}

export default FileUploadMappingLoader;
