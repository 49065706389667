import React from 'react';
import FileUploadMappingError from './FileUploadMappingError';

interface IFileUploadMappingErrorContainer {
  onCloseButtonClick: () => void;
}

function FileUploadMappingErrorContainer({ onCloseButtonClick }: IFileUploadMappingErrorContainer) {
  function handleReportIssueClick() {}

  return <FileUploadMappingError onCloseButtonClick={onCloseButtonClick} onReportIssueClick={handleReportIssueClick} />;
}

export default FileUploadMappingErrorContainer;
