import { useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const useOAuthRedirect = (redirect: (state: string) => string | undefined, name: string) => {
  const [isOpen, setIsOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const win = useRef<Window | null>(null);

  const close = () => {
    if (win.current) {
      win.current.close();
    }
  };

  const redirectTo = (state: string) => {
    const url = redirect(state);
    if (!url) {
      return;
    }

    // Saving latest state to browser history before redirecting
    // replace replacing last record in the history
    // so we need to do it twice
    history.push({ pathname: location.pathname, search: '?qualtrics=0' });
    history.push({ pathname: location.pathname, search: '?qualtrics=0' });

    window.location.replace(url);
  };

  const open = (state: string) => {
    return new Promise<boolean>((resolve, _) => {
      const url = redirect(state);
      if (!url) {
        resolve(false);
        return;
      }

      const oauthWindow = window.open(url, name, 'width=600,height=600');
      if (!oauthWindow) {
        resolve(false);
        console.error('Failed to initilize popup window for OAuth');
        return;
      }
      if (oauthWindow) {
        win.current = oauthWindow;

        setIsOpen(true);
        const interval = setInterval(function () {
          if (!!win.current && win.current?.closed) {
            setIsOpen(false);
            clearInterval(interval);
            resolve(false);
            return;
          }
          try {
            if (win.current?.location.href.includes('oauth')) {
              if (win.current.location.href.includes('error')) {
                clearInterval(interval);
                win.current.close();
                setIsOpen(false);
                resolve(false);
              }
              if (win.current.location.href.includes('success')) {
                clearInterval(interval);
                win.current.close();
                setIsOpen(false);
                resolve(true);
              }
            }
          } catch (e) {
            // different origin, and we can't access its location.
          }
        }, 1000);
      }
    });
  };

  return {
    isOpen,
    open,
    close,
    redirectTo,
  };
};

export default useOAuthRedirect;
