import React, { ForwardedRef } from 'react';
import styled from 'styled-components';
import { User } from '../../../../../../store/reducers/profile';
import { DashboardQuery } from '../../../../../../types/DashboardQuery';
import BaseItemWithAvatar from './BaseItemWithAvatar';

const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--white);

  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--double-border-radius);
`;

const UserNameContainer = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
`;
const QuestionContainer = styled.div`
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: break-spaces;
  max-width: 700px;
`;

interface ITextQuestion {
  user: User;
  query: DashboardQuery;
}
function TextQuestion({ user, query }: ITextQuestion, ref: ForwardedRef<HTMLDivElement>) {
  if (!query.data) {
    return null;
  }
  return (
    <BaseItemWithAvatar ref={ref} user={user}>
      <Container>
        <UserNameContainer>{user.fullname}</UserNameContainer>
        <QuestionContainer>{query.data.message}</QuestionContainer>
      </Container>
    </BaseItemWithAvatar>
  );
}

export default React.forwardRef(TextQuestion);
