import React, { ForwardedRef } from 'react';

import Markdown from '../../../../../../../components/Markdown/Markdown';
import { ProgressIndicator } from '../../../../../../../components/ProgressIndicator/ProgressIndicator';
import { ThumbsDown, ThumbsUp } from '../../../../../../../components/Thumbs/Thumbs';
import { FilledButton } from '../../../../../../../components/UI/Button';
import { Feedback, ThumbStatus } from '../../../../../../../store/reducers/dashboardQueries/types';
import { DashboardQuery } from '../../../../../../../types/DashboardQuery';
import BaseItemWithAvatar from '../BaseItemWithAvatar';
import DynamicTextArea from './DynamicTextArea';

import {
  CheckIconStyled,
  FeedbackContainer,
  FeedbackSent,
  ResponseContainer,
  TellUsMoreContainer,
  TextContainer,
  TextResponseContainer,
  ThumbsContainer,
  UserNameContainer,
} from './styles';

interface ITextResponse {
  query: DashboardQuery;
  feedback: Feedback;
  isUserFeedbackVisible: boolean;
  userFeedback: string;
  onFeedbackChange: (newFeedback: string) => void;
  onThumbsToggle: (thumb: ThumbStatus) => void;
  onSendClick: () => void;
  onCloseIconClick: () => void;
}

function TextResponse(
  { query, isUserFeedbackVisible, feedback, userFeedback, onFeedbackChange, onSendClick, onThumbsToggle, onCloseIconClick }: ITextResponse,
  ref: ForwardedRef<HTMLDivElement>,
) {
  if (!query.data) {
    return null;
  }
  return (
    <BaseItemWithAvatar ref={ref}>
      <TextResponseContainer>
        <TextContainer>
          <UserNameContainer>Beehive AI</UserNameContainer>
          <ResponseContainer>
            <Markdown text={query.data.message} />
          </ResponseContainer>
        </TextContainer>
        <FeedbackContainer>
          <ThumbsContainer>
            <ThumbsUp $isSelected={feedback.thumb === ThumbStatus.UP} onClick={onThumbsToggle.bind(null, ThumbStatus.UP)} />
            <ThumbsDown $isSelected={feedback.thumb === ThumbStatus.DOWN} onClick={onThumbsToggle.bind(null, ThumbStatus.DOWN)} />
          </ThumbsContainer>
          {renderUserFeedback()}
        </FeedbackContainer>
      </TextResponseContainer>
    </BaseItemWithAvatar>
  );

  // RENDERS
  function renderUserFeedback() {
    if (!isUserFeedbackVisible) {
      return null;
    }

    if (feedback.wasSubmitted) {
      return renderFeedbackSent();
    }

    return renderTellUsMorePart();
  }

  function renderFeedbackSent() {
    return (
      <FeedbackSent>
        Feedback Sent <CheckIconStyled />
      </FeedbackSent>
    );
  }

  function renderTellUsMorePart() {
    if (feedback.thumb === null) {
      return;
    }

    return (
      <TellUsMoreContainer>
        <DynamicTextArea
          isCloseButtonVisible={!feedback.isLoading}
          feedback={userFeedback}
          onInputChange={onFeedbackChange}
          onCloseIconClick={onCloseIconClick}
        />
        <FilledButton onClick={onSendClick}>{feedback.isLoading ? <ProgressIndicator $color='var(--white)' /> : 'Send'}</FilledButton>
      </TellUsMoreContainer>
    );
  }
}

export default React.forwardRef(TextResponse);
