import React, { useEffect, useState } from 'react';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { sendQuery, stopQuery } from '../../../store/actions/dashboardQueries';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { QueryStatus } from '../../../types/DashboardQuery';
import Queries from './Queries';

interface IQueriesContainer {
  onResizeHappened?: (newSize: number) => void;
}
function QueriesContainer({ onResizeHappened }: IQueriesContainer) {
  const user = useAppSelector((state) => state.profile.user);
  const { queries, isGenerating, aiVersion, wasInitialPongReceived, sessionID } = useAppSelector((state) => state.dashboardQueries);
  const companyName = useAppSelector((state) => state.profile.company?.name ?? '');
  const userID = useAppSelector((state) => state.profile.user?.id ?? '');
  const dashboardID = useAppSelector((state) => state.dashboard.dashboard?.id ?? '');
  const dispatch = useAppDispatch();

  // USE STATE
  const [messageToSend, setMessageToSend] = useState<string | null>(null);

  // USE EFFECT
  useEffect(() => {
    if (!messageToSend?.length || isGenerating) {
      return;
    }
    sendUserTrackingEvent();
    dispatch(sendQuery({ query: messageToSend }));
    setMessageToSend(null);
  }, [messageToSend, isGenerating]);

  useEffect(
    function trackErrorMessages() {
      if (queries[0]?.status === QueryStatus.FAILURE_STATUS) {
        eventsTracker.track(EVENTS.AI_AGENT_ERROR_REQUEST_CANNOT_BE_PROCESSED, {
          'Dashboard ID': dashboardID,
          'Workspace Name': companyName,
          'Debug Session ID': sessionID,
          'User ID': userID,
          'Reason for Error': queries[0].error ?? '',
        });
      }
    },
    [queries],
  );

  // HANDLERS
  function handleSubmit(query: string) {
    if (!isGenerating) {
      sendUserTrackingEvent();
      dispatch(sendQuery({ query }));
    } else {
      dispatch(stopQuery());
      setMessageToSend(query);
    }
  }

  function sendUserTrackingEvent() {
    eventsTracker.track(EVENTS.AI_AGENT_QUERY_CHAT_SUBMIT, {
      'Debug Session ID': sessionID,
    });
  }

  function handleResize(newSize: number) {
    if (onResizeHappened) {
      onResizeHappened(newSize);
    }
  }

  if (!user || !wasInitialPongReceived) {
    return null;
  }

  return (
    <Queries
      isGenerating={isGenerating}
      queries={queries}
      user={user}
      onSubmit={handleSubmit}
      onResize={handleResize}
      aiVersion={aiVersion}
    />
  );
}

export default QueriesContainer;
