import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { openConnectionsModal, updateSelectedIntegration } from '../../store/actions/connectors';
import { dashboardsUploadModalToggle } from '../../store/actions/dashboards';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { fetchConnectors, fetchSurveys, resetConnectors } from '../../store/thunks/connectors';
import { Integration, SurveySourceEnum } from '../../types/connections';
import { NOTIFICATIONS } from '../Notification/constants';
import { NotificationsContext } from '../Notification/NotificationsContext';
import { SEVERITY } from '../Notification/types';
import ConnectorsTab from './ConnectorsTab';

import { SOURCES } from './constants';
import { TabEnum } from './types';
import { TAB_TO_LABEL } from './utils';

function ConnectorsTabContainer() {
  const location = useLocation();
  const history = useHistory();
  const { showNewMessages } = useIntercom();
  const dispatch = useAppDispatch();
  const {
    connectorsLoadingError: isConnectorsLoadingError,
    surveysLoadingError: isSurveysLoadingError,
    connectorsLoading: isConnectorsLoading,
    surveysLoading: isSurveysLoading,
    integrations,
  } = useAppSelector((state) => state.connectors);
  const { addNotification, removeNotification } = useContext(NotificationsContext);
  const [selectedTab, setSelectedTab] = useState(TabEnum.INTEGRATIONS);

  useEffect(() => {
    dispatch(resetConnectors());
    dispatch(fetchConnectors());
    dispatch(fetchSurveys());
    return () => {
      dispatch(resetConnectors());
    };
  }, []);

  // Handle Qualtrics redirect
  useEffect(() => {
    if (isConnectorsLoading || isSurveysLoading) {
      return;
    }
    if (!location) {
      return;
    }
    if (!location.search) {
      return;
    }
    if (!integrations) {
      return;
    }
    if (integrations.length === 0) {
      return;
    }
    if (!addNotification) {
      return;
    }
    if (!removeNotification) {
      return;
    }

    const searchParams = new URLSearchParams(location.search);
    if (searchParams.has('qualtrics')) {
      const qualtrics = searchParams.get('qualtrics');
      // Success state
      if (qualtrics === '1') {
        dispatch(updateSelectedIntegration({ source: SurveySourceEnum.QUALTRICS }));
        dispatch(openConnectionsModal({ source: SurveySourceEnum.QUALTRICS, isAuthEdit: false }));

        history.replace({
          search: '',
        });

        return;
      }
      // Error state
      if (qualtrics === '0') {
        // wait a litte bit to show the notification after the page is loaded
        setTimeout(() => {
          removeNotification(NOTIFICATIONS.ConnectorsUploadMoreData);
          addNotification({
            id: NOTIFICATIONS.ConnectorsAuthError,
            title: 'Qualtrics authentication failed',
            severity: SEVERITY.ERROR,
            persistent: true,
          });
        }, 500);

        history.replace({
          search: '',
        });

        return;
      }
    }
  }, [location, isConnectorsLoading, isSurveysLoading, integrations, addNotification, removeNotification]);

  useEffect(() => {
    if (!isConnectorsLoadingError) {
      removeNotification(NOTIFICATIONS.ConnectorsLoadingError);
      return;
    }
    addNotification({
      id: NOTIFICATIONS.ConnectorsLoadingError,
      title: 'An error occurred while loading your connections.',
      severity: SEVERITY.ERROR,
      persistent: true,
    });
    return () => {
      removeNotification(NOTIFICATIONS.ConnectorsLoadingError);
    };
  }, [isConnectorsLoadingError]);

  useEffect(() => {
    if (!isSurveysLoadingError) {
      removeNotification(NOTIFICATIONS.ConnectorsSurveysLoadingError);
      return;
    }
    addNotification({
      id: NOTIFICATIONS.ConnectorsSurveysLoadingError,
      title: 'An error occurred while loading your csv surveys.',
      severity: SEVERITY.ERROR,
      persistent: true,
    });
    return () => {
      removeNotification(NOTIFICATIONS.ConnectorsSurveysLoadingError);
    };
  }, [isSurveysLoadingError]);

  function handleAddConnection(source: SurveySourceEnum | null) {
    if (!source) {
      return;
    }

    eventsTracker.track(EVENTS.CONNECTIONS_ADD_CONNECTION_SELECT, {
      'App/Integration name': source,
    });

    if (source === SurveySourceEnum.CSV) {
      eventsTracker.track(EVENTS.FiLE_UPLOAD_BUTTON_SELECT, {
        'Button location': 'Connections Page',
      });
      dispatch(dashboardsUploadModalToggle());
      return;
    }
    if (source === SurveySourceEnum.SURVEYMONKEY || source === SurveySourceEnum.TYPEFORM) {
      const message = SOURCES[source]?.intercomMessage ?? '';
      showNewMessages(message);
      return;
    }
    dispatch(openConnectionsModal({ source }));
  }

  function handleEnrich(source: SurveySourceEnum, title: string, existingEnrichment?: string | null) {
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_ENRICH, {
      'Survey title': title,
      'Existing enrichment name': existingEnrichment,
      'Data source name': source,
    });
    showNewMessages(SOURCES[source]?.intercomEnrichmentMessage(title, existingEnrichment));
  }

  function handleEditAuthentication(integration: Integration) {
    dispatch(openConnectionsModal({ source: integration.source, isAuthEdit: true }));
  }

  function handleTabChange(_: any, value: React.SetStateAction<TabEnum>) {
    eventsTracker.track(EVENTS.CONNECTIONS_APP_TAB_SELECT, {
      Origin: TAB_TO_LABEL[selectedTab],
      Destination: TAB_TO_LABEL[value as TabEnum],
    });
    setSelectedTab(value);
  }

  return (
    <ConnectorsTab
      selectedTab={selectedTab}
      onConnectionAdd={handleAddConnection}
      onAuthenticationEdit={handleEditAuthentication}
      onEnrich={handleEnrich}
      onTabChange={handleTabChange}
    />
  );
}

export default ConnectorsTabContainer;
