import { useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { loadDashboardsForUploadModal } from '../../../../store/thunks/dashboards';

const useDashboards = (onUpdateError?: (err: string) => void) => {
  const dispatch = useAppDispatch();
  const { dashboards, isOpen, isLoading, error: isDashboardsError } = useAppSelector((state) => state.dashboards.uploadModal);

  const [selectedDashboards, setSelectedDashboards] = useState<string[]>([]);

  function handleSelectDashboard(newDashboards: string | string[]) {
    if (Array.isArray(newDashboards)) {
      setSelectedDashboards(newDashboards);
    } else {
      setSelectedDashboards([newDashboards]);
    }
  }

  useEffect(() => {
    if (!isLoading && isDashboardsError && onUpdateError) {
      onUpdateError('Failed to load dashboards.');
      return;
    }

    if (!dashboards && isOpen && !isLoading) {
      dispatch(loadDashboardsForUploadModal());
    }
  }, [isLoading, isDashboardsError, dispatch, onUpdateError]);

  return {
    dashboards,
    selectedDashboard: selectedDashboards[0] ?? '',
    setSelectedDashboard: handleSelectDashboard,
    selectedDashboards,
    setSelectedDashboards: handleSelectDashboard,
  };
};

export default useDashboards;
