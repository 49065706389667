import React, { useState } from 'react';
import Input from './Input';

interface IInputContainer {
  isGenerating: boolean;
  onSubmit: (message: string) => void;
  onInputHeightChange: (newHeight: number) => void;
}
function InputContainer({ isGenerating, onSubmit, onInputHeightChange }: IInputContainer) {
  const [query, setQuery] = useState('');

  function handleQueryChange(newQuery: string) {
    setQuery(newQuery);
  }

  function handleSubmitClick() {
    if (!isGenerating && query.length === 0) {
      return;
    }

    onSubmit(query.trim());
    setQuery('');
  }
  return (
    <Input
      onInputHeightChange={onInputHeightChange}
      isGenerating={isGenerating}
      query={query}
      onSubmit={handleSubmitClick}
      onInputChange={handleQueryChange}
    />
  );
}

export default InputContainer;
