import React, { useContext, useEffect, useState } from 'react';
import { hideFeedbackModal } from '../../../../store/actions/aiInsights';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { ThumbStatus } from '../../../../components/Widgets/components/GeneratedInsights/GeneratedInsightsContainer';
import GenerationFeedback from './GenerationFeedback';
import { submitInsightsFeedback } from '../../../../store/thunks/insightsFeedback';
import { NotificationsContext } from '../../../../components/Notification/NotificationsContext';
import { NOTIFICATIONS } from '../../../../components/Notification/constants';
import { SEVERITY } from '../../../../components/Notification/types';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';

const THUMB_UP_PROMPT = 'What did you like about this response?';
const THUMB_DOWN_PROMPT = 'What was the issue with the response? How could it be improved?';

function GenerationFeedbackContainer() {
  const { addNotification } = useContext(NotificationsContext);
  const { feedbackModal } = useAppSelector((state) => state.aiInsights);
  const { dashboard } = useAppSelector((state) => state.dashboard);
  const dispatch = useAppDispatch();

  const [feedback, setFeedback] = useState('');
  const [wasLoading, setWasLoading] = useState(false);

  useEffect(() => {
    if (!feedbackModal) {
      return;
    }

    if (feedbackModal.isVisible && feedbackModal.isLoading) {
      setFeedback('');
      dispatch(hideFeedbackModal());
      setWasLoading(true);
    } else if (wasLoading && !feedbackModal.isLoading && !feedbackModal.isError) {
      addNotification({
        id: NOTIFICATIONS.AIInsightsFeedbackSuccess,
        title: 'Feedback submitted successfully!',
        severity: SEVERITY.SUCCESS,
        persistent: false,
      });
      setWasLoading(false);
    } else if (wasLoading && !feedbackModal.isLoading && feedbackModal.isError) {
      addNotification({
        id: NOTIFICATIONS.AIInsightsFeedbackError,
        title: 'Error submitting your feedback.',
        severity: SEVERITY.ERROR,
        persistent: false,
      });
      setWasLoading(false);
    }
  }, [wasLoading, feedbackModal?.isLoading, feedbackModal?.isError, feedbackModal?.isVisible]);

  function handleClose() {
    dispatch(hideFeedbackModal());
    eventsTracker.track(EVENTS.GEN_AI_USER_FEEDBACK, {
      'Submit/Cancel': 'Cancel',
      'User feedback text': feedback,
      'Like/Dislike': feedbackModal?.thumbStatus === ThumbStatus.UP ? 'Like' : 'Dislike',
      'Insight ID': feedbackModal?.relatedInsightID,
      'Data Source ID': feedbackModal?.dataSourceID,
      'Widget ID': feedbackModal?.widgetID,
      'Dashboard ID': dashboard?.id,
    });
    setFeedback('');
  }

  function handleSubmit() {
    dispatch(submitInsightsFeedback({ feedback }));
    eventsTracker.track(EVENTS.GEN_AI_USER_FEEDBACK, {
      'Submit/Cancel': 'Submit',
      'User feedback text': feedback,
      'Like/Dislike': feedbackModal?.thumbStatus === ThumbStatus.UP ? 'Like' : 'Dislike',
      'Insight ID': feedbackModal?.relatedInsightID,
      'Data Source ID': feedbackModal?.dataSourceID,
      'Widget ID': feedbackModal?.widgetID,
      'Dashboard ID': dashboard?.id,
    });
  }

  function handleFeedbackChange(feedback: string) {
    setFeedback(feedback);
  }

  if (!feedbackModal) {
    return null;
  }

  return (
    <GenerationFeedback
      feedback={feedback}
      isOpen={feedbackModal.isVisible}
      onCancel={handleClose}
      onSubmit={handleSubmit}
      onOpinionTextChange={handleFeedbackChange}
      placeholder={feedbackModal.thumbStatus === ThumbStatus.UP ? THUMB_UP_PROMPT : THUMB_DOWN_PROMPT}
    />
  );
}

export default GenerationFeedbackContainer;
