import React, { useEffect, useRef, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { resetCSVUploadStatus, uploadFiles } from '../../../../store/thunks/connectors';
import { CsvUploadErrorEnum } from '../../../../types/connections';
import { DashboardVisibility, FileStatus, FileUploadSystemError, IFile } from '../../../ConnectorsTab/types';
import { UploadErrorResult } from '../../../../store/reducers/connectors/types';

import FileUploadDialog from './FileUploadDialog';

interface IFileUploadDialogContainer {
  dashboardName?: string;
  dashboardID?: string;
  shouldShowVisibilityMenu: boolean;
  onClose: (fileAdded?: boolean) => void;
  onUploadSuccess: (fileNames: string[]) => void;
}

function FileUploadDialogContainer({
  dashboardName,
  dashboardID,
  shouldShowVisibilityMenu,
  onClose,
  onUploadSuccess,
}: IFileUploadDialogContainer) {
  const dispatch = useAppDispatch();
  const { addCSV } = useAppSelector((state) => state.connectors);
  // required to be a ref by the dropzone for accurate file tracking during quick state changing actions e.g. cypress tests
  const filesRef = useRef<{ [fileName: string]: IFile }>({});
  // used by - and for triggering - re-renders
  const [files, setFiles] = useState<{ [fileName: string]: IFile }>({});
  const [visibility, setVisibility] = useState(DashboardVisibility.WORKSPACE);

  const [uploadError, handleUploadErrorSet] = useState<FileUploadSystemError | null>(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [allFilesUploaded, setAllFilesUploaded] = useState(false);
  const { showNewMessages } = useIntercom();

  function handleFilesChange(newFiles: { [fileName: string]: IFile }) {
    filesRef.current = newFiles;
    setFiles(filesRef.current);
    setIsSubmitDisabled(
      Object.keys(filesRef.current).length < 1 ||
        uploadError !== null ||
        Object.values(filesRef.current).every((file) => file.status === FileStatus.ERROR),
    );
    setAllFilesUploaded(
      Object.values(filesRef.current).every((file) => file.status === FileStatus.UPLOADED) && Object.values(filesRef.current).length > 0,
    );
  }

  useEffect(() => {
    if (addCSV.submittingUpload) {
      return;
    }
    if (addCSV.uploadingSuccess) {
      for (const { fileName } of addCSV.uploadErrorResults ?? []) {
        const newFiles = {
          ...filesRef.current,
          [fileName]: {
            ...filesRef.current[fileName],
            // for select cypress tests where a file is not actually present
            file: filesRef.current[fileName]?.file ?? new File([], fileName),
            error: null,
            status: FileStatus.UPLOADED,
          },
        };
        handleFilesChange(newFiles);
      }
    }
    if (addCSV.uploadingError) {
      handleUploadError(addCSV.uploadingError, addCSV?.uploadErrorResults ?? []);
    }
  }, [addCSV]);

  useEffect(() => {
    if (!allFilesUploaded) {
      return;
    }

    onUploadSuccess(Object.values(filesRef.current).map((file) => file.file.name));
    handleClose();
  }, [allFilesUploaded]);

  function handleUploadError(errorMessage: CsvUploadErrorEnum, uploadErrorResults: UploadErrorResult[]) {
    if (errorMessage !== CsvUploadErrorEnum.DUPLICATE_NAME) {
      handleUploadErrorSet(FileUploadSystemError.UNKNOWN);
    } else {
      let newFiles = { ...filesRef.current };
      for (const { fileName, error } of uploadErrorResults ?? []) {
        if (error === CsvUploadErrorEnum.DUPLICATE_NAME) {
          newFiles = {
            ...newFiles,
            [fileName]: {
              ...newFiles[fileName],
              error: CsvUploadErrorEnum.DUPLICATE_NAME,
              status: FileStatus.ERROR,
            },
          };
        }
      }
      handleFilesChange(newFiles);
    }
  }

  function handleClose() {
    onClose(false);
    // Reset all values after successful upload
    handleUploadErrorSet(null);
    dispatch(resetCSVUploadStatus());
    handleFilesChange({});
  }

  async function handleSubmit() {
    if (isSubmitDisabled) {
      return;
    }
    eventsTracker.track(EVENTS.FILE_UPLOAD_POPUP_NEXT_BUTTON_SELECT, {
      'Number of files uploaded': Object.values(filesRef.current).length,
    });

    dispatch(
      uploadFiles({ files: filesRef.current, dashboardName, dashboardID, isDashboardPrivate: visibility === DashboardVisibility.PRIVATE }),
    );
  }

  function handleSelectFile() {
    eventsTracker.track(EVENTS.FILE_UPLOAD_SELECT_FILE_CLICK);
  }

  function handleChangeVisibility(v: string) {
    setVisibility(v);
  }

  function handleContactUsClick() {
    showNewMessages('');
  }

  useEffect(() => {
    return () => {
      dispatch(resetCSVUploadStatus());
      handleFilesChange({});
    };
  }, []);

  return (
    <FileUploadDialog
      isSubmitDisabled={isSubmitDisabled}
      uploadError={uploadError}
      filesRef={filesRef}
      files={files}
      isUploading={addCSV.submittingUpload ?? false}
      visibility={visibility}
      shouldShowVisibilityMenu={shouldShowVisibilityMenu}
      handleSelectFile={handleSelectFile}
      handleClose={handleClose}
      handleSubmit={handleSubmit}
      handleFilesChange={handleFilesChange}
      handleUploadErrorSet={handleUploadErrorSet}
      handleChangeVisibility={handleChangeVisibility}
      handleContactUsClick={handleContactUsClick}
    />
  );
}

export default FileUploadDialogContainer;
