import Chip from '@material-ui/core/Chip';
import Popper from '@material-ui/core/Popper';
import TextField from '@material-ui/core/TextField';
import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import Autocomplete from '@material-ui/lab/Autocomplete';
import classNames from 'classnames';
import React, { ChangeEvent, SyntheticEvent, useEffect, useRef, useState } from 'react';

import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand-icon.svg';
import { AutoCompleteLabel } from './lib';
import { InvalidChip } from './style';
import styles from './styles.module.css';

interface ILabelsAutocompleteControl {
  options: AutoCompleteLabel[];
  value: AutoCompleteLabel[];
  testID: string;
  filterInputValue: string;
  isLoading: boolean;
  getOptionLabel: (option: AutoCompleteLabel) => string;
  getIsOptionSelected: (option: AutoCompleteLabel, value: AutoCompleteLabel) => boolean;
  getIsOptionDisabled: (option: AutoCompleteLabel) => boolean;
  getIsOptionChecked: (option: AutoCompleteLabel) => boolean;
  getIsParentExpanded: (parentName: string) => boolean;
  onChange: (event: object, options: AutoCompleteLabel[], reason: AutocompleteChangeReason, details: any) => void;
  onInputChange: (event: ChangeEvent<object>, value: string, reason: AutocompleteInputChangeReason) => void;
  onParentExpandClick: (optionName: string, isExpanded: boolean, event: SyntheticEvent) => void;
  onDropdownOpen: () => void;
  onDropdownClose: () => void;
}

function LabelsAutocompleteControl({
  options,
  value,
  testID,
  filterInputValue,
  isLoading,
  getOptionLabel,
  getIsOptionSelected,
  getIsOptionDisabled,
  getIsParentExpanded,
  getIsOptionChecked,
  onChange,
  onInputChange,
  onParentExpandClick,
  onDropdownOpen,
  onDropdownClose,
  ...props
}: ILabelsAutocompleteControl) {
  const [isOpen, setIsOpen] = useState(false);

  function handleOpen() {
    setIsOpen(true);
    onDropdownOpen();
  }

  function handleClose() {
    setIsOpen(false);
    onDropdownClose();
  }

  return (
    <Autocomplete
      data-testid={testID}
      multiple
      disableCloseOnSelect
      size='small'
      inputValue={filterInputValue}
      open={isOpen}
      onOpen={handleOpen}
      onClose={handleClose}
      onChange={onChange}
      onInputChange={onInputChange}
      options={options}
      loading={isLoading}
      value={value}
      filterOptions={(x) => x}
      getOptionLabel={getOptionLabel}
      getOptionSelected={getIsOptionSelected}
      getOptionDisabled={getIsOptionDisabled}
      PopperComponent={Popper}
      renderOption={renderOption}
      renderTags={renderTags}
      renderInput={renderInput}
      classes={{
        option: styles.optionsWrapper,
      }}
      {...props}
    />
  );

  function renderInput(params: any) {
    return (
      <TextField
        {...params}
        variant='outlined'
        inputProps={{
          ...params.inputProps,
        }}
      />
    );
  }

  function renderOption(option: AutoCompleteLabel) {
    if (option.isSpecial) {
      return (
        <>
          <div className={styles.optionContentSpecial} data-tooltip-id='base-tooltip' data-tooltip-content={option.tooltip}>
            <span>{option.label} </span>
          </div>
          {option.hasDivider && <hr className={styles.divider} />}
        </>
      );
    }

    const isExpanded = getIsParentExpanded(option.name);

    const checked = getIsOptionChecked(option);
    const indeterminate = !checked && option.children?.some((child) => value.some((v) => v.name === child));
    const containerClassNames = classNames({
      [styles.optionContentLabel]: true,
      [styles.isParent]: !!option.parent,
    });
    const expandIconClassNames = classNames({
      [styles.expandIcon]: true,
      [styles.isExpanded]: isExpanded,
    });
    return (
      <div
        key={`${option.parent}-${option.name}`}
        className={containerClassNames}
        data-tooltip-id='base-tooltip'
        data-tooltip-content={option.tooltip}
      >
        <Checkbox key={option.name} indeterminate={!!indeterminate} checked={checked} />
        {option.parent === null && option.children && option.children.length > 0 && (
          <ExpandIcon className={expandIconClassNames} onClick={onParentExpandClick.bind(null, option.name, isExpanded)} />
        )}
        <span>{option.label} </span>({option.percentage?.toFixed(0)}%)
      </div>
    );
  }
}

function Checkbox({ indeterminate = false, checked = false }) {
  const checkboxRef = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (!checkboxRef.current) {
      return;
    }
    checkboxRef.current.indeterminate = indeterminate;
  }, [checkboxRef, indeterminate]);

  return <input type='checkbox' readOnly checked={checked} ref={checkboxRef} />;
}

function renderTags(tagValue: any, getTagProps: any) {
  return tagValue.map((option: any, index: number) => {
    const props = getTagProps({ index });
    const key = props.key;
    delete props.key;
    if (option.deleted) {
      return (
        <InvalidChip
          key={key}
          data-tooltip-id='base-tooltip'
          data-tooltip-content='This concept has been renamed or deleted.'
          label={option.label}
          size='small'
          {...props}
        />
      );
    }
    return (
      <Chip key={key} data-tooltip-id='base-tooltip' data-tooltip-content={option.label} label={option.label} size='small' {...props} />
    );
  });
}

export default LabelsAutocompleteControl;
