import styled from 'styled-components';
import { ReactComponent as closeIcon } from '../../../assets/icons/cross-icon.svg';
import TextField from '../../UI/TextField';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin: 16px;
  padding: 16px;

  > * {
    margin-bottom: 16px;
  }

  .fw {
    width: 100%;
  }
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Chip = styled.div<{ $boxColor: string }>`
  width: 100%;
  height: 45px;
  padding: 8px;
  border-radius: 4px;
  background-color: ${(props) => props.$boxColor};
  display: flex;
  flex-direction: row;
`;

export const ChipLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const ChipText = styled.div<{ $textColor: string }>`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-size: 14px;
  color: ${(props) => props.$textColor};
  margin-right: 4px;
  flex-grow: 1;
`;

export const ContactUsText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 700;
`;

export const UploadErrorText = styled.div`
  font-size: 14px;
  color: var(--dark-red);
  flex-grow: 1;
`;

export const UploadErrorContactUsText = styled(UploadErrorText)`
  flex-shrink: 0;
  margin-left: 12px;
`;

export const UploadErrorChipLine = styled(ChipLine)`
  align-items: flex-start;
`;

export const UploadErrorChip = styled(Chip)`
  padding: 16px;
  height: auto;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
`;

export const CloseIcon = styled(closeIcon)`
  cursor: pointer;
  color: var(--black);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--grey-transparent-10);
  }
`;

export const DialogTitle = styled.div`
  font-weight: bold;
  font-size: 32px;
`;

export const DialogSubtext = styled.div`
  font-size: 16px;
  color: var(--dark-gray);
  margin-bottom: 32px;
`;

export const DropdownElement = styled.div`
  display: flex;
  align-items: center;
`;

export const FullWidth = styled.div`
  width: 100%;
`;

export const StyledTextField = styled(TextField)`
  padding: 16px 10px;
  font-size: 16px;
  margin-bottom: 16px;
  width: 400px;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
  outline-color: var(--beehive-purple);
  align-self: start;

  .MuiOutlinedInput-notchedOutline {
    border-color: var(--beehive-purple);
  }
  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
    border-color: var(--beehive-purple);
  }
  .MuiOutlinedInput-input {
    padding: 12px 8px;
  }
`;
