import { useContext, useEffect, useState } from 'react';

import { NotificationsContext } from '../../../Notification/NotificationsContext';
import { SEVERITY } from '../../../Notification/types';
import { NOTIFICATIONS } from '../../../Notification/constants';
import { OperationTypeEnum, ProviderAuthentication, SurveySourceEnum } from '../../../../types/connections';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { createConnection } from '../../../../store/thunks/connectors';
import { clearLastAddedConnection, closeConnectionsModal } from '../../../../store/actions/connectors';
import { ScheduleEnum, ExportTypeEnum } from '../../types';

import useBrands from '../hooks/useBrands';
import useDashboards from '../hooks/useDashboards';
import useAzureFiles from '../hooks/useAzureFiles';
import useLanguages from '../hooks/useLanguages';
import ConnectAzure from './ConnectAzure';

interface IConnectAzureContainer {
  authentication: ProviderAuthentication;
  onUpdateError: (err: string) => void;
}

function ConnectAzureContainer({ authentication, onUpdateError }: IConnectAzureContainer) {
  const dispatch = useAppDispatch();
  const { addNotification } = useContext(NotificationsContext);

  const { lastAddedConnection, submittingConnection: isSubmittingConnection } = useAppSelector((state) => state.connectors).addConnection;

  const { brands, selectedBrand, setSelectedBrand } = useBrands(onUpdateError);
  const { dashboards, selectedDashboard, setSelectedDashboard } = useDashboards(onUpdateError);
  const { files, selectedFile, setSelectedFile } = useAzureFiles(onUpdateError, authentication);
  const { languages, selectedLanguage, setSelectedLanguage } = useLanguages(onUpdateError);

  const [operationType, setOperationType] = useState(OperationTypeEnum.IMPORT);
  const [scheduleStartDate, setScheduleStartDate] = useState('');
  const [updateInterval, setUpdateInterval] = useState(ScheduleEnum.ONE_TIME);
  const [selectedExportType, setSelectedExportType] = useState(ExportTypeEnum.Standard);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);

  const dashboardsProcessed = dashboards ? dashboards : [];

  function handleChangeOperationType(operationType: OperationTypeEnum) {
    setOperationType(operationType);
  }

  function handleSelectFile(file: string) {
    setSelectedFile(file);
  }

  function handleSelectBrand(brand: string) {
    setSelectedBrand(brand);
  }

  function handleSelectLanguage(language: string) {
    setSelectedLanguage(language);
  }

  function handleSelectDashboard(dashboard: string) {
    setSelectedDashboard(dashboard);
  }

  function handleChangeUpdateInterval(interval: ScheduleEnum) {
    setUpdateInterval(interval);
  }

  function handleSelectExportType(exportType: ExportTypeEnum) {
    setSelectedExportType(exportType);
  }

  async function handleCreateImportConnection() {
    const startDate = new Date(scheduleStartDate);
    startDate.setHours(24);
    const startTime = startDate.getTime() / 1000;
    dispatch(
      createConnection({
        operation: operationType,
        source: SurveySourceEnum.AZURE,
        config: {
          fileName: selectedFile,
          brandID: selectedBrand,
          startTime,
          updateInterval,
          language: selectedLanguage,
          authID: authentication.id,
        },
        startDate,
      }),
    );
  }

  async function handleCreateExportConnection() {
    const startDate = new Date(scheduleStartDate);
    startDate.setHours(24);
    const startTime = startDate.getTime() / 1000;
    dispatch(
      createConnection({
        operation: operationType,
        source: SurveySourceEnum.AZURE,
        config: {
          dashboardID: selectedDashboard,
          dashboardName: (dashboards as { id: string; title: string }[]).find(({ id }) => id === selectedDashboard)?.title ?? '',
          startTime,
          updateInterval,
          authID: authentication.id,
          exportType: selectedExportType,
        },
        startDate,
      }),
    );
  }

  useEffect(() => {
    if (lastAddedConnection?.source !== SurveySourceEnum.AZURE) {
      return;
    }
    dispatch(clearLastAddedConnection());

    // if successful, show a notification on the connectors home page
    // else show an error message in the modal
    if (lastAddedConnection.type === OperationTypeEnum.IMPORT) {
      if (lastAddedConnection.success) {
        addNotification({
          id: NOTIFICATIONS.AzureImportSuccess,
          title: `Import connection successfully created.`,
          severity: SEVERITY.SUCCESS,
        });
        dispatch(closeConnectionsModal());
        return;
      }

      onUpdateError('Failed to create import connection.');
      return;
    }

    if (lastAddedConnection.success) {
      addNotification({
        id: NOTIFICATIONS.AzureExportSuccess,
        title: `Export connection successfully created.`,
        severity: SEVERITY.SUCCESS,
      });
      dispatch(closeConnectionsModal());
      return;
    }
    onUpdateError('Failed to create export connection.');
  }, [addNotification, dispatch, lastAddedConnection, onUpdateError]);

  function handleSubmitConnection() {
    switch (operationType) {
      case OperationTypeEnum.IMPORT:
        handleCreateImportConnection();
        break;
      case OperationTypeEnum.EXPORT:
        handleCreateExportConnection();
        break;
      default:
        break;
    }
  }

  // disable submit if no file or dashboard selected
  useEffect(() => {
    switch (operationType) {
      case OperationTypeEnum.IMPORT:
        setIsSubmitDisabled(!selectedFile || !scheduleStartDate);
        break;
      case OperationTypeEnum.EXPORT:
        setIsSubmitDisabled(!selectedDashboard || !scheduleStartDate);
        break;
      default:
        break;
    }
  }, [operationType, selectedFile, selectedDashboard, scheduleStartDate]);

  return (
    <ConnectAzure
      operationType={operationType}
      files={files}
      brands={brands}
      dashboards={dashboardsProcessed}
      languages={languages}
      selectedFile={selectedFile}
      selectedBrand={selectedBrand}
      selectedLanguage={selectedLanguage}
      selectedDashboard={selectedDashboard}
      scheduleStartDate={scheduleStartDate}
      updateInterval={updateInterval}
      selectedExportType={selectedExportType}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmittingConnection ?? false}
      onChangeOperationType={handleChangeOperationType}
      onSelectFile={handleSelectFile}
      onSelectBrand={handleSelectBrand}
      onSelectLanguage={handleSelectLanguage}
      onSelectDashboard={handleSelectDashboard}
      onChangeUpdateInterval={handleChangeUpdateInterval}
      onSelectExportType={handleSelectExportType}
      onChangeScheduleStartDate={setScheduleStartDate}
      onSubmitConnection={handleSubmitConnection}
    />
  );
}

export default ConnectAzureContainer;
