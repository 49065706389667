export interface UserMapping {
  // Uniq Column name
  columnName: string;
  // Enum for possible column types
  // Have undefined state
  columnType: string;
  // Skip Column or no
  shouldSkip: boolean;
  // index of column
  columnIndex: number;
  // Rows examples
  rows: string[];

  // Only client field to show
  // that column was updated by user action
  byUserAction: boolean;
}

export enum MappingState {
  PREDICTING = 'PREDICTING',
  LOADING_MAPPING_STATE = 'LOADING_MAPPING_STATE',
  LOADING_TEMPLATES = 'LOADING_TEMPLATES',
  UPLOADING = 'UPLOADING',
  VALIDATING_DATE_FORMAT = 'VALIDATING_DATE_FORMAT',
  VALIDATING_MAPPING = 'VALIDATING_MAPPING',
  REQUESTING_TEMPLATE = 'REQUESTING_TEMPLATE',
  REPORTING_ISSUE = 'REPORTING_ISSUE',
  DEFAULT = 'DEFAULT',
}

export enum FileType {
  CSV = 'CSV',
  INTERVIEW = 'Interview',
}

export interface FileState {
  // File descriptor
  externalID: string;
  // Original File name
  fileName: string;
  // Name for tmp file
  // that was saved during the mapping
  tempFileName: string;
  // Filetype
  fileType: FileType;
  // Selected Template
  templateID: string | null;
  // Start row
  startRow: number | null;
  numberOfRows: number | null;
  // DateFormat
  dateFormat: string | null;
  // Did user choose to use custom date format for the file
  isCustomDateFormat: boolean;
  // Is the selected date format a valid date format (not necessarily valid for the file - use isMappingValid for that)
  isDateFormatValid: boolean | null;
  // whether prediction was called for file
  hasAlreadyPredicted: boolean;
  // Map of columns
  // Better to work with data as with Record
  // to easy way to get Column just by column name
  userMapping: Record<string, UserMapping>;
  // list of columns with date format errors
  dateFormatErrors: string[] | null;
  // Has the mapping been validated by the backend
  isMappingValid: boolean | null;
  // Was there error during prediction
  isErrorPredicting: boolean;
  // Speakers list for interview file types
  // Can be a moderator
  speakers?: { name: string; isModerator: boolean }[];
}

export interface Template {
  id: string;
  name: string;

  displayName: string;
  filetype: FileType;
  defaultStartRow: number;
}

export type MappingLoadingState = Partial<{
  [key in MappingState]: boolean;
}>;
