import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { setCsrfToken } from '../../contexts/api-routes';
import { APP_PATH } from '../../constants';
import { profileSetCompanies, profileSetHmac, profileSetUser } from '../../store/actions/profile';
import Loading from '../LoadingLabel/LoadingLabel';
import { eventsTracker } from '../../services/EventTrackerService';
import * as api from '../tools/api';
import CONFIG from '../../config';
import { withChatAI } from '../tools';

function handleWelcomeRedirect(userData, history) {
  if (!userData?.signup_finished) {
    history.push(APP_PATH.WELCOME);
    return true;
  } else if (window.location.pathname.includes(APP_PATH.WELCOME)) {
    history.push(APP_PATH.COMPANIES);
  }
  return false;
}

const AppInitializerWrapper = ({ children }) => {
  const { getAccessTokenSilently } = useAuth0();
  const { boot: intercomBoot } = useIntercom();
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    const prepare = async () => {
      // get auth0 token
      const bearerToken = await getAccessTokenSilently();
      const data = await api.fetchCsrfToken(bearerToken);
      setCsrfToken(data.csrf_token);
      const auth0UserInfo = await api.fetchUserInformation(bearerToken);
      const isInternal = Object.keys(auth0UserInfo).some((key) => key.endsWith('/internal') && auth0UserInfo[key]);
      const isAIAgentEnabled = Object.keys(auth0UserInfo).some((key) => key.endsWith('/ai_agent_enabled') && auth0UserInfo[key]);
      const profileUser = {
        isInternal,
        fullname: auth0UserInfo.name,
        email: auth0UserInfo.email,
        emailVerified: auth0UserInfo.email_verified,
        isAIAgentEnabled: isAIAgentEnabled || withChatAI(),
        avatarURL: auth0UserInfo.picture,
        id: auth0UserInfo.sub.slice(6), // to cut a prefix from the sub string "auth0|<id>"
      };
      dispatch(profileSetUser(profileUser));

      // get list of companies and user HMAC
      const userData = await api.fetchUser();
      dispatch(profileSetCompanies(userData?.companies || []));
      dispatch(profileSetHmac(userData?.user_hmac));

      const redirectToWelcome = handleWelcomeRedirect(userData, history);

      // disable tracking for internal users on production
      if (CONFIG.APP.ENV === 'production' && isInternal) {
        eventsTracker.disableTracking();
      } else {
        eventsTracker.enableTracking();
      }

      if (profileUser && userData) {
        eventsTracker.identify(profileUser.id);
        // Intercom
        intercomBoot({
          ...(profileUser.fullname !== profileUser.email && { name: profileUser.fullname }),
          email: profileUser.email,
          userHash: userData?.user_hmac,
          alignment: 'left',
        });
      }

      if (!redirectToWelcome) {
        if (userData?.edit_profile) {
          history.push(APP_PATH.PROFILE);
        }
      }

      setLoading(false);
    };
    prepare();
  }, [dispatch, getAccessTokenSilently, intercomBoot]);

  if (isLoading) {
    return <Loading />;
  }

  return children;
};

export default AppInitializerWrapper;
