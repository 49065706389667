import { AutocompleteChangeReason, AutocompleteInputChangeReason } from '@material-ui/lab';
import { debounce } from 'lodash';
import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react';
import LabelsAutocompleteControl from './LabelsAutocompleteControl';
import { AutoCompleteLabel } from './lib';
import {
  AutocompleteLabelsFilterRequest,
  AutocompleteLabelsFilterResponse,
  AutocompleteLabelsTransformRequest,
  AutocompleteLabelsTransformResponse,
  BaseResponseAction,
  filterOptionsRequestType,
  filterOptionsResponseType,
  transformToMapRequestType,
  transformToMapResponseType,
} from './types';

interface ILabelsAutocompleteControlContainer {
  options: AutoCompleteLabel[];
  value: string[];
  handleOnChange: (selectedOptions: string[]) => void;
  testID: string;
}

function LabelsAutocompleteControlContainer({ options, value, handleOnChange, testID, ...props }: ILabelsAutocompleteControlContainer) {
  const [expanded, setExpanded] = useState(new Set<string>());
  const [isLoading, setIsLoading] = useState(true);
  const [optionsByName, setOptionsByName] = useState();
  const [optionsToRender, setOptionsToRender] = useState(options);
  const [filterInputValue, setFilterInputValue] = useState('');
  const [enabled, setEnabled] = useState<string[]>([]);

  const selected = useMemo(
    () =>
      value
        ? value.map((name: string) => {
            const item = options.find((option) => option.name === name);
            if (item) {
              return item;
            }

            return {
              name,
              label: name,
              key: name,
              deleted: true,
            } as AutoCompleteLabel;
          })
        : [],
    [options, value],
  );

  const worker: Worker = useMemo(() => new Worker(new URL('./dataProcessorWorker.ts', import.meta.url)), []);

  useEffect(() => {
    if (window.Worker) {
      worker.onmessage = (e: MessageEvent<string>) => {
        const response = JSON.parse(e.data) as BaseResponseAction;
        console.debug('Component received message from Web Worker');

        switch (response.type) {
          case transformToMapResponseType: {
            const typedResponse = response as AutocompleteLabelsTransformResponse;
            setOptionsByName(typedResponse.data);
            _filterOptions({
              filterBy: filterInputValue,
              expanded: Array.from(expanded),
              options,
            });
            break;
          }
          case filterOptionsResponseType: {
            const typedResponse = response as AutocompleteLabelsFilterResponse;
            setOptionsToRender(typedResponse.data.filteredLabels);
            setEnabled(typedResponse.data.enabled);
            setIsLoading(typedResponse.isLoading);
            break;
          }
          default:
            console.warn(`Unknown message type was received from web worker: ${response.type}`);
        }
      };
    }
    return () => {
      if (window.Worker) {
        worker.terminate();
      }
    };
  }, [worker]);

  const filterOptionsDebounced = useCallback(
    debounce((params) => {
      setIsLoading(true);
      _filterOptions(params);
    }, 500),
    [],
  );

  function getIsOptionSelected(option: AutoCompleteLabel, value: AutoCompleteLabel) {
    return option.name === value.name;
  }

  function getOptionLabel(option: AutoCompleteLabel) {
    return option.isSpecial ? option.label : `${option.label} (${option.percentage?.toFixed(0)}%)`;
  }

  function handleChange(event: object, options: AutoCompleteLabel[], reason: AutocompleteChangeReason, details: any) {
    let selectedOptions: AutoCompleteLabel[];
    switch (reason) {
      case 'select-option':
        const selectedOption: AutoCompleteLabel = details.option;
        if (selectedOption.parent === null) {
          // remove children of the selected option from the selection
          selectedOptions = options.filter((option) => option.parent === null || selectedOption.name !== option.parent);
        } else {
          const selectedParent = options.find((option) => selectedOption.parent === option.name);
          if (selectedParent) {
            // remove parent and selected option from the list
            selectedOptions = options.filter((option) => option.name !== selectedOption.name && option.name !== selectedParent.name);
            // find other children of the parent and add them to the list
            if (selectedParent.children && optionsByName) {
              const children = selectedParent.children.filter((child) => child !== selectedOption.name);
              const childrenOptions = children.map((child) => optionsByName[child]);
              selectedOptions = [...selectedOptions, ...childrenOptions];
            }
          } else {
            selectedOptions = options;
            if (!optionsByName) {
              return;
            }
            const parent: AutoCompleteLabel = optionsByName[selectedOption.parent as string];
            // all children of the parent are selected
            if (parent.children?.every((child) => options.some((option) => option.name === child))) {
              // remove children and add parent
              selectedOptions = selectedOptions.filter((option) => !parent.children?.includes(option.name));
              selectedOptions = [...selectedOptions, parent];
            }
          }
        }
        break;
      case 'remove-option':
        selectedOptions = options;
        break;
      default:
        selectedOptions = [];
    }
    handleOnChange(selectedOptions.map((option) => option.name));
  }

  function getIsOptionChecked(option: AutoCompleteLabel) {
    return selected.some((c) => c.name === option.name || c.name === option.parent);
  }

  function getIsOptionDisabled(option: AutoCompleteLabel) {
    return !!enabled.length && !enabled.includes(option.name);
  }

  function handleInputChange(event: ChangeEvent<object>, value: string, reason: AutocompleteInputChangeReason) {
    if (reason === 'reset' && event?.type !== 'blur') {
      return;
    }

    let newInputValue = '';

    if (reason === 'input') {
      newInputValue = value.toLowerCase();
    } else {
      newInputValue = '';
    }

    setFilterInputValue(newInputValue);
    filterOptionsDebounced({
      filterBy: newInputValue,
      options,
      expanded: Array.from(expanded),
    });
  }

  function handleParentExpandClick(optionName: string, isExpanded: boolean, event: SyntheticEvent) {
    if (isExpanded) {
      expanded.delete(optionName);
    } else {
      expanded.add(optionName);
    }
    const newExpanded = new Set(expanded);
    setExpanded(newExpanded);
    _filterOptions({
      filterBy: filterInputValue,
      options,
      expanded: Array.from(newExpanded),
    });
    event.stopPropagation();
  }

  function handleOpen() {
    if (!optionsByName) {
      setIsLoading(true);
      _mapOptions({ options: optionsToRender });
    }
  }

  function handleClose() {
    setIsLoading(false);
  }

  function getIsParentExpanded(parentName: string) {
    return expanded.has(parentName);
  }

  return (
    <LabelsAutocompleteControl
      options={isLoading ? [] : optionsToRender}
      filterInputValue={filterInputValue}
      isLoading={isLoading}
      value={selected}
      testID={testID}
      onChange={handleChange}
      onInputChange={handleInputChange}
      getIsOptionDisabled={getIsOptionDisabled}
      getIsOptionSelected={getIsOptionSelected}
      onParentExpandClick={handleParentExpandClick}
      getOptionLabel={getOptionLabel}
      getIsParentExpanded={getIsParentExpanded}
      getIsOptionChecked={getIsOptionChecked}
      onDropdownOpen={handleOpen}
      onDropdownClose={handleClose}
      {...props}
    />
  );

  function _filterOptions({ filterBy, expanded, options }: AutocompleteLabelsFilterRequest['data']) {
    worker.postMessage(
      JSON.stringify({
        type: filterOptionsRequestType,
        data: {
          filterBy,
          expanded,
          options,
        },
      } as AutocompleteLabelsFilterRequest),
    );
  }

  function _mapOptions({ options }: AutocompleteLabelsTransformRequest['data']) {
    worker.postMessage(
      JSON.stringify({
        type: transformToMapRequestType,
        data: options,
      } as AutocompleteLabelsTransformRequest),
    );
  }
}

export default LabelsAutocompleteControlContainer;
