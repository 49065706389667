import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import styled from 'styled-components';
import FileUploadMappingErrorContainer from './FileUploadMappingErrorContainer';

export const Container = styled.div`
  width: 600px;
  height: 271px;
  display: flex;
  position: relative;
`;

interface IFileUploadingMappingError {
  onClose: () => void;
}

function FileUploadMappingErrorModal({ onClose }: IFileUploadingMappingError) {
  return (
    <Dialog open={true} maxWidth={false} onClose={onClose}>
      <Container>
        <FileUploadMappingErrorContainer onCloseButtonClick={onClose} />
      </Container>
    </Dialog>
  );
}

export default FileUploadMappingErrorModal;
