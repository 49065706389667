import AddIcon from '@material-ui/icons/Add';
import React, { useState } from 'react';

import AddDataDialog, { DATA_TYPES } from '../../pages/Programs/AddDataDialog';
import FileUploadDialogContainer from './components/FileUploadDialog/FileUploadDialogContainer';
import SuccessfulUploadDialog from './components/FileUploadMappingSuccess/SuccessfulUploadDialogContainer';

import FileUploadMappingModal from './components/FileUploadMapping/FileUploadMappingModal';
import FileUploadMappingErrorModal from './components/FileUploadMappingError/FileUploadMappingErrorModal';
import FileUploadMappingLoaderModal from './components/FileUploadMappingLoader/FileUploadMappingLoaderModal';
import { CreateNewButton } from './styles';

interface IFileUploadFlow {
  isNewDashboard: boolean;
  fileUploadButtonTitle: string;
  isAddDataDialogOpen: boolean;
  isFileUploadOpen: boolean;
  isSuccessfulUploadDialogOpen: boolean;
  isFailureUploadDialogOpen: boolean;
  isColumnsMappingLoading: boolean;
  mappedColumnsFileNames: string[];
  dashboardName?: string;
  dashboardID?: string;
  onCreateNewClick: () => void;
  onCreateNewClose: () => void;
  onAddDataSelect: (dataType: DATA_TYPES) => void;
  onFileUploadClose: () => void;
  onFileUploadSuccess: () => void;
  onFileMappingDialogClose: () => void;
}

function FileUploadFlow({
  dashboardID,
  dashboardName,
  fileUploadButtonTitle,
  isAddDataDialogOpen,
  isColumnsMappingLoading,
  isFailureUploadDialogOpen,
  isFileUploadOpen,
  isNewDashboard,
  isSuccessfulUploadDialogOpen,
  mappedColumnsFileNames,
  onCreateNewClick,
  onCreateNewClose,
  onAddDataSelect,
  onFileUploadClose,
  onFileUploadSuccess,
  onFileMappingDialogClose,
}: IFileUploadFlow) {
  const [uploadedFileNames, setUploadedFileNames] = useState<null | string[]>(null);
  const [isMappingFinished, setIsMappingFinished] = useState(false);

  function handleUploadSuccess(fileNames: string[]) {
    setUploadedFileNames(fileNames);
    onFileUploadSuccess();
  }

  function handleMappingFinish() {
    setUploadedFileNames(null);
    setIsMappingFinished(true);
  }

  function handleSuccessDialogClose() {
    setUploadedFileNames(null);
    onFileMappingDialogClose();
    setIsMappingFinished(false);
  }

  return (
    <>
      <CreateNewButton data-tf-popup='qPCQQ73Y' variant='contained' color='primary' onClick={onCreateNewClick} startIcon={<AddIcon />}>
        {fileUploadButtonTitle}
      </CreateNewButton>
      {isAddDataDialogOpen && <AddDataDialog onSelect={onAddDataSelect} onClose={onCreateNewClose} />}
      {isFileUploadOpen && (
        <FileUploadDialogContainer
          dashboardName={dashboardName}
          dashboardID={dashboardID}
          // TODO: rework dashboard privacy
          // shouldShowVisibilityMenu={isNewDashboard}
          shouldShowVisibilityMenu={false}
          onClose={onFileUploadClose}
          onUploadSuccess={handleUploadSuccess}
        />
      )}
      {!!uploadedFileNames && <FileUploadMappingModal onDialogClose={handleSuccessDialogClose} onMappingFinish={handleMappingFinish} />}
      {isColumnsMappingLoading && <FileUploadMappingLoaderModal onClose={handleSuccessDialogClose} />}
      {isMappingFinished && isSuccessfulUploadDialogOpen && (
        <SuccessfulUploadDialog fileNames={mappedColumnsFileNames} isNewDashboard={isNewDashboard} onClose={handleSuccessDialogClose} />
      )}
      {isFailureUploadDialogOpen && <FileUploadMappingErrorModal onClose={onFileMappingDialogClose} />}
    </>
  );
}

export default FileUploadFlow;
