import { useState } from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DeleteForeverOutlinedIcon from '@material-ui/icons/DeleteForeverOutlined';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Box, Checkbox, FormControlLabel } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import styled from 'styled-components';

import { CancelButton, CustomDialogActions, DeleteButton, SubmitButton } from './styles';
import { useAppSelector } from '../../store/hooks';

const TitleTextField = styled(TextField)`
  .MuiInputBase-root {
    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 26px;
    font-weight: 100;
  }
  .MuiInput-underline:before {
    border-bottom-style: dashed;
  }
`;

interface IHiveEditDialog {
  hive: any;
  dashboards: any[];
  onClose: () => void;
  onSave: (hive: any) => void;
  onDelete: (hiveID: string) => void;
}

const HiveEditDialog = ({ hive, dashboards, onClose, onSave, onDelete }: IHiveEditDialog) => {
  const isEditDialog = !!hive.id;
  const { company, user } = useAppSelector((state) => state.profile);
  const [dashboardIDs, setDashboardIDs] = useState(hive && hive.dashboardIDs ? [...hive.dashboardIDs] : []);
  const [title, setTitle] = useState(isEditDialog ? hive.title : '');
  const [disabled, setDisabled] = useState(hive.title === undefined || hive.title.length === 0);
  const [isPrivate, setIsPrivate] = useState(isEditDialog ? hive.private : false);
  const dialogTitle = isEditDialog ? 'Edit hive' : 'Create hive';
  const isOwnerOrAdmin = user?.id === hive.userID || company?.admin;
  const deleteTooltip = !isOwnerOrAdmin ? 'A hive may only be removed by its creator or a workspace admin.' : '';

  const handleDbClick = (dashboardID: string) => {
    if (dashboardIDs.includes(dashboardID)) {
      setDashboardIDs(dashboardIDs.filter((id) => id !== dashboardID));
    } else {
      setDashboardIDs([...dashboardIDs, dashboardID]);
    }
  };

  const handleSaveClick = () => {
    onSave({
      ...hive,
      dashboardIDs,
      title,
      private: isPrivate,
    });
  };

  const handleTitleChange = (event: any) => {
    setTitle(event.target.value);
    setDisabled(event.target.value.trim().length === 0);
  };

  return (
    <Dialog maxWidth='md' fullWidth onClose={onClose} open={true}>
      <DialogTitle>
        <span style={{ fontSize: '26px', fontFamily: 'Rubik', fontWeight: '100' }}>{dialogTitle}</span>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row'>
          <Grid item xs={4}>
            <TitleTextField value={title} onChange={handleTitleChange} fullWidth placeholder='Untitled' />
          </Grid>
          <Grid item xs={2} />
          {/* TODO: rework dashboard privacy */}
          {/* {(isOwnerOrAdmin || !isEditDialog) && (
            <Grid item xs={6}>
              <Grid container direction='row'>
                <Grid item xs={8} style={{ fontSize: '16px', fontWeight: '100' }}>
                  <p style={{ textDecoration: 'underline' }}>Make private</p>
                  When a hive is set to private, it can only be viewed by the hive creator (you)
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={3} style={{ display: 'flex', alignItems: 'center' }}>
                  <FormControlLabel
                    control={<Switch checked={isPrivate} onChange={() => setIsPrivate(!isPrivate)} color='primary' />}
                    label='Private'
                  />
                </Grid>
              </Grid>
            </Grid>
          )} */}
        </Grid>
        <Typography variant='h5' style={{ margin: '20px 0' }}>
          <span style={{ fontSize: '26px', fontFamily: 'Rubik', fontWeight: '100' }}>Select dashboards</span>
        </Typography>
        <Box
          style={{
            display: 'grid',
            gap: '16px',
            gridTemplateColumns: 'repeat(3, 1fr)',
            height: '350px',
            gridAutoRows: 'min-content',
            overflowY: 'auto',
          }}
        >
          {dashboards.map((dashboard) => (
            <Box
              key={dashboard.id}
              style={{
                height: dashboardIDs.includes(dashboard.id) ? '84px' : '88px',
                display: 'flex',
                alignItems: 'center',
                padding: dashboardIDs.includes(dashboard.id) ? '0' : '0 2px',
                border: '1px solid #e3e3f5',
                borderRadius: '8px',
                cursor: 'pointer',
                borderWidth: dashboardIDs.includes(dashboard.id) ? '3px' : '1px',
                minWidth: '0',
              }}
              onClick={() => handleDbClick(dashboard.id)}
            >
              <Box>
                <Checkbox checked={dashboardIDs.includes(dashboard.id)} color='primary' />
              </Box>
              <Box>
                <Avatar alt={dashboard.brandName} src={dashboard.logo} />
              </Box>
              <Typography noWrap style={{ padding: '0 10px' }} data-tooltip-id='base-tooltip' data-tooltip-content={dashboard.title}>
                {dashboard.title}
              </Typography>
            </Box>
          ))}
        </Box>
      </DialogContent>
      <CustomDialogActions>
        <Box>
          {isEditDialog && (
            <Box data-tooltip-id='base-tooltip' data-tooltip-content={deleteTooltip}>
              <DeleteButton
                variant='outlined'
                disabled={!isOwnerOrAdmin}
                startIcon={<DeleteForeverOutlinedIcon />}
                onClick={() => onDelete(hive.id)}
              >
                Delete
              </DeleteButton>
            </Box>
          )}
        </Box>
        <Box>
          <CancelButton onClick={onClose}>Cancel</CancelButton>
          <SubmitButton variant='outlined' disabled={disabled} onClick={handleSaveClick}>
            Save
          </SubmitButton>
        </Box>
      </CustomDialogActions>
    </Dialog>
  );
};

export default HiveEditDialog;
