import React, { useHistory } from 'react-router-dom';

import CongratulationsPage from './CongratulationsPage';
import { APP_PATH } from '../../../constants';

function CongratulationsPageContainer() {
  const history = useHistory();

  function handleGoToWorkspace() {
    history.push(APP_PATH.COMPANIES);
  }

  return <CongratulationsPage onGoToWorkspaceClick={handleGoToWorkspace} />;
}

export default CongratulationsPageContainer;
