import * as echarts from 'echarts/core';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart, LineChart, CustomChart } from 'echarts/charts';
import {
  GridSimpleComponent,
  GraphicComponent,
  TooltipComponent,
  TitleComponent,
  LegendComponent,
  LegendScrollComponent,
  DatasetComponent,
  TransformComponent,
  ToolboxComponent,
  MarkPointComponent,
} from 'echarts/components';

import { CanvasRenderer } from 'echarts/renderers';
import { transform } from './transformers/movingAverage';

echarts.use([
  CanvasRenderer,

  TitleComponent,
  TooltipComponent,
  GridSimpleComponent,
  GraphicComponent,
  LegendComponent,
  LegendScrollComponent,
  DatasetComponent,
  TransformComponent,
  ToolboxComponent,
  MarkPointComponent,

  BarChart,
  LineChart,
  CustomChart,
]);

echarts.registerTransform(transform);

const Chart = ({ option, eventHandlers = {}, ...props }) => {
  return <ReactEChartsCore option={option} echarts={echarts} onEvents={eventHandlers} notMerge={true} {...props} />;
};

export default Chart;
