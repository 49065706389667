import React, { useRef } from 'react';

import { Survey } from '../../store/reducers/dashboard/types';
import { SURVEY_STATUS, SURVEY_STATUS_CONFIG } from '../../constants';
import {
  HiveStatusDetailedViewContainer,
  HiveStatusDetailedViewHeader,
  HiveStatusDetailedViewProgramName,
  HiveStatusDetailedViewProgramRow,
  HiveStatusDetailedViewProgramStatus,
  HiveStatusDetailedViewTooltip,
  HiveStatusProgramsTotal,
  InfoIcon,
} from './styles';

interface IHiveStatus {
  status: string;
  surveys: Survey[];
  onToggleDetailedView: (shouldShowDetailedView: boolean, surveys: Survey[], top: number, left: number) => void;
}

function HiveStatus({ status, surveys, onToggleDetailedView }: IHiveStatus) {
  const elRef = useRef<HTMLSpanElement | null>(null);

  return <DashboardStatus status={status} />;

  function handleDetailedViewToggle(shouldShowDetailedView: boolean) {
    if (!elRef?.current) {
      return;
    }
    const { top, left } = (elRef.current as HTMLSpanElement).getBoundingClientRect();

    // only show the detailed view if there are surveys
    onToggleDetailedView(shouldShowDetailedView && !!surveys.length, surveys, top, left);
  }

  function DashboardStatus({ status }: { status: string }) {
    if (!(status in SURVEY_STATUS_CONFIG)) {
      status = SURVEY_STATUS.CONFIGURATION;
    }

    const { color, title } = SURVEY_STATUS_CONFIG[status];

    return (
      <span ref={elRef} onMouseEnter={handleDetailedViewToggle.bind(null, true)}>
        <span style={{ color }}>●</span> {title} <InfoIcon />
      </span>
    );
  }
}

interface IHiveStatusDetailedView {
  surveys: Survey[];
  top: number;
  left: number;
  onMouseLeave: () => void;
}
export function HiveStatusDetailedView({ surveys, top, left, onMouseLeave }: IHiveStatusDetailedView) {
  function ProgramRow({ survey: { status, title: name } }: { survey: Survey }) {
    function Status() {
      if (!(status in SURVEY_STATUS_CONFIG)) {
        status = SURVEY_STATUS.CONFIGURATION;
      }
      const { color, title } = SURVEY_STATUS_CONFIG[status];
      return (
        <HiveStatusDetailedViewProgramStatus>
          <span style={{ color }}>●</span> {title}
        </HiveStatusDetailedViewProgramStatus>
      );
    }

    return (
      <HiveStatusDetailedViewProgramRow>
        <HiveStatusDetailedViewProgramName title={name}>{name}</HiveStatusDetailedViewProgramName>
        <Status />
      </HiveStatusDetailedViewProgramRow>
    );
  }

  return (
    <HiveStatusDetailedViewTooltip $top={top} $left={left} onMouseLeave={onMouseLeave}>
      <HiveStatusDetailedViewContainer>
        <HiveStatusDetailedViewHeader>
          Data Sources
          <HiveStatusProgramsTotal>{surveys.length}</HiveStatusProgramsTotal>
        </HiveStatusDetailedViewHeader>
        {surveys.map((survey) => (
          <ProgramRow key={survey.id} survey={survey} />
        ))}
      </HiveStatusDetailedViewContainer>
    </HiveStatusDetailedViewTooltip>
  );
}

export default HiveStatus;
