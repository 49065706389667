import * as api from '../../components/tools/api';

import { Action } from '../hooks';
import {
  profileSetCompanies,
  profileSetCreateWorkspaceState,
  profileSetUser,
  profileSetUserProfile,
  profileSetUserProfleFetchState,
  profileSetUserProfleUpdateState,
} from '../actions/profile';
import { Company, User, UserProfile } from '../reducers/profile';
import { FETCH_STATUS_STATE } from '../../constants';

interface ICreateWorkspace {
  firstName: string;
  lastName: string;
  password: string;
}
export const createWorkspace =
  ({ firstName, lastName, password }: ICreateWorkspace): Action =>
  async (dispatch) => {
    dispatch(profileSetCreateWorkspaceState(FETCH_STATUS_STATE.LOADING));
    try {
      const res = await api.createWorkspace({ first_name: firstName, last_name: lastName, password: password });
      const companies = res as Company[];
      dispatch(profileSetCompanies(companies));
      dispatch(profileSetCreateWorkspaceState(FETCH_STATUS_STATE.LOADED));
    } catch (error) {
      const errorStatus = (error as any)?.status;
      const isPasswordWrong = errorStatus === 444;
      dispatch(profileSetCreateWorkspaceState(FETCH_STATUS_STATE.ERROR, isPasswordWrong));
    }
  };

export const fetchUserProfile = (): Action => async (dispatch) => {
  dispatch(profileSetUserProfleFetchState(FETCH_STATUS_STATE.LOADING));
  try {
    const profile = await api.fetchProfile();
    dispatch(profileSetUserProfile(profile));
    dispatch(profileSetUserProfleFetchState(FETCH_STATUS_STATE.LOADED));
  } catch (error) {
    dispatch(profileSetUserProfleFetchState(FETCH_STATUS_STATE.ERROR));
  }
};

export const updateUserProfile =
  (userProfle: UserProfile): Action =>
  async (dispatch, getState) => {
    const user = getState().profile.user;

    dispatch(profileSetUserProfleUpdateState(FETCH_STATUS_STATE.LOADING));
    try {
      if (!user) {
        dispatch(profileSetUserProfleUpdateState(FETCH_STATUS_STATE.ERROR));
      }

      await api.updateProfile(userProfle);
      dispatch(
        profileSetUser({
          ...(user as User),
          fullname: `${userProfle.firstName} ${userProfle.lastName}`,
        }),
      );
      dispatch(profileSetUserProfleUpdateState(FETCH_STATUS_STATE.LOADED));
    } catch (error) {
      dispatch(profileSetUserProfleUpdateState(FETCH_STATUS_STATE.ERROR));
    }
  };
