export const PERCENT_DECIMAL_NUM = 0;

export const DATA_SOURCE_TYPE = {
  OTHER: 'Other',
  OPEN_ENDED: 'OpenEnded',
  INTERVIEW: 'Interview',
  MULTI_CHOICE: 'MultiChoice',
};

export const DASHBOARD_TEMPLATE_ID = {
  BASIC: 'BASIC',
};

export type FETCH_STATUS_TYPE = 'IDLE' | 'LOADING' | 'LOADED' | 'ERROR';

export enum FETCH_STATUS_STATE {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADED = 'LOADED',
  ERROR = 'ERROR',
}

export const FETCH_STATUS: Record<FETCH_STATUS_TYPE, FETCH_STATUS_TYPE> = {
  IDLE: 'IDLE',
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

export const FILTER_ID_ALL = '___all___filter___id___';
export const FILTER_ID_CURRENT = '___current___filter___id___';

export const FILTER_ID_AVERAGE = '___average_filter_id___';
export const FILTER_NAME_AVERAGE = 'Average';

export const LOGOUT_URL = 'https://app.beehive.ai';

export const DEFAULT_WIDGET_HEIGHT = 24;

export const FILTER_TYPE = {
  INCLUDES: 'includes',
  EXCLUDES: 'excludes',
  SENTIMENT: 'sentiment',
};

export const WIDGET_HEIGHT = {
  DEFAULT: 24,
  COLLAPSED: 3,
  DEFAULT_OPEN_ENDED: 35,
  DEFAULT_INTERVIEW: 35,
  EXPANDED: 47,
};

export const TESTS_URL_PARAMS = {
  UNSAFE: '_unsafe',
  DEBUG: '_debug',
  VALIDATION: '_validation',
  CHAT_AI: 'chatai',
};

export const LOG_MESSAGE_TYPE = {
  TOO_MANY_ITEMS: 'TOO_MANY_ITEMS',
  TOTAL_PERCENTAGE_IS_OVER_100: 'TOTAL_PERCENTAGE_IS_OVER_100',
  LABEL_WITH_MULTIPLE_PARENTS: 'LABEL_WITH_TWO_PARENTS',
  LABEL_IS_USED_AS_A_PARENT_AND_A_CHILD: 'LABEL_IS_A_PARENT_AND_A_CHILD',
  INVALID_SAVED_FILTER: 'INVALID_SAVED_FILTER',
  INVALID_WIDGET_CONFIGURATION: 'INVALID_WIDGET_CONFIGURATION',
  INVALID_WIDGET_FILTER: 'INVALID_WIDGET_FILTER',
  DETACHED_WIDGET: 'DETACHED_WIDGET',
  JAVASCRIPT_ERROR: 'JAVASCRIPT_ERROR',
  API_ERROR: 'API_ERROR',
  VALIDATION_COMPLETE: 'VALIDATION_COMPLETE',
};

export const LOG_MESSAGE_LEVEL = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
};

export const DASHBOARD_SORT_FIELDS = {
  FILTERED_TOTAL: 'filteredTotal',
  DATE_CREATED: 'createdAt',
  LATEST_UPDATED_RESPONSE: 'latestUpdatedResponse',
};

export const DASHBOARD_SORT_FIELD_NAME = {
  [DASHBOARD_SORT_FIELDS.FILTERED_TOTAL]: 'Responses',
  [DASHBOARD_SORT_FIELDS.DATE_CREATED]: 'Created',
  [DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE]: 'Last update',
};

export const SORT_ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const FRACTION_DIGITS_TO_CHECK_IN_PERCENTAGE = 0;
export const TRENDS_ITEM_ALL_KEY = '__all__values__';
export const TRENDS_ITEM_TOP_5_KEY = '__top_5_value__';
export const TRENDS_ITEM_TOP_10_KEY = '__top_10_value__';

export const DATE_FORMAT_API = 'yyyy-MM-dd';
export const DATE_FORMAT_DISPLAY = 'MM/dd/yyyy';

export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const PERIOD = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  QUARTER: 'quarter',
};

export const MOVING_AVERAGE_WINDOW_SIZE = 7;
export const AVERAGE_POSTFIX = '__average';
export const SESSION_ID = 'bh_session_id';

export const WIDGET_TYPE = {
  OVERVIEW: 'overview',
  OPEN_ENDED: 'openEnded',
  INTERVIEW: 'interview',
  BARS: 'bars',
  TRENDS: 'trends',
};

export const FIXED_SIZE_WIDGETS = [WIDGET_TYPE.INTERVIEW, WIDGET_TYPE.OPEN_ENDED];

export const SURVEY_STATUS = {
  CONFIGURATION: 'configuration',
  REVIEW: 'review',
  ANALYZING: 'analyzing',
  ACTIVE: 'active',
};

export const SURVEY_STATUS_CONFIG = {
  [SURVEY_STATUS.CONFIGURATION]: {
    color: '#ff7900',
    title: 'Analyzing & Verifying', // the same as analyzing from the client's perspective
  },
  [SURVEY_STATUS.REVIEW]: {
    color: '#fcca3d',
    title: 'Review',
  },
  [SURVEY_STATUS.ANALYZING]: {
    color: '#ff7900',
    title: 'Analyzing & Verifying',
  },
  [SURVEY_STATUS.ACTIVE]: {
    color: '#229e46',
    title: 'Live',
  },
};

export const SURVEY_STATUSES_ORDERED = [SURVEY_STATUS.CONFIGURATION, SURVEY_STATUS.REVIEW, SURVEY_STATUS.ANALYZING, SURVEY_STATUS.ACTIVE];

export const FADE_TIMEOUT = 350;

export const GROUP_OTHER_TITLE = 'Other';

export const APP_PATH = {
  SIGN_OUT: '/signout',
  CONGRATULATIONS: '/signup/congratulations',
  WELCOME: '/signup/welcome',
  COMPANY: '/company/:company',
  PROFILE: '/profile',
  FREE_TIER_NOT_AVAILABLE: '/free-tier-not-available',
  COMPANIES: '/',
};

export const getURLForCompany = (companyName: string) => APP_PATH.COMPANY.replace(':company', companyName);
