import BaseWidgetContainer from '../BaseWidgetContainer';
import WidgetSettings from './Settings/WidgetSettings';
import { WidgetMenuWithTrendsOption } from '../../WidgetMenu/WidgetMenu';
import OpenEndedWidgetContent from './OpenEndedWidgetContent';

const OpenEndedWidgetSettings = (props) => {
  return <WidgetSettings {...props} hideConfig={true} />;
};

const OpenEndedWidget = ({ config, height, onDimensionsChange, groupTitle }) => {
  return (
    <BaseWidgetContainer
      config={config}
      height={height}
      groupTitle={groupTitle}
      onDimensionsChange={onDimensionsChange}
      SettingsComponent={OpenEndedWidgetSettings}
      MenuComponent={WidgetMenuWithTrendsOption}
      ContentComponent={OpenEndedWidgetContent}
    />
  );
};

export default OpenEndedWidget;
