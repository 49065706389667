import React, { ForwardedRef } from 'react';
import styled from 'styled-components';
import { ProgressIndicator } from '../../../../../../components/ProgressIndicator/ProgressIndicator';
import { FailureIcon } from '../../../../../../components/Widgets/OpenEndedWidget/Data/AIInsights/Components/styles';
import { FAILURE_STATUSES } from '../../../../../../store/reducers/dashboardQueries/reducer';
import { DashboardQuery, QueryStatus } from '../../../../../../types/DashboardQuery';
import BaseItemWithAvatar from './BaseItemWithAvatar';

const STATUS_TO_TEXT: Record<QueryStatus, string> = {
  [QueryStatus.ANALYZING_DATA_STATUS]: 'Analyzing...',
  [QueryStatus.COLLECTING_DATA_STATUS]: 'Collecting data...',
  [QueryStatus.GENERATING_ANSWER_STATUS]: 'Generating your answer...',
  [QueryStatus.SUCCESS_STATUS]: '',
  [QueryStatus.FAILURE_STATUS]: "The request can't be processed at the moment, please, try again later",
  [QueryStatus.CANCEL_STATUS]: 'You have cancelled the request',
  [QueryStatus.CANCELING_STATUS]: 'Cancelling...',
};
const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  margin-top: 10px;
`;

interface IQueryInProcess {
  query: DashboardQuery;
}
function QueryInProcess({ query }: IQueryInProcess, ref: ForwardedRef<HTMLDivElement>) {
  const Icon = FAILURE_STATUSES.includes(query.status) ? FailureIcon : ProgressIndicator;
  return (
    <BaseItemWithAvatar ref={ref}>
      <Container>
        <Icon />
        {STATUS_TO_TEXT[query.status]}
      </Container>
    </BaseItemWithAvatar>
  );
}

export default React.forwardRef(QueryInProcess);
