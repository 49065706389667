import React from 'react';
import { Filter } from '../../../../types/Config';
import { numberWithCommas } from '../../../tools';
import WidgetFilterMenu from '../../WidgetFilterMenu';
import SentimentStatus from './Components/Sentiment';
import { Counter, OpenEndedWidgetStatusPanelContainer } from './Components/style';
import WidgetElement from './Components/WidgetElement';
import { SentimentSummary } from './StatusPanelContainer';

interface IOpenEndedWidgetStatusPanel {
  widgetFilter: Filter;
  widgetTitle: string;
  widgetType: string;
  groupTitle: string;
  isWidgetFilterApplied: boolean;
  filteredTotal: number;
  answeredCount: number;
  sentimentSummary: SentimentSummary;
  total: number;
  hasSentiment: boolean;
  weightedTotal: number;
  weightedMetricsUsed: boolean;
  percentage: number;
  onFilterApply: (filter: Filter) => void;
}

function OpenEndedWidgetStatusPanel({
  widgetFilter,
  widgetTitle,
  widgetType,
  groupTitle,
  filteredTotal,
  isWidgetFilterApplied,
  answeredCount,
  sentimentSummary,
  total,
  hasSentiment,
  weightedTotal,
  weightedMetricsUsed,
  percentage,
  onFilterApply,
}: IOpenEndedWidgetStatusPanel) {
  const shouldRenderSentiment = hasSentiment && sentimentSummary;
  return (
    <OpenEndedWidgetStatusPanelContainer data-testid='status-panel'>
      {isWidgetFilterApplied && (
        <WidgetElement
          title='Filtered Total'
          isHighlighted={true}
          renderOptionalIcon={renderFilteredTotalIcon}
          renderChild={renderFilteredTotal}
        ></WidgetElement>
      )}

      <WidgetElement title='Total' renderChild={renderAnswered} />

      {weightedMetricsUsed && <WidgetElement title='Weighted Total' dataTestId='weighted-container' renderChild={renderWeighted} />}

      <WidgetElement title='Coverage' renderChild={renderAnalysedAnswers} />

      {shouldRenderSentiment && (
        <WidgetElement title='Sentiment' dataTestId='sentiment-statuses-container' renderChild={renderSentimentAnalysis} />
      )}
    </OpenEndedWidgetStatusPanelContainer>
  );

  function renderFilteredTotalIcon() {
    return (
      <div>
        <WidgetFilterMenu
          widgetFilter={widgetFilter}
          widgetTitle={widgetTitle}
          widgetType={widgetType}
          onFilterApply={onFilterApply}
          groupTitle={groupTitle}
        />
      </div>
    );
  }

  function renderFilteredTotal() {
    return <Counter>{numberWithCommas(filteredTotal)}</Counter>;
  }

  function renderAnswered() {
    return <Counter data-testid='answered'>{numberWithCommas(answeredCount)}</Counter>;
  }

  function renderWeighted() {
    return <Counter data-testid='weighted-total'>{numberWithCommas(weightedTotal)}</Counter>;
  }

  function renderAnalysedAnswers() {
    return <Counter data-testid='coverage'>{percentage}%</Counter>;
  }

  function renderSentimentAnalysis() {
    return <SentimentStatus sentimentSummary={sentimentSummary} total={total} />;
  }
}

export default OpenEndedWidgetStatusPanel;
