import React from 'react';
import InfoModal from '../../../../../Modals/InfoModal/InfoModal';
import PromptModal from '../../../../../Modals/PromptModal/PromptModal';
import { RequestBeehiveRow, SupportLink } from '../../styles';
import { FLOW_STATES } from './RequestBeehiveContainer';

interface IReportIssue {
  flowState: FLOW_STATES;
  onReportIssueButtonClick: () => void;
  onCancelButtonClick: () => void;
  onSendButtonClick: (message: string) => void;
}

function RequestBeehive({ flowState, onReportIssueButtonClick, onCancelButtonClick, onSendButtonClick }: IReportIssue) {
  return (
    <>
      <RequestBeehiveRow>
        Your data source not listed? <SupportLink onClick={onReportIssueButtonClick}>Request Beehive</SupportLink> to add the template
      </RequestBeehiveRow>
      {flowState === FLOW_STATES.PROMPT && (
        <PromptModal
          headerText='Request Template'
          placeholder='Where is your file from?'
          cancelButtonText='Cancel'
          mainActionButtonText='Send'
          onCancelButtonClick={onCancelButtonClick}
          onMainActionButtonClick={onSendButtonClick}
        />
      )}
      {flowState === FLOW_STATES.FINISH && (
        <InfoModal
          headerText='Thank you!'
          bodyText='Your message was sent successfully. We will be in touch with you when the template is available.'
          mainActionButtonText='OK'
          onCancelButtonClick={onCancelButtonClick}
          onMainActionButtonClick={onCancelButtonClick}
        />
      )}
    </>
  );
}

export default RequestBeehive;
