import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { Box } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ReactCountryFlag } from 'react-country-flag';
import styled from 'styled-components';

import palette from '../../components/tools/palette';
import { numberWithCommas } from '../../components/tools';
import UpdateDashboardButton from './UpdateDashboardButton';
import { filterSetQuery } from '../../store/actions/dashboardFilter';
import { COMBINATOR, OPERATOR_NAME } from '../../components/FilterForm/constants';
import { createDateRule, createGroup } from '../../components/FilterForm/lib';
import { DASHBOARD_SORT_FIELDS, SURVEY_STATUS, SURVEY_STATUS_CONFIG } from '../../constants';
import { eventsTracker, EVENTS, EVENT_PROPERTY_DASHBOARD_NAME } from '../../services/EventTrackerService';
import { Dashboard } from '../../store/reducers/dashboards/types';
import { useAppSelector } from '../../store/hooks';
import { ReactComponent as LockIcon } from '../../assets/icons/lock-icon.svg';
import DashboardStatus from './HiveStatus';
import { Survey } from '../../store/reducers/dashboard/types';
import { shouldShowProcessingAsResponseCount } from './utils/utils';

const DashboardGrid = styled(Grid)<{ $muted: boolean }>`
  & {
    background-color: #fff;
    border: 1px solid ${palette.lightBlue};
    border-radius: 8px;
    padding: 0 10px;
    height: 88px;
    opacity: ${(props) => (props.$muted ? '0.6' : '1')};
  }
`;

const DashboardCellGrid = styled(Grid)`
  & {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const DashboardLink = styled(Link)`
  min-width: 0;
  width: 100%;
  color: ${palette.primaryColorDark};
  text-decoration: none;
  display: flex;
  text-overflow: ellipsis;
  overflow: hidden;
  font-weight: bold;
  white-space: nowrap;
`;

const FlagsContainer = styled(Box)`
  display: flex;
  flex-grow: 1;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 100%;
  align-items: center;
  justify-content: center;
  font-size: 18px;
`;

const LockIconStyled = styled(LockIcon)`
  margin-left: 8px;
  width: 16px;
  height: 16px;
`;

const CountryItem = ({ countryCode }: { countryCode: string }) => {
  const regionNames = Intl && Intl.DisplayNames && new Intl.DisplayNames(['en'], { type: 'region' });
  const countryName = regionNames && regionNames.of(countryCode);
  return (
    <span data-tooltip-id='base-tooltip' data-tooltip-content={countryName}>
      <ReactCountryFlag svg countryCode={countryCode} style={{ marginRight: '5px' }} aria-label={countryName} />
    </span>
  );
};

const timestamp = (ts: string): string => {
  if (!ts) {
    return '';
  }
  // Safari couldn't parse timestamp from the BE, so date is recreated from the timestamp elements
  const d = ts.split(/[^0-9]/);
  const currentDate = new Date(Number(d[0]), Number(d[1]) - 1, Number(d[2]), Number(d[3]), Number(d[4]));
  return currentDate.toLocaleDateString();
};

interface IHiveRow {
  dashboard: Dashboard;
  onToggleDetailedStatusView: (shouldShowDetailedView: boolean, surveys: Survey[], top: number, left: number) => void;
}

const HiveRow = ({ dashboard, onToggleDetailedStatusView }: IHiveRow) => {
  const { company } = useAppSelector((state) => state.profile);
  const { dateRange } = useAppSelector((state) => state.dashboards);
  const dispatch = useDispatch();

  const handleLinkClick = (dashboard: Dashboard) => {
    eventsTracker.track(EVENTS.DASHBOARD_SELECT, {
      [EVENT_PROPERTY_DASHBOARD_NAME]: dashboard.title,
      'Date Filter': !!dateRange,
      Status: dashboard.status in SURVEY_STATUS_CONFIG ? SURVEY_STATUS_CONFIG[dashboard.status].title : dashboard.status,
    });
    if (dateRange) {
      const dataRangeGroup = createGroup(COMBINATOR.AND, [
        createDateRule(OPERATOR_NAME.GREATER_THAN_OR_EQUAL_TO, dateRange.startDate),
        createDateRule(OPERATOR_NAME.LESS_THAN_OR_EQUAL_TO, dateRange.endDate),
      ]);
      dispatch(filterSetQuery(createGroup(COMBINATOR.AND, [dataRangeGroup])));
    }
  };

  const DashboardLastUpdated = ({ status }: { status: string }) => {
    if (!(status in SURVEY_STATUS_CONFIG)) {
      return timestamp(dashboard.createdAt);
    }
    return timestamp(dashboard[DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE as keyof Dashboard]);
  };

  const DashboardResponses = ({ status }: { status: string }) => {
    if (shouldShowProcessingAsResponseCount(status, dashboard)) {
      return 'processing';
    }
    return numberWithCommas(dashboard.filteredTotal);
  };

  const muted =
    dashboard.filteredTotal === 0 && (dashboard.status === SURVEY_STATUS.ACTIVE || dashboard.status === SURVEY_STATUS.ANALYZING);

  return (
    <DashboardGrid container direction='row' $muted={muted}>
      <DashboardCellGrid item xs={4} style={{ justifyContent: 'left', paddingLeft: '10px' }}>
        {/* Removed as not many brands had Avatars, so it looked sloppy */}
        {/* <DashboardAvatar alt={dashboard.brandName} src={dashboard.logo}/> */}
        <DashboardLink
          data-testid='dashboard-link'
          data-dashboard-title={dashboard.title}
          onClick={() => handleLinkClick(dashboard)}
          href='#'
          to={{
            pathname: `/company/${company?.name}/dashboard/${dashboard.id}`,
            state: {
              title: dashboard.title,
            },
          }}
          color='primary'
        >
          <span>{dashboard.title}</span>
          {/* TODO: rework dahsboard privacy */}
          {/* {dashboard.isPrivate && (
            <LockIconStyled data-testid='hive-row-private-icon' data-tooltip-id='base-tooltip' data-tooltip-content='Private analysis' />
          )} */}
        </DashboardLink>
      </DashboardCellGrid>
      <DashboardCellGrid item xs={2} style={{ justifyContent: 'left' }}>
        <DashboardStatus status={dashboard.status} surveys={dashboard.surveys} onToggleDetailedView={onToggleDetailedStatusView} />
      </DashboardCellGrid>
      <DashboardCellGrid item xs={2}>
        <DashboardResponses status={dashboard.status} />
      </DashboardCellGrid>
      <DashboardCellGrid item xs={1}>
        {timestamp(dashboard.createdAt)}
      </DashboardCellGrid>
      <DashboardCellGrid item xs={1}>
        <DashboardLastUpdated status={dashboard.status} />
      </DashboardCellGrid>
      <DashboardCellGrid item xs={1} style={{ height: '100%' }}>
        <FlagsContainer>
          {dashboard.countries && dashboard.countries.map((countryCode) => <CountryItem key={countryCode} countryCode={countryCode} />)}
        </FlagsContainer>
      </DashboardCellGrid>
      <DashboardCellGrid item xs={1}>
        <UpdateDashboardButton title={dashboard.title} />
      </DashboardCellGrid>
    </DashboardGrid>
  );
};

export default HiveRow;
