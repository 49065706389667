import { useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FormControl, Grid, MenuItem, Select, TextField } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import capitalize from 'lodash/capitalize';

import { DATA_SOURCE_TYPE, WIDGET_TYPE } from '../../../../constants';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import WidgetLabelsAutocompleteControl from '../../../UI/LabelsAutocompleteControl/WidgetLabelsAutocompleteControl';
import {
  prepareBarsWidgetConfig,
  prepareInterviewWidgetConfig,
  prepareOpenEndedWidgetConfig,
  prepareTrendsWidgetConfig,
} from './prepareConfig';
import { OutlinedButton } from '../../../UI/Button';
import { dashboardAddWidget } from '../../../../store/thunks/dashboard';

const DEFAULT_GROUP_TITLE = 'Default';

const AddSingleWidget = ({ type, onClose }) => {
  const { translationUsed } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  const widgetGroups = useSelector((state) => state.dashboard.widgetGroups);
  const { id: dashboardID, dataSources: dashboardDataSources } = useSelector((state) => state.dashboard.dashboard);
  const [title, setTitle] = useState('');
  const [dataSourceID, setDataSourceID] = useState('');
  const [selectedItems, setSelectedItems] = useState([]);
  const [widgetGroupTitle, setWidgetGroupTitle] = useState('');
  const prevTypeRef = useRef(type);

  const titleField = translationUsed ? 'title' : 'originalTitle';

  if (prevTypeRef.current !== type) {
    setDataSourceID('');
    setTitle('');
    setSelectedItems([]);
    prevTypeRef.current = type;
  }

  const visibleDataSources = useMemo(() => {
    return dashboardDataSources.filter((dataSource) => {
      if (!dataSource.visible) {
        return false;
      }
      if (type === WIDGET_TYPE.BARS) {
        return dataSource.type !== DATA_SOURCE_TYPE.OPEN_ENDED;
      }
      if (type === WIDGET_TYPE.OPEN_ENDED) {
        return dataSource.type === DATA_SOURCE_TYPE.OPEN_ENDED;
      }
      if (type === WIDGET_TYPE.INTERVIEW) {
        return dataSource.type === DATA_SOURCE_TYPE.INTERVIEW;
      }

      return true;
    });
  }, [dashboardDataSources, type]);

  const dataSourceTitleById = useMemo(() => {
    return dashboardDataSources.reduce(
      (acc, dataSource) => ({
        ...acc,
        [dataSource.dataSourceID]: dataSource[titleField],
      }),
      {},
    );
  }, [dashboardDataSources, titleField]);

  const groupTitles = useMemo(() => {
    return widgetGroups
      .filter((widgetGroup) => !widgetGroup.isOverview)
      .map((widgetGroup) => {
        if (widgetGroup.isDefault) {
          return DEFAULT_GROUP_TITLE;
        }
        return widgetGroup[titleField];
      });
  }, [widgetGroups, titleField]);

  const disabled = !dataSourceID || title === undefined || title.trim().length === 0 || widgetGroupTitle.trim().length === 0;

  const handleTitleChange = (event) => {
    setTitle(event.target.value);
  };

  const handleDataSourceChange = (event) => {
    setTitle(dataSourceTitleById[event.target.value]);
    setSelectedItems([]);
    setDataSourceID(event.target.value);
  };

  const handleGroupChange = (_, value) => {
    setWidgetGroupTitle(value ?? '');
  };

  const handleGroupValueChange = (event) => {
    setWidgetGroupTitle(event.target.value ?? '');
  };

  const config = useMemo(() => {
    switch (type) {
      case WIDGET_TYPE.TRENDS:
        return prepareTrendsWidgetConfig({
          dashboardID,
          dataSourceID,
          selectedItems,
          title,
        });
      case WIDGET_TYPE.OPEN_ENDED:
        return prepareOpenEndedWidgetConfig({
          dashboardID,
          dataSourceID,
          selectedItems,
          title,
        });
      case WIDGET_TYPE.INTERVIEW:
        return prepareInterviewWidgetConfig({
          dashboardID,
          dataSourceID,
          selectedItems,
          title,
        });
      default:
        return prepareBarsWidgetConfig({
          dashboardID,
          dataSourceID,
          selectedItems,
          title,
        });
    }
  }, [dashboardID, dataSourceID, selectedItems, type, title]);

  const handleSave = () => {
    const widgetGroup = widgetGroups.find((widgetGroup) => {
      return widgetGroup[titleField] === widgetGroupTitle || (widgetGroup.isDefault && widgetGroupTitle === DEFAULT_GROUP_TITLE);
    });
    const widgetGroupID = widgetGroup ? widgetGroup.id : null;

    eventsTracker.track(EVENTS.ADD_NEW_WIDGET_SAVE, {
      'Widget Name': title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      'Item Count': config.base.type === WIDGET_TYPE.TRENDS ? selectedItems.length : null,
      'Group Name': widgetGroupTitle,
    });

    dispatch(dashboardAddWidget(config, widgetGroupID, widgetGroupTitle));
    onClose();
  };

  return (
    <Grid container direction='column' style={{ height: '100%' }}>
      <Grid item style={{ flex: 1 }} xs={11}>
        <Grid container direction='row' spacing={1} style={{ marginBottom: '8px' }}>
          <Grid item xs={6}>
            <TextField
              variant='outlined'
              fullWidth
              size='small'
              value={title}
              onChange={handleTitleChange}
              data-testid='widget-title'
              placeholder='Name widget…'
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl variant='outlined' size='small' fullWidth>
              <Select
                value={dataSourceID}
                onChange={handleDataSourceChange}
                fullWidth
                displayEmpty
                data-testid='select-data-source'
                renderValue={(id) => (id !== '' ? dataSourceTitleById[id] : <span style={{ opacity: 0.42 }}>Select Source</span>)}
              >
                {visibleDataSources.map((dataSource) => (
                  <MenuItem key={dataSource.dataSourceID} value={dataSource.dataSourceID}>
                    {translationUsed ? dataSource.title : dataSource.originalTitle}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              fullWidth
              freeSolo
              autoComplete={true}
              onChange={handleGroupChange}
              onInputChange={handleGroupValueChange}
              options={groupTitles}
              renderInput={(params) => (
                <TextField {...params} fullWidth variant='outlined' size='small' placeholder='Group' data-testid='group-name' />
              )}
            />
          </Grid>
        </Grid>
        <WidgetLabelsAutocompleteControl
          dataSourceID={dataSourceID}
          value={selectedItems}
          onChange={(items) => setSelectedItems(items)}
          disabled={!dataSourceID}
          enableEmpty
        />
        {/* BDTC-3766: Hide all widget previews in "Add to dashboard" pop-up */}
        {/* {!!dataSourceID && <Box display="flex" flexDirection="column" height="400px" marginTop="8px" >
                {type === WIDGET_TYPE.BARS && <WidgetPreviewBars config={config} key={dataSourceID} /> }
                {type === WIDGET_TYPE.OPEN_ENDED && <WidgetPreviewOpenEnded config={config} key={dataSourceID}/> }
                {type === WIDGET_TYPE.TRENDS && <WidgetPreviewTrends config={config} key={dataSourceID}/> }
            </Box>} */}
      </Grid>
      <Grid item style={{ marginTop: '10px', textAlign: 'right' }}>
        <OutlinedButton data-testid='save-button' disabled={disabled} onClick={handleSave}>
          Add
        </OutlinedButton>
      </Grid>
    </Grid>
  );
};

export default AddSingleWidget;
