import '@fontsource/rubik/300.css';
import React, { Component, useEffect } from 'react';
import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';

import store from './store/store';
import InnerApp from './InnerApp';
import HttpsRedirect from './Https';
import { hotjar } from 'react-hotjar';
import { datadogLogs } from '@datadog/browser-logs';
import { IntercomProvider } from 'react-use-intercom';
import { LOG_MESSAGE_TYPE } from './constants';
import { TestsProvider } from './contexts/TestsContext';
import CONFIG from './config';
import AppTooltip from './components/UI/AppTooltip';
import { NotificationsProvider } from './components/Notification/NotificationsContext';

const App = () => {
  useEffect(() => {
    // Init hotjar tracking
    hotjar.initialize(CONFIG.HOTJAR.ID, CONFIG.HOTJAR.VERSION);

    datadogLogs.init({
      clientToken: CONFIG.DATADOG.CLIENT_TOKEN,
      version: CONFIG.APP.VERSION,
      service: `app-client-${CONFIG.APP.ENV}`,
      site: 'datadoghq.com',
      forwardErrorsToLogs: true,
      sampleRate: 100,
      beforeSend: (log) => {
        // discard ResizeObserver error
        if (log.message && (log.message.startsWith('ResizeObserver loop') || log.message === 'Script error.')) {
          return false;
        }
        // log 3rd party errors as "info"
        if (
          log.status === 'error' &&
          log.message &&
          (log.message.startsWith('XHR error POST') || log.message.startsWith('XHR error GET'))
        ) {
          datadogLogs.logger.info(log.message);
          return false;
        }

        if (log.error && log.error.origin === 'source') {
          // get state directly from the store to avoid re-rendering App component on changes
          const state = store.getState();
          log.message = `[Javascript Error][${log.message}]`;
          datadogLogs.addLoggerGlobalContext('appState', { ...state.app });
          datadogLogs.addLoggerGlobalContext('type', LOG_MESSAGE_TYPE.JAVASCRIPT_ERROR);
        } else if (log.error && log.error.origin === 'network') {
          if (log.error.stack.startsWith('AbortError')) {
            datadogLogs.logger.info(log.message);
            return false;
          }
          log.message = `[API Error][${log.message}]`;
          datadogLogs.removeLoggerGlobalContext('appState');
          datadogLogs.addLoggerGlobalContext('type', LOG_MESSAGE_TYPE.API_ERROR);
        } else {
          datadogLogs.removeLoggerGlobalContext('appState');
          datadogLogs.removeLoggerGlobalContext('type');
        }
      },
    });
    if (!['production', 'staging'].includes(CONFIG.APP.ENV)) {
      datadogLogs.logger.setHandler('console');
    }
  }, []);

  return (
    <IntercomProvider appId={CONFIG.INTERCOM.APP_ID}>
      <Provider store={store}>
        <TestsProvider>
          <NotificationsProvider>
            <Router>
              <InnerApp />
            </Router>
            <AppTooltip id='base-tooltip' />
          </NotificationsProvider>
        </TestsProvider>
      </Provider>
    </IntercomProvider>
  );
};

/**
 * Redirect to SSL
 * @return {html} code
 */
export default class HttpsApp extends Component {
  render() {
    return (
      <HttpsRedirect>
        <App />
      </HttpsRedirect>
    );
  }
}
