import React from 'react';
import styled from 'styled-components';

import { Select } from '../../UI/Select';

import FileUploadChip from './FileUploadChip';
import { FileStatus } from '../types';

export const StyledSelect = styled(Select)`
  flex-grow: 1;
  border: 1px solid var(--medium-gray);
  border-radius: var(--double-border-radius);
  margin-left: 16px;
`;

interface IFileUploadSurvey {
  fileName: string;
  status: FileStatus;
  onDeleteFile: (fileName: string) => void;
}

function FileUploadSurvey({ fileName, status, onDeleteFile }: IFileUploadSurvey) {
  return <FileUploadChip fileName={fileName} status={status} handleDeleteFile={onDeleteFile} />;
}

export default FileUploadSurvey;
