import styled from 'styled-components';
import { ReactComponent as CheckIcon } from '../../../../../../../assets/icons/check-circle-icon.svg';

export const TextResponseContainer = styled.div``;
export const FeedbackContainer = styled.div`
  padding: 16px 18px;
  gap: 16px;
  display: flex;
  flex-direction: column;
`;

export const ThumbsContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const TellUsMoreContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: flex-start;
  justify-content: stretch;
`;

export const TextContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: var(--white);

  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: var(--double-border-radius);
`;

export const UserNameContainer = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
  line-height: 120%;
`;

export const ResponseContainer = styled.div`
  text-overflow: ellipsis;
  word-wrap: break-word;
  white-space: break-spaces;
  max-width: 700px;
`;

export const FeedbackSent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 8px;
  color: var(--dark-purple);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const CheckIconStyled = styled(CheckIcon)`
  fill: var(--dark-purple);
  width: 18px;
  height: 18px;
`;
