import React from 'react';
import { useIntercom } from 'react-use-intercom';

import UploadInDashboard from './UploadInDashboard';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';

interface IUploadInDashboardContainer {
  dashboardName: string;
  dashboardID: string;
  isCloseable: boolean;
  onReturnToHivesClick: () => void;
  onExploreMyDataClick: () => void;
  onCloseClick: () => void;
}

function UploadInDashboardContainer({
  dashboardName,
  dashboardID,
  isCloseable,
  onReturnToHivesClick,
  onExploreMyDataClick,
  onCloseClick,
}: IUploadInDashboardContainer) {
  const { showNewMessages } = useIntercom();

  function handleGetSupportClick() {
    showNewMessages();
  }

  function handleAddMoreDataClick() {
    // TODO: Fix callback to FileUploadFlow
    eventsTracker.track(EVENTS.FiLE_UPLOAD_BUTTON_SELECT, {
      'Button location': 'Dashboard Status Page',
    });
  }

  return (
    <UploadInDashboard
      dashboardName={dashboardName}
      dashboardID={dashboardID}
      isCloseable={isCloseable}
      onAddMoreDataClick={handleAddMoreDataClick}
      onReturnToHivesClick={onReturnToHivesClick}
      onExploreMyDataClick={onExploreMyDataClick}
      onCloseClick={onCloseClick}
      onGetSupportClick={handleGetSupportClick}
    />
  );
}

export default UploadInDashboardContainer;
