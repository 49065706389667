import { useContext, useEffect, useMemo, useState } from 'react';
import { Concept } from '../../../../../store/reducers/aiInsights';

import { Label } from '../../../../../types/Label';
import { Filter } from '../../../../../types/Query';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { dashboardCreateLabel, dashboardDeleteLabel, dashboardRenameLabel } from '../../../../../store/thunks/dashboard';
import { dashboardLabelStateReset } from '../../../../../store/actions/dashboard';

import Topics from './Topics';
import { SortingQuery } from '../../../../../types/SortingQuery';
import { NotificationsContext } from '../../../../Notification/NotificationsContext';
import { SEVERITY } from '../../../../Notification/types';
import { LabelOperationType } from '../../../../../store/reducers/dashboard/types';

export interface TopicsSorting {
  sortBy: string;
  desc: boolean;
}

interface ITopicsContainer {
  dataSourceID: string;
  widgetID: string;
  labels: Label[];
  filtersById: {
    [p: string]: Filter;
  };
  selectedFilterIds: string[];
  clickable: boolean;
  disabled: boolean;
  selectedLabelItem: Concept | null;
  topicsSorting: SortingQuery;
  average?: boolean;
  onLabelClick: (label: string | null, withScroll: boolean) => void;
  onTopicsSortingChange: (sorting: SortingQuery) => void;
}

function TopicsContainer({
  dataSourceID,
  widgetID,
  labels,
  filtersById,
  selectedFilterIds,
  clickable,
  disabled,
  selectedLabelItem,
  topicsSorting,
  average,
  onLabelClick,
  onTopicsSortingChange,
}: ITopicsContainer) {
  const dispatch = useAppDispatch();
  const { addNotification } = useContext(NotificationsContext);
  const { labelsStateByDataSourceId: labelsState } = useAppSelector((state) => state.dashboard);
  // part of buisness logic and affected by labelsState changes
  const [isCreatingTopic, setIsCreatingTopic] = useState(false);
  const [isDeletingTopic, setIsDeletingTopic] = useState(false);
  const [topic, setTopic] = useState<string>('');

  const isTopicManagingLoading = useMemo(() => {
    if (topic) {
      if (labelsState[dataSourceID] && labelsState[dataSourceID][topic]) {
        return labelsState[dataSourceID][topic].isLoading;
      }
      return false;
    }
    return false;
  }, [labelsState, dataSourceID]);

  function handleErrors(operation: LabelOperationType) {
    let title;
    switch (operation) {
      case LabelOperationType.Create:
        title = `There was a problem creating the topic "${topic}"`;
        break;
      case LabelOperationType.Delete:
        title = `There was a problem deleting the topic "${topic}"`;
        break;
      case LabelOperationType.Rename:
        title = `There was a problem renaming the topic "${topic}"`;
        break;

      default:
        title = 'Something went wrong';
        break;
    }
    addNotification({
      id: `error-${dataSourceID}-${topic}`,
      title: title,
      severity: SEVERITY.ERROR,
      duration: 5000,
      persistent: false,
    });

    handleCleanupState();
  }

  function handleOperationsSuccess(operation: LabelOperationType) {
    if (!topic) {
      handleCleanupState();
      return;
    }

    switch (operation) {
      case LabelOperationType.Delete:
        if (selectedLabelItem?.title === topic) {
          onLabelClick(null, false);
        }
        break;

      default:
        break;
    }
    handleCleanupState();
  }

  function handleCleanupState() {
    setTopic('');
    setIsCreatingTopic(false);
    setIsDeletingTopic(false);
  }

  // Handling LabelState
  useEffect(() => {
    if (labelsState[dataSourceID] && labelsState[dataSourceID][topic]) {
      const operation = labelsState[dataSourceID][topic].operation;
      if (labelsState[dataSourceID][topic].isError) {
        if (operation) {
          handleErrors(operation);
        }
        dispatch(dashboardLabelStateReset({ dataSourceID, label: topic }));
        return;
      }

      if (labelsState[dataSourceID][topic].isDoneSuccessful) {
        if (operation) {
          handleOperationsSuccess(operation);
        }
        dispatch(dashboardLabelStateReset({ dataSourceID, label: topic }));
        return;
      }
    }
  }, [labelsState, dataSourceID, dispatch, addNotification]);

  function handleSortingChange(e: React.ChangeEvent<{ name?: string; value: unknown }>) {
    onTopicsSortingChange({
      sortBy: e.target.value as string,
      desc: topicsSorting.desc,
    });
  }

  function handleSortingDirectionChange(desc: boolean) {
    onTopicsSortingChange({
      ...topicsSorting,
      desc: desc,
    });
  }

  function handleTopicCreatingModeChange(close: boolean) {
    if (isTopicManagingLoading) return;
    setIsCreatingTopic(!close);
    setTopic('');
  }

  function handleTopicCreate(newTopic: string) {
    if (labels.find((x) => x.title === newTopic)) {
      setIsCreatingTopic(false);
      onLabelClick(newTopic, false);
      return;
    }

    dispatch(dashboardCreateLabel({ dataSourceID: dataSourceID, label: newTopic }));
    setTopic(newTopic);
  }

  function handleRenameTopicStart(label: string) {
    if (isTopicManagingLoading) return;

    setIsCreatingTopic(false);
    setTopic(label);
  }

  function handleRenameTopicCancel() {
    setTopic('');
  }

  function handleRenameTopic(newTopic: string) {
    if (newTopic && topic && topic !== newTopic) {
      dispatch(dashboardRenameLabel({ dataSourceID, widgetID: widgetID, originalLabel: topic, newLabel: newTopic }));
      setTopic(newTopic);
    }
  }

  function handleDeleteTopicStart(deletingTopic: string) {
    if (isTopicManagingLoading) return;
    setTopic(deletingTopic);
    setIsDeletingTopic(true);
    setIsCreatingTopic(false);
  }

  function handleDeleteTopicCancel() {
    setTopic('');
    setIsDeletingTopic(false);
  }

  function handleDeleteTopic() {
    if (topic) {
      dispatch(dashboardDeleteLabel({ dataSourceID, widgetID: widgetID, label: topic }));
    }
  }

  return (
    <Topics
      labels={labels}
      filtersById={filtersById}
      selectedFilterIds={selectedFilterIds}
      clickable={clickable}
      disabled={disabled}
      selectedLabelItem={selectedLabelItem}
      topicsSorting={topicsSorting}
      isCreatingTopic={isCreatingTopic}
      isTopicManagingLoading={isTopicManagingLoading}
      topic={topic}
      isTopicDeleteConfirmationOpen={isDeletingTopic}
      average={average}
      onLabelClick={onLabelClick}
      onSortingChange={handleSortingChange}
      onSortingDirectionChange={handleSortingDirectionChange}
      onTopicCreatingModeChange={handleTopicCreatingModeChange}
      onTopicCreate={handleTopicCreate}
      onTopicRenameClick={handleRenameTopicStart}
      onTopicRenameCancel={handleRenameTopicCancel}
      onTopicRename={handleRenameTopic}
      onTopicDeleteClick={handleDeleteTopicStart}
      onTopicDeleteCancel={handleDeleteTopicCancel}
      onTopicDelete={handleDeleteTopic}
    />
  );
}

export default TopicsContainer;
