import React from 'react';
import styled from 'styled-components';

import { ReactComponent as FileIcon } from '../../../assets/icons/file-icon.svg';
import { ReactComponent as closeIcon } from '../../../assets/icons/cross-icon.svg';
import { ReactComponent as CheckIcon } from '../../../assets/icons/check-icon.svg';

import { Centered, Chip, ChipLine, ChipText } from './styles';
import { FileStatus } from '../types';

const StyledFileIcon = styled(FileIcon)`
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  margin-right: 8px;
  fill: var(--beehive-purple);
`;

const StyledChip = styled(Chip)`
  min-width: 260px;
  max-width: 260px;
  flex-direction: column;
  justify-content: center;
`;

const BubbleClosedCircle = styled(Centered)`
  background-color: var(--beehive-purple);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  flex-shrink: 0;
  transition: background-color 0.3s ease-in-out;
  &:hover {
    background-color: var(--dark-purple);
  }
`;

const BubbleClosedCross = styled(closeIcon)`
  color: var(--white);
  cursor: pointer;
  width: 14px;
  height: 14px;
`;

const BubbleClosedCheck = styled(CheckIcon)`
  fill: var(--white);
  width: 8px;
  height: 8px;
`;

interface IFileUploadChip {
  fileName: string;
  status: FileStatus;
  handleDeleteFile: (fileName: string) => void;
}

function FileUploadChip({ fileName, status, handleDeleteFile }: IFileUploadChip) {
  return (
    <StyledChip $boxColor='var(--beehive-purple-transparent-10)' data-testid={`chip-${fileName}`}>
      <ChipLine>
        <StyledFileIcon />
        <ChipText $textColor='var(--dark-purple)'> {fileName} </ChipText>
        <BubbleClosedCircle>
          {status === FileStatus.UPLOADED ? (
            <BubbleClosedCheck />
          ) : (
            <BubbleClosedCross data-testid={`chip-delete-${fileName}`} onClick={handleDeleteFile.bind(null, fileName)} />
          )}
        </BubbleClosedCircle>
      </ChipLine>
    </StyledChip>
  );
}

export default FileUploadChip;
