import React from 'react';
import InfoModal from '../../../../../Modals/InfoModal/InfoModal';
import { HaveExpertMapFilesButton } from '../../styles';
import { FLOW_STATES } from './HaveExpertMapFilesContainer';

interface IReportIssue {
  flowState: FLOW_STATES;
  onHaveExpertMapFilesButtonClick: () => void;
  onCancelButtonClick: () => void;
  onAcceptButtonClick: () => void;
}

function HaveExpertMapFiles({ flowState, onHaveExpertMapFilesButtonClick, onCancelButtonClick, onAcceptButtonClick }: IReportIssue) {
  return (
    <>
      <HaveExpertMapFilesButton onClick={onHaveExpertMapFilesButtonClick}>Have a Beehive Expert Map Your Files</HaveExpertMapFilesButton>
      {flowState === FLOW_STATES.PROMPT && (
        <InfoModal
          headerText='Have a Beehive Expert Map Your Files?'
          bodyText={
            <>
              This may delay the analysis of your data, but our experts will begin mapping your files as soon as possible. <br />
              <br /> Would you like to continue?
            </>
          }
          mainActionButtonText='Yes, Request Expert Mapping'
          onCancelButtonClick={onCancelButtonClick}
          onMainActionButtonClick={onAcceptButtonClick}
          withCancelButton={true}
          cancelButtonText='Cancel'
        />
      )}
    </>
  );
}

export default HaveExpertMapFiles;
