import { useCallback, useEffect, useState } from 'react';

import { useAppSelector } from '../../../store/hooks';

import AddConnection from './AddConnection';

interface IAddConnectionContainer {
  isEditingAuth: boolean;
}

function AddConnectionContainer({ isEditingAuth }: IAddConnectionContainer) {
  const [error, setError] = useState('');
  const integration = useAppSelector((state) => state.connectors).selectedIntegration;
  const auth = integration?.authentication;

  const handleUpdateError = useCallback((err: string) => {
    setError(err);
  }, []);

  useEffect(() => {
    // Reset only if there are no error was setted
    if (!auth?.error) {
      setError('');
    }
  }, [auth, isEditingAuth]);

  return (
    <AddConnection isEditingAuth={isEditingAuth} integration={integration} auth={auth} error={error} onUpdateError={handleUpdateError} />
  );
}

export default AddConnectionContainer;
