import React, { ForwardedRef, useEffect, useImperativeHandle, useRef } from 'react';
import styled from 'styled-components';
import { FAILURE_STATUSES, FINISHED_STATUSES } from '../../../../../store/reducers/dashboardQueries/reducer';
import { User } from '../../../../../store/reducers/profile';
import { DashboardQuery, QueryStatus, QueryType } from '../../../../../types/DashboardQuery';
import QueryInProcess from './QueryItems/QueryInProcess';
import TextQuestion from './QueryItems/TextQuestion';
import TextResponseContainer from './QueryItems/TextResponse/TextResponseContainer';

const Container = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  gap: 16px;
  height: 100%;
  overflow: auto;
`;

interface IQueriesList {
  queries: DashboardQuery[];
  user: User;
  onScrollToggle: (didReachTop: boolean, didReachBottom: boolean) => void;
  onUserFeedbackToggle: () => void;
}

export interface IQueriesListRef {
  scrollToBottom: () => void;
}

function QueriesList({ queries, user, onScrollToggle, onUserFeedbackToggle }: IQueriesList, ref: ForwardedRef<IQueriesListRef>) {
  // USE REF
  const queriesContainerRef = useRef<HTMLDivElement | null>(null);
  const queriesEndRef = useRef<HTMLDivElement | null>(null);

  // USE EFFECT
  useEffect(() => {
    if (queriesContainerRef.current) {
      updateScrollPositionInfo(queriesContainerRef.current);
    } else {
      return;
    }

    if (queries.length === 2) {
      queriesContainerRef.current?.scrollTo({
        top: queriesEndRef.current?.offsetTop,
        behavior: 'instant',
      });
    }
  }, [queriesEndRef?.current]);

  // HANDLERS
  useImperativeHandle(
    ref,
    () => ({
      scrollToBottom() {
        queriesContainerRef.current?.scrollTo({
          top: queriesEndRef.current?.offsetHeight,
          behavior: 'smooth',
        });
      },
    }),
    [],
  );

  function updateScrollPositionInfo(divEl: HTMLDivElement) {
    onScrollToggle(divEl.offsetHeight + 0.5 - divEl.scrollTop >= divEl.scrollHeight, divEl.scrollTop >= 0);
  }

  function handleScrollEvent(e: React.UIEvent<HTMLDivElement>) {
    const divEl = e.target as HTMLDivElement;
    updateScrollPositionInfo(divEl);
  }

  function handleUserFeedbackToggle(isFirst: boolean) {
    if (!isFirst) {
      return;
    }
    onUserFeedbackToggle();
  }

  return (
    <Container ref={queriesContainerRef} onScroll={handleScrollEvent}>
      {queries.map((q, idx) => {
        if (q.status === QueryStatus.SUCCESS_STATUS) {
          switch (q.queryType) {
            case QueryType.TEXT_QUESTION:
              return <TextQuestion key={q.id} ref={idx === 0 ? queriesEndRef : null} user={user} query={q} />;
            case QueryType.TEXT_RESPONSE:
              return (
                <TextResponseContainer
                  key={q.id}
                  ref={idx === 0 ? queriesEndRef : null}
                  query={q}
                  onUserFeedbackToggle={handleUserFeedbackToggle.bind(null, idx === 0)}
                />
              );
          }
        } else if (!FINISHED_STATUSES.includes(q.status) || FAILURE_STATUSES.includes(q.status)) {
          return <QueryInProcess key={q.id} ref={idx === 0 ? queriesEndRef : null} query={q} />;
        }
      })}
    </Container>
  );
}

export default React.forwardRef(QueriesList);
