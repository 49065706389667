import styled from 'styled-components';

import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-icon.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/cross-icon.svg';
import { ReactComponent as HyperlinkIcon } from '../../../../assets/icons/hyperlink-icon.svg';
import { ReactComponent as AnswersStartingRowHelp } from '../../../../assets/images/answers-starting-row-info.svg';
import { ReactComponent as DateFormatHelp } from '../../../../assets/images/date-format-info.svg';
import { ReactComponent as GhostColumns } from '../../../../assets/images/file-mapping-ghost-columns.svg';
import { ReactComponent as GhostDropdowns } from '../../../../assets/images/file-mapping-ghost-dropdowns.svg';
import { ReactComponent as SelectTemplateHelp } from '../../../../assets/images/select-template-info.svg';
import { TextButton } from '../../../UI/Button';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 32px;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 32px;
  right: 32px;
`;

export const CheckIconStyled = styled(CheckIcon)`
  width: 14px;
  height: 14px;
  fill: var(--white);
`;

export const CrossIcon = styled(CloseIcon)`
  width: 24px;
  height: 24px;
`;

export const CloseIconStyled = styled(CrossIcon)`
  cursor: pointer;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Background = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

export const Header = styled.div`
  color: var(--black);
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;

export const HeaderSubtitle = styled.div`
  color: var(--dark-gray);
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const ActiveFileName = styled.div`
  margin-top: 20px;
  color: var(--black);
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

export const MappingConfigurationContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: 24px;
  position: static;
  z-index: 1;
`;

const ColumnsMappingBase = styled.div`
  margin-top: 12px;
  min-height: 448px;
`;

export const GhostColumnsContainer = styled(ColumnsMappingBase)`
  position: relative;
`;

export const GhostColumnsStyled = styled(GhostColumns)`
  width: 100%;
`;

export const GhostDropdownsStyled = styled(GhostDropdowns)`
  width: 100%;
`;

export const FileInfoPopup = styled.div`
  position: absolute;
  right: 50%;
  top: 40%;
  display: flex;
  width: 815px;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 32px;
  background-color: var(--white);
  transform: translate(50%, -50%);
  border-radius: var(--double-border-radius);
  border: 1px solid var(--medium-gray);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(2px);
  font-size: 20px;
`;

export const FileSkipTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const LoadingTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ColumnsMappingContainer = styled(ColumnsMappingBase)<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  padding: 16px;

  ${({ $isActive }) => {
    if ($isActive) {
      return `
      border-radius: var(--double-border-radius);
      background-color: var(--beehive-purple-transparent-30);
      `;
    }
  }}
`;

export const RequestBeehiveRow = styled.div`
  display: flex;
  height: 40px;
  padding: 12px 16px;
  align-items: center;
  align-content: center;
  gap: 5px;
  align-self: stretch;
  border-top: 1px solid var(--medium-gray);
`;

export const SupportLink = styled.span`
  color: var(--dark-purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration-line: underline;
  cursor: pointer;
`;

export const ColumnsMappingHeader = styled.div`
  color: var(--black);
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 6px;
`;

export const HyperlinkIconStyled = styled(HyperlinkIcon)`
  position: relative;
  fill: var(--black);
  cursor: pointer;
`;

export const SelectTemplateHelpStyled = styled(SelectTemplateHelp)`
  position: absolute;
  width: 488px;
  height: 235px;
  z-index: 99999;
`;

export const AnswersStartingRowHelpStyled = styled(AnswersStartingRowHelp)`
  position: absolute;
  width: 533px;
  height: 220px;
  z-index: 99999;
`;

export const DateFormatHelpStyled = styled(DateFormatHelp)`
  position: absolute;
  width: 488px;
  height: 380px;
  z-index: 99999;
`;

export const ColumnsContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-top: 20px;
  padding-left: 2px;
  padding-right: 2px;
  padding-bottom: 16px;
  overflow-x: scroll;
  width: 100%;
`;

export const ColumnContainer = styled.div<{ $isActive: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  flex-grow: 1;
  height: fit-content;

  ${({ $isActive }) => {
    if ($isActive) {
      return `
      border-radius: var(--double-border-radius);
      background-color: var(--beehive-purple-transparent-30);
      `;
    }
  }}
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 214px;
  flex-shrink: 0;
`;

export const ExampleColumn = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: var(--border-radius);
  background: var(--white);

  box-shadow:
    0px 1px 2px 0px rgba(15, 12, 12, 0.3),
    0px 2px 6px 2px rgba(15, 12, 12, 0.15);
`;

export const ExampleColumnHeader = styled.div<{ $isMapped: boolean; $shouldSkip: boolean; $isError: boolean }>`
  display: flex;
  height: 48px;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: var(--border-radius);
  align-items: center;
  justify-content: space-between;
  text-overflow: ellipsis;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  background-color: ${({ $isMapped, $shouldSkip, $isError }) => {
    let bc = 'var(--medium-gray)';
    if ($isError) {
      bc = 'var(--error-message)';
    } else if ($isMapped && !$shouldSkip) {
      bc = 'var(--beehive-purple)';
    } else if ($isMapped && $shouldSkip) {
      bc = 'var(--gray-disabled)';
    }

    return bc;
  }};
  color: ${({ $isMapped }) => ($isMapped ? 'var(--white)' : 'var(--grey)')};
`;

export const ExampleColumnHeaderTitle = styled.div`
  text-overflow: ellipsis;
  height: 100%;
  line-height: 32px;
  flex-grow: 0;
  white-space: nowrap;
  overflow: hidden;
`;

export const SkipColumnToggle = styled.div<{ $isMapped: boolean }>`
  cursor: ${({ $isMapped }) => ($isMapped ? 'pointer' : 'not-allowed')};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ExampleColumnExample = styled.div`
  height: 48px;
  padding: 8px 16px;
  gap: 16px;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 32px;
  color: var(--grey);
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  text-align: left;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin-top: 40px;
`;

export const FilesInfo = styled.div`
  color: var(--black);
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const ButtonsBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const HaveExpertMapFilesButton = styled(TextButton)`
  && {
    padding: 10px 40px;
    font-size: 18px;
    text-decoration-line: underline;
  }

  svg {
    fill: white;
  }
`;

export const LoaderPopupContainer = styled.div`
  position: absolute;
  width: 663px;
  height: 55px;
  top: 50%;
  right: 50%;
`;
