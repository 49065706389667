import { useEffect, useMemo, useRef, useState } from 'react';

import { DASHBOARD_SORT_FIELD_NAME, SORT_ORDER } from '../../constants';

import { EVENT_PROPERTY_DASHBOARD_NAME, EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { filterReset } from '../../store/actions/dashboardFilter';

import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Dashboard, View } from '../../store/reducers/dashboards/types';
import {
  createDashboardsView,
  dashboardsInitialize,
  deleteDashboardsView,
  setDashboardsDateRange,
  updateDashboardsView,
} from '../../store/thunks/dashboards';

import { DATE_RANGE, DATE_RANGE_ID } from './DateRangeFilter';
import HivesTab from './HivesTab';
import { dashboardsAndViewsToHives } from './utils/dashboardsAndViewsToHives';

export type Hive = View & {
  dashboards: Dashboard[];
};

function HivesTabContainer() {
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.profile);
  const { initialized, dashboards, views, dateRange, status } = useAppSelector((state) => state.dashboards);

  const [selectedHiveId, setSelectedHiveId] = useState<string | null>(null);
  const [isHiveEditDialogOpen, setIsHiveEditDialogOpen] = useState(false);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [selectedHive, setSelectedHive] = useState<Hive | null>(null);

  const dateRangeRef = useRef(DATE_RANGE[DATE_RANGE_ID.ALL]);

  useEffect(() => {
    if (!initialized) {
      dispatch(dashboardsInitialize(dateRange));
    }
  }, [initialized, dispatch]);

  useEffect(() => {
    eventsTracker.removeFromContext([EVENT_PROPERTY_DASHBOARD_NAME]);
    eventsTracker.track(EVENTS.VIEW_COMPANY_DASHBOARDS);
    dispatch(filterReset());
  }, [dispatch]);

  const hives = useMemo(() => {
    return dashboardsAndViewsToHives(dashboards, views);
  }, [views, dashboards]);

  function handleEditClick(hive: Hive) {
    if (hive) {
      eventsTracker.track(EVENTS.HIVE_EDIT_VIEW);
      setSelectedHive(hive);
    } else {
      eventsTracker.track(EVENTS.HIVE_NEW_CREATE);
      setSelectedHive({ dashboardIDs: [], title: '', dashboards: [] });
    }
    setIsHiveEditDialogOpen(true);
  }

  function handleDialogClose() {
    eventsTracker.track(selectedHive?.id ? EVENTS.HIVE_EDIT_CANCEL : EVENTS.HIVE_NEW_CANCEL);
    setIsHiveEditDialogOpen(false);
  }

  function handleDialogSaveClick(hive: Hive) {
    const { ...baseView } = hive;
    if (hive.id && selectedHive) {
      eventsTracker.track(EVENTS.HIVE_EDIT_SAVE, {
        'Old Hive Name': selectedHive.title,
        'New Hive Name': hive.title,
        'Old Privacy': selectedHive.private,
        'New Privacy': hive.private,
        'Old Dashboard Count': selectedHive.dashboardIDs ? selectedHive.dashboardIDs.length : 0,
        'New Dashboard Count': hive.dashboardIDs.length,
      });
      dispatch(updateDashboardsView(hive));
    } else {
      eventsTracker.track(EVENTS.HIVE_NEW_SAVE, {
        'Hive Name': baseView.title,
        'Dashboard Count': baseView.dashboardIDs.length,
        Private: baseView.private,
      });
      if (!user) {
        console.error('No user set');
        return;
      }
      dispatch(createDashboardsView(baseView, user.id));
    }
    setIsHiveEditDialogOpen(false);
  }

  function handleDialogDeleteClick() {
    setIsHiveEditDialogOpen(false);
    setIsDeleteConfirmationOpen(true);
  }

  function handleRemoveConfirmation() {
    if (!selectedHive) {
      return;
    }
    eventsTracker.track(EVENTS.HIVE_EDIT_DELETE, {
      'Hive Name': selectedHive.title,
    });
    dispatch(deleteDashboardsView(selectedHive));
    setIsDeleteConfirmationOpen(false);
  }

  function handleDateRangeChange(value: any, dr: any) {
    dispatch(setDashboardsDateRange(value));
    dateRangeRef.current = dr;
  }

  function handleHiveClick(hiveId: string) {
    const hive = hives.find((hive) => hive.id === hiveId);
    eventsTracker.track(EVENTS.HIVE_FILTER, { 'Hive Name': hive?.title });
    setSelectedHiveId(hiveId);
  }

  function handleSort(field: string, order: string) {
    const hive = hives.find((hive) => hive.id === selectedHiveId);
    eventsTracker.track(EVENTS.DASHBOARD_SORT, {
      'Sort Criteria': DASHBOARD_SORT_FIELD_NAME[field],
      'Sort Order': order === SORT_ORDER.ASC ? 'Ascending' : 'Descending',
      'Time Filter': dateRangeRef.current.label,
      'Hive Filter': hive ? hive.title : '',
    });
  }

  function handleCancelDelete() {
    setIsDeleteConfirmationOpen(false);
    setIsHiveEditDialogOpen(true);
  }

  return (
    <HivesTab
      isHiveEditDialogOpen={isHiveEditDialogOpen}
      isDeleteConfirmationOpen={isDeleteConfirmationOpen}
      isInitialized={initialized}
      dashboards={dashboards}
      selectedHiveId={selectedHiveId}
      hives={hives}
      status={status}
      selectedHive={selectedHive}
      dateRange={dateRange}
      onHiveClick={handleHiveClick}
      onDateRangeChange={handleDateRangeChange}
      onEditClick={handleEditClick}
      onSort={handleSort}
      onCancelDelete={handleCancelDelete}
      onRemoveConfirmation={handleRemoveConfirmation}
      onDialogClose={handleDialogClose}
      onDialogSaveClick={handleDialogSaveClick}
      onDialogDeleteClick={handleDialogDeleteClick}
    />
  );
}

export default HivesTabContainer;
