import { useMemo } from 'react';
import useFilters from '../../../../hooks/useFilters';
import { FILTER_ID_ALL } from '../../../../constants';
import { BreakdownFilter } from '../../../../store/reducers/overviewReducer';
import { BreakdownData } from '../../../../types/BreakdownData';
import { WidgetDataSource } from '../../../../types/DataSource';
import { BreakdownLabel } from '../../../../types/Label';
import { Filter } from '../../../../types/Query';
import { createInValuesRule } from '../../../FilterForm/lib';
import { ResponseListItem } from '../../../overview/lib';
import { prepareBreakdownLabels, prepareLabelsList } from './lib';

export function useBreakdownLabels(
  isBreakdownView: boolean,
  selectedFilterIds: string[],
  breakdownData: BreakdownData = {},
  dataSource: any,
  filtersById: { [key: string]: Filter },
  showChildren: boolean,
  selectedItems: string[],
) {
  const breakdownLabels = useMemo((): BreakdownLabel[] => {
    if (isBreakdownView) {
      const ids = Object.keys(breakdownData);
      if (!selectedFilterIds.every((id) => ids.includes(id))) {
        return [];
      }
      return prepareBreakdownLabels(breakdownData, dataSource, filtersById, selectedFilterIds, showChildren, selectedItems);
    } else {
      return [];
    }
  }, [breakdownData, dataSource, filtersById, selectedFilterIds, showChildren, selectedItems]);

  return { breakdownLabels };
}

export function useDataFilters(isBreakdownView: boolean, preview: boolean): { filters: Filter[]; filtersById: { [key: string]: Filter } } {
  const [origFilters, filtersById] = useFilters();
  const filters = useMemo(() => {
    if (isBreakdownView) {
      return origFilters;
    }
    return preview ? ((origFilters as Filter[]).filter((filter) => filter.id === FILTER_ID_ALL) as Filter[]) : (origFilters as Filter[]);
  }, [origFilters, preview, isBreakdownView]);

  return { filters, filtersById };
}

export function useResponsesBreakdownQueryRule(breakdownFilter: BreakdownFilter | null, selectedLabelItem?: BreakdownLabel | null) {
  const responsesBreakdownQueryRule = useMemo(() => {
    if (!breakdownFilter || !selectedLabelItem || selectedLabelItem.breakdownValue === undefined) {
      return null;
    }
    return createInValuesRule(breakdownFilter.dataSourceID, [selectedLabelItem.breakdownValue]);
  }, [breakdownFilter, selectedLabelItem]);

  return { responsesBreakdownQueryRule };
}

export function useLabels(
  isBreakdownView: boolean,
  breakdownLabels: BreakdownLabel[],
  overviewDataById: Map<string, ResponseListItem>,
  dataSource: WidgetDataSource,
  filtersById: { [key: string]: Filter },
  selectedFilterIds: string[],
  showChildren: boolean,
  selectedItems: string[],
) {
  const labels = useMemo(() => {
    if (isBreakdownView) {
      const usedTitles: any[] = [];
      const labels: BreakdownLabel[] = [];
      for (const breakdownLabel of breakdownLabels) {
        if (!usedTitles.includes(breakdownLabel.displayTitle)) {
          usedTitles.push(breakdownLabel.displayTitle);
          labels.push({
            ...breakdownLabel,
            children: breakdownLabel.children ? breakdownLabel.children.map((child) => child.displayTitle) : breakdownLabel.children,
          } as BreakdownLabel);
        }
      }

      return labels;
    } else {
      const ids = [...overviewDataById.keys()];
      if (!selectedFilterIds.every((id) => ids.includes(id))) {
        return [];
      }
      return prepareLabelsList(overviewDataById, dataSource, filtersById, selectedFilterIds, showChildren, selectedItems);
    }
  }, [overviewDataById, dataSource, filtersById, selectedFilterIds, showChildren, selectedItems]);

  return { labels };
}
