import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { Middleware } from 'redux';
import { v4 } from 'uuid';

import { Query } from '../../types/Query';
import {
  AI_INSIGHTS_GENERATE,
  AI_INSIGHTS_RESET,
  generateInsightsRequest,
  generateInsightsRequestFailure,
  generateInsightsRequestStart,
} from '../actions/aiInsights';
import {
  loadAnswersFailure,
  loadAnswersSuccess,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS,
} from '../actions/openEndedWidget';
import { AppDispatch, TypedDispatch } from '../hooks';
import { IApplicationState } from '../reducers';
import { GenerationStatus } from '../reducers/aiInsights';
import { generateInsights, InsightsQuery } from '../thunks/aiInsights';

export type InsightInfo = {
  responsesQuery: Query;
  insightsQuery: InsightsQuery[];
  insightID: string;
  dashboardID: string;
  dataSourceID: string;
  isTryAgain: boolean;
};
export type InsightsInProgress = { [key: string]: InsightInfo[] };
export type LoadedResponses = { [key: string]: { totalResponses: number } };

export class InsightsLoader {
  insightsInProgress: InsightsInProgress;
  loadedResponses: LoadedResponses;

  constructor(initialInsightsInProgress: InsightsInProgress, initialLoadedResponses: LoadedResponses) {
    this.insightsInProgress = initialInsightsInProgress;
    this.loadedResponses = initialLoadedResponses;
  }

  insightsLoader: Middleware<object, IApplicationState, TypedDispatch> =
    ({ dispatch, getState }) =>
    (next) =>
    (action) => {
      if (!action) {
        return;
      }

      if (action?.type === AI_INSIGHTS_GENERATE) {
        this.handleInsightsGenerateRequest(action, getState(), dispatch);

        return;
      }

      if (action?.type === OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS) {
        this.handleLoadAnswersSuccess(action, getState(), dispatch);
      }

      if (action?.type === OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE) {
        this.handleLoadAnswersFailure(action, getState(), dispatch);
      }

      if (action?.type === AI_INSIGHTS_RESET) {
        for (const key of Object.keys(this.insightsInProgress)) {
          delete this.insightsInProgress[key];
        }
        for (const key of Object.keys(this.loadedResponses)) {
          delete this.loadedResponses[key];
        }
      }
      // Otherwise, pass the action down the middleware chain as usual
      return next(action);
    };

  handleInsightsGenerateRequest(action: ReturnType<typeof generateInsightsRequest>, state: IApplicationState, dispatch: AppDispatch) {
    const {
      availableFilters,
      customPrompt,
      segmentsNames,
      selectedConcept,
      widgetID,
      insightID,
      isTryAgain = false,
      insightsQuery,
      responsesQuery,
      dashboardID,
      dataSourceID,
    } = action.payload;
    const insightsForWidget = state.aiInsights.insightsByWidgetID.find((insights) => insights.widgetID === widgetID);
    const insight = insightsForWidget?.insights?.find((insight) => insight.id === insightID);
    let id = insightID ?? v4();

    if (isTryAgain && !insight?.generationStatuses?.includes(GenerationStatus.FAILURE)) {
      id = v4();
    }

    if (!isTryAgain) {
      const responsesInfo = this.loadedResponses[widgetID];

      dispatch(
        generateInsightsRequestStart({
          id,
          timestamp: new Date().toISOString(),
          widgetID,
          customPrompt,
          selectedConcept,
          filter: insightsQuery,
          segmentsNames,
          isTryAgain,
          availableFilters,
        }),
      );
      if (responsesInfo) {
        // If responses already loaded
        dispatch(
          generateInsights({
            insightID: id,
            dashboardID,
            dataSourceID,
            widgetID,
            customPrompt,
            selectedConcept,
            isTryAgain,
            filter: insightsQuery,
            totalResponses: responsesInfo.totalResponses,
          }),
        );
        delete this.loadedResponses[widgetID];
      } else if (!this.insightsInProgress[widgetID]) {
        this.insightsInProgress[widgetID] = [
          cloneDeep({
            insightsQuery,
            responsesQuery,
            insightID: id,
            isTryAgain,
            dashboardID,
            dataSourceID,
          }),
        ];
      } else {
        this.insightsInProgress[widgetID].push(
          cloneDeep({
            insightsQuery,
            responsesQuery,
            insightID: id,
            isTryAgain,
            dashboardID,
            dataSourceID,
          }),
        );
      }
    } else {
      const customPrompt = insight!.customPrompt;
      const concept = insight!.concept;
      const totalResponses = insight!.totalResponses!;
      const filter = insight!.filter;
      const segmentsNames = insight!.segmentsNames!;

      dispatch(
        generateInsightsRequestStart({
          id,
          timestamp: new Date().toISOString(),
          widgetID,
          customPrompt,
          selectedConcept: concept,
          filter,
          segmentsNames,
          isTryAgain,
          availableFilters,
        }),
      );
      dispatch(
        generateInsights({
          insightID: id,
          dashboardID,
          dataSourceID,
          widgetID,
          customPrompt,
          selectedConcept: concept,
          isTryAgain,
          filter,
          totalResponses,
        }),
      );
    }
  }

  handleLoadAnswersSuccess(action: ReturnType<typeof loadAnswersSuccess>, state: IApplicationState, dispatch: AppDispatch) {
    const { totalResponses, widgetID } = action.payload;
    const { query } = state.openEndedWidgets.configsByWidgetID[widgetID];
    const { offset } = state.openEndedWidgets.responsesStatesByWidgetID[widgetID];

    const infoIndex = this.insightsInProgress[widgetID]?.findIndex((iip) => isEqual(iip.responsesQuery, query));
    if (infoIndex >= 0) {
      const { insightID, dashboardID, dataSourceID, isTryAgain, insightsQuery } = this.insightsInProgress[widgetID][infoIndex];
      const insightsIndex = state.aiInsights.insightsByWidgetID.findIndex((i) => i.widgetID === widgetID);
      if (insightsIndex >= 0) {
        const insightIndex = state.aiInsights.insightsByWidgetID[insightsIndex].insights.findIndex((i) => i.id === insightID);
        if (insightsIndex >= 0) {
          const { customPrompt, concept } = state.aiInsights.insightsByWidgetID[insightsIndex].insights[insightIndex];

          if (totalResponses > 0) {
            dispatch(
              generateInsights({
                insightID,
                dashboardID,
                dataSourceID,
                widgetID,
                customPrompt,
                selectedConcept: concept,
                isTryAgain,
                filter: insightsQuery,
                totalResponses,
              }),
            );
          } else {
            dispatch(
              generateInsightsRequestFailure({
                id: insightID,
                widgetID,
                timestamp: new Date().toISOString(),
                totalResponses,
              }),
            );
          }

          this.insightsInProgress[widgetID].splice(infoIndex, 1);
        } else {
          console.error(
            `Could not locate the insight with id: ${insightID} in widget with id: ${widgetID} for response with filter ${JSON.stringify(
              query,
            )}`,
          );
        }
      } else {
        console.error(
          `Could not locate the insights in widget with id: ${widgetID}  for response with filter ${JSON.stringify(query)} and insights id: ${insightID}`,
        );
      }
    } else if (offset === 0) {
      // we need to store only when responses/insights query change, otherwise we know the totalResponses value
      this.loadedResponses[widgetID] = { totalResponses };
    }
  }

  handleLoadAnswersFailure(action: ReturnType<typeof loadAnswersFailure>, state: IApplicationState, dispatch: AppDispatch) {
    const { widgetID } = action.payload;
    const { query } = state.openEndedWidgets.configsByWidgetID[widgetID];
    const { offset } = state.openEndedWidgets.responsesStatesByWidgetID[widgetID];

    const infoIndex = this.insightsInProgress[widgetID]?.findIndex((iip) => isEqual(iip.responsesQuery, query));
    if (infoIndex >= 0) {
      const { insightID, dashboardID, dataSourceID, isTryAgain, insightsQuery } = this.insightsInProgress[widgetID][infoIndex];
      const insightsIndex = state.aiInsights.insightsByWidgetID.findIndex((i) => i.widgetID === widgetID);
      if (insightsIndex >= 0) {
        const insightIndex = state.aiInsights.insightsByWidgetID[insightsIndex].insights.findIndex((i) => i.id === insightID);
        if (insightsIndex >= 0) {
          const { customPrompt, concept } = state.aiInsights.insightsByWidgetID[insightsIndex].insights[insightIndex];
          dispatch(
            generateInsights({
              insightID,
              dashboardID,
              dataSourceID,
              widgetID,
              customPrompt,
              selectedConcept: concept,
              isTryAgain,
              filter: insightsQuery,
              totalResponses: 0,
            }),
          );

          this.insightsInProgress[widgetID].splice(infoIndex, 1);
        } else {
          console.error(
            `Could not locate the insight with id: ${insightID} in widget with id: ${widgetID} for response with filter ${JSON.stringify(
              query,
            )}`,
          );
        }
      } else {
        console.error(
          `Could not locate the insights in widget with id: ${widgetID}  for response with filter ${JSON.stringify(query)} and insights id: ${insightID}`,
        );
      }
    } else if (offset === 0) {
      // we need to store only when responses/insights query change, otherwise we know the totalResponses value
      this.loadedResponses[widgetID] = { totalResponses: 0 };
    }
  }
}
