import dayjs from 'dayjs';
import * as api from '../../components/tools/api';
import {
  fileMappingLoadFileMappingError,
  fileMappingLoadFileMappingStart,
  fileMappingLoadFileMappingSuccess,
  fileMappingPredictError,
  fileMappingPredictStart,
  fileMappingPredictSuccess,
  fileMappingReportIssueError,
  fileMappingReportIssueStart,
  fileMappingReportIssueSuccess,
  fileMappingRequestTemplateError,
  fileMappingRequestTemplateStart,
  fileMappingRequestTemplateSuccess,
  fileMappingReset,
  fileMappingTemplatesError,
  fileMappingTemplatesStart,
  fileMappingTemplatesSuccess,
  fileMappingUpdateFileInfo,
  fileMappingUpdateUserMapping,
  fileMappingUploadError,
  fileMappingUploadStart,
  fileMappingUploadSuccess,
  fileMappingValidateDateFormatStart,
  fileMappingValidateDateFormatSuccess,
  fileMappingValidateMappingError,
  fileMappingValidateMappingStart,
  fileMappingValidateMappingSuccess,
} from '../actions/fileMapping';
import { Action } from '../hooks';
import {
  GET_TEMPLATES_ERROR,
  LOAD_DOCUMENTS_ERROR,
  MAPPING_ERROR,
  PREDICTING_ERROR,
  REPORT_ISSUE_ERROR,
  REQUEST_TEMPLATE_ERROR,
} from '../reducers/fileMapping/errors';
import { FileState } from '../reducers/fileMapping/types';
import { updateRenameDashboard } from '../actions/connectors';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { UNKNOWN_TYPE } from '../../components/FileUploadFlow/components/FileUploadMapping/constants';

const BE_EXPECTED_DATE_TIME = '2006-01-02 15:04:05';

let getTemplatesAbortController: AbortController | null = null;
const loadFileMappingAbortControllerMap: Map<string, AbortController> = new Map();
const predictFileMappingAbortControllerMap: Map<string, AbortController> = new Map();

// get list of stock templates
export const getTemplates = (): Action => async (dispatch) => {
  if (getTemplatesAbortController) {
    getTemplatesAbortController.abort();
  }
  getTemplatesAbortController = new AbortController();
  dispatch(fileMappingTemplatesStart());
  try {
    const response = await api.getTemplates(getTemplatesAbortController.signal);
    dispatch(fileMappingTemplatesSuccess({ templates: response }));
  } catch (error: any) {
    if (error.name !== 'AbortError') {
      dispatch(fileMappingTemplatesError({ error: GET_TEMPLATES_ERROR }));
    }
  }
  getTemplatesAbortController = null;
};

export const resetMapping = (): Action => async (dispatch) => {
  if (getTemplatesAbortController) {
    getTemplatesAbortController.abort();
    getTemplatesAbortController = null;
  }
  for (const [, controller] of loadFileMappingAbortControllerMap) {
    controller.abort();
  }
  loadFileMappingAbortControllerMap.clear();
  dispatch(fileMappingReset());
};

// get predicted user mapping for the file
interface IGetMappingState {
  externalID: string;
  templateID: string;
  dateFormat: string;
  startRow: number;
}
export const getPredictedMapping =
  (payload: IGetMappingState): Action =>
  async (dispatch, getState) => {
    const { templates } = getState().fileMapping;
    const selectedTemplate = templates.find((t) => t.id === payload.templateID)?.displayName ?? '';
    if (predictFileMappingAbortControllerMap.has(payload.externalID)) {
      predictFileMappingAbortControllerMap.get(payload.externalID)?.abort();
      predictFileMappingAbortControllerMap.delete(payload.externalID);
    }
    const abortController = new AbortController();
    predictFileMappingAbortControllerMap.set(payload.externalID, abortController);
    dispatch(fileMappingPredictStart());
    try {
      const response = await api.getFileMappingState(payload, true, abortController.signal);
      dispatch(fileMappingPredictSuccess());
      if (!response) {
        throw new Error('No response from server');
      }
      const { userMapping } = response;
      dispatch(fileMappingUpdateUserMapping({ userMapping, byUserAction: false }));
      eventsTracker.track(EVENTS.FILE_MAPPING_TEMPLATE_LOADED, {
        'Applied Template': selectedTemplate,
        'Total column count': Object.values(userMapping ?? {}).length,
        'Mapped column count': Object.values(userMapping ?? {}).filter((col) => col.columnType !== UNKNOWN_TYPE).length,
      });
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        dispatch(fileMappingPredictError({ error: PREDICTING_ERROR }));
      }
    }
    predictFileMappingAbortControllerMap.delete(payload.externalID);
  };

// get list of sample rows for the file - no type predictions will be made
export const getFileData =
  (payload: IGetMappingState): Action =>
  async (dispatch) => {
    if (loadFileMappingAbortControllerMap.has(payload.externalID)) {
      loadFileMappingAbortControllerMap.get(payload.externalID)?.abort();
      loadFileMappingAbortControllerMap.delete(payload.externalID);
    }
    const abortController = new AbortController();
    loadFileMappingAbortControllerMap.set(payload.externalID, abortController);
    dispatch(fileMappingLoadFileMappingStart());
    try {
      const response = await api.getFileMappingState(payload, false, abortController.signal);
      dispatch(fileMappingLoadFileMappingSuccess());
      if (!response) {
        throw new Error('No response from server');
      }
      const { userMapping, dateFormat, templateID, startRow, numberOfRows } = response;
      dispatch(fileMappingUpdateUserMapping({ userMapping, byUserAction: false }));
      dispatch(fileMappingUpdateFileInfo({ fileState: { dateFormat, templateID, startRow, numberOfRows } }));
    } catch (error: any) {
      if (error.name !== 'AbortError') {
        dispatch(fileMappingLoadFileMappingError({ error: PREDICTING_ERROR }));
      }
    }
    loadFileMappingAbortControllerMap.delete(payload.externalID);
  };

// submit finalized user mappings for all files
// do not allow mid-request canceling of this action
interface ILoadDocuments {
  files: FileState[];
  isPrivate: boolean | null;
  dashboardID: string | null;
  requiresManualProcessing: boolean;
}
export const loadDocuments =
  (payload: ILoadDocuments): Action =>
  async (dispatch) => {
    const startTime = performance.now();
    dispatch(fileMappingUploadStart());
    for (const [_, controller] of predictFileMappingAbortControllerMap) {
      controller.abort();
    }
    predictFileMappingAbortControllerMap.clear();
    const { dashboardID, isPrivate, requiresManualProcessing } = payload;
    try {
      const files = payload.files.map((file) => ({
        ...file,
        dateFormat: file.dateFormat ? dayjs(new Date(BE_EXPECTED_DATE_TIME)).format(file.dateFormat) : null,
        // set to false when users require beehive expert to map their files
        requiresManualProcessing: requiresManualProcessing || file.isMappingValid !== true,
      }));
      const response = await api.fileMappingLoadDocuments({ files, isDashboardPrivate: isPrivate, dashboardID });
      dispatch(
        updateRenameDashboard({
          submitting: false,
          success: false,
          dashboardID: response.dashboardID,
          originalDashboardName: response.dashboardName,
          files: response.files,
          requestedManualProcessing: requiresManualProcessing,
        }),
      );
      dispatch(fileMappingUploadSuccess(response));
      const endTime = performance.now();
      eventsTracker.track(EVENTS.FILE_MAPPING_SUBMIT_BUTTON_SELECT, {
        'Submit Status': 'Success',
        'Number of files uploaded': files.length,
      });
      eventsTracker.track(EVENTS.FILE_UPLOAD_SUCCESS, {
        'Processing Time': endTime - startTime,
        'File Submit Method': requiresManualProcessing ? 'Have a Beehive Expert Map your Files (Skipped)' : 'Mapped and Submitted',
        'Number of Files Uploaded': files.length,
        'Number of Mapped Files': files.filter((file) => file.isMappingValid).length,
      });
    } catch (error) {
      dispatch(fileMappingUploadError({ error: LOAD_DOCUMENTS_ERROR }));
      eventsTracker.track(EVENTS.FILE_MAPPING_SUBMIT_BUTTON_SELECT, {
        'Submit Status': 'Error',
        'Error Description': error,
        'Number of files uploaded': payload.files.length,
      });
    }
  };

interface IValidateDateFormat {
  dateFormat: string;
}
export const validateDateFormat =
  (payload: IValidateDateFormat): Action =>
  async (dispatch) => {
    dispatch(fileMappingValidateDateFormatStart());
    try {
      const goLangDateFormat = dayjs(new Date(BE_EXPECTED_DATE_TIME)).format(payload.dateFormat);
      const response = await api.validateDateFormat({ dateFormat: goLangDateFormat });
      const isValid = response?.isValid;
      dispatch(fileMappingValidateDateFormatSuccess({ isValid, dateFormat: payload.dateFormat }));
    } catch (error) {
      dispatch(fileMappingValidateDateFormatSuccess({ isValid: false, dateFormat: null }));
    }
  };

interface IValidateMapping {
  file: FileState;
}
export const validateMapping =
  (payload: IValidateMapping): Action =>
  async (dispatch, getState) => {
    dispatch(fileMappingValidateMappingStart());
    const { externalID } = payload.file;
    if (predictFileMappingAbortControllerMap.has(externalID)) {
      predictFileMappingAbortControllerMap.get(externalID)?.abort();
      predictFileMappingAbortControllerMap.delete(externalID);
    }
    const state = getState();
    const { isPrivate, dashboardID, selectedIndex, files } = state.fileMapping;
    const isLastFile = selectedIndex >= files.length - 1;
    const eventName = isLastFile ? EVENTS.FILE_MAPPING_SUBMIT_BUTTON_SELECT : EVENTS.FILE_MAPPING_NEXT_BUTTON_SELECT;
    const { file } = payload;
    try {
      file.dateFormat = file.dateFormat ? dayjs(new Date(BE_EXPECTED_DATE_TIME)).format(file.dateFormat) : null;
      const res = await api.documentValidateMapping(payload.file);
      const { isValid, dateFormatErrors } = res;
      dispatch(fileMappingValidateMappingSuccess({ isValid, dateFormatErrors }));
      if (!isLastFile) {
        eventsTracker.track(eventName, {
          'Next Status': isValid ? 'Success' : 'Error',
          'Error Description': isValid ? '' : 'Date format errors',
          'Number of files uploaded': files.length,
        });
      } else if (!isValid) {
        eventsTracker.track(eventName, {
          'Submit Status': 'Error',
          'Error Description': 'Date format errors',
          'Number of files uploaded': files.length,
        });
      } else {
        // isValid && isLastFile
        files.map((f) => {
          if (f.externalID === file.externalID) {
            f.isMappingValid = true;
          }
          return f;
        });
        dispatch(loadDocuments({ files, isPrivate, dashboardID, requiresManualProcessing: false }));
      }
    } catch (error) {
      fileMappingValidateMappingError({ error: MAPPING_ERROR });
      const statusPropName = isLastFile ? 'Submit Status' : 'Next Status';
      eventsTracker.track(eventName, {
        [statusPropName]: 'Error',
        'Error Description': error,
        'Number of files uploaded': files.length,
      });
    }
  };

export const requestTemplate =
  (message: string): Action =>
  async (dispatch) => {
    dispatch(fileMappingRequestTemplateStart());
    try {
      await api.requestTemplate({ message });
      dispatch(fileMappingRequestTemplateSuccess());
    } catch (error) {
      dispatch(fileMappingRequestTemplateError({ error: REQUEST_TEMPLATE_ERROR }));
      console.error('Error requesting template', error);
    }
  };

export const reportIssue =
  (message: string): Action =>
  async (dispatch, getState) => {
    const { selectedIndex, files, templates } = getState().fileMapping;
    const file = files[selectedIndex];
    const { templateID } = file;
    const selectedTemplate = templates.find((t) => t.id === templateID)?.displayName ?? '';
    const fileNames = files.map((f) => f.fileName);
    dispatch(fileMappingReportIssueStart());
    try {
      await api.fileMappingReportIssue({ message, selectedTemplate, fileNames, currentFile: file.fileName });
      dispatch(fileMappingReportIssueSuccess());
    } catch (error) {
      dispatch(fileMappingReportIssueError({ error: REPORT_ISSUE_ERROR }));
      console.error('Error reporting issue', error);
    }
  };
