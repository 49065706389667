import Dialog from '@material-ui/core/Dialog';
import React, { useState } from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/cross-icon.svg';
import { FilledButton, OutlinedButton } from '../../UI/Button';

import styles from './styles.module.css';

interface IPromptModal {
  headerText: string;
  placeholder: string;
  cancelButtonText: string;
  mainActionButtonText: string;
  numberOfLines?: number;
  onCancelButtonClick: () => void;
  onMainActionButtonClick: (text: string) => void;
}

function PromptModal({
  headerText,
  placeholder,
  numberOfLines = 1,
  cancelButtonText,
  mainActionButtonText,
  onCancelButtonClick,
  onMainActionButtonClick,
}: IPromptModal) {
  const [promptText, setPromptText] = useState('');

  function handlePromptChange(event: React.ChangeEvent<HTMLTextAreaElement>) {
    setPromptText(event.currentTarget.value);
  }

  return (
    <Dialog maxWidth={false} open={true} onClose={onCancelButtonClick}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerText}>{headerText}</div>
          <CloseIcon className={styles.closeIcon} onClick={onCancelButtonClick} />
        </div>
        <textarea placeholder={placeholder} rows={numberOfLines} value={promptText} className={styles.body} onChange={handlePromptChange} />
        <div className={styles.footer}>
          <OutlinedButton onClick={onCancelButtonClick}>{cancelButtonText}</OutlinedButton>
          <FilledButton onClick={onMainActionButtonClick.bind(null, promptText)}>{mainActionButtonText}</FilledButton>
        </div>
      </div>
    </Dialog>
  );
}

export default PromptModal;
