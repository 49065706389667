import { Button } from '@material-ui/core';
import { useIntercom } from 'react-use-intercom';

import palette from '../../components/tools/palette';

const UpdateDashboardButton = ({ title }: { title: string }) => {
  const { showNewMessages } = useIntercom();
  const handleNewMessagesWithContent = () => showNewMessages(`Please make the following update(s) to "${title}" dashboard: `);

  return (
    <Button
      style={{
        textTransform: 'initial',
        fontWeight: 'bold',
        fontFamily: 'Rubik',
        fontSize: 'initial',
        color: palette.primaryColorLight,
      }}
      disabled={title === undefined}
      onClick={handleNewMessagesWithContent}
    >
      Update
    </Button>
  );
};

export default UpdateDashboardButton;
