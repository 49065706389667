import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { EMPTY_FILTER_QUERY } from '../../components/FilterForm/lib';
import { RESPONSES_SORT_FIELDS } from '../../components/Widgets/OpenEndedWidget/Data/ResponsesList/Components/ResponsesListFilterContainer';
import { ResponseModelFactory } from '../../models/Response';
import { Query } from '../../types/Query';
import {
  OPEN_ENDED_WIDGET_INITIALIZE,
  OPEN_ENDED_WIDGET_INVALIDATE_BOOKMARKS_LOADING_STATE,
  OPEN_ENDED_WIDGET_INVALIDATE_EXTRA_CONCEPTS_LOADING_STATE,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_START,
  OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS,
  OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_FAILURE,
  OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_START,
  OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_SUCCESS,
  OPEN_ENDED_WIDGET_QUERIES_CHANGE,
  OPEN_ENDED_WIDGET_SEARCH_STRING_CHANGE,
  OPEN_ENDED_WIDGET_SELECT_CONCEPT,
  OPEN_ENDED_WIDGET_SORTING_CHANGE,
  OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_FAILURE,
  OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_START,
  OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_SUCCESS,
  OPEN_ENDED_WIDGET_TOGGLE_BOOKMARKED_ONLY,
  changeSearchString,
  changeSorting,
  initialize,
  invalidateBookmarksLoadingState,
  invalidateExtraConceptLoadingState,
  loadAnswersFailure,
  loadAnswersStart,
  loadAnswersSuccess,
  loadExtraConceptsFailure,
  loadExtraConceptsStart,
  loadExtraConceptsSuccess,
  selectConcept,
  toggleBookmarkedOnly,
  toggleBookmarkResponseFailure,
  toggleBookmarkResponseStart,
  toggleBookmarkResponseSuccess,
  updateWidgetQueries,
  OPEN_ENDED_WIDGET_RESET_WIDGETS,
} from '../actions/openEndedWidget';
import { InsightsQuery } from '../thunks/aiInsights';
import { Concept } from './aiInsights';

export const defaultSorting = {
  sortBy: RESPONSES_SORT_FIELDS.NUMBER_OF_LABELS,
  desc: true,
};

export type ResponsesState = {
  offset: number;
  sorting: ResponsesSorting;
  isLoading: boolean;
  isError: boolean;
  responsesTotal: number;
  isBookmarkOnly: boolean;
  searchString: string;
};

export type ResponsesSorting = {
  sortBy: string;
  desc: boolean;
};

export type WidgetConfig = {
  dataSourceID: string;
  query: Query;
  insightsQuery: InsightsQuery[];
  selectedConcept: null | Concept;
};

export type ChangesToResponsesLoadingState = {
  isLoading: boolean;
  isError: boolean;
  rowIndex: number;
  responseID: string;
};

export interface IOpenEndedWidgetsReducer {
  responsesStatesByWidgetID: { [key: string]: ResponsesState };
  responsesByWidgetID: {
    [key: string]: ReturnType<typeof ResponseModelFactory>[];
  };
  conceptsByWidgetID: { [key: string]: Concept[] };
  configsByWidgetID: { [key: string]: WidgetConfig };
  extraLabelsByWidgetID: { [key: string]: ChangesToResponsesLoadingState[] };
  bookmarkedResponsesByWidgetID: {
    [key: string]: ChangesToResponsesLoadingState[];
  };
  keyQuotesModal?: {
    isVisible: boolean;
    widgetID: string;
    insightID: string;
    selectedInsight: string;
  };
}

type OpenEndedWidgetActionTypes = ReturnType<typeof loadAnswersStart> &
  ReturnType<typeof loadAnswersSuccess> &
  ReturnType<typeof loadAnswersFailure> &
  ReturnType<typeof loadExtraConceptsStart> &
  ReturnType<typeof loadExtraConceptsFailure> &
  ReturnType<typeof loadExtraConceptsSuccess> &
  ReturnType<typeof invalidateExtraConceptLoadingState> &
  ReturnType<typeof initialize> &
  ReturnType<typeof selectConcept> &
  ReturnType<typeof changeSorting> &
  ReturnType<typeof toggleBookmarkedOnly> &
  ReturnType<typeof toggleBookmarkResponseStart> &
  ReturnType<typeof toggleBookmarkResponseFailure> &
  ReturnType<typeof toggleBookmarkResponseSuccess> &
  ReturnType<typeof updateWidgetQueries> &
  ReturnType<typeof changeSearchString>;

const initialState: IOpenEndedWidgetsReducer = {
  responsesStatesByWidgetID: {},
  responsesByWidgetID: {},
  conceptsByWidgetID: {},
  configsByWidgetID: {},
  extraLabelsByWidgetID: {},
  bookmarkedResponsesByWidgetID: {},
};

const OpenEndedWidgetReducer = (state = initialState, action: OpenEndedWidgetActionTypes) => {
  switch (action.type) {
    case OPEN_ENDED_WIDGET_INITIALIZE:
      return handleInitialize(state, action);
    case OPEN_ENDED_WIDGET_LOAD_ANSWERS_START:
      return handleLoadAnswersStart(state, action);
    case OPEN_ENDED_WIDGET_LOAD_ANSWERS_FAILURE:
      return handleLoadAnswersFailure(state, action);
    case OPEN_ENDED_WIDGET_LOAD_ANSWERS_SUCCESS:
      return handleLoadAnswersSuccess(state, action);
    case OPEN_ENDED_WIDGET_SELECT_CONCEPT:
      return handleSelectConcept(state, action);
    case OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_START:
      return handleLoadExtraConceptsStart(state, action);
    case OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_FAILURE:
      return handleLoadExtraConceptsFailure(state, action);
    case OPEN_ENDED_WIDGET_LOAD_EXTRA_CONCEPTS_SUCCESS:
      return handleLoadExtraConceptsSuccess(state, action);
    case OPEN_ENDED_WIDGET_INVALIDATE_EXTRA_CONCEPTS_LOADING_STATE:
      return handleInvalidateExtraConceptsLoadingState(state, action);
    case OPEN_ENDED_WIDGET_SORTING_CHANGE:
      return handleSortingChange(state, action);
    case OPEN_ENDED_WIDGET_TOGGLE_BOOKMARKED_ONLY:
      return handleToggleBookmarkedOnly(state, action);
    case OPEN_ENDED_WIDGET_SEARCH_STRING_CHANGE:
      return handleSearchStringChange(state, action);
    case OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_START:
      return handleToggleBookmarkResponseStart(state, action);
    case OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_FAILURE:
      return handleToggleBookmarkResponseFailure(state, action);
    case OPEN_ENDED_WIDGET_TOGGLE_BOOKMARK_RESPONSE_SUCCESS:
      return handleToggleBookmarkResponseSuccess(state, action);
    case OPEN_ENDED_WIDGET_INVALIDATE_BOOKMARKS_LOADING_STATE:
      return handleInvalidateBookmarksLoadingState(state, action);
    case OPEN_ENDED_WIDGET_QUERIES_CHANGE:
      return handleWidgetQueriesChange(state, action);
    case OPEN_ENDED_WIDGET_RESET_WIDGETS:
      return initialState;
    default:
      return state;
  }
};

function handleInitialize(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof initialize>): IOpenEndedWidgetsReducer {
  return {
    ...state,
    configsByWidgetID: {
      ...state.configsByWidgetID,
      [action.payload.widgetID]: {
        dataSourceID: action.payload.dataSourceID,
        query: EMPTY_FILTER_QUERY,
        insightsQuery: [],
        selectedConcept: null,
      },
    },
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [action.payload.widgetID]: {
        offset: 0,
        sorting: defaultSorting,
        isLoading: false,
        isError: false,
        responsesTotal: 1,
        isBookmarkOnly: false,
        searchString: '',
      },
    },
  };
}

function handleSelectConcept(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof selectConcept>): IOpenEndedWidgetsReducer {
  const { widgetID, title, options } = action.payload;
  const extraOptions = { showWithScroll: options?.withScroll };

  const currentConcept = state.configsByWidgetID[widgetID].selectedConcept;
  return {
    ...state,
    configsByWidgetID: {
      ...state.configsByWidgetID,
      [widgetID]: {
        ...state.configsByWidgetID[widgetID],
        selectedConcept:
          currentConcept !== null && currentConcept.title === title ? null : { title, isEnabled: true, options: extraOptions },
      },
    },
  };
}

function handleSortingChange(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof changeSorting>): IOpenEndedWidgetsReducer {
  const { widgetID, sorting } = action.payload;
  const responsesState = state.responsesStatesByWidgetID[widgetID];
  return {
    ...state,
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [widgetID]: {
        ...responsesState,
        sorting,
      },
    },
  };
}

function handleToggleBookmarkedOnly(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof toggleBookmarkedOnly>,
): IOpenEndedWidgetsReducer {
  const { widgetID, isBookmarkOnly } = action.payload;
  const responsesState = state.responsesStatesByWidgetID[widgetID];
  return {
    ...state,
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [widgetID]: {
        ...responsesState,
        isBookmarkOnly,
      },
    },
  };
}

function handleSearchStringChange(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof changeSearchString>,
): IOpenEndedWidgetsReducer {
  const { widgetID, searchString } = action.payload;
  const responsesState = state.responsesStatesByWidgetID[widgetID];
  return {
    ...state,
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [widgetID]: {
        ...responsesState,
        searchString,
      },
    },
  };
}

function handleLoadAnswersStart(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof loadAnswersStart>): IOpenEndedWidgetsReducer {
  const { widgetID, sorting, offset, isBookmarkOnly, filterQuery } = action.payload;
  const responsesState = state.responsesStatesByWidgetID[widgetID];
  const configByWidgetID = state.configsByWidgetID[widgetID];

  const shouldCleanResponses =
    (responsesState.offset > 0 && offset === 0) ||
    !isEqual(responsesState.sorting, sorting) ||
    !isEqual(configByWidgetID.query, filterQuery) ||
    responsesState.isBookmarkOnly !== isBookmarkOnly;

  return {
    ...state,
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [widgetID]: {
        ...state.responsesStatesByWidgetID[action.payload.widgetID],
        isLoading: true,
        isError: false,
        offset: offset,
        sorting: sorting,
        isBookmarkOnly: isBookmarkOnly,
      },
    },
    configsByWidgetID: {
      ...state.configsByWidgetID,
      [widgetID]: {
        ...state.configsByWidgetID[widgetID],
        query: filterQuery,
      },
    },
    responsesByWidgetID: {
      ...state.responsesByWidgetID,
      [widgetID]: shouldCleanResponses ? [] : state.responsesByWidgetID[widgetID],
    },
  };
}

function handleLoadAnswersFailure(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof loadAnswersFailure>,
): IOpenEndedWidgetsReducer {
  return {
    ...state,
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [action.payload.widgetID]: {
        ...state.responsesStatesByWidgetID[action.payload.widgetID],
        isLoading: false,
        isError: true,
      },
    },
  };
}

function handleLoadAnswersSuccess(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof loadAnswersSuccess>,
): IOpenEndedWidgetsReducer {
  const { widgetID, totalResponses } = action.payload;
  const responses = state.responsesByWidgetID[widgetID] || [];
  const newResponses = action.payload.responses.map(ResponseModelFactory);

  return {
    ...state,
    responsesByWidgetID: {
      ...state.responsesByWidgetID,
      [widgetID]: [...responses, ...newResponses],
    },
    responsesStatesByWidgetID: {
      ...state.responsesStatesByWidgetID,
      [widgetID]: {
        ...state.responsesStatesByWidgetID[widgetID],
        isLoading: false,
        isError: false,
        responsesTotal: totalResponses,
        offset: state.responsesStatesByWidgetID[widgetID].offset + newResponses.length,
      },
    },
  };
}

function handleLoadExtraConceptsStart(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof loadExtraConceptsStart>,
): IOpenEndedWidgetsReducer {
  const { widgetID, rowIndex, responseID } = action.payload;
  const extraLabelsStateForWidget = state.extraLabelsByWidgetID[widgetID] || [];
  return {
    ...state,
    extraLabelsByWidgetID: {
      ...state.extraLabelsByWidgetID,
      [widgetID]: [
        ...extraLabelsStateForWidget,
        {
          isLoading: true,
          isError: false,
          rowIndex,
          responseID,
        },
      ],
    },
  };
}

function handleLoadExtraConceptsFailure(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof loadExtraConceptsFailure>,
): IOpenEndedWidgetsReducer {
  const { widgetID, rowIndex, responseID } = action.payload;
  const extraLabelsStateForWidget = state.extraLabelsByWidgetID[widgetID].map((labelsState) => {
    if (labelsState.responseID === responseID && labelsState.rowIndex === rowIndex) {
      return {
        isError: true,
        isLoading: false,
        rowIndex,
        responseID,
      };
    }

    return labelsState;
  });

  return {
    ...state,
    extraLabelsByWidgetID: {
      ...state.extraLabelsByWidgetID,
      [widgetID]: extraLabelsStateForWidget,
    },
  };
}

function handleLoadExtraConceptsSuccess(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof loadExtraConceptsSuccess>,
): IOpenEndedWidgetsReducer {
  const { widgetID, responseID, rowIndex, extraConcepts } = action.payload;
  const responses = cloneDeep(state.responsesByWidgetID[widgetID]);
  if (responses[rowIndex].id === responseID) {
    responses[rowIndex].addAdditionalLabels(extraConcepts);
  } else {
    const responseToChange = responses.find((response) => response.id === responseID);
    if (responseToChange) {
      responseToChange.addAdditionalLabels(extraConcepts);
    } else {
      console.error(`Unable to find the response ${responseID} in ${widgetID} at ${rowIndex}`);
    }
  }

  const extraLabelsStateForWidget = state.extraLabelsByWidgetID[widgetID].map((labelsState) => {
    if (labelsState.responseID === responseID && labelsState.rowIndex === rowIndex) {
      return {
        isError: false,
        isLoading: false,
        rowIndex,
        responseID,
      };
    }

    return labelsState;
  });

  return {
    ...state,
    responsesByWidgetID: {
      ...state.responsesByWidgetID,
      [action.payload.widgetID]: [...responses],
    },
    extraLabelsByWidgetID: {
      ...state.extraLabelsByWidgetID,
      [widgetID]: extraLabelsStateForWidget,
    },
  };
}

function handleInvalidateExtraConceptsLoadingState(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof invalidateExtraConceptLoadingState>,
): IOpenEndedWidgetsReducer {
  const { widgetID, responseID, rowIndex } = action.payload;
  const extraLabelsStateForWidget = state.extraLabelsByWidgetID[widgetID].filter(
    (labelsState) => labelsState.responseID !== responseID && labelsState.rowIndex !== rowIndex,
  );

  return {
    ...state,
    extraLabelsByWidgetID: {
      ...state.extraLabelsByWidgetID,
      [widgetID]: extraLabelsStateForWidget,
    },
  };
}

function handleToggleBookmarkResponseStart(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof toggleBookmarkResponseStart>) {
  const { widgetID, rowIndex, responseID } = action.payload;
  const bookmarkedResponsesForWidgetID = state.bookmarkedResponsesByWidgetID[widgetID] || [];
  return {
    ...state,
    bookmarkedResponsesByWidgetID: {
      ...state.bookmarkedResponsesByWidgetID,
      [widgetID]: [
        ...bookmarkedResponsesForWidgetID,
        {
          isLoading: true,
          isError: false,
          rowIndex,
          responseID,
        },
      ],
    },
  };
}

function handleToggleBookmarkResponseFailure(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof toggleBookmarkResponseFailure>) {
  const { widgetID, rowIndex, responseID } = action.payload;
  const bookmarkedResponsesForWidgetID = state.bookmarkedResponsesByWidgetID[widgetID].map((labelsState) => {
    if (labelsState.responseID === responseID && labelsState.rowIndex === rowIndex) {
      return {
        isError: true,
        isLoading: false,
        rowIndex,
        responseID,
      };
    }

    return labelsState;
  });

  return {
    ...state,
    bookmarkedResponsesByWidgetID: {
      ...state.bookmarkedResponsesByWidgetID,
      [widgetID]: bookmarkedResponsesForWidgetID,
    },
  };
}

function handleToggleBookmarkResponseSuccess(state: IOpenEndedWidgetsReducer, action: ReturnType<typeof toggleBookmarkResponseSuccess>) {
  const { widgetID, responseID, rowIndex, isBookmarked } = action.payload;
  const responses = cloneDeep(state.responsesByWidgetID[widgetID]);
  if (responses[rowIndex].id === responseID) {
    responses[rowIndex].bookmarked = isBookmarked;
  } else {
    const responseToChange = responses.find((response) => response.id === responseID);
    if (responseToChange) {
      responseToChange.bookmarked = isBookmarked;
    } else {
      console.error(`Unable to find the response ${responseID} in ${widgetID} at ${rowIndex}`);
    }
  }

  const bookmarkedResponsesForWidget = state.bookmarkedResponsesByWidgetID[widgetID].map((labelsState) => {
    if (labelsState.responseID === responseID && labelsState.rowIndex === rowIndex) {
      return {
        isError: false,
        isLoading: false,
        rowIndex,
        responseID,
      };
    }

    return labelsState;
  });

  return {
    ...state,
    responsesByWidgetID: {
      ...state.responsesByWidgetID,
      [widgetID]: [...responses],
    },
    bookmarkedResponsesByWidgetID: {
      ...state.bookmarkedResponsesByWidgetID,
      [widgetID]: bookmarkedResponsesForWidget,
    },
  };
}

function handleInvalidateBookmarksLoadingState(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof invalidateBookmarksLoadingState>,
): IOpenEndedWidgetsReducer {
  const { widgetID, responseID, rowIndex } = action.payload;
  const bookmarksStateForWidget = state.bookmarkedResponsesByWidgetID[widgetID].filter(
    (bookmarkState) => bookmarkState.responseID !== responseID && bookmarkState.rowIndex !== rowIndex,
  );

  return {
    ...state,
    bookmarkedResponsesByWidgetID: {
      ...state.bookmarkedResponsesByWidgetID,
      [widgetID]: bookmarksStateForWidget,
    },
  };
}

function handleWidgetQueriesChange(
  state: IOpenEndedWidgetsReducer,
  action: ReturnType<typeof updateWidgetQueries>,
): IOpenEndedWidgetsReducer {
  const { widgetID, responsesQuery, insightsQuery } = action.payload;
  const configByWidgetID = state.configsByWidgetID[widgetID];
  return {
    ...state,
    configsByWidgetID: { ...state.configsByWidgetID, [widgetID]: { ...configByWidgetID, query: responsesQuery, insightsQuery } },
  };
}

export default OpenEndedWidgetReducer;
