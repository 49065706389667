import { Middleware } from 'redux';
import { RegenerationStatus } from '../../types/DataSource';
import {
  DASHBOARD_GET_REGENERATION_STATUS_FAILURE,
  DASHBOARD_GET_REGENERATION_STATUS_SUCCESS,
  DASHBOARD_REGENERATION_IN_PROGRESS,
  DASHBOARD_WATCHER_CLEAR,
  DASHBOARD_WATCHER_INIT,
  dashboardClearWatcher,
  dashboardGetRegenerationStatusFailure,
  dashboardGetRegenerationStatusSuccess,
  dashboardInitWidgetWatcher,
  dashboardRegenerationInProgress,
} from '../actions/dashboard';

import { TypedDispatch } from '../hooks';
import { IApplicationState } from '../reducers';
import { dashboardDataSourceReload } from '../thunks/dashboard';
import { abortCheckDataSources, checkDataSources } from '../thunks/interviewGenerateWatcher';

const CHECK_REGENERATION_STATUS_INTERVAL = 5000;

const dataSourcesInProgress = new Set<string>();
let dashboardID: string | undefined;
let interval: ReturnType<typeof setInterval> | null = null;

const interviewGenerateWatcher: Middleware<object, IApplicationState, TypedDispatch> =
  ({ dispatch }) =>
  (next) =>
  (action) => {
    if (!action) {
      return;
    }

    if (action?.type === DASHBOARD_WATCHER_INIT) {
      const {
        payload: { dashboardID: newDashboardID },
      } = action as ReturnType<typeof dashboardInitWidgetWatcher>;
      if (!dashboardID) {
        dashboardID = newDashboardID;
      } else {
        console.error(
          `Trying to re-initialize the interview generate watcher DASHBOARD_ID from dashboard with ID: ${newDashboardID}, current dashboardID is: ${dashboardID}`,
        );
      }

      if (!interval) {
        interval = setInterval(() => {
          const dataSourceIDs = Array.from(dataSourcesInProgress.keys());
          if (dataSourceIDs.length > 0 && dashboardID) {
            dispatch(checkDataSources({ dataSourceIDs, dashboardID }));
          }
        }, CHECK_REGENERATION_STATUS_INTERVAL);
      } else {
        console.error(
          `Trying to re-initialize the interview generate watcher INTERVAL from dashboard with ID: ${newDashboardID}, current dashboardID is: ${dashboardID}`,
        );
      }
    }

    if (action?.type === DASHBOARD_REGENERATION_IN_PROGRESS) {
      const {
        payload: { dataSourceIDs },
      } = action as ReturnType<typeof dashboardRegenerationInProgress>;
      for (const ds of dataSourceIDs) {
        if (!dataSourcesInProgress.has(ds)) {
          dataSourcesInProgress.add(ds);
        } else {
          console.warn(`DataSource with ID: ${ds} is already in list of regenerations`);
        }
      }
    }

    if (action?.type === DASHBOARD_GET_REGENERATION_STATUS_SUCCESS) {
      const {
        payload: { items },
      } = action as ReturnType<typeof dashboardGetRegenerationStatusSuccess>;

      for (const { dataSourceID, status } of items) {
        switch (status) {
          case RegenerationStatus.Generated:
            if (dataSourcesInProgress.has(dataSourceID)) {
              dataSourcesInProgress.delete(dataSourceID);
              // reload dataSource
              dispatch(dashboardDataSourceReload({ dataSourceID }));
            } else {
              console.log(
                `Received dataSourceID: ${dataSourceID} with status ${status}, but it was not in the list: ${dataSourcesInProgress}`,
              );
            }
            break;
          case RegenerationStatus.Generating:
            if (!dataSourcesInProgress.has(dataSourceID)) {
              dataSourcesInProgress.add(dataSourceID);
            }
            break;
          default:
            console.error(`Received unknown regeneration status: ${status} for dataSourceID: ${dataSourceID}`);
        }
      }
    }

    if (action?.type === DASHBOARD_GET_REGENERATION_STATUS_FAILURE) {
      const {
        payload: { dataSourceIDs },
      } = action as ReturnType<typeof dashboardGetRegenerationStatusFailure>;
      for (const dataSourceID of dataSourceIDs) {
        if (dataSourcesInProgress.has(dataSourceID)) {
          dataSourcesInProgress.delete(dataSourceID);
          // reload dataSource
          dispatch(dashboardDataSourceReload({ dataSourceID }));
        } else {
          console.warn(`DataSource with ID: ${dataSourceID} was failed to get status, removing from the list of dataSourcesInProgress`);
        }
      }
    }

    if (action?.type === DASHBOARD_WATCHER_CLEAR) {
      const { payload } = action as ReturnType<typeof dashboardClearWatcher>;
      dataSourcesInProgress.clear();
      if (payload.dashboardID === dashboardID) {
        dashboardID = undefined;
      } else {
        console.warn(`Trying to clear the unmatching dashboardID: ${payload.dashboardID}, when existing one is: ${dashboardID}`);
        dashboardID = undefined;
      }

      if (interval) {
        clearInterval(interval);
        interval = null;
      } else {
        console.warn(`Trying to clear the interval for dashboardID: ${payload.dashboardID}, but it's not set`);
      }

      abortCheckDataSources();
    }

    return next(action);
  };

export default interviewGenerateWatcher;
