import { Query } from '../../types/Query';
import { Participant } from '../reducers/interviewWidget/types';
import { InsightsQuery } from '../thunks/aiInsights';
import { Response } from '../thunks/interviewWidget';

export const INTERVIEW_WIDGET_WIDGET_INITIALIZE = 'INTERVIEW_WIDGET_INITIALIZE';

export const INTERVIEW_WIDGET_SELECT_CONCEPT = 'INTERVIEW_WIDGET_SELECT_CONCEPT';
export const INTERVIEW_WIDGET_QUERIES_CHANGE = 'INTERVIEW_WIDGET_QUERIES_CHANGE';

export const INTERVIEW_WIDGET_LOAD_ANSWERS_START = 'INTERVIEW_WIDGET_LOAD_ANSWERS_START';
export const INTERVIEW_WIDGET_LOAD_ANSWERS_FAILURE = 'INTERVIEW_WIDGET_LOAD_ANSWERS_FAILURE';
export const INTERVIEW_WIDGET_LOAD_ANSWERS_SUCCESS = 'INTERVIEW_WIDGET_LOAD_ANSWERS_SUCCESS';

export const INTERVIEW_WIDGET_LOAD_PARTICIPANTS_START = 'INTERVIEW_WIDGET_LOAD_PARTICIPANTS_START';
export const INTERVIEW_WIDGET_LOAD_PARTICIPANTS_FAILURE = 'INTERVIEW_WIDGET_LOAD_PARTICIPANTS_FAILURE';
export const INTERVIEW_WIDGET_LOAD_PARTICIPANTS_SUCCESS = 'INTERVIEW_WIDGET_LOAD_PARTICIPANTS_SUCCESS';

export const INTERVIEW_WIDGET_CREATE_PARTICIPANT_START = 'INTERVIEW_WIDGET_CREATE_PARTICIPANT_START';
export const INTERVIEW_WIDGET_CREATE_PARTICIPANT_FAILURE = 'INTERVIEW_WIDGET_CREATE_PARTICIPANT_FAILURE';
export const INTERVIEW_WIDGET_CREATE_PARTICIPANT_SUCCESS = 'INTERVIEW_WIDGET_CREATE_PARTICIPANT_SUCCESS';

export const INTERVIEW_WIDGET_UPDATE_PARTICIPANT_START = 'INTERVIEW_WIDGET_UPDATE_PARTICIPANT_START';
export const INTERVIEW_WIDGET_UPDATE_PARTICIPANT_FAILURE = 'INTERVIEW_WIDGET_UPDATE_PARTICIPANT_FAILURE';
export const INTERVIEW_WIDGET_UPDATE_PARTICIPANT_SUCCESS = 'INTERVIEW_WIDGET_UPDATE_PARTICIPANT_SUCCESS';

export const INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_START = 'INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_START';
export const INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_FAILURE = 'INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_FAILURE';
export const INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_SUCCESS = 'INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_SUCCESS';

export const INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_START = 'INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_START';
export const INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_FAILURE = 'INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_FAILURE';
export const INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_SUCCESS = 'INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_SUCCESS';

export const INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_START = 'INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_START';
export const INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_FAILURE = 'INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_FAILURE';
export const INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_SUCCESS = 'INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_SUCCESS';

export const INTERVIEW_WIDGET_SEARCH_STRING_CHANGE = 'INTERVIEW_WIDGET_SEARCH_STRING_CHANGE';

export const INTERVIEW_WIDGET_RESET_WIDGETS = 'INTERVIEW_WIDGET_RESET_WIDGETS';

interface IInitialize {
  dataSourceID: string;
  widgetID: string;
}
export const initialize = ({ dataSourceID, widgetID }: IInitialize) => ({
  type: INTERVIEW_WIDGET_WIDGET_INITIALIZE,
  payload: {
    dataSourceID,
    widgetID,
  },
});

interface ILoadAnswersStart {
  widgetID: string;
  offset: number;
  isBookmarkOnly: boolean;
  filterQuery: Query;
}
export const loadAnswersStart = ({ widgetID, offset, filterQuery, isBookmarkOnly }: ILoadAnswersStart) => ({
  type: INTERVIEW_WIDGET_LOAD_ANSWERS_START,
  payload: {
    widgetID,
    offset,
    filterQuery,
    isBookmarkOnly,
  },
});

interface ILoadAnswersFailure {
  widgetID: string;
}
export const loadAnswersFailure = ({ widgetID }: ILoadAnswersFailure) => ({
  type: INTERVIEW_WIDGET_LOAD_ANSWERS_FAILURE,
  payload: {
    widgetID,
  },
});

interface ILoadAnswersSuccess {
  widgetID: string;
  totalResponses: number;
  responses: Response[];
}
export const loadAnswersSuccess = ({ widgetID, totalResponses, responses }: ILoadAnswersSuccess) => ({
  type: INTERVIEW_WIDGET_LOAD_ANSWERS_SUCCESS,
  payload: {
    widgetID,
    totalResponses,
    responses,
  },
});

interface ISelectConcept {
  title: string | null;
  widgetID: string;
  options?: {
    withScroll?: boolean;
  };
}
export const selectConcept = ({ title, widgetID, options = {} }: ISelectConcept) => ({
  type: INTERVIEW_WIDGET_SELECT_CONCEPT,
  payload: {
    title,
    widgetID,
    options,
  },
});

interface ILoadParticipantsStart {
  dataSourceID: string;
}
export const loadParticipantsStart = ({ dataSourceID }: ILoadParticipantsStart) => ({
  type: INTERVIEW_WIDGET_LOAD_PARTICIPANTS_START,
  payload: {
    dataSourceID,
  },
});

interface ILoadParticipantsFailure {
  dataSourceID: string;
}
export const loadParticipantsFailure = ({ dataSourceID }: ILoadParticipantsFailure) => ({
  type: INTERVIEW_WIDGET_LOAD_PARTICIPANTS_FAILURE,
  payload: {
    dataSourceID,
  },
});

interface ILoadParticipantsSuccess {
  dataSourceID: string;
  participants: Participant[];
}
export const loadParticipantsSuccess = ({ dataSourceID, participants }: ILoadParticipantsSuccess) => ({
  type: INTERVIEW_WIDGET_LOAD_PARTICIPANTS_SUCCESS,
  payload: {
    dataSourceID,
    participants,
  },
});

interface ICreateParticipantStart {}
export const createParticipantStart = ({}: ICreateParticipantStart) => ({
  type: INTERVIEW_WIDGET_CREATE_PARTICIPANT_START,
  payload: {},
});

interface ICreateParticipantFailure {}
export const createParticipantFailure = ({}: ICreateParticipantFailure) => ({
  type: INTERVIEW_WIDGET_CREATE_PARTICIPANT_FAILURE,
  payload: {},
});

interface ICreateParticipantSuccess {}
export const createParticipantSuccess = ({}: ICreateParticipantSuccess) => ({
  type: INTERVIEW_WIDGET_CREATE_PARTICIPANT_SUCCESS,
  payload: {},
});

interface IUpdateParticipantStart {
  dataSourceID: string;
}
export const updateParticipantStart = ({}: IUpdateParticipantStart) => ({
  type: INTERVIEW_WIDGET_UPDATE_PARTICIPANT_START,
  payload: {},
});

interface IUpdateParticipantFailure {}
export const updateParticipantFailure = ({}: IUpdateParticipantFailure) => ({
  type: INTERVIEW_WIDGET_UPDATE_PARTICIPANT_FAILURE,
  payload: {},
});

interface IUpdateParticipantSuccess {}
export const updateParticipantSuccess = ({}: IUpdateParticipantSuccess) => ({
  type: INTERVIEW_WIDGET_UPDATE_PARTICIPANT_SUCCESS,
  payload: {},
});

interface IAssignTopicToResponseStart {
  widgetID: string;
  responseID: string;
}
export const assignTopicToResponseStart = ({ widgetID, responseID }: IAssignTopicToResponseStart) => ({
  type: INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_START,
  payload: {
    widgetID,
    responseID,
  },
});

interface IAssignTopicToResponseFailure {
  widgetID: string;
  responseID: string;
}
export const assignTopicToResponseFailure = ({ widgetID, responseID }: IAssignTopicToResponseFailure) => ({
  type: INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_FAILURE,
  payload: {
    widgetID,
    responseID,
  },
});

interface IAssignTopicToResponseSuccess {
  widgetID: string;
  responseID: string;
  label: string;
}
export const assignTopicToResponseSuccess = ({ widgetID, responseID, label }: IAssignTopicToResponseSuccess) => ({
  type: INTERVIEW_WIDGET_ASSIGN_TOPIC_TO_RESPONSE_SUCCESS,
  payload: {
    widgetID,
    responseID,
    label,
  },
});

interface IRemoveTopicFromResponseStart {
  widgetID: string;
  responseID: string;
}
export const removeTopicFromResponseStart = ({ widgetID, responseID }: IRemoveTopicFromResponseStart) => ({
  type: INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_START,
  payload: {
    widgetID,
    responseID,
  },
});

interface IRemoveTopicFromResponseFailure {
  widgetID: string;
  responseID: string;
}
export const removeTopicFromResponseFailure = ({ widgetID, responseID }: IRemoveTopicFromResponseFailure) => ({
  type: INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_FAILURE,
  payload: {
    widgetID,
    responseID,
  },
});

interface IRemoveTopicFromResponseSuccess {
  widgetID: string;
  responseID: string;
  label: string;
}
export const removeTopicFromResponseSuccess = ({ widgetID, responseID, label }: IRemoveTopicFromResponseSuccess) => ({
  type: INTERVIEW_WIDGET_REMOVE_TOPIC_FROM_RESPONSE_SUCCESS,
  payload: {
    widgetID,
    responseID,
    label,
  },
});

interface IChangeParticipantForResponseStart {}
export const changeParticipantForResponseStart = ({}: IChangeParticipantForResponseStart) => ({
  type: INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_START,
  payload: {},
});

interface IChangeParticipantForResponseFailure {}
export const changeParticipantForResponseFailure = ({}: IChangeParticipantForResponseFailure) => ({
  type: INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_FAILURE,
  payload: {},
});

interface IChangeParticipantForResponseSuccess {}
export const changeParticipantForResponseSuccess = ({}: IChangeParticipantForResponseSuccess) => ({
  type: INTERVIEW_WIDGET_CHANGE_PARTICIPANT_FOR_RESPONSE_SUCCESS,
  payload: {},
});

interface IChangeSearchString {
  widgetID: string;
  searchString: string;
}
export const changeSearchString = ({ widgetID, searchString }: IChangeSearchString) => ({
  type: INTERVIEW_WIDGET_SEARCH_STRING_CHANGE,
  payload: {
    widgetID,
    searchString,
  },
});

interface IUpdateWidgetQueries {
  widgetID: string;
  responsesQuery: Query;
  insightsQuery: InsightsQuery[];
}
export const updateWidgetQueries = ({ widgetID, responsesQuery, insightsQuery }: IUpdateWidgetQueries) => ({
  type: INTERVIEW_WIDGET_QUERIES_CHANGE,
  payload: {
    widgetID,
    responsesQuery,
    insightsQuery,
  },
});

export const resetWidgetsStates = () => ({
  type: INTERVIEW_WIDGET_RESET_WIDGETS,
  payload: {},
});
