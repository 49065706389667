import React from 'react';

import { Header } from '../styles';

import { AboutLink, BeehiveLogoStyled, Container, HyperLinkIconStyled, InfoStyled, Link, PageContainerStyled } from './styles';

function FreeTierNotAvailable() {
  return (
    <PageContainerStyled>
      <Container>
        <BeehiveLogoStyled />
        <Header>Free Tier Not Available in Your Area</Header>
        <InfoStyled>Thank you for your interest! Unfortunately, our free tier is currently not available in your region.</InfoStyled>
        <InfoStyled>
          However, we do make exceptions - feel free to reach out to us at{' '}
          <Link href='mailto:support@beehive.ai?subject=Free Tier Not Available in My Area'>support@beehive.ai</Link> to share your use
          case, and we will be happy to consider it.
        </InfoStyled>
        <AboutLink href='https://www.beehive.ai/' target='_blank'>
          <HyperLinkIconStyled />
          About Beehive AI
        </AboutLink>
      </Container>
    </PageContainerStyled>
  );
}

export default FreeTierNotAvailable;
