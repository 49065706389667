import styled from 'styled-components';
import { TextButton } from '../../../../components/UI/Button';

export const Container = styled.div`
  width: 1079px;
  height: 363px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 44px;
  position: relative;
`;
export const Background = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
`;

export const Header = styled.div`
  font-family: Rubik;
  display: flex;
  width: 100%;
  z-index: 1;
`;
export const LeftPart = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0;
`;
export const Title = styled.span`
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
export const SubTitle = styled.span`
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  color: var(--dark-gray);
`;
export const SupportLink = styled.span`
  color: var(--dark-purple);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  text-decoration-line: underline;
  cursor: pointer;
`;
export const RightPart = styled.div``;

export const StatusContainer = styled.div`
  display: flex;
  gap: 24px;
  flex-direction: column;

  color: var(--black);
  font-family: Rubik;
  z-index: 1;
`;

export const StatusHeader = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;

  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
`;
export const StatusContent = styled.div`
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FooterLeft = styled.div``;
export const FooterRight = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;
export const ReturnToHivesButton = styled(TextButton)`
  && {
    padding: 10px 40px;
    font-size: 18px;
  }

  svg {
    fill: white;
  }
`;
