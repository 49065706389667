import { useSelector } from 'react-redux';

import WidgetsGroup from '../../WidgetsGroup/WidgetsGroup';
import { DashboardContainer } from '../../dashboard/style';
import EmptyDashboard from './EmptyDashboard';
import SummaryDataProvider from '../../../contexts/SummaryDataContext';

const ResponsesTab = ({ bottomMargin }) => {
  const widgetGroups = useSelector((state) => state.dashboard.widgetGroups);

  if (widgetGroups.length === 0 || (widgetGroups.length === 1 && widgetGroups[0].isDefault && widgetGroups[0].layout.length === 0)) {
    return <EmptyDashboard />;
  }

  const groups = widgetGroups
    .filter((widgetGroup) => widgetGroup.layout.length > 0 || !widgetGroup.isDefault)
    .map((widgetGroup, index) => <WidgetsGroup key={widgetGroup.id} data={widgetGroup} index={index} />);
  return (
    <SummaryDataProvider>
      <DashboardContainer $marginBottom={bottomMargin}>{groups}</DashboardContainer>
    </SummaryDataProvider>
  );
};

export default ResponsesTab;
