import Dialog from '@material-ui/core/Dialog';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/cross-icon.svg';
import { FilledButton, OutlinedButton } from '../../UI/Button';

import styles from './styles.module.css';

interface IInfoModal {
  headerText: string;
  bodyText: string | React.JSX.Element;
  mainActionButtonText: string;
  onCancelButtonClick: () => void;
  onMainActionButtonClick: () => void;
  withCancelButton?: boolean;
  cancelButtonText?: string;
}

function InfoModal({
  headerText,
  bodyText,
  mainActionButtonText,
  onCancelButtonClick,
  onMainActionButtonClick,
  withCancelButton = false,
  cancelButtonText = 'Cancel',
}: IInfoModal) {
  return (
    <Dialog maxWidth={false} open={true} onClose={onCancelButtonClick}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerText}>{headerText}</div>
          <CloseIcon className={styles.closeIcon} onClick={onCancelButtonClick} />
        </div>
        <div className={styles.body}>{bodyText}</div>
        <div className={styles.footer}>
          {withCancelButton && <OutlinedButton onClick={onCancelButtonClick}>{cancelButtonText}</OutlinedButton>}
          <FilledButton onClick={onMainActionButtonClick}>{mainActionButtonText}</FilledButton>
        </div>
      </div>
    </Dialog>
  );
}

export default InfoModal;
