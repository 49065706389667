import React, { useState } from 'react';

import HaveExpertMapFiles from './HaveExpertMapFiles';

export enum FLOW_STATES {
  IDLE = 'IDLE',
  PROMPT = 'PROMPT',
  FINISH = 'FINISH',
}

interface IHaveExpertMapFilesContainer {
  onSubmitClick: () => void;
}
function HaveExpertMapFilesContainer({ onSubmitClick }: IHaveExpertMapFilesContainer) {
  const [flowState, setFlowState] = useState<FLOW_STATES>(FLOW_STATES.IDLE);

  function handleReportIssueButtonClick() {
    setFlowState(FLOW_STATES.PROMPT);
  }

  function handleCancelButtonClick() {
    setFlowState(FLOW_STATES.IDLE);
  }

  function handleAcceptButtonClick() {
    setFlowState(FLOW_STATES.IDLE);
    onSubmitClick();
  }

  return (
    <HaveExpertMapFiles
      flowState={flowState}
      onHaveExpertMapFilesButtonClick={handleReportIssueButtonClick}
      onCancelButtonClick={handleCancelButtonClick}
      onAcceptButtonClick={handleAcceptButtonClick}
    />
  );
}

export default HaveExpertMapFilesContainer;
