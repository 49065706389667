import React from 'react';
import { GeneratedInsight } from '../../../../store/actions/aiInsights';

import { Concept } from '../../../../store/reducers/aiInsights';
import { ThumbsDown, ThumbsUp } from '../../../Thumbs/Thumbs';
import { ConceptPill } from '../Labels/ConceptPill';
import { getConceptTooltipName } from '../../OpenEndedWidget/Data/AIInsights/utils';
import InsightPrompt from '../InsightPrompt/InsightPrompt';
import {
  AIInsightContainer,
  HeaderContainer,
  HeaderContainerLeftPart,
  StyledBeehiveLogo,
} from '../../OpenEndedWidget/Data/AIInsights/Components/styles';
//TODO: uncomment for https://beehiveai.atlassian.net/browse/BDTC-3750 import CopyToClipboardContent from '../CopyToClipboardContent';
import { ThumbStatus } from './GeneratedInsightsContainer';
import { Insights, InsightText, KeyQuoteButton, QuoteIconStyled, ReactionsBlock, RelatedConcepts, StyledReloadIcon } from './styles';

interface IGeneratedInsights {
  id: string;
  availableFilters: string[];
  generatedInsights: GeneratedInsight[];
  relatedConcepts: Concept[];
  isWidgetLevelFilterApplied: boolean;
  segmentsNames?: string[];
  customPrompt?: string;
  selectedConcept?: Concept;
  widgetSelectedConcept?: Concept;
  isDisabled?: boolean;
  thumbStatus?: ThumbStatus;
  onGenerateClick: (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => void;
  onThumbsClick: (thumbStatus: ThumbStatus) => void;
  onQuoteClick: (insight: GeneratedInsight) => void;
  onCopyClick?: (html: string | undefined, text: string | null | undefined) => void;
}

function GeneratedInsights({
  id,
  availableFilters,
  selectedConcept,
  isWidgetLevelFilterApplied,
  widgetSelectedConcept,
  generatedInsights,
  relatedConcepts,
  customPrompt,
  segmentsNames,
  isDisabled = false,
  thumbStatus,
  onGenerateClick,
  onThumbsClick,
  onQuoteClick,
}: IGeneratedInsights) {
  // TODO: uncomment for https://beehiveai.atlassian.net/browse/BDTC-3750 const clipboardCopyContentRef = useRef<null | HTMLDivElement>(null);
  return (
    <AIInsightContainer data-testid={`generated-insights-${id}`}>
      <div>
        <HeaderContainer>
          <HeaderContainerLeftPart>
            <StyledBeehiveLogo />
            <InsightPrompt
              availableFilters={availableFilters}
              customPrompt={customPrompt}
              selectedConcept={selectedConcept}
              segmentsNames={segmentsNames}
              isWidgetLevelFilterApplied={isWidgetLevelFilterApplied}
            />
          </HeaderContainerLeftPart>
          <ReactionsBlock>
            <ThumbsUp
              data-testid='thumbs-up'
              data-tooltip-id='base-tooltip'
              data-tooltip-content='Like generated insights'
              $isSelected={thumbStatus === ThumbStatus.UP}
              onClick={onThumbsClick.bind(null, ThumbStatus.UP)}
            />
            <ThumbsDown
              data-testid='thumbs-down'
              data-tooltip-id='base-tooltip'
              data-tooltip-content='Dislike generated insights'
              $isSelected={thumbStatus === ThumbStatus.DOWN}
              onClick={onThumbsClick.bind(null, ThumbStatus.DOWN)}
            />
            <StyledReloadIcon data-testid='reload' onClick={onGenerateClick.bind(null, selectedConcept, true, id)} />
          </ReactionsBlock>
        </HeaderContainer>
        <Insights data-testid='insights'>
          {generatedInsights.map((insight) => (
            <InsightText key={insight.insight}>
              {insight.insight}
              {renderKeyQuote(insight)}
            </InsightText>
          ))}
        </Insights>
        {renderOptionalRelatedConcepts()}
      </div>
    </AIInsightContainer>
  );
  // TODO: uncomment for https://beehiveai.atlassian.net/browse/BDTC-3750
  // <HiddenCopyToClipboardContent ref={clipboardCopyContentRef}>
  //   <CopyToClipboardContent
  //     generatedInsights={generatedInsights}
  //     customPrompt={customPrompt}
  //     selectedConcept={selectedConcept}
  //   />
  // </HiddenCopyToClipboardContent>
  function renderKeyQuote(insight: GeneratedInsight) {
    if (!insight.keyQuotes.length) {
      return null;
    }

    return (
      <KeyQuoteButton
        data-tooltip-id='base-tooltip'
        data-tooltip-content='Top Supporting Sources'
        onClick={onQuoteClick.bind(null, insight)}
      >
        <QuoteIconStyled />
      </KeyQuoteButton>
    );
  }

  function renderOptionalRelatedConcepts() {
    if (!relatedConcepts.length) {
      return;
    }

    return (
      <>
        <b>You might be interested in key insights for:</b>
        <RelatedConcepts data-testid='concepts'>
          {relatedConcepts.map((concept, idx) => (
            <ConceptPill
              key={`${idx}-${concept.title}`}
              text={concept.title}
              isSelected={widgetSelectedConcept?.title === concept.title}
              isClickable={true}
              isHierarchy={false}
              isParent={false}
              isDisabled={isDisabled || !concept.isEnabled}
              tooltipContent={getConceptTooltipName(concept)}
              onClick={onGenerateClick.bind(null, concept, false)}
            />
          ))}
        </RelatedConcepts>
      </>
    );
  }
}

export default GeneratedInsights;
