import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { ReactComponent as SendIcon } from '../../../../../assets/icons/send-icon.svg';
import { ReactComponent as StopIcon } from '../../../../../assets/icons/stop-icon.svg';
import styles from './Input.module.css';

interface IInput {
  isGenerating: boolean;
  query: string;
  onInputChange: (newQuery: string) => void;
  onInputHeightChange: (newHeight: number) => void;
  onSubmit: () => void;
}

const MAX_HEIGHT = 400; // px
const ENTER_KEY = 'Enter';

function Input({ isGenerating, query, onInputChange, onSubmit, onInputHeightChange }: IInput) {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);
  const [previousHeight, setPreviousHeight] = useState(textAreaRef?.current?.style.height || 0);

  useEffect(
    function watchForQueryChangeAndResize() {
      if (!textAreaRef.current) {
        return;
      }

      textAreaRef.current.style.height = 'auto';
      // Set height to match content
      const newHeight = Math.min(textAreaRef.current.scrollHeight, MAX_HEIGHT);
      textAreaRef.current.style.height = newHeight + 'px';

      if (previousHeight === 0) {
        setPreviousHeight(newHeight);
      } else if (previousHeight !== newHeight) {
        setPreviousHeight(newHeight);
        onInputHeightChange(newHeight);
      }
    },
    [query],
  );

  function handleSubmit() {
    if (!textAreaRef.current || query.trim().length === 0) {
      return;
    }
    textAreaRef.current.style.height = 'auto';

    const newHeight = Math.min(textAreaRef.current.scrollHeight, MAX_HEIGHT);
    setPreviousHeight(newHeight);
    onInputHeightChange(newHeight);

    onSubmit();
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    // unify behaviour for Mac & Win by appending new line
    if ((e.key === ENTER_KEY && e.shiftKey) || (e.key === ENTER_KEY && e.ctrlKey)) {
      onInputChange(`${query}\n`);
      e.preventDefault();
    } else if (e.key === ENTER_KEY) {
      handleSubmit();
      e.preventDefault();
    }
  }

  function handleInputChange(event: ChangeEvent<HTMLTextAreaElement>) {
    if (!textAreaRef.current) {
      return;
    }

    onInputChange(event.target.value);
  }

  const Icon = isGenerating ? StopIcon : SendIcon;
  return (
    <div className={styles.container}>
      <textarea
        rows={1}
        ref={textAreaRef}
        className={styles.dynamicTextarea}
        placeholder='Ask a question...'
        value={query}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
      />
      <Icon className={styles.actionIcon} onClick={handleSubmit} />
    </div>
  );
}

export default Input;
