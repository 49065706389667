import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { LABEL_NOT_APPLICABLE } from '../../../utils/lib';

const LabelsContainerGrid = styled(Grid)`
  &.MuiGrid-root {
    background-color: transparent;
    overflow-y: scroll;
    flex-wrap: nowrap;
    height: calc(100% - 24px);
    width: 100%;
  }
`;

const createLabelsListComponent = (Component) => {
  return ({ labels, onLabelClick, selectedLabelItem, clickable, disabled, onLabelExpand, ...props }) => {
    const [expanded, setExpanded] = useState({});
    const pendingScroll = useRef(false);
    const labelsContainerRef = useRef(false);

    const labelsToShow = useMemo(
      () => labels.filter((label) => label.parentLabel === null || expanded[label.parentKey]),
      [labels, expanded],
    );
    const refs = useMemo(() => labelsToShow.reduce((acc, label) => ({ ...acc, [label.key]: React.createRef() }), {}), [labelsToShow]);

    useEffect(() => {
      if (selectedLabelItem !== null) {
        const labelsToExpand = labels.filter((label) => label.title === selectedLabelItem.title);
        const newExpanded = {};
        for (let labelToExpand of labelsToExpand) {
          const parentLabelKey = labelToExpand.parentKey ? labelToExpand.parentKey : labelToExpand.key;
          newExpanded[parentLabelKey] = true;
        }
        pendingScroll.current = true;
        setExpanded({ ...expanded, ...newExpanded });
      }
    }, [selectedLabelItem, labels]);

    useEffect(() => {
      // scroll to the expanded element after adding new elements
      const parentKeys = Object.keys(expanded);
      if (parentKeys.length && pendingScroll.current && selectedLabelItem && selectedLabelItem.options?.showWithScroll) {
        let scrollRef = refs[parentKeys[0]];
        const { title, breakdownValue } = selectedLabelItem;
        if (title in refs) {
          scrollRef = refs[title];
        } else if (`${title}-${breakdownValue}` in refs) {
          scrollRef = refs[`${title}-${breakdownValue}`];
        } else {
          return;
        }
        if (!scrollRef?.current || !labelsContainerRef?.current) {
          return;
        }
        labelsContainerRef.current.scrollTo({
          top: scrollRef.current.offsetTop - labelsContainerRef.current.offsetTop,
          behavior: 'smooth',
        });
        pendingScroll.current = false;
      } else if (!selectedLabelItem?.options?.showWithScroll) {
        pendingScroll.current = false;
      }
    }, [selectedLabelItem, refs]);

    function handleToggle(key) {
      if (!expanded[key]) {
        onLabelExpand();
      }
      setExpanded({ ...expanded, [key]: !expanded[key] });
    }

    return (
      <LabelsContainerGrid container direction='column' className='labels-list' ref={labelsContainerRef}>
        {labelsToShow.map(renderLabel)}
      </LabelsContainerGrid>
    );

    function renderLabel(label) {
      const isParent = /^\p{Lu}/u.test(label.title) || (label.children && label.children.length > 0);
      const isCurrentParentSelected = isParent && !!label.children?.find((c) => c === selectedLabelItem?.title);
      const selected =
        selectedLabelItem !== null &&
        (isCurrentParentSelected ||
          (selectedLabelItem.title === label.title &&
            (!selectedLabelItem.breakdownValue || selectedLabelItem.breakdownValue === label.breakdownValue)));
      const opened = expanded[label.key];
      return (
        <Grid item key={label.key} style={{ marginBottom: '1em' }} ref={refs[label.key]}>
          <Component
            {...props}
            label={label}
            selected={selected}
            opened={opened}
            clickable={clickable}
            disabled={disabled}
            isParent={isParent || label.title === LABEL_NOT_APPLICABLE}
            onLabelClick={onLabelClick.bind(null, label.title, false, label.breakdownValue)}
            onToggleClick={handleToggle.bind(null, label.key)}
          />
        </Grid>
      );
    }
  };
};
export default createLabelsListComponent;
