import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import styled from 'styled-components';
import FileUploadMappingLoader from './FileUploadMappingLoader';

export const Container = styled.div`
  width: 1210px;
  height: 241px;
  display: flex;
  position: relative;
`;

interface IFileUploadMappingLoaderModal {
  onClose: () => void;
}

function FileUploadMappingLoaderModal({ onClose }: IFileUploadMappingLoaderModal) {
  return (
    <Dialog open={true} maxWidth={false}>
      <Container>
        <FileUploadMappingLoader onCloseButtonClick={onClose} />
      </Container>
    </Dialog>
  );
}

export default FileUploadMappingLoaderModal;
