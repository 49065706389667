import React from 'react';

import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error-icon.svg';
import styles from './ErrorMessage.module.css';

interface IErrorMessage {
  text: string;
  containerClassName?: string;
  errorTextClassName?: string;
}

function ErrorMessage({ text, containerClassName, errorTextClassName }: IErrorMessage) {
  return (
    <div className={`${styles.errorContainer} ${containerClassName}`}>
      <ErrorIcon className={styles.errorIcon} />
      <div className={`${styles.errorText} ${errorTextClassName}`}>{text}</div>
    </div>
  );
}

export default ErrorMessage;
