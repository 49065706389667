import { NestedPartial } from '../../utils/generics';
import { FileUploadMetadata } from '../reducers/connectors/types';
import { FileState, Template, UserMapping } from '../reducers/fileMapping/types';

export const FILE_MAPPING_RESET = 'FILE_MAPPING_RESET';
export const FILE_MAPPING_INIT = 'FILE_MAPPING_INIT';
export const FILE_MAPPING_PREDICT_START = 'FILE_MAPPING_PREDICT_START';
export const FILE_MAPPING_PREDICT_ERROR = 'FILE_MAPPING_PREDICT_ERROR';
export const FILE_MAPPING_PREDICT_SUCCESS = 'FILE_MAPPING_PREDICT_SUCCESS';
export const FILE_MAPPING_LOAD_FILE_MAPPING_START = 'FILE_MAPPING_LOAD_FILE_MAPPING_START';
export const FILE_MAPPING_LOAD_FILE_MAPPING_ERROR = 'FILE_MAPPING_LOAD_FILE_MAPPING_ERROR';
export const FILE_MAPPING_LOAD_FILE_MAPPING_SUCCESS = 'FILE_MAPPING_LOAD_FILE_MAPPING_SUCCESS';
export const FILE_MAPPING_GET_TEMPLATES_LOADING = 'FILE_MAPPING_UPDATE_TEMPLATES_LOADING';
export const FILE_MAPPING_GET_TEMPLATES_ERROR = 'FILE_MAPPING_UPDATE_TEMPLATES_ERROR';
export const FILE_MAPPING_GET_TEMPLATES_SUCCESS = 'FILE_MAPPING_UPDATE_TEMPLATES_SUCCESS';
export const FILE_MAPPING_UPDATE_FILE_INFO = 'FILE_MAPPING_UPDATE_FILE_INFO';
export const FILE_MAPPING_UPDATE_USER_MAPPING = 'FILE_MAPPING_UPDATE_USER_MAPPING';
export const FILE_MAPPING_UPDATE_SELECTED_INDEX = 'FILE_MAPPING_UPDATE_SELECTED_INDEX';
export const FILE_MAPPING_UPLOAD_START = 'FILE_MAPPING_UPLOAD_START';
export const FILE_MAPPING_UPLOAD_ERROR = 'FILE_MAPPING_UPLOAD_ERROR';
export const FILE_MAPPING_UPLOAD_SUCCESS = 'FILE_MAPPING_UPLOAD_SUCCESS';
export const FILE_MAPPING_VALIDATE_DATE_FORMAT_START = 'FILE_MAPPING_VALIDATE_DATE_FORMAT_START';
export const FILE_MAPPING_VALIDATE_DATE_FORMAT_SUCCESS = 'FILE_MAPPING_VALIDATE_DATE_FORMAT_SUCCESS';
export const FILE_MAPPING_VALIDATE_MAPPING_START = 'FILE_MAPPING_VALIDATE_MAPPING_START';
export const FILE_MAPPING_VALIDATE_MAPPING_ERROR = 'FILE_MAPPING_VALIDATE_MAPPING_ERROR';
export const FILE_MAPPING_VALIDATE_MAPPING_SUCCESS = 'FILE_MAPPING_VALIDATE_MAPPING_SUCCESS';
export const FILE_MAPPING_VALIDATE_MAPPING_CONTINUE = 'FILE_MAPPING_VALIDATE_MAPPING_CONTINUE';
export const FILE_MAPPING_REPORT_ISSUE_START = 'FILE_MAPPING_REPORT_ISSUE_START';
export const FILE_MAPPING_REPORT_ISSUE_ERROR = 'FILE_MAPPING_REPORT_ISSUE_ERROR';
export const FILE_MAPPING_REPORT_ISSUE_SUCCESS = 'FILE_MAPPING_REPORT_ISSUE_SUCCESS';
export const FILE_MAPPING_REQUEST_TEMPLATE_START = 'FILE_MAPPING_REQUEST_TEMPLATE_START';
export const FILE_MAPPING_REQUEST_TEMPLATE_ERROR = 'FILE_MAPPING_REQUEST_TEMPLATE_ERROR';
export const FILE_MAPPING_REQUEST_TEMPLATE_SUCCESS = 'FILE_MAPPING_REQUEST_TEMPLATE_SUCCESS';

export const fileMappingReset = () => ({
  type: FILE_MAPPING_RESET,
  payload: {},
});

interface IFileMappingInit {
  files: FileUploadMetadata[];
  isPrivate: boolean | null;
  dashboardID: string | null;
}
export const fileMappingInit = (payload: IFileMappingInit) => ({
  type: FILE_MAPPING_INIT,
  payload,
});

export const fileMappingPredictStart = () => ({
  type: FILE_MAPPING_PREDICT_START,
});

interface IFileMappingPredictError {
  error: string | null;
}
export const fileMappingPredictError = (payload: IFileMappingPredictError) => ({
  type: FILE_MAPPING_PREDICT_ERROR,
  payload,
});

// mapping updates are handled by fileMappingUpdateUserMapping
export const fileMappingLoadFileMappingSuccess = () => ({
  type: FILE_MAPPING_LOAD_FILE_MAPPING_SUCCESS,
});

export const fileMappingLoadFileMappingStart = () => ({
  type: FILE_MAPPING_LOAD_FILE_MAPPING_START,
});

interface IFileMappingLoadFileMappingError {
  error: string | null;
}
export const fileMappingLoadFileMappingError = (payload: IFileMappingLoadFileMappingError) => ({
  type: FILE_MAPPING_LOAD_FILE_MAPPING_ERROR,
  payload,
});

// mapping updates are handled by fileMappingUpdateUserMapping
export const fileMappingPredictSuccess = () => ({
  type: FILE_MAPPING_PREDICT_SUCCESS,
});

export const fileMappingTemplatesStart = () => ({
  type: FILE_MAPPING_GET_TEMPLATES_LOADING,
});

interface IFileMappingTemplatesError {
  error: string | null;
}
export const fileMappingTemplatesError = (payload: IFileMappingTemplatesError) => ({
  type: FILE_MAPPING_GET_TEMPLATES_ERROR,
  payload: {
    loading: false,
    error: payload.error,
  },
});

interface IFileMappingTemplatesSuccess {
  templates: Template[];
}
export const fileMappingTemplatesSuccess = (payload: IFileMappingTemplatesSuccess) => ({
  type: FILE_MAPPING_GET_TEMPLATES_SUCCESS,
  payload,
});

interface IFileMappingUpdateFileInfo {
  fileState: NestedPartial<FileState>;
}
export const fileMappingUpdateFileInfo = (payload: IFileMappingUpdateFileInfo) => ({
  type: FILE_MAPPING_UPDATE_FILE_INFO,
  payload,
});

interface IFileMappingUpdateUserMapping {
  userMapping: Record<string, UserMapping>;
  byUserAction: boolean;
  hasAlreadyPredicted?: boolean;
  // dateFormat?: string | null;
  // templateID?: string | null;
  // startRow?: number | null;
  // numberOfRows?: number | null;
  // isCustomDateFormat?: boolean;
  // isDateFormatValid?: boolean;
}
export const fileMappingUpdateUserMapping = (payload: IFileMappingUpdateUserMapping) => ({
  type: FILE_MAPPING_UPDATE_USER_MAPPING,
  payload,
});

interface IFileMappingUpdateSelectedIndex {
  index: number;
}
export const fileMappingUpdateSelectedIndex = (payload: IFileMappingUpdateSelectedIndex) => ({
  type: FILE_MAPPING_UPDATE_SELECTED_INDEX,
  payload,
});

export const fileMappingUploadStart = () => ({
  type: FILE_MAPPING_UPLOAD_START,
  payload: {},
});

interface IFileMappingUploadError {
  error: string;
}
export const fileMappingUploadError = (payload: IFileMappingUploadError) => ({
  type: FILE_MAPPING_UPLOAD_ERROR,
  payload,
});

interface IFileMappingUploadSuccess {}
export const fileMappingUploadSuccess = ({}: IFileMappingUploadSuccess = {}) => ({
  type: FILE_MAPPING_UPLOAD_SUCCESS,
  payload: {},
});

export const fileMappingValidateDateFormatStart = () => ({
  type: FILE_MAPPING_VALIDATE_DATE_FORMAT_START,
  payload: {},
});

interface IFileMappingValidateDateFormatSuccess {
  isValid: boolean;
  dateFormat: string | null;
}
export const fileMappingValidateDateFormatSuccess = (payload: IFileMappingValidateDateFormatSuccess) => ({
  type: FILE_MAPPING_VALIDATE_DATE_FORMAT_SUCCESS,
  payload,
});

export const fileMappingValidateMappingStart = () => ({
  type: FILE_MAPPING_VALIDATE_MAPPING_START,
});

interface IFileMappingValidateMappingError {
  error: string;
}
export const fileMappingValidateMappingError = (payload: IFileMappingValidateMappingError) => ({
  type: FILE_MAPPING_VALIDATE_MAPPING_ERROR,
  payload,
});

interface IFileMappingValidateMappingSuccess {
  isValid: boolean;
  dateFormatErrors: string[];
}
export const fileMappingValidateMappingSuccess = (payload: IFileMappingValidateMappingSuccess) => ({
  type: FILE_MAPPING_VALIDATE_MAPPING_SUCCESS,
  payload,
});

export const fileMappingValidateMappingContinue = () => ({
  type: FILE_MAPPING_VALIDATE_MAPPING_CONTINUE,
});

export const fileMappingReportIssueStart = () => ({
  type: FILE_MAPPING_REPORT_ISSUE_START,
});

interface IFileMappingReportIssueError {
  error: string;
}
export const fileMappingReportIssueError = (payload: IFileMappingReportIssueError) => ({
  type: FILE_MAPPING_REPORT_ISSUE_ERROR,
  payload,
});

interface IFileMappingReportIssueSuccess {}
export const fileMappingReportIssueSuccess = ({}: IFileMappingReportIssueSuccess = {}) => ({
  type: FILE_MAPPING_REPORT_ISSUE_SUCCESS,
  payload: {},
});

export const fileMappingRequestTemplateStart = () => ({
  type: FILE_MAPPING_REQUEST_TEMPLATE_START,
});

interface IFileMappingRequestTemplateError {
  error: string;
}
export const fileMappingRequestTemplateError = (payload: IFileMappingRequestTemplateError) => ({
  type: FILE_MAPPING_REQUEST_TEMPLATE_ERROR,
  payload,
});

interface IFileMappingRequestTemplateSuccess {}
export const fileMappingRequestTemplateSuccess = ({}: IFileMappingRequestTemplateSuccess = {}) => ({
  type: FILE_MAPPING_REQUEST_TEMPLATE_SUCCESS,
  payload: {},
});
