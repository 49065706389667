import { AutoCompleteLabel } from './lib';

export const transformToMapRequestType = 'transformToMapRequest';
export const transformToMapResponseType = 'transformToMapResponse';
export const filterOptionsRequestType = 'filterOptionsRequest';
export const filterOptionsResponseType = 'filterOptionsResponse';

export type AllActionTypes =
  | typeof transformToMapRequestType
  | typeof transformToMapResponseType
  | typeof filterOptionsRequestType
  | typeof filterOptionsResponseType;

export type BaseRequestAction = {
  type: AllActionTypes;
  data: any;
};
export type BaseResponseAction = {
  type: AllActionTypes;
  isLoading: boolean;
  data: any;
};

export type AutocompleteLabelsTransformRequest = {
  data: AutoCompleteLabel[];
} & BaseRequestAction;

export type AutocompleteLabelsTransformResponse = {
  data: { [key: string]: AutoCompleteLabel[] };
} & BaseResponseAction;

export type AutocompleteLabelsFilterRequest = {
  data: {
    options: AutoCompleteLabel[];
    filterBy: string;
    expanded: string[];
  };
} & BaseRequestAction;

export type AutocompleteLabelsFilterResponse = {
  data: {
    filteredLabels: AutoCompleteLabel[];
    filterBy: string;
    enabled: string[];
  };
} & BaseResponseAction;
