import { SURVEY_STATUS, SURVEY_STATUS_CONFIG } from '../../../constants';
import { Dashboard } from '../../../store/reducers/dashboards/types';

export function shouldShowProcessingAsResponseCount(status: string, dashboard: Dashboard) {
  if (!!dashboard.filteredTotal) {
    return false;
  }
  if (!(status in SURVEY_STATUS_CONFIG)) {
    return true;
  }
  if (status === SURVEY_STATUS.CONFIGURATION) {
    return true;
  }
  return false;
}
