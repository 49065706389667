import styled from 'styled-components';

const baseComponent = `
  width: 40px;
  height: 40px;
  border-radius: 50%;
`;

export const AvatarImg = styled.img`
  ${baseComponent};
`;

export const AvatarContainer = styled.div<{ $color: string }>`
  ${baseComponent};
  background-color: ${(props) => props.$color};

  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  text-transform: uppercase;
`;
