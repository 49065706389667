import React from 'react';
import { AvatarContainer, AvatarImg } from './styles';

interface IAvatar {
  name: string;
  avatarURL?: string;
  color?: string;
}

function Avatar({ name, color = 'var(--avatar-default)', avatarURL }: IAvatar) {
  return avatarURL ? <AvatarImg src={avatarURL} /> : <AvatarContainer $color={color}>{GetInitials(name)}</AvatarContainer>;
}

function GetInitials(name: string) {
  if (name) {
    const names = name.trim().split(' ');
    if (names.length === 0) {
      return '';
    }

    if (names.length === 1) {
      return names[0][0];
    }

    return names[0][0] + names[names.length - 1][0];
  }

  return '';
}

export default Avatar;
