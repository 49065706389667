import { Feedback } from '../store/reducers/dashboardQueries/types';

export type DashboardQuery = {
  id: string;
  date: string;
  queryType: QueryType;
  data: QueryData | null;
  status: QueryStatus;
  generationInfo?: object;
  error?: string;
};

export type Feedbacks = { [key: string]: Feedback };

export type QueryData = {
  message: string;
};

export enum QueryType {
  TEXT_RESPONSE = 'TEXT_RESPONSE',
  TEXT_QUESTION = 'TEXT_QUESTION',
}

export enum QueryStatus {
  COLLECTING_DATA_STATUS = 'collecting_data',
  ANALYZING_DATA_STATUS = 'analyzing_data',
  GENERATING_ANSWER_STATUS = 'generating_answer',
  SUCCESS_STATUS = 'success',
  FAILURE_STATUS = 'error',
  CANCEL_STATUS = 'canceled',
  CANCELING_STATUS = 'canceling',
}
