import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import { Backdrop } from '@material-ui/core';
import { useSelector } from 'react-redux';

import './App.css';
import { Route, Switch, useHistory } from 'react-router-dom';
import { v4 } from 'uuid';
import AppInitializerWrapper from './components/AppInitializerWrapper/AppInitializerWrapper';
import CompanyRouter from './components/Company/CompanyRouter';
import { APP_PATH, LOGOUT_URL, SESSION_ID } from './constants';
import Companies from './pages/Companies/Companies';
import ProfileContainer from './pages/Profile/ProfileContainer';
import CongratulationsPageContainer from './pages/Welcome/CongratulationsPage/CongratulationsPageContainer';
import FreeTierNotAvailable from './pages/Welcome/FreeTier/FreeTierNotAvailable';
import WelcomePageContainer from './pages/Welcome/WelcomePage/WelcomePageContainer';

const ProtectedAppInitializerWrapper = withAuthenticationRequired(AppInitializerWrapper);

function handleFreeTierNotAvailable(history) {
  const search = new URLSearchParams(window.location.search);
  const searchParams = search.size > 0 ? Object.fromEntries(search) : null;
  if (searchParams?.error === 'access_denied' && searchParams?.error_description === 'Access denied from your location') {
    history.push(APP_PATH.FREE_TIER_NOT_AVAILABLE);
  }
}

function InnerApp() {
  const { logout, isAuthenticated, isLoading } = useAuth0();
  const history = useHistory();
  const showBlocker = useSelector((state) => state.app.showBlocker || false);
  handleFreeTierNotAvailable(history);
  const session_id = localStorage.getItem(SESSION_ID);
  if (
    (!isAuthenticated && !isLoading) || // no auth
    (isAuthenticated && !isLoading && !session_id) // auth, no session id
  ) {
    localStorage.setItem(SESSION_ID, v4());
  }

  if (showBlocker) {
    return <Backdrop open style={{ zIndex: 10 }} invisible={true} />;
  }

  return (
    <Switch>
      <Route path={APP_PATH.FREE_TIER_NOT_AVAILABLE} component={FreeTierNotAvailable} />
      <ProtectedAppInitializerWrapper>
        <Switch>
          <Route exact path={APP_PATH.COMPANIES} component={Companies} />
          <Route
            path={APP_PATH.SIGN_OUT}
            component={() => {
              logout({ returnTo: LOGOUT_URL, client_id: null });
              return <></>;
            }}
          />
          <Route path={APP_PATH.CONGRATULATIONS} component={CongratulationsPageContainer} />
          <Route path={APP_PATH.WELCOME} component={WelcomePageContainer} />
          <Route path={APP_PATH.COMPANY} component={CompanyRouter} />
          <Route path={APP_PATH.PROFILE} component={ProfileContainer} />
        </Switch>
      </ProtectedAppInitializerWrapper>
    </Switch>
  );
}

export default InnerApp;
