import styled from 'styled-components';

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
`;

export const Status = styled.div`
  text-align: center;
  font-family: Rubik;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 135%;
`;
