import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { OPERATOR_NAME_LABEL } from '../FilterForm/constants';

const GroupWrapper = styled('div')`
  background-color: transparent;
  border: solid white;
  padding: 0 2px 0 30px;
  position: relative;
  margin-left: 10px;

  background-image: linear-gradient(white, white), none, linear-gradient(white, white), none;
  background-repeat: no-repeat;
  background-size: 5px 1px;
  background-position:
    top left,
    top right,
    bottom left,
    bottom right;
  border-width: 0 0 0 1px;
`;

const GroupCombinatorWrapper = styled('div')`
  color: black;
  font-size: 10px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  margin-left: -30px;
  padding: 0 2px;
  background-color: white;
  width: 25px;
  text-align: center;
  border-radius: 2px;
`;

const RuleOperator = styled('span')`
  background-color: lightgrey;
  color: black;
  padding: 0 3px;
  border-radius: 3px;
`;

const SavedFilterTooltipError = styled('span')`
  font-size: 14px;
`;

const getOperatorName = (rule) => {
  return OPERATOR_NAME_LABEL[rule.operator] ? OPERATOR_NAME_LABEL[rule.operator] : '';
};

const RulePresentation = ({ rule, dashboardTitlesById }) => {
  const operator = getOperatorName(rule);
  switch (rule.type) {
    case 'group':
      if (rule.rules.length === 1) {
        return <RulePresentation rule={rule.rules[0]} dashboardTitlesById={dashboardTitlesById} />;
      }
      return (
        <GroupWrapper>
          {rule.rules.map((rule, index) => (
            <RulePresentation key={index} rule={rule} dashboardTitlesById={dashboardTitlesById} />
          ))}
          <GroupCombinatorWrapper>{rule.combinator.toUpperCase()}</GroupCombinatorWrapper>
        </GroupWrapper>
      );
    case 'date':
      return (
        <div>
          Date <RuleOperator>{operator}</RuleOperator> {rule.value}
        </div>
      );
    case 'sentiment':
      return (
        <div>
          {dashboardTitlesById[rule.dataSourceID]} <RuleOperator>Sentiment</RuleOperator> ({rule.values.join(', ')})
        </div>
      );
    default:
      return (
        <div>
          {dashboardTitlesById[rule.dataSourceID]} <RuleOperator>{operator}</RuleOperator> ({rule.values.join(', ')})
        </div>
      );
  }
};

const SavedFilterTooltip = ({ savedFilter }) => {
  const { translationUsed } = useSelector((state) => state.app);
  const dataSources = useSelector((state) => state.dashboard.dashboard.dataSources);

  const dashboardTitlesById = dataSources.reduce((acc, dataSource) => {
    const title = dataSource[translationUsed ? 'title' : 'originalTitle'];
    return { ...acc, [dataSource.dataSourceID]: title };
  }, {});
  if (!savedFilter.valid) {
    return (
      <SavedFilterTooltipError>
        The data in this saved segment has changed. To apply this filter please edit the parameters to match updated values.
      </SavedFilterTooltipError>
    );
  }

  return (
    <>
      {savedFilter.description !== undefined && savedFilter.description.length > 0 && (
        <p style={{ marginTop: 0 }}>{savedFilter.description}</p>
      )}
      <RulePresentation rule={savedFilter.query} dashboardTitlesById={dashboardTitlesById} />
    </>
  );
};

export default SavedFilterTooltip;
