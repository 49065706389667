import Grid from '@material-ui/core/Grid';
import React, { useRef } from 'react';
import styled from 'styled-components';

import { FIXED_SIZE_WIDGETS, WIDGET_TYPE } from '../../constants';
import { Config, Filter } from '../../types/Config';
import { Query } from '../../types/Query';
import { WidgetContainer, WidgetTotalBlock } from '../UI';

import WidgetHeader from './WidgetHeader';
import WidgetTotal from './WidgetTotal';
import { WidgetTotalsProvider } from './WidgetTotalsContext';

const WidgetContainerStyled = styled(WidgetContainer)<{ $areSettingsVisible: boolean; $withBottomPadding: boolean }>`
  height: 100%;
  display: ${({ $areSettingsVisible }) => ($areSettingsVisible ? 'none!important' : 'inherit')};
`;

interface IBaseWidget {
  isMaximizable: boolean;
  isUnfixable: boolean;
  areSettingsVisible: boolean;
  config: Config;
  groupTitle: string;
  query: Query | null;
  refContent: React.MutableRefObject<HTMLElement | undefined>;
  refCsvExport: React.MutableRefObject<(() => void) | undefined>;
  refSettings: React.MutableRefObject<HTMLElement | undefined>;
  SettingsComponent: React.ElementType;
  MenuComponent: React.ElementType;
  ContentComponent: React.ElementType;
  onShowSettingsClick: () => void;
  onHideSettingsClick: () => void;
  onCollapseClick: () => void;
  onMaximizeClick: () => void;
  onExpand: () => void;
  onAutoSizeHeightUpdate: (calculatedHeight: any) => void;
  onWidgetHeightReset: (type: any) => void;
  onCSVClick: () => void;
  onPNGClick: () => void;
  onAllFiltersReset: () => void;
  onFilterApply: (filter: Filter) => void;
}

function BaseWidget({
  isMaximizable,
  isUnfixable,
  areSettingsVisible,
  config,
  groupTitle,
  query,
  refContent,
  refCsvExport,
  refSettings,
  SettingsComponent,
  MenuComponent,
  ContentComponent,
  onShowSettingsClick,
  onHideSettingsClick,
  onCollapseClick,
  onMaximizeClick,
  onExpand,
  onAutoSizeHeightUpdate,
  onWidgetHeightReset,
  onCSVClick,
  onPNGClick,
  onAllFiltersReset,
  onFilterApply,
}: IBaseWidget) {
  const refHeader = useRef();
  const refFiltersCount = useRef(0);

  return (
    <div style={{ height: '100%' }} data-testid='widget' data-widget-title={config.base.title} data-widget-type={config.base.type}>
      <WidgetTotalsProvider
        query={query}
        widgetDataSources={config.settings.dataSources}
        forceFetch={config.base.type === WIDGET_TYPE.TRENDS}
      >
        {areSettingsVisible && (
          <SettingsComponent config={config} onClose={onHideSettingsClick} componentRef={refSettings} groupTitle={groupTitle} />
        )}
        <WidgetContainerStyled
          $withBottomPadding={FIXED_SIZE_WIDGETS.includes(config.base.type)}
          $areSettingsVisible={areSettingsVisible}
          container
          direction='column'
        >
          <WidgetHeader
            config={config}
            refHeader={refHeader}
            onCollapseClick={onCollapseClick}
            onMaximizeClick={onMaximizeClick}
            onCsvClick={onCSVClick}
            onPngClick={onPNGClick}
            onErrorClick={onShowSettingsClick}
            onFilterApply={onFilterApply}
            refFiltersCount={refFiltersCount}
            groupTitle={groupTitle}
            isMaximizable={isMaximizable}
            unfixable={isUnfixable}
            menuComponent={
              <MenuComponent
                config={config}
                onEdit={onShowSettingsClick}
                onWidgetTypeChange={onWidgetHeightReset}
                groupTitle={groupTitle}
                unfixable={isUnfixable}
              />
            }
          />
          {renderWidgetContent()}
        </WidgetContainerStyled>
        {!(areSettingsVisible || config.base.collapsed) && (
          <WidgetTotalBlock>
            <WidgetTotal config={config} />
          </WidgetTotalBlock>
        )}
      </WidgetTotalsProvider>
    </div>
  );

  function renderWidgetContent() {
    if (config.base.collapsed || areSettingsVisible) {
      return null;
    }

    return (
      <Grid
        item
        style={{
          flex: '1',
          height: '0',
          width: '100%',
        }}
      >
        <Grid container direction='column' style={{ height: '100%' }}>
          <ContentComponent
            config={config}
            showSettings={areSettingsVisible}
            updateAutoSizeHeight={onAutoSizeHeightUpdate}
            groupTitle={groupTitle}
            refCsvExport={refCsvExport}
            refContent={refContent}
            refFiltersCount={refFiltersCount}
            refHeader={refHeader}
            query={query}
            onResetAllFilters={onAllFiltersReset}
            onFilterApply={onFilterApply}
            onExpand={onExpand}
          />
        </Grid>
      </Grid>
    );
  }
}

export default BaseWidget;
