import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

import { Concept, Insight, InsightType } from '../../../../../store/reducers/aiInsights';
import GeneratedInsightsContainer, { ThumbStatus } from '../../../components/GeneratedInsights/GeneratedInsightsContainer';
import GenerationStatus from '../../../components/GenerationStatus/GenerationStatus';
import { GeneratedInsight } from '../../../../../store/actions/aiInsights';
import { isWidgetLevelFilterEnabled } from '../../../utils/lib';
import NotEnoughData from './Components/NotEnoughData';

const InsightsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 8px;
  padding: 0 8px 10px 8px;
  width: 100%;
  height: calc(100% - 26px);
  overflow-y: scroll;
  overflow-x: hidden;
`;

interface IAIInsights {
  insights: Insight[];
  selectedConcept?: Concept;
  onGenerateClick: (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => void;
  onSendCustomUserPromptClick: (prompt: string) => void;
  onClearAllInsights: () => void;
  onLearnMoreClick: () => void;
  onCopyClick: (insightID: string) => void;
  onThumbsClick: (insightID: string, thumbsStatus: ThumbStatus) => void;
  onQuoteClick: (insightID: string, insight: GeneratedInsight) => void;
}

function AIInsights({
  insights,
  selectedConcept,
  onGenerateClick,
  onSendCustomUserPromptClick,
  onClearAllInsights,
  onLearnMoreClick,
  onCopyClick,
  onThumbsClick,
  onQuoteClick,
}: IAIInsights) {
  const insightsContainerRef = useRef<HTMLDivElement | null>(null);
  const insightsEndRef = useRef<HTMLDivElement | null>(null);

  function scrollToBottom() {
    insightsContainerRef.current?.scrollTo({
      top: insightsEndRef.current?.offsetTop,
      behavior: 'smooth',
    });
  }

  useEffect(() => {
    scrollToBottom();
  }, [insights]);

  return (
    <InsightsContainer ref={insightsContainerRef}>
      {insights.map((insight) => renderInsightComponent(insight))}
      <div ref={insightsEndRef} />
    </InsightsContainer>
  );

  function renderInsightComponent(insight: Insight) {
    const isWidgetLevelFilterApplied = !!insight.filter.find(isWidgetLevelFilterEnabled);
    switch (insight.type) {
      case InsightType.GENERATING:
        return (
          <GenerationStatus
            key={insight.id}
            id={insight.id}
            isWidgetLevelFilterApplied={isWidgetLevelFilterApplied}
            availableFilters={insight.availableFilters}
            statuses={insight.generationStatuses!}
            customPrompt={insight.customPrompt}
            selectedConcept={insight.concept}
            segmentsNames={insight.segmentsNames}
            onTryAgainClick={onGenerateClick.bind(null, insight.concept, true, insight.id)}
          />
        );
      case InsightType.AI_GENERATED:
        return (
          <GeneratedInsightsContainer
            key={insight.id}
            id={insight.id}
            availableFilters={insight.availableFilters}
            selectedConcept={insight.concept!}
            widgetSelectedConcept={selectedConcept}
            generatedInsights={insight.generatedInsights!}
            relatedConcepts={insight.relatedConcepts!}
            isWidgetLevelFilterApplied={isWidgetLevelFilterApplied}
            isDisabled={insight.isDisabled}
            customPrompt={insight.customPrompt}
            segmentsNames={insight.segmentsNames}
            onGenerateClick={onGenerateClick}
            onClearAllInsights={onClearAllInsights}
            onCopyClick={onCopyClick.bind(null, insight.id)}
            onThumbsClick={onThumbsClick.bind(null, insight.id)}
            onQuoteClick={onQuoteClick.bind(null, insight.id)}
          />
        );
      case InsightType.NOT_ENOUGH_DATA:
        return (
          <NotEnoughData
            key={insight.id}
            availableFilters={insight.availableFilters}
            selectedConcept={insight.concept}
            segmentsNames={insight.segmentsNames}
          />
        );
    }
  }
}

export default AIInsights;
