import React, { ForwardedRef } from 'react';
import styled from 'styled-components';
import Avatar from '../../../../../../components/Avatar/Avatar';
import { User } from '../../../../../../store/reducers/profile';
import { ReactComponent as BeehiveLogo } from '../../../../../../assets/icons/beehive-icon.svg';

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  gap: 16px;
`;

const AvatarContainer = styled.div``;
const ContentContainer = styled.div``;

const StyledBeehiveLogo = styled(BeehiveLogo)`
  padding: 8px;
  width: 40px;
  height: 40px;
  min-width: 12px;
  background-color: var(--white);
  border-radius: 50%;
`;

interface IBaseItemWithAvatar {
  children: React.ReactElement;
  user?: User;
}

function BaseItemWithAvatar({ user, children }: IBaseItemWithAvatar, ref: ForwardedRef<HTMLDivElement>) {
  const avatarComponent = user ? <Avatar avatarURL={user.avatarURL} name={user.fullname} /> : <StyledBeehiveLogo />;
  return (
    <Container ref={ref}>
      <AvatarContainer>{avatarComponent}</AvatarContainer>
      <ContentContainer>{children}</ContentContainer>
    </Container>
  );
}

export default React.forwardRef(BaseItemWithAvatar);
