import { postDashboardQueriesFeedback } from '../../components/tools/api';
import { feedbackSubmitError, feedbackSubmitStart, feedbackSubmitSuccess } from '../actions/dashboardQueries';
import { Action } from '../hooks';
import { ThumbStatus } from '../reducers/dashboardQueries/types';

interface ISubmitDashboardQueriesFeedback {
  userFeedback: string;
  queryID: string;
}

export const submitDashboardQueriesFeedback =
  ({ userFeedback, queryID }: ISubmitDashboardQueriesFeedback): Action =>
  async (dispatch, getState) => {
    dispatch(
      feedbackSubmitStart({
        queryID,
      }),
    );
    const { queries, feedbacks, sessionID } = getState().dashboardQueries;
    const { dashboard } = getState().dashboard;
    const { user, company } = getState().profile;

    const requestBody = {
      userName: user?.fullname || '',
      dashboardID: dashboard?.id || '',
      workspace: company?.name || '',
      userFeedback: feedbacks[queryID].thumb === ThumbStatus.UP ? 'Thumbs UP' : 'Thumbs DOWN',
      userFeedbackText: userFeedback,
      debugSessionID: sessionID.length ? sessionID : "Couldn't find Session ID",
    };
    try {
      const resp = await postDashboardQueriesFeedback(dashboard?.id ?? '', requestBody);
      if (resp.error) {
        dispatch(
          feedbackSubmitError({
            queryID,
          }),
        );
      }
      dispatch(
        feedbackSubmitSuccess({
          queryID,
        }),
      );
    } catch {
      dispatch(
        feedbackSubmitError({
          queryID,
        }),
      );
    }
  };
