import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import palette from '../../components/tools/palette';
import DialogActions from '@material-ui/core/DialogActions';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

export const CustomDialogActions = styled(DialogActions)`
  &.MuiDialogActions-root {
    border-top: 1px solid #979797;
    margin-top: 20px;
    padding: 15px;
    justify-content: space-between;
  }
`;

export const SubmitButton = styled(Button)`
  &.MuiButtonBase-root {
    text-transform: capitalize;
    border-color: ${palette.primaryColorLight};
    font-weight: 100;
    .MuiButton-label {
      color: ${palette.primaryColorLight};
    }
  }

  &.MuiButtonBase-root:hover {
    border-color: ${palette.primaryColorLightHover};
    background-color: white;
    .MuiButton-label {
      color: ${palette.primaryColorLightHover};
    }
  }
`;

export const CancelButton = styled(Button)`
  &.MuiButtonBase-root {
    text-transform: capitalize;
    font-weight: 100;
    margin-right: 16px;
  }
`;

export const DeleteButton = styled(SubmitButton)`
  &.MuiButtonBase-root, &.MuiButtonBase-root:hover {
    border-color: #e3e3f5;

    .MuiButton-label {
      color: red;
    }
  }

  &.MuiButtonBase-root:hover {
    border-color: red;
  }

  &.Mui-disabled {
    .MuiButton-label {
      color: ${palette.linkColorLight};
    }
  }
}
`;

export const HiveStatusDetailedViewTooltip = styled.div<{ $top: number; $left: number }>`
  position: absolute;
  top: ${({ $top }) => $top}px;
  left: ${({ $left }) => $left}px;
  z-index: 99999;
  width: 448px;
  max-height: 300px;
  padding-top: 40px;
`;

export const HiveStatusDetailedViewContainer = styled.div`
  width: 448px;
  background-color: white;
  border: 1px solid #d8d8d8;
  border-radius: 8px;
  padding: 0 16px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-height: 260px;
  overflow: auto;
`;

export const HiveStatusDetailedViewHeader = styled.div`
  display: flex;
  height: 43px;
  font-weight: bold;
  font-size: 16px;
  padding: 12px 0;
  align-items: center;
`;

export const HiveStatusProgramsTotal = styled.div`
  display: flex;
  align-items: center;
  padding: 0 4px;
  color: var(--dark-purple);
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  height: 16px;
  border-radius: var(--double-border-radius);
  margin-left: 8px;
  background-color: var(--beehive-purple-transparent-10);
  color: var(--dark-purple);
  border: none;

  &:hover {
    background-color: var(--light-purple-50-transparency);
    color: var(--dark-purple);
  }
`;

export const HiveStatusDetailedViewProgramRow = styled.div`
  display: flex;
  height: 43px;
  justify-content: space-between;
  padding: 12px 0;
  font-size: 16px;
`;

export const HiveStatusDetailedViewProgramName = styled.span`
  text-overflow: ellipsis;
  overflow: hidden;
  text-wrap: nowrap;
  flex: 0 1 auto;
`;

export const HiveStatusDetailedViewProgramStatus = styled.span`
  flex: 0 0 auto;
  margin-left: 8px;
`;

export const InfoIcon = styled(InfoOutlinedIcon)`
  &.MuiSvgIcon-root {
    width: 16px;
    height: 16px;
    margin-right: 2px;
    margin-bottom: -2px;
  }
`;
