import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import React, { MutableRefObject, Ref, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useIntercom } from 'react-use-intercom';

import { FETCH_STATUS_TYPE } from '../../../../constants';
import { useSavedFilters } from '../../../../contexts/SavedFiltersContext';
import useSelectedFilterIdsToggle from '../../../../hooks/useSelectedFilterIdsToggle';
import { generateInsightsRequest, initializeInsightsForWidget } from '../../../../store/actions/aiInsights';
import { initialize, selectConcept, updateWidgetQueries } from '../../../../store/actions/openEndedWidget';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Concept } from '../../../../store/reducers/aiInsights';
import {
  CurrentDashboardDataSource,
  CurrentDashboardInfo,
  DashboardSentimentSummariesSentiment,
} from '../../../../store/reducers/dashboard/types';
import { ResponsesSorting } from '../../../../store/reducers/openEndedWidget';
import { stopGeneratingInsights } from '../../../../store/thunks/aiInsights';
import { loadResponses } from '../../../../store/thunks/openEndedWidget';
import { BreakdownData } from '../../../../types/BreakdownData';
import { Config, Filter } from '../../../../types/Config';
import { WidgetDataSource } from '../../../../types/DataSource';
import { BreakdownLabel } from '../../../../types/Label';
import { Query } from '../../../../types/Query';
import { ResponseListItem, useDataBySelectedSavedFilterId } from '../../../overview/lib';
import { exportOpenEndedBreakdown, exportOpenEndedPlain, getTimestampString } from '../../../tools/export';
import { getAppliedFiltersCount } from '../../../UI/lib';
import { WidgetTotalsContext } from '../../WidgetTotalsContext';

import { useBreakdownLabels, useDataFilters, useLabels, useResponsesBreakdownQueryRule } from './dataHooks';
import { DATA_VIEW, getChildrenLabels, getLabelToParent } from './lib';
import OpenEndedWidgetData from './OpenWidgetData';
import { convertInsightsFiltersToQuery, convertResponsesFiltersToQuery } from './utils/filterQueries';

export type CurrentDashboardDataSourceInfo = {
  sentimentSummary: DashboardSentimentSummariesSentiment;
  usersNum: number;
} & CurrentDashboardDataSource;

interface IOpenWidgetDataContainer {
  currentDashboardDataSourceInfo: CurrentDashboardDataSourceInfo;
  breakdownData?: BreakdownData;
  overviewAPIOutput: ResponseListItem[];
  config: Config;
  groupTitle: string;
  refCsvExport: Ref<() => void>;
  isBreakdownView: boolean;
  refContent?: MutableRefObject<HTMLDivElement> | null;
  onResetAllFilters: () => void;
  refFiltersCount: MutableRefObject<number>;
  selectedItems: string[];
  query: Query;
  loading: FETCH_STATUS_TYPE;
  isPreview: boolean;
  onExpand: () => void;
  onFeedbackClick: (title: { title: string }) => void;
  onLabelSelectionTrack: (label: string, isParent: boolean, count: number) => void;
  onLabelExpand: () => void;
  onFilterApply: (filter: Filter) => void;
}

function OpenWidgetDataContainer({
  currentDashboardDataSourceInfo,
  breakdownData,
  overviewAPIOutput,
  config,
  groupTitle,
  refCsvExport,
  isBreakdownView,
  refContent,
  refFiltersCount,
  selectedItems,
  query: currentWidgetFilter,
  loading,
  isPreview,
  onResetAllFilters,
  onExpand,
  onLabelSelectionTrack,
  onLabelExpand,
  onFeedbackClick,
  onFilterApply,
}: IOpenWidgetDataContainer) {
  const widgetID = config.base.id as string;
  const dispatch = useAppDispatch();
  const { showNewMessages } = useIntercom();
  const { filters, filtersById } = useDataFilters(isBreakdownView, isPreview);
  const { savedFiltersById, savedFilters } = useSavedFilters();

  const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);
  const overviewDataById = useDataBySelectedSavedFilterId(overviewAPIOutput, filters);
  const { loading: totalsLoading, totalsByDataSourcesAndFiltersIds } = useContext(WidgetTotalsContext);

  const { hasSentiment, dataSources: dashboardDataSources } = useAppSelector((state) => state.dashboard.dashboard) as CurrentDashboardInfo;
  const { translationUsed, weightedMetricsUsed } = useAppSelector((state) => state.app);
  const currentDashboardFilter = useAppSelector((state) => state.filter.query);
  const dataSources = useAppSelector((state) => state.dashboard.dataSources);
  const breakdownFilter = useAppSelector((state) => state.overview.breakdownFilter);
  const widgetConfig = useAppSelector((state) => state.openEndedWidgets.configsByWidgetID[widgetID]);
  const insightsState = useAppSelector((state) => state.aiInsights.insightsByWidgetID).find((insights) => insights.widgetID === widgetID);
  const responsesState = useAppSelector((state) => state.openEndedWidgets.responsesStatesByWidgetID[widgetID]);
  const [areInitialInsightsBeingGenerated, setAreInitialInsightsBeingGenerated] = useState(!insightsState?.insights?.length);
  const [prevInsightsQuery, setPrevInsightsQuery] = useState(() => widgetConfig?.insightsQuery);

  const generateInsightsDebounce = useCallback(
    debounce((params) => {
      dispatch(generateInsightsRequest(params));
    }, 1000),
    [],
  );

  const loadResponsesDebounce = useCallback(
    debounce((params) => {
      dispatch(loadResponses(params));
    }, 1000),
    [],
  );

  refFiltersCount.current = selectedFilterIds.length;

  const hasResponses = responsesState?.responsesTotal > 0 || currentDashboardDataSourceInfo.usersNum > 0;
  const showResponses = config.settings.showResponses as boolean;
  const showChildren = config.settings.detailed === DATA_VIEW.DETAILED;
  const average = config.settings.merge && getAppliedFiltersCount(filters) > 0;
  const dataSourceID = currentDashboardDataSourceInfo.dataSourceID;

  const dataSource = useMemo(() => {
    const dataSource = dataSources.find((ds) => ds.dataSourceID === dataSourceID);
    const dataSourceInfo = dashboardDataSources.find((ds) => ds.dataSourceID === dataSourceID);
    const valueType = weightedMetricsUsed ? 'weighted' : 'base';
    const items = dataSourceInfo?.isEmpty
      ? []
      : dataSource?.items
          .map((item) => ({
            ...item,
            displayResponse: translationUsed ? item.response : item.originalResponse,
          }))
          .sort((a, b) => (dataSourceInfo?.ordered ? 1 : b[valueType].value - a[valueType].value));

    return {
      ...dataSourceInfo,
      ...dataSource,
      items,
    } as WidgetDataSource;
  }, [currentDashboardDataSourceInfo, dataSources, dashboardDataSources, weightedMetricsUsed, translationUsed]);

  useEffect(() => {
    if (!widgetConfig) {
      dispatch(
        initialize({
          widgetID,
          dataSourceID,
        }),
      );

      dispatch(
        initializeInsightsForWidget({
          widgetID,
          selectedConcept: undefined,
          dataSource,
          filteredConcepts: selectedItems,
        }),
      );
    }
    return () => {
      dispatch(stopGeneratingInsights({ widgetID }));
      loadResponsesDebounce.cancel();
      generateInsightsDebounce.cancel();
    };
  }, []);

  const { breakdownLabels } = useBreakdownLabels(
    isBreakdownView,
    selectedFilterIds,
    breakdownData,
    dataSource,
    filtersById,
    showChildren,
    selectedItems,
  );

  const { labels } = useLabels(
    isBreakdownView,
    breakdownLabels,
    overviewDataById,
    dataSource,
    filtersById,
    selectedFilterIds,
    showChildren,
    selectedItems,
  );

  const { responsesBreakdownQueryRule } = useResponsesBreakdownQueryRule(
    breakdownFilter,
    widgetConfig?.selectedConcept as unknown as BreakdownLabel,
  );

  const hiddenLabels = useMemo(() => {
    if (showChildren) {
      return [];
    }

    return getChildrenLabels(dataSource);
  }, [showChildren, dataSource]);

  const labelToParent = useMemo(() => getLabelToParent(dataSource), [dataSource]);

  useEffect(() => {
    if (!widgetConfig) {
      return;
    }
    const responsesQuery = convertResponsesFiltersToQuery(
      dataSourceID,
      widgetConfig,
      selectedItems,
      currentWidgetFilter,
      responsesBreakdownQueryRule,
      responsesState,
      selectedFilterIds,
      currentDashboardFilter,
      savedFiltersById,
    );
    const insightsQuery = convertInsightsFiltersToQuery(
      dataSourceID,
      widgetConfig,
      selectedItems,
      currentWidgetFilter,
      responsesBreakdownQueryRule,
      responsesState,
      selectedFilterIds,
      currentDashboardFilter,
      savedFiltersById,
    );
    if (!isEqual(widgetConfig.query, responsesQuery) && !isEqual(widgetConfig.insightsQuery, insightsQuery)) {
      dispatch(updateWidgetQueries({ widgetID, insightsQuery, responsesQuery }));
    }
  }, [
    selectedFilterIds,
    currentDashboardFilter,
    currentWidgetFilter,
    dataSourceID,
    selectedItems,
    responsesState?.searchString,
    savedFiltersById,
    widgetConfig?.selectedConcept,
    responsesBreakdownQueryRule,
  ]);

  const appliedSegmentsNames = useMemo(() => {
    return selectedFilterIds.map((filterID) => filters.find((filter) => filterID === filter.id)?.name || '') ?? [];
  }, [selectedFilterIds, filters]);

  useEffect(() => {
    if (!responsesState) {
      return;
    }

    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: 0,
      sorting: responsesState?.sorting,
      isBookmarkOnly: responsesState?.isBookmarkOnly,
    });
  }, [widgetConfig?.query]);

  useEffect(() => {
    if (!areInitialInsightsBeingGenerated && !isEqual(prevInsightsQuery, widgetConfig?.insightsQuery)) {
      setPrevInsightsQuery(widgetConfig?.insightsQuery);
      generateInsightsDebounce({
        widgetID,
        dashboardID: config.base.dashboardID,
        dataSourceID: dataSource.dataSourceID,
        insightsQuery: widgetConfig?.insightsQuery,
        responsesQuery: widgetConfig?.query,
        selectedConcept: widgetConfig?.selectedConcept ?? undefined,
        availableFilters: filters.map((f) => f.name),
        isTryAgain: false,
        segmentsNames: appliedSegmentsNames,
      });
    }
  }, [widgetConfig?.insightsQuery]);

  (refCsvExport as MutableRefObject<() => void>).current = () => {
    const titleField = translationUsed ? 'title' : 'originalTitle';
    const title = dataSource[titleField];
    const timestampString = getTimestampString();
    const filename = `${title} - ${timestampString}`;
    const labelToParent = dataSource?.items
      ?.filter((item) => item.children)
      .reduce(
        (labelToParent, item) => {
          const relations = item.children?.reduce(
            (acc, child) => ({
              ...acc,
              [child]: item.displayResponse,
            }),
            {},
          );
          return { ...labelToParent, ...relations };
        },
        {} as {
          [key: string]: string;
        },
      );
    if (isBreakdownView) {
      const breakdownDataSource = dataSources.find((dataSource) => dataSource.dataSourceID === breakdownFilter?.dataSourceID);
      const breakdownItems = breakdownFilter?.values.map((value) => breakdownDataSource?.items.find((item) => item.response === value));
      const breakdownData = dashboardDataSources?.find(
        (dashboardDataSource) => dashboardDataSource.dataSourceID === breakdownFilter?.dataSourceID,
      );
      let breakdownTitle = '';
      if (breakdownData) {
        breakdownTitle = breakdownData[titleField];
      }
      const filename = `${title} (breakdown by ${breakdownTitle}) - ${timestampString}`;
      exportOpenEndedBreakdown(
        breakdownData,
        filters,
        selectedFilterIds,
        breakdownItems,
        dataSource,
        translationUsed,
        labelToParent,
        filename,
      );
    } else {
      exportOpenEndedPlain(overviewDataById, dataSource, filters, selectedFilterIds, filtersById, filename, labelToParent);
    }
  };

  const handleLabelClick = useCallback(
    (title: string, withScroll: boolean) => {
      const item = dataSource?.items?.find((item) => item.response === title);
      if (item) {
        onLabelSelectionTrack(title, !!item?.children, item[weightedMetricsUsed ? 'weighted' : 'base'].value);
      }
      dispatch(
        selectConcept({
          title,
          widgetID,
          options: {
            withScroll,
          },
        }),
      );
    },
    [dataSource, onLabelSelectionTrack, weightedMetricsUsed],
  );

  const handleFilterButtonChange = useCallback(
    (ids: React.SetStateAction<string[]>) => {
      (setSelectedFilterIds as React.Dispatch<React.SetStateAction<string[]>>)(ids);
    },
    [setSelectedFilterIds],
  );

  function handleGenerateInsightsClick(selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) {
    if (selectedConcept?.title && !isTryAgain) {
      dispatch(
        selectConcept({
          title: selectedConcept.title,
          widgetID,
          options: {
            withScroll: true,
          },
        }),
      );
    } else {
      setAreInitialInsightsBeingGenerated(false);
      generateInsightsDebounce({
        dashboardID: config.base.dashboardID,
        widgetID,
        dataSourceID: dataSource.dataSourceID,
        selectedConcept,
        isTryAgain,
        insightsQuery: widgetConfig?.insightsQuery,
        availableFilters: filters.map((f) => f.name),
        responsesQuery: widgetConfig?.query,
        segmentsNames: appliedSegmentsNames,
        insightID,
      });
    }
  }

  function handleResponsesLoad() {
    if (responsesState.isLoading) {
      return;
    }
    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: responsesState?.offset,
      sorting: responsesState?.sorting,
      isBookmarkOnly: responsesState?.isBookmarkOnly,
    });
  }

  function handleResponsesSortingChange(sorting: ResponsesSorting) {
    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: 0,
      sorting,
      isBookmarkOnly: responsesState!.isBookmarkOnly,
    });
  }

  function handleResponsesBookmarkedToggle() {
    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: 0,
      sorting: responsesState!.sorting,
      isBookmarkOnly: !responsesState.isBookmarkOnly,
    });
  }

  function handleFeedbackClick(title: string, widgetTitle: string, displayAnswerText?: string) {
    showNewMessages(
      `Please make the following update(s) to “${title}” dashboard, regarding the response “${displayAnswerText}”, in the “${widgetTitle}” widget: `,
    );
  }

  if (!widgetConfig?.dataSourceID) {
    return null;
  }

  return (
    <OpenEndedWidgetData
      analyzedAnswers={currentDashboardDataSourceInfo.analyzed}
      areFiltersEmpty={currentDashboardDataSourceInfo.isEmpty}
      average={average}
      breakdownLabels={breakdownLabels}
      config={config}
      dataSource={dataSource}
      dataSourceID={dataSourceID}
      filters={filters}
      filtersById={filtersById}
      groupTitle={groupTitle}
      hasResponses={hasResponses}
      hasSentiment={hasSentiment}
      hiddenLabels={hiddenLabels}
      isBreakdownView={isBreakdownView}
      isPreview={isPreview}
      labels={labels}
      labelToParent={labelToParent}
      loading={loading}
      responsesCount={responsesState?.responsesTotal}
      responsesLoading={responsesState?.isLoading}
      savedFilters={savedFilters}
      selectedFilterIds={selectedFilterIds}
      selectedItems={selectedItems}
      selectedLabelItem={widgetConfig?.selectedConcept}
      sentimentSummary={currentDashboardDataSourceInfo.sentimentSummary}
      showResponses={showResponses}
      totalAnswers={currentDashboardDataSourceInfo.total}
      totalsByDataSourcesAndFiltersIds={totalsByDataSourcesAndFiltersIds}
      totalsLoading={totalsLoading}
      totalWeightedAnswers={dataSource.weightedTotal}
      onExpand={onExpand}
      onFeedbackClick={handleFeedbackClick}
      onFilterButtonChange={handleFilterButtonChange}
      onLabelClick={handleLabelClick}
      onLabelExpand={onLabelExpand}
      onLoadMoreResponses={handleResponsesLoad}
      onResponsesSortingChange={handleResponsesSortingChange}
      onResponsesBookmarkedToggle={handleResponsesBookmarkedToggle}
      onRefContent={refContent}
      onWidgetFiltersClearClick={onResetAllFilters}
      onGenerateInsightsClick={handleGenerateInsightsClick}
      onFilterApply={onFilterApply}
    />
  );
}

export default OpenWidgetDataContainer;
