import styled, { keyframes } from 'styled-components';

export const LoadIndicatorContainer = styled.div`
  position: relative;
  margin-top: 35px;
  width: 100%;
  height: 6px;
  background-color: var(--gray-disabled);
  border-radius: 3px;
`;
const loading = keyframes`
  0% {
    left: 0;
  }
  50% {
    left: calc(100% - 371px);
  }
  100% {
    left: 0;
  }
`;

export const LoadIndicator = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  height: 6px;
  width: 371px;
  background-color: var(--beehive-purple);
  border-radius: 3px;
  animation: ${loading} 4s linear infinite;
`;
