import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useState } from 'react';
import { ReactComponent as MedalliaIcon } from '../../../../assets/icons/medallia-icon.svg';
import { ReactComponent as QualtricsIcon } from '../../../../assets/icons/qualtrics-icon.svg';
import { ReactComponent as AIGeneratedMappingIcon } from '../../../../assets/icons/sparkle-icon.svg';
import { ReactComponent as SurveyMonkeyIcon } from '../../../../assets/icons/survey-monkey-icon.svg';
import { ReactComponent as TypeFormIcon } from '../../../../assets/icons/typeform-icon.svg';
import { ReactComponent as UserTestingIcon } from '../../../../assets/icons/user-testing-icon.svg';

import backgroundImage from '../../../../assets/images/upload-in-dashboard-background.svg';
import { UserMapping } from '../../../../store/reducers/fileMapping/types';
import Dropdown, { DropdownOption } from '../../../Dropdown/Dropdown';
import WarningModal from '../../../Modals/WarningModal/WarningModal';
import { FilledButton, OutlinedButton } from '../../../UI/Button';
import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import HorizontalLoader from '../../common/HorizontalLoader/HorizontalLoader';
import CustomDateFormatContainer from './components/CustomDateFormat/CustomDateFormatContainer';
import HaveExpertMapFiles from './components/HaveExpertMapFiles/HaveExpertMapFilesContainer';
import HoverableHelp from './components/HoverableHelp/HoverableHelp';
import RequestBeehive from './components/RequestBeehive/RequestBeehiveContainer';
import {
  AI_GENERATED_SOURCE,
  MEDALLIA_SOURCE,
  QUALTRICS_SOURCE,
  SURVEY_MONKEY_SOURCE,
  TYPEFORM_SOURCE,
  USER_TESTING_SOURCE,
} from './constants';

import styles from './FileUploadMapping.module.css';
import {
  ANSWERS_START_FROM_COLUMN,
  ColumnsMappingConfig,
  DATE_FORMAT_COLUMN,
  MAPPING_COLUMNS,
  SELECT_MODERATOR_COLUMN,
  SOURCE_COLUMN,
} from './FileUploadMappingContainer';
import {
  ActiveFileName,
  AnswersStartingRowHelpStyled,
  Background,
  ButtonsBlock,
  CheckIconStyled,
  CloseButtonContainer,
  CloseIconStyled,
  Column,
  ColumnContainer,
  ColumnsContainer,
  ColumnsMappingContainer,
  ColumnsMappingHeader,
  Container,
  CrossIcon,
  DateFormatHelpStyled,
  ExampleColumn,
  ExampleColumnExample,
  ExampleColumnHeader,
  ExampleColumnHeaderTitle,
  FileInfoPopup,
  FilesInfo,
  FileSkipTextContainer,
  Footer,
  GhostColumnsContainer,
  GhostColumnsStyled,
  GhostDropdownsStyled,
  Header,
  HeaderContainer,
  HeaderSubtitle,
  LoadingTextContainer,
  MappingConfigurationContainer,
  SelectTemplateHelpStyled,
  SkipColumnToggle,
} from './styles';

type ActionPendingApproval = {
  action: (option: DropdownOption) => void;
  option: DropdownOption;
};

interface IFileUploadMapping {
  activeField: string;
  activeFileName: string;
  answersStartFrom: DropdownOption | null;
  availableAnswersStartFromValues: DropdownOption[];
  availableDateFormats: DropdownOption[];
  availableMappingValues: DropdownOption[];
  columnsMappingConfig: ColumnsMappingConfig[];
  currentFileNumber: number;
  currentMappedNumberOfColumns: number;
  dateFormat: DropdownOption | null;
  dateFormatErrors: string[] | null;
  exampleColumns: UserMapping[];
  isBackButtonEnabled: boolean;
  isCustomDateFormat: boolean;
  isErrorPredicting: boolean;
  isInterviewFile: boolean;
  isLoading: boolean;
  isNextButtonEnabled: boolean;
  isPredictLoading: boolean;
  isUnrecoverableError: boolean;
  moderator: DropdownOption[] | null;
  numberOfColumnsWithWrongDate: number;
  possibleFileSources: DropdownOption[];
  source: DropdownOption | null;
  totalNumberOfColumnsInActiveFile: number;
  totalNumberOfFiles: number;
  onBackButtonClick: () => void;
  onCloseButtonClick: () => void;
  onColumnMappingChange: (columnInfo: UserMapping, option: DropdownOption) => void;
  onColumnSkipToggle: (columnInfo: UserMapping) => void;
  onNextButtonClick: () => void;
  onExpertMapRequest: () => void;
}

function FileUploadMapping({
  activeField,
  activeFileName,
  answersStartFrom,
  availableAnswersStartFromValues,
  availableDateFormats,
  availableMappingValues,
  columnsMappingConfig,
  currentFileNumber,
  currentMappedNumberOfColumns,
  dateFormat,
  dateFormatErrors,
  exampleColumns,
  isBackButtonEnabled,
  isCustomDateFormat,
  isErrorPredicting,
  isInterviewFile,
  isLoading,
  isNextButtonEnabled,
  isPredictLoading,
  isUnrecoverableError,
  moderator,
  numberOfColumnsWithWrongDate,
  possibleFileSources,
  source,
  totalNumberOfColumnsInActiveFile,
  totalNumberOfFiles,
  onBackButtonClick,
  onCloseButtonClick,
  onColumnMappingChange,
  onColumnSkipToggle,
  onNextButtonClick,
  onExpertMapRequest,
}: IFileUploadMapping) {
  const [didClickOnClose, setDidClickOnClose] = useState(false);
  const [actionPendingApproval, setActionPendingApproval] = useState<ActionPendingApproval | null>(null);

  function handleCloseButtonClick() {
    setDidClickOnClose(true);
  }

  const nextButtonTitle = currentFileNumber === totalNumberOfFiles ? 'Submit' : 'Next';
  return (
    <>
      <Container>
        <Background src={backgroundImage} />
        <HeaderContainer>
          <Header>AI-Generated Field Mapping & Validation</Header>
          <HeaderSubtitle>
            Once you confirm the AI-mapped data types for each column, our AI will begin processing your data.
          </HeaderSubtitle>
          {renderDateParsingError()}
          <CloseButtonContainer onClick={handleCloseButtonClick}>
            <CloseIconStyled />
          </CloseButtonContainer>
        </HeaderContainer>

        <ActiveFileName title={activeFileName}>{activeFileName}</ActiveFileName>
        {renderConfigurationBlock()}
        {renderMappingBlock()}
        <Footer>
          <FilesInfo>
            {currentFileNumber} of {totalNumberOfFiles} files
          </FilesInfo>
          <ButtonsBlock>
            <HaveExpertMapFiles onSubmitClick={onExpertMapRequest} />
            <OutlinedButton disabled={!isBackButtonEnabled} onClick={onBackButtonClick}>
              Back
            </OutlinedButton>
            {renderNextButton()}
          </ButtonsBlock>
        </Footer>
      </Container>
      {renderWarningPageLeave()}
      {renderWarningInitialParamChange()}
    </>
  );

  function renderConfigurationBlock() {
    if (isInterviewFile) {
      return (
        <MappingConfigurationContainer>
          <GhostDropdownsStyled />
        </MappingConfigurationContainer>
      );
    }

    return (
      <MappingConfigurationContainer>
        {columnsMappingConfig.map((config, index) => (
          <ColumnContainer $isActive={!isUnrecoverableError && config.columnName === activeField} key={index}>
            {renderConfigurationColumn(config, index, isUnrecoverableError)}
          </ColumnContainer>
        ))}
      </MappingConfigurationContainer>
    );
  }

  function renderConfigurationColumn(config: ColumnsMappingConfig, index: number, isUnrecoverableError: boolean) {
    function handleIrreversibleConfigChange(actionToCall: (option: DropdownOption) => void, option: DropdownOption) {
      if (currentMappedNumberOfColumns !== 0) {
        setActionPendingApproval({
          action: actionToCall,
          option,
        });
      } else {
        actionToCall(option);
      }
    }

    function renderSourceOptionTitle(option: DropdownOption | DropdownOption[] | null) {
      if (!option) {
        return <div>''</div>;
      }
      // we do not expect multiple options selected for Source field
      const typedOption = option as DropdownOption;
      switch (typedOption.name) {
        case MEDALLIA_SOURCE:
          return (
            <div className={styles.sourceOptionTitleContainer}>
              <MedalliaIcon />
              {typedOption.title}
            </div>
          );
        case SURVEY_MONKEY_SOURCE:
          return (
            <div className={styles.sourceOptionTitleContainer}>
              <SurveyMonkeyIcon />
              {typedOption.title}
            </div>
          );
        case TYPEFORM_SOURCE:
          return (
            <div className={styles.sourceOptionTitleContainer}>
              <TypeFormIcon />
              {typedOption.title}
            </div>
          );
        case QUALTRICS_SOURCE:
          return (
            <div className={styles.sourceOptionTitleContainer}>
              <QualtricsIcon />
              {typedOption.title}
            </div>
          );
        case AI_GENERATED_SOURCE:
          return (
            <div className={styles.sourceOptionTitleContainer}>
              <AIGeneratedMappingIcon />
              {typedOption.title}
            </div>
          );
        case USER_TESTING_SOURCE:
          return (
            <div className={styles.sourceOptionTitleContainer}>
              <UserTestingIcon />
              {typedOption.title}
            </div>
          );
        default:
          return <>{typedOption.title}</>;
      }
    }

    switch (config.columnName) {
      case SOURCE_COLUMN:
        return (
          <>
            <div className={`${styles.columnHeader} ${styles.columnHeaderWide}`}>
              Select a Mapping Template
              <HoverableHelp renderHelpElement={renderSelectTemplateHelp} />
            </div>
            <Dropdown
              isDisabled={isUnrecoverableError}
              placeholder={config.placeholder}
              value={source}
              renderAfterOption={renderRequestTemplateOption}
              options={possibleFileSources}
              renderValueTitle={renderSourceOptionTitle}
              renderOptionTitle={renderSourceOptionTitle}
              onChange={handleIrreversibleConfigChange.bind(null, config.action)}
            />
          </>
        );
      case ANSWERS_START_FROM_COLUMN:
        return (
          <>
            <div className={styles.columnHeader}>
              Answers Start From
              <HoverableHelp renderHelpElement={renderAnswersStartingRowHelp} />
            </div>
            <Dropdown
              isDisabled={isUnrecoverableError}
              placeholder={config.placeholder}
              value={answersStartFrom}
              options={availableAnswersStartFromValues}
              onChange={config.action}
            />
          </>
        );
      case DATE_FORMAT_COLUMN:
        return (
          <>
            <div className={`${styles.columnHeader} ${styles.columnHeaderMiddle}`}>
              Date Format
              <HoverableHelp renderHelpElement={renderDateFormatHelp} />
            </div>
            <Dropdown
              isDisabled={isUnrecoverableError}
              placeholder={config.placeholder}
              options={availableDateFormats}
              value={dateFormat}
              onChange={config.action}
            />
            {isCustomDateFormat && <CustomDateFormatContainer />}
          </>
        );
      case SELECT_MODERATOR_COLUMN:
        return (
          <>
            <div className={styles.columnHeader}>Select Moderator(s)</div>
            <Dropdown isDisabled={isUnrecoverableError} placeholder={config.placeholder} value={moderator} onChange={config.action} />
          </>
        );
      default:
        console.error(`Unknown column name submitted: `, config.columnName);
        return null;
    }
  }

  function renderRequestTemplateOption() {
    return <RequestBeehive />;
  }

  function renderMappingBlock() {
    if (isInterviewFile || isPredictLoading) {
      return (
        <GhostColumnsContainer>
          <GhostColumnsStyled />
          <FileInfoPopup>
            {isInterviewFile ? (
              <FileSkipTextContainer>
                Mapping not required for this file type. Please select “{nextButtonTitle}” to continue.
              </FileSkipTextContainer>
            ) : (
              <>
                <LoadingTextContainer>Hang tight! AI is mapping your file—this may take a moment.</LoadingTextContainer>
                <HorizontalLoader />
              </>
            )}
          </FileInfoPopup>
        </GhostColumnsContainer>
      );
    }

    if (isErrorPredicting) {
      return (
        <GhostColumnsContainer>
          <GhostColumnsStyled />
          <FileInfoPopup>
            <ErrorMessage text='There was an Error reading the file.' />
            <div>
              The error has been reported to Beehive AI support, and this file will be manually processed by a Beehive AI representative.
            </div>
          </FileInfoPopup>
        </GhostColumnsContainer>
      );
    }

    return (
      <ColumnsMappingContainer $isActive={activeField === MAPPING_COLUMNS}>
        <ColumnsMappingHeader>
          Mapped Columns ({currentMappedNumberOfColumns} of {totalNumberOfColumnsInActiveFile}){' '}
          {isLoading && <CircularProgress size={20} color='inherit' />}
        </ColumnsMappingHeader>
        <ColumnsContainer>{exampleColumns.map(renderMappingColumn)}</ColumnsContainer>
      </ColumnsMappingContainer>
    );
  }

  function renderDateParsingError() {
    if (numberOfColumnsWithWrongDate === 0) {
      return;
    }

    return (
      <ErrorMessage
        text={`There was an Error parsing the date format in ${numberOfColumnsWithWrongDate} columns. Choose a different format or exclude these columns from analysis before proceeding.`}
        containerClassName={styles.dateFormatErrorMappingMessageContainer}
      />
    );
  }

  function renderMappingColumn(columnInfo: UserMapping) {
    const { columnName, rows, columnIndex, columnType } = columnInfo;
    const dropdownValue = availableMappingValues.find((v) => v.value === columnType);
    return (
      <Column key={`${columnIndex}-${columnName?.length ? columnName : 'Possible Unique ID'}`}>
        <Dropdown
          placeholder='Select type'
          value={dropdownValue ?? null}
          options={availableMappingValues}
          onChange={onColumnMappingChange.bind(null, columnInfo)}
        />
        {renderExampleColumn(
          columnName,
          !!dropdownValue,
          rows,
          columnIndex,
          columnInfo.shouldSkip,
          dateFormatErrors?.includes(columnName) ?? false,
          onColumnSkipToggle.bind(null, columnInfo),
        )}
      </Column>
    );
  }

  function renderExampleColumn(
    title: string,
    isMapped: boolean,
    examples: string[] = [],
    columnIndex: number,
    shouldSkip: boolean,
    isError: boolean,
    onColumnSkipToggle: () => void,
  ) {
    let tooltipText = 'Excluded from analysis.\nSelect type to include.';
    if (isMapped && !shouldSkip) {
      tooltipText = 'Included in analysis.\nClick to exclude.';
    } else if (isMapped && shouldSkip) {
      tooltipText = 'Excluded from analysis.\nClick to include.';
    }
    return (
      <ExampleColumn key={`${columnIndex}-${title?.length ? title : 'Possible Unique ID'}`}>
        <ExampleColumnHeader
          data-tooltip-id='base-tooltip'
          data-tooltip-content={title}
          $isMapped={isMapped}
          $shouldSkip={shouldSkip}
          $isError={isError && !shouldSkip}
        >
          <ExampleColumnHeaderTitle>{title}</ExampleColumnHeaderTitle>
          <SkipColumnToggle
            $isMapped={isMapped}
            data-tooltip-id='base-tooltip'
            data-tooltip-content={tooltipText}
            onClick={onColumnSkipToggle}
          >
            {shouldSkip || !isMapped ? <CrossIcon /> : <CheckIconStyled />}
          </SkipColumnToggle>
        </ExampleColumnHeader>
        {examples.map((e, idx) => (
          <ExampleColumnExample key={`${columnIndex}-${e}-${idx}`} data-tooltip-id='base-tooltip' data-tooltip-content={e}>
            {e}
          </ExampleColumnExample>
        ))}
      </ExampleColumn>
    );
  }

  function renderSelectTemplateHelp(top: number, left: number) {
    return (
      <SelectTemplateHelpStyled
        style={{
          top: `${top + 20}px`,
          left: `${left - 210}px`,
        }}
      />
    );
  }

  function renderAnswersStartingRowHelp(top: number, left: number) {
    return (
      <AnswersStartingRowHelpStyled
        style={{
          top: `${top + 20}px`,
          left: `${left - 160}px`,
        }}
      />
    );
  }

  function renderDateFormatHelp(top: number, left: number) {
    return (
      <DateFormatHelpStyled
        style={{
          top: `${top + 20}px`,
          left: `${left - 240}px`,
        }}
      />
    );
  }

  function renderWarningPageLeave() {
    if (!didClickOnClose) {
      return null;
    }

    function handleCancelClick() {
      setDidClickOnClose(false);
    }

    return (
      <WarningModal
        headerText='Are you sure?'
        bodyText='If you leave this page, your progress will not be saved and your files will not be uploaded for analysis.'
        cancelButtonText='Go Back'
        mainActionButtonText='Leave Page'
        onCancelButtonClick={handleCancelClick}
        onMainActionButtonClick={onCloseButtonClick}
      />
    );
  }

  function renderWarningInitialParamChange() {
    if (!actionPendingApproval) {
      return null;
    }

    function handleCancelClick() {
      setActionPendingApproval(null);
    }

    function handleAcceptClick() {
      if (!actionPendingApproval) {
        return;
      }
      actionPendingApproval.action(actionPendingApproval.option);
      setActionPendingApproval(null);
    }

    return (
      <WarningModal
        headerText='Start from Scratch?'
        bodyText='Changing your selection will erase your current mapping progress. Do you want to proceed?'
        cancelButtonText='Go Back'
        mainActionButtonText='Continue'
        onCancelButtonClick={handleCancelClick}
        onMainActionButtonClick={handleAcceptClick}
      />
    );
  }

  function renderNextButton() {
    let tooltipContent = '';
    if (numberOfColumnsWithWrongDate !== 0) {
      tooltipContent = 'Please resolve issues before continuing.';
    } else {
      // Though currently (21.03.2025) some fields cannot be active (Source, Answers Start From, Moderator) I added handling these cases
      // just so we cover all of them in case in future we will add support for them.
      switch (activeField) {
        case SOURCE_COLUMN:
          tooltipContent = 'Please select a Template before continuing.';
          break;
        case ANSWERS_START_FROM_COLUMN:
          tooltipContent = 'Please select Answers Start From before continuing.';
          break;
        case DATE_FORMAT_COLUMN:
          tooltipContent = 'Please select a Date format before continuing.';
          break;
        case SELECT_MODERATOR_COLUMN:
          tooltipContent = 'Please select a Moderator before continuing.';
          break;
        case MAPPING_COLUMNS:
          tooltipContent = 'Please map at least one column before continuing.';
          break;
        default:
          tooltipContent = '';
      }
    }

    return (
      <FilledButton
        data-tooltip-id={!isNextButtonEnabled ? 'base-tooltip' : undefined}
        data-tooltip-content={tooltipContent}
        disabled={!isNextButtonEnabled}
        onClick={onNextButtonClick}
      >
        {nextButtonTitle}
      </FilledButton>
    );
  }
}

export default FileUploadMapping;
