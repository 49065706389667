import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { QueryBuilder } from 'react-querybuilder';
import 'react-querybuilder/dist/query-builder.css';
import { makeStyles } from '@material-ui/core';

import { ControlRuleGroup } from './Controls/ControlRuleGroup';
import { MaterialValueEditor } from './Controls/MaterialValueEditor';
import { ControlAddActionButton } from './Controls/ControlAddActionButton';
import { ControlRemoveActionButton } from './Controls/ControlRemoveActionButton';
import { ControlFieldSelector } from './Controls/ControlFieldSelector';
import { DATA_SOURCE_TYPE, GROUP_OTHER_TITLE } from '../../constants';
import { OPERATOR_NAME, OPERATOR, OPTION_SELECT_OPERATOR, OPTION_SELECT_SOURCE } from './constants';
import ControlOperatorSelector from './Controls/ControlOperatorSelector';
import ControlAddRuleAction from './Controls/ControlAddRuleAction';
import { prepareLabelsAutocompleteOptions } from '../UI/LabelsAutocompleteControl/lib';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { countProperties, transformQueryToFilter } from './lib';

const useStyles = makeStyles(() => ({
  queryBuilder: {
    padding: '10px',
    width: '100%',
  },
  ruleGroup: {
    backgroundColor: 'transparent',
    border: 'solid #8b92a6',
    padding: '10px',
    width: '100%',

    backgroundImage: 'linear-gradient(#8b92a6, #8b92a6), none, linear-gradient(#8b92a6, #8b92a6), none',
    backgroundRepeat: 'no-repeat',
    backgroundSize: '5px 2px',
    backgroundPosition: 'top left, top right, bottom left, bottom right',
    borderWidth: '0 0 0 2px',

    position: 'relative',
    paddingLeft: '30px',
    marginLeft: '15px',
  },
  rule: {
    '& .rule-remove': {
      display: 'none',
    },
    '&:hover .rule-remove': {
      display: 'block',
    },
  },
  combinators: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
    top: '50%',
    marginLeft: '-30px',
    padding: '3px',
    fontSize: '12px',
    backgroundColor: 'white',
    appearance: 'none',
    width: '40px',
    textAlign: 'center',
    borderRadius: '3px',
  },
}));

const VALUE_EDITOR_TYPE = {
  LABEL_SELECTOR: 'labelSelector',
  DATE: 'date',
};

const CustomQueryBuilder = ({ value, onChange, disabled = false, enableMountQueryChange = true, isFilterEdit = false }) => {
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const sentimentSummaries = useSelector((state) => state.dashboard.sentimentSummaries);
  const { dataSources: dashboardDataSources, groups, total } = useSelector((state) => state.dashboard.dashboard);
  const { weightedMetricsUsed, translationUsed } = useSelector((state) => state.app);

  const classes = useStyles();

  const sentimentSummaryById = useMemo(() => {
    return sentimentSummaries.reduce((acc, item) => {
      return { ...acc, [item.dataSourceID]: item.sentiment };
    }, {});
  }, [sentimentSummaries]);

  const groupIdToGroup = groups.reduce((acc, group) => ({ ...acc, [group.id]: group }), {});
  const sortedDashboardDatasource = [...dashboardDataSources].sort((a, b) => {
    if ((!a.groupID && !b.groupID) || a.groupID === b.groupID) {
      return a.order - b.order;
    }
    if (!a.groupID) {
      return 1;
    }
    if (!b.groupID) {
      return -1;
    }

    return groupIdToGroup[a.groupID].order - groupIdToGroup[b.groupID].order;
  });

  const titleField = translationUsed ? 'title' : 'originalTitle';

  const fields = sortedDashboardDatasource
    .filter((dashboardDataSource) => dashboardDataSource.visible)
    .map((dashboardDataSource) => {
      const operators = [OPERATOR.IN, OPERATOR.NOT_IN];
      if (
        (dashboardDataSource.type === DATA_SOURCE_TYPE.OPEN_ENDED || dashboardDataSource.type === DATA_SOURCE_TYPE.INTERVIEW) &&
        sentimentSummaryById[dashboardDataSource.dataSourceID]
      ) {
        operators.push(OPERATOR.SENTIMENT);
      }
      return {
        operators,
        name: dashboardDataSource.dataSourceID,
        label: dashboardDataSource[titleField],
        group: dashboardDataSource.groupID ? groupIdToGroup[dashboardDataSource.groupID][titleField] : GROUP_OTHER_TITLE,
        valueEditorType: VALUE_EDITOR_TYPE.LABEL_SELECTOR,
        total: `${dashboardDataSource.total}/${Math.round((dashboardDataSource.total / total) * 100)}%`,
      };
    });
  fields.push({
    name: 'date',
    label: 'Date',
    group: 'Date',
    valueEditorType: VALUE_EDITOR_TYPE.DATE,
    operators: [OPERATOR.EQUAL_TO, OPERATOR.GREATER_THAN_OR_EQUAL_TO, OPERATOR.LESS_THAN_OR_EQUAL_TO, OPERATOR.DATE_RANGE],
    defaultValue: '',
  });

  const controlElements = {
    fieldSelector: ControlFieldSelector,
    operatorSelector: ControlOperatorSelector,
    ruleGroup: ControlRuleGroup,
    valueEditor: MaterialValueEditor,
    addGroupAction: ControlAddActionButton,
    addRuleAction: ControlAddRuleAction,
    removeRuleAction: ControlRemoveActionButton,
    removeGroupAction: ControlRemoveActionButton,
  };

  const getValues = (field, operator) => {
    // date
    if (field === 'date') {
      return null;
    }

    const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === field);
    if (!dashboardDataSource) {
      return [];
    }
    // sentiment
    if (operator === OPERATOR_NAME.SENTIMENT) {
      const total = dashboardDataSource.total;
      const sentimentSummary = sentimentSummaryById[field];
      return [
        {
          name: 'positive',
          label: 'Positive',
          percentage: Math.round((sentimentSummary.positive / total) * 100),
          parent: null,
        },
        {
          name: 'neutral',
          label: 'Neutral',
          percentage: Math.round((sentimentSummary.neutral / total) * 100),
          parent: null,
        },
        {
          name: 'negative',
          label: 'Negative',
          percentage: Math.round((sentimentSummary.negative / total) * 100),
          parent: null,
        },
      ];
    }
    // labels
    const dataSource = dataSources.find((dataSource) => dataSource.dataSourceID === field);
    return prepareLabelsAutocompleteOptions(dataSource, dashboardDataSource.ordered, translationUsed, weightedMetricsUsed);
  };

  const controlClassnames = {
    ...classes,
  };

  const translations = {
    addRule: {
      title: 'Add filter',
      label: 'Add filter',
    },
    addGroup: {
      title: 'You can create a group of multiple different properties to filter by',
      label: 'Add a property group',
    },
    fields: {
      title: 'Fields',
      placeholderName: OPTION_SELECT_SOURCE.name,
      placeholderLabel: OPTION_SELECT_SOURCE.label,
      placeholderGroupLabel: null,
    },
    operators: {
      title: 'Operators',
      placeholderName: OPTION_SELECT_OPERATOR.name,
      placeholderLabel: OPTION_SELECT_OPERATOR.label,
      placeholderGroupLabel: null,
    },
  };

  // override default value preselection
  const getDefaultValue = () => {
    return '';
  };

  const handleOnAddRule = (rule, parentPath, query) => {
    const filterQuery = transformQueryToFilter(query);
    eventsTracker.track(EVENTS.FILTER_SELECT_PROPERTY, {
      'Filter Origin': isFilterEdit ? 'Edit' : 'New',
      'In Group': parentPath.length > 0,
      'Property Count': countProperties(filterQuery),
    });
    return rule;
  };

  const handleOnAggGroup = (ruleGroup, parentPath, query) => {
    eventsTracker.track(EVENTS.FILTER_ADD_PROPERTY_GROUP, {
      'Filter Origin': isFilterEdit ? 'Edit' : 'New',
      'In Group': parentPath.length > 0,
      'Property Count': countProperties(query),
    });
    return ruleGroup;
  };

  return (
    <QueryBuilder
      fields={fields}
      query={value}
      controlElements={controlElements}
      onQueryChange={onChange}
      onAddRule={handleOnAddRule}
      onAddGroup={handleOnAggGroup}
      resetOnOperatorChange={true}
      controlClassnames={controlClassnames}
      translations={translations}
      getValues={getValues}
      autoSelectField={false}
      autoSelectOperator={false}
      getDefaultValue={getDefaultValue}
      listsAsArrays={true}
      enableMountQueryChange={enableMountQueryChange}
      disabled={disabled}
    />
  );
};

export default CustomQueryBuilder;
