import React, { useCallback } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { dashboardUpdateTitle } from '../../../../store/thunks/dashboard';

import SuccessfulUploadDialog from './SuccessfulUploadDialog';
import { debounce } from 'lodash';
import { updateRenameDashboard } from '../../../../store/actions/connectors';
import { userFinishedUpload } from '../../../tools/api';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';

const DEBOUNCE_PERIOD = 500;

interface ISuccessfulUploadDialogContainer {
  fileNames: string[];
  isNewDashboard: boolean;
  onClose: () => void;
}

function SuccessfulUploadDialogContainer({ fileNames, isNewDashboard, onClose }: ISuccessfulUploadDialogContainer) {
  const dispatch = useAppDispatch();
  const renameDashboard = useAppSelector((state) => state.connectors.renameDashboard);
  const [dashboardName, setDashboardName] = React.useState('');

  function handleDashboardNameChange(event: React.ChangeEvent<HTMLInputElement>) {
    // start showing loading spinner before the debounced call is made
    let newName = event.target.value ?? '';
    newName = newName.slice(0, 50); // limit to 50 characters
    setDashboardName(newName);
    dispatch(updateRenameDashboard({ submitting: true, success: false }));
    if (!renameDashboard.dashboardID) {
      dispatch(updateRenameDashboard({ submitting: false, success: false }));
      return;
    }
    callDashboardUpdateTitle(newName);
  }

  const callDashboardUpdateTitle = useCallback(
    debounce((title) => {
      if (!renameDashboard.dashboardID || !renameDashboard.originalDashboardName) {
        dispatch(updateRenameDashboard({ submitting: false, success: false }));
        return;
      }
      if (title) {
        dispatch(dashboardUpdateTitle({ id: renameDashboard.dashboardID, title }));
      } else {
        dispatch(dashboardUpdateTitle({ id: renameDashboard.dashboardID, title: renameDashboard.originalDashboardName ?? '' }));
      }
    }, DEBOUNCE_PERIOD),
    [dispatch],
  );

  function handleClose() {
    eventsTracker.track(EVENTS.FILE_UPLOAD_SUCCESSFUL_CLOSE_POPUP, {
      'Dashboard name entry': dashboardName,
      'Number of files uploaded': fileNames.length,
    });
    if (!renameDashboard.dashboardID) {
      console.error('No dashboard ID found');
      return;
    }
    userFinishedUpload(renameDashboard.dashboardID, isNewDashboard, renameDashboard.files ?? []);
    onClose();
  }

  return (
    <SuccessfulUploadDialog
      fileNames={fileNames}
      requestedManualProcessing={renameDashboard.requestedManualProcessing}
      shouldShowRenameDashboard={isNewDashboard && !!renameDashboard.dashboardID}
      dashboardName={dashboardName}
      dashboardRenameSubmitting={renameDashboard.submitting}
      dashboardRenameSuccess={renameDashboard.success}
      onClose={handleClose}
      onDashboardNameChange={handleDashboardNameChange}
    />
  );
}

export default SuccessfulUploadDialogContainer;
