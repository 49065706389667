import { Dashboard, View } from '../../../store/reducers/dashboards/types';
import { Hive } from '../HivesTabContainer';

export function dashboardsAndViewsToHives(dashboards: Dashboard[], views: View[]) {
  const hives = [] as Hive[];
  const dashboardsById = dashboards.reduce((acc: any, dashboard: Dashboard) => ({ ...acc, [dashboard.id]: dashboard }), {});
  const ids = new Set(Object.keys(dashboardsById));
  for (const view of views) {
    const viewDashboards = [];
    if (view.dashboardIDs) {
      for (const dashboardID of view.dashboardIDs) {
        if (dashboardsById[dashboardID]) {
          viewDashboards.push(dashboardsById[dashboardID]);
          ids.delete(dashboardID);
        }
      }
      hives.push({
        ...view,
        dashboards: viewDashboards,
      });
    } else {
      hives.push({
        ...view,
        dashboards: [],
      });
    }
  }

  // add unattached dashboards to the first hive (it's a synthetic hive with id = null)
  if (ids.size > 0) {
    hives[0].dashboards = [...ids.values()].map((id) => dashboardsById[id]);
  }

  return hives;
}
