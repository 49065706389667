import { cloneDeep } from 'lodash';
import { UNKNOWN_TYPE } from '../../../components/FileUploadFlow/components/FileUploadMapping/constants';
import {
  FILE_MAPPING_GET_TEMPLATES_ERROR,
  FILE_MAPPING_GET_TEMPLATES_LOADING,
  FILE_MAPPING_GET_TEMPLATES_SUCCESS,
  FILE_MAPPING_INIT,
  FILE_MAPPING_LOAD_FILE_MAPPING_ERROR,
  FILE_MAPPING_LOAD_FILE_MAPPING_START,
  FILE_MAPPING_LOAD_FILE_MAPPING_SUCCESS,
  FILE_MAPPING_PREDICT_ERROR,
  FILE_MAPPING_PREDICT_START,
  FILE_MAPPING_PREDICT_SUCCESS,
  FILE_MAPPING_REPORT_ISSUE_ERROR,
  FILE_MAPPING_REPORT_ISSUE_START,
  FILE_MAPPING_REPORT_ISSUE_SUCCESS,
  FILE_MAPPING_REQUEST_TEMPLATE_ERROR,
  FILE_MAPPING_REQUEST_TEMPLATE_START,
  FILE_MAPPING_REQUEST_TEMPLATE_SUCCESS,
  FILE_MAPPING_RESET,
  FILE_MAPPING_UPDATE_FILE_INFO,
  FILE_MAPPING_UPDATE_SELECTED_INDEX,
  FILE_MAPPING_UPDATE_USER_MAPPING,
  FILE_MAPPING_UPLOAD_ERROR,
  FILE_MAPPING_UPLOAD_START,
  FILE_MAPPING_UPLOAD_SUCCESS,
  FILE_MAPPING_VALIDATE_DATE_FORMAT_START,
  FILE_MAPPING_VALIDATE_DATE_FORMAT_SUCCESS,
  FILE_MAPPING_VALIDATE_MAPPING_CONTINUE,
  FILE_MAPPING_VALIDATE_MAPPING_ERROR,
  FILE_MAPPING_VALIDATE_MAPPING_START,
  FILE_MAPPING_VALIDATE_MAPPING_SUCCESS,
  fileMappingInit,
  fileMappingLoadFileMappingError,
  fileMappingPredictError,
  fileMappingReportIssueError,
  fileMappingRequestTemplateError,
  fileMappingTemplatesError,
  fileMappingTemplatesSuccess,
  fileMappingUpdateFileInfo,
  fileMappingUpdateSelectedIndex,
  fileMappingUpdateUserMapping,
  fileMappingUploadError,
  fileMappingValidateDateFormatSuccess,
  fileMappingValidateMappingError,
  fileMappingValidateMappingSuccess,
} from '../../actions/fileMapping';
import { FileState, FileType, MappingState, Template, UserMapping } from './types';
import { canExcludeFromDateFormatParsingError, isReplacingTheUniqueType } from './utils';

export interface IMappingReducer {
  // List of files with all additional information
  files: FileState[];
  // dashboard id if the user is adding files to an existing dashboard
  dashboardID: string | null;
  // Selected File Index
  selectedIndex: number;

  // Dashboard privacy setting if new dashboard
  isPrivate: boolean | null;

  // Current state of mapping: PREDICTING, LOADING_TEMPLATES, UPLOADING, DEFAULT
  isLoading: Partial<{
    [key in MappingState]: boolean;
  }>;
  error: {
    [key in MappingState]: string | null;
  };
  templates: Template[];
}

const getFileInitialState = () => ({
  fileName: '',
  externalID: '',
  tempFileName: '',
  templateID: null,
  startRow: 0,
  numberOfRows: null,
  dateFormat: null,
  speakers: [],
  userMapping: {},
  dateFormatErrors: null,
  isErrorPredicting: false,
  isMappingValid: null,
  isCustomDateFormat: false,
  isDateFormatValid: null,
  hasAlreadyPredicted: false,
});

export const getInitialIsLoadingState = () => ({
  [MappingState.LOADING_TEMPLATES]: false,
  [MappingState.PREDICTING]: false,
  [MappingState.LOADING_MAPPING_STATE]: false,
  [MappingState.UPLOADING]: false,
  [MappingState.VALIDATING_DATE_FORMAT]: false,
  [MappingState.VALIDATING_MAPPING]: false,
  [MappingState.REPORTING_ISSUE]: false,
  [MappingState.REQUESTING_TEMPLATE]: false,
});

export const getInitialErrorState = () => ({
  [MappingState.DEFAULT]: null,
  [MappingState.LOADING_TEMPLATES]: null,
  [MappingState.PREDICTING]: null,
  [MappingState.LOADING_MAPPING_STATE]: null,
  [MappingState.UPLOADING]: null,
  [MappingState.VALIDATING_DATE_FORMAT]: null,
  [MappingState.VALIDATING_MAPPING]: null,
  [MappingState.REPORTING_ISSUE]: null,
  [MappingState.REQUESTING_TEMPLATE]: null,
});

export const getInitialState = (): IMappingReducer => ({
  files: [],
  dashboardID: null,
  selectedIndex: 0,
  isPrivate: false,
  isLoading: getInitialIsLoadingState(),
  templates: [],
  error: getInitialErrorState(),
});

export default function mappingReducer(state = getInitialState(), action: any): IMappingReducer {
  switch (action.type) {
    case FILE_MAPPING_INIT:
      return handleFileMappingInit(state, action);
    case FILE_MAPPING_RESET:
      return handleFileMappingReset();
    case FILE_MAPPING_PREDICT_START:
      return handleFileMappingPredictStart(state);
    case FILE_MAPPING_PREDICT_ERROR:
      return handleFileMappingPredictError(state, action);
    case FILE_MAPPING_PREDICT_SUCCESS:
      return handleFileMappingPredictSuccess(state);
    case FILE_MAPPING_LOAD_FILE_MAPPING_START:
      return handleFileMappingLoadFileMappingStart(state);
    case FILE_MAPPING_LOAD_FILE_MAPPING_ERROR:
      return handleFileMappingLoadFileMappingError(state, action);
    case FILE_MAPPING_LOAD_FILE_MAPPING_SUCCESS:
      return handleFileMappingLoadFileMappingSuccess(state);
    case FILE_MAPPING_UPDATE_USER_MAPPING:
      return handleFileMappingUpdateUserMapping(state, action);
    case FILE_MAPPING_UPDATE_FILE_INFO:
      return handleFileMappingUpdateFileInfo(state, action);
    case FILE_MAPPING_UPDATE_SELECTED_INDEX:
      return handleFileMappingUpdateSelectedIndex(state, action);
    case FILE_MAPPING_GET_TEMPLATES_LOADING:
      return handleFileMappingTemplatesStart(state);
    case FILE_MAPPING_GET_TEMPLATES_ERROR:
      return handleFileMappingTemplatesError(state, action);
    case FILE_MAPPING_GET_TEMPLATES_SUCCESS:
      return handleFileMappingTemplatesSuccess(state, action);
    case FILE_MAPPING_UPLOAD_START:
      return handleFileMappingUploadStart(state);
    case FILE_MAPPING_UPLOAD_ERROR:
      return handleFileMappingUploadError(state, action);
    case FILE_MAPPING_UPLOAD_SUCCESS:
      return handleFileMappingUploadSuccess(state);
    case FILE_MAPPING_VALIDATE_DATE_FORMAT_START:
      return handleFileMappingValidateDateFormatStart(state);
    case FILE_MAPPING_VALIDATE_DATE_FORMAT_SUCCESS:
      return handleFileMappingValidateDateFormatSuccess(state, action);
    case FILE_MAPPING_VALIDATE_MAPPING_START:
      return handleFileMappingValidateMappingStart(state);
    case FILE_MAPPING_VALIDATE_MAPPING_ERROR:
      return handleFileMappingValidateMappingError(state, action);
    case FILE_MAPPING_VALIDATE_MAPPING_SUCCESS:
      return handleFileMappingValidateMappingSuccess(state, action);
    case FILE_MAPPING_VALIDATE_MAPPING_CONTINUE:
      return handleFileMappingValidateMappingContinue(state);
    case FILE_MAPPING_REPORT_ISSUE_START:
      return handleFileMappingReportIssueStart(state);
    case FILE_MAPPING_REPORT_ISSUE_ERROR:
      return handleFileMappingReportIssueError(state, action);
    case FILE_MAPPING_REPORT_ISSUE_SUCCESS:
      return handleFileMappingReportIssueSuccess(state);
    case FILE_MAPPING_REQUEST_TEMPLATE_START:
      return handleFileMappingRequestTemplateStart(state);
    case FILE_MAPPING_REQUEST_TEMPLATE_ERROR:
      return handleFileMappingRequestTemplateError(state, action);
    case FILE_MAPPING_REQUEST_TEMPLATE_SUCCESS:
      return handleFileMappingRequestTemplateSuccess(state);
    default:
      return state;
  }
}

function handleFileMappingInit(state: IMappingReducer, action: ReturnType<typeof fileMappingInit>): IMappingReducer {
  return {
    ...state,
    isPrivate: action.payload.isPrivate,
    dashboardID: action.payload.dashboardID,
    files: action.payload.files.map((md) => ({
      ...getFileInitialState(),
      externalID: md.externalID,
      fileType: md.programType as FileType,
      fileName: md.fileName,
    })),
  };
}

function handleFileMappingReset(): IMappingReducer {
  return getInitialState();
}

function handleFileMappingPredictStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.PREDICTING]: true },
    error: {
      ...state.error,
      [MappingState.PREDICTING]: null,
    },
  };
}

function handleFileMappingPredictError(state: IMappingReducer, action: ReturnType<typeof fileMappingPredictError>): IMappingReducer {
  const clonedCurrentFile = cloneDeep(state.files[state.selectedIndex]);
  clonedCurrentFile.isErrorPredicting = true;
  const newFilesState = state.files.toSpliced(state.selectedIndex, 1, clonedCurrentFile);
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.PREDICTING]: false },
    error: {
      ...state.error,
      [MappingState.PREDICTING]: action.payload.error,
    },
    files: newFilesState,
  };
}

function handleFileMappingPredictSuccess(state: IMappingReducer): IMappingReducer {
  const clonedCurrentFile = cloneDeep(state.files[state.selectedIndex]);
  clonedCurrentFile.hasAlreadyPredicted = true;
  const newFilesState = state.files.toSpliced(state.selectedIndex, 1, clonedCurrentFile);
  return {
    ...state,
    files: [...newFilesState],
    isLoading: { ...state.isLoading, [MappingState.PREDICTING]: false },
    error: {
      ...state.error,
      [MappingState.PREDICTING]: null,
    },
  };
}

function handleFileMappingLoadFileMappingStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.LOADING_MAPPING_STATE]: true },
    error: {
      ...state.error,
      [MappingState.LOADING_MAPPING_STATE]: null,
    },
  };
}

function handleFileMappingLoadFileMappingError(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingLoadFileMappingError>,
): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.LOADING_MAPPING_STATE]: false },
    error: {
      ...state.error,
      [MappingState.LOADING_MAPPING_STATE]: action.payload.error,
    },
  };
}

function handleFileMappingLoadFileMappingSuccess(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.LOADING_MAPPING_STATE]: false },
    error: {
      ...state.error,
      [MappingState.LOADING_MAPPING_STATE]: null,
    },
  };
}

function handleFileMappingUpdateUserMapping(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingUpdateUserMapping>,
): IMappingReducer {
  const { userMapping, byUserAction } = action.payload;
  const clonedCurrentFile = cloneDeep(state.files[state.selectedIndex]);

  if (byUserAction) {
    const newMappingValue = Object.values(userMapping)[0];
    const newMappingColumnName = Object.keys(userMapping)[0];
    const columnType = newMappingValue.columnType;

    const currentColumnMappingState = clonedCurrentFile.userMapping[newMappingColumnName];
    // we want check only on type changes
    const hasColumnTypeChanged = currentColumnMappingState?.columnType !== columnType;

    // ensure that these types are not already set, otherwise we rewrite the type of previously chosen column:
    if (isReplacingTheUniqueType(hasColumnTypeChanged, columnType)) {
      const possibleColumnWithType = Object.values(clonedCurrentFile.userMapping).find((um) => um.columnType === columnType);
      if (!!possibleColumnWithType) {
        possibleColumnWithType.columnType = UNKNOWN_TYPE;
      }
    }

    if (clonedCurrentFile.dateFormatErrors?.length && canExcludeFromDateFormatParsingError(hasColumnTypeChanged, columnType)) {
      clonedCurrentFile.dateFormatErrors = clonedCurrentFile.dateFormatErrors?.filter((colName) => colName !== newMappingValue.columnName);
      if (clonedCurrentFile.dateFormatErrors.length === 0) {
        clonedCurrentFile.dateFormatErrors = null;
      }
    }
  }

  let newFilesState = state.files;

  if (byUserAction) {
    newFilesState = state.files.toSpliced(state.selectedIndex, 1, clonedCurrentFile);
  }

  return {
    ...state,
    files: newFilesState.map((file, index): FileState => {
      if (index === state.selectedIndex) {
        const newUserMapping: Record<string, UserMapping> = { ...file.userMapping };
        Object.keys(userMapping).forEach((key) => {
          if (byUserAction) {
            newUserMapping[key] = {
              ...newUserMapping[key],
              ...userMapping[key],
            };
            return;
          }
          // updating row samples
          if (key in newUserMapping && userMapping[key].columnType === UNKNOWN_TYPE) {
            newUserMapping[key] = {
              ...userMapping[key],
              columnType: newUserMapping[key].columnType,
              shouldSkip: newUserMapping[key].shouldSkip,
            };
            return;
          }
          newUserMapping[key] = userMapping[key];
        });
        return {
          ...file,
          userMapping: newUserMapping,
        };
      }
      return file;
    }),
  };
}

function handleFileMappingUpdateFileInfo(state: IMappingReducer, action: ReturnType<typeof fileMappingUpdateFileInfo>): IMappingReducer {
  return {
    ...state,
    files: state.files.map((file, index): FileState => {
      if (index === state.selectedIndex) {
        return {
          ...file,
          ...action.payload.fileState,
        } as FileState;
      }
      return file;
    }),
  };
}

function handleFileMappingUpdateSelectedIndex(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingUpdateSelectedIndex>,
): IMappingReducer {
  const { index } = action.payload;
  const isGoBack = index < state.selectedIndex;
  let files = state.files;
  if (isGoBack) {
    files = cloneDeep(files);
    files[index].isMappingValid = null;
  }
  return {
    ...state,
    selectedIndex: index,
    files,
    error: getInitialErrorState(),
    isLoading: getInitialIsLoadingState(),
  };
}

function handleFileMappingTemplatesStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.LOADING_TEMPLATES]: true },
    error: {
      ...state.error,
      [MappingState.LOADING_TEMPLATES]: null,
    },
  };
}

function handleFileMappingTemplatesError(state: IMappingReducer, action: ReturnType<typeof fileMappingTemplatesError>): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.LOADING_TEMPLATES]: false },
    error: {
      ...state.error,
      [MappingState.LOADING_TEMPLATES]: action.payload.error,
    },
  };
}

function handleFileMappingTemplatesSuccess(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingTemplatesSuccess>,
): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.LOADING_TEMPLATES]: false },
    templates: action.payload.templates,
    error: {
      ...state.error,
      [MappingState.LOADING_TEMPLATES]: null,
    },
  };
}

function handleFileMappingUploadStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.UPLOADING]: true },
    error: {
      ...state.error,
      [MappingState.UPLOADING]: null,
    },
  };
}

function handleFileMappingUploadError(state: IMappingReducer, action: ReturnType<typeof fileMappingUploadError>): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.UPLOADING]: false },
    error: {
      ...state.error,
      [MappingState.UPLOADING]: action.payload.error,
    },
  };
}

function handleFileMappingUploadSuccess(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.UPLOADING]: false },
    error: {
      ...state.error,
      [MappingState.UPLOADING]: null,
    },
  };
}

function handleFileMappingValidateDateFormatStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.VALIDATING_DATE_FORMAT]: true },
    files: state.files.map((file, index): FileState => {
      if (index === state.selectedIndex) {
        return {
          ...file,
          isDateFormatValid: null,
        };
      }
      return file;
    }),
  };
}

function handleFileMappingValidateDateFormatSuccess(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingValidateDateFormatSuccess>,
): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.VALIDATING_DATE_FORMAT]: false },
    files: state.files.map((file, index): FileState => {
      if (index === state.selectedIndex) {
        return {
          ...file,
          isDateFormatValid: action.payload.isValid,
          dateFormat: action.payload.dateFormat,
          isMappingValid: action.payload.isValid ? null : file.isMappingValid,
          dateFormatErrors: action.payload.isValid ? null : file.dateFormatErrors,
        };
      }
      return file;
    }),
  };
}

function handleFileMappingValidateMappingStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.VALIDATING_MAPPING]: true },
    error: {
      ...state.error,
      [MappingState.VALIDATING_MAPPING]: null,
    },
    files: state.files.map((file, index) => {
      if (index === state.selectedIndex) {
        return {
          ...file,
          dateFormatErrors: null,
          isMappingValid: null,
        };
      }
      return file;
    }),
  };
}

function handleFileMappingValidateMappingError(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingValidateMappingError>,
): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.VALIDATING_MAPPING]: false },
    error: {
      ...state.error,
      [MappingState.VALIDATING_MAPPING]: action.payload.error,
    },
    files: state.files.map((file, index) => {
      if (index === state.selectedIndex) {
        return {
          ...file,
          isMappingValid: null,
        };
      }
      return file;
    }),
  };
}

function handleFileMappingValidateMappingSuccess(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingValidateMappingSuccess>,
): IMappingReducer {
  const { dateFormatErrors, isValid } = action.payload;

  const canProceedToNextFile = isValid && !dateFormatErrors.length;
  const newFiles = state.files.map((file, index) => {
    if (index === state.selectedIndex) {
      return {
        ...file,
        dateFormatErrors: action.payload.dateFormatErrors,
        isMappingValid: action.payload.isValid,
      };
    }
    return file;
  });
  const newSelectedFileIndex = canProceedToNextFile ? (state.selectedIndex += 1) : state.selectedIndex;

  return {
    ...state,
    error: getInitialErrorState(),
    isLoading: getInitialIsLoadingState(),
    files: newFiles,
    selectedIndex: newSelectedFileIndex,
  };
}

function handleFileMappingValidateMappingContinue(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    selectedIndex: state.selectedIndex + 1,
    error: getInitialErrorState(),
    isLoading: getInitialIsLoadingState(),
  };
}

function handleFileMappingReportIssueStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.REPORTING_ISSUE]: true },
    error: {
      ...state.error,
      [MappingState.REPORTING_ISSUE]: null,
    },
  };
}

function handleFileMappingReportIssueError(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingReportIssueError>,
): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.REPORTING_ISSUE]: false },
    error: {
      ...state.error,
      [MappingState.REPORTING_ISSUE]: action.payload.error,
    },
  };
}

function handleFileMappingReportIssueSuccess(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.REPORTING_ISSUE]: false },
    error: {
      ...state.error,
      [MappingState.REPORTING_ISSUE]: null,
    },
  };
}

function handleFileMappingRequestTemplateStart(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.REQUESTING_TEMPLATE]: true },
    error: {
      ...state.error,
      [MappingState.REQUESTING_TEMPLATE]: null,
    },
  };
}

function handleFileMappingRequestTemplateError(
  state: IMappingReducer,
  action: ReturnType<typeof fileMappingRequestTemplateError>,
): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.REQUESTING_TEMPLATE]: false },
    error: {
      ...state.error,
      [MappingState.REQUESTING_TEMPLATE]: action.payload.error,
    },
  };
}

function handleFileMappingRequestTemplateSuccess(state: IMappingReducer): IMappingReducer {
  return {
    ...state,
    isLoading: { ...state.isLoading, [MappingState.REQUESTING_TEMPLATE]: false },
    error: {
      ...state.error,
      [MappingState.REQUESTING_TEMPLATE]: null,
    },
  };
}
