import React, { useEffect, useRef, useState } from 'react';

import { User } from '../../../store/reducers/profile';
import { DashboardQuery } from '../../../types/DashboardQuery';

import InputContainer from './components/Input/InputContainer';
import QueriesList, { IQueriesListRef } from './components/QueriesList/QueriesList';
import {
  Backdrop,
  BREAKING_SCREEN_SIZE,
  Container,
  DownButton,
  DownIconStyled,
  ExpandIconStyled,
  GenaiIconStyled,
  Header,
  InputBar,
  MainElementsContainer,
  MAX_TAB_HEIGHT,
  QueriesListContainer,
  Statement,
  Title,
} from './styles';

const PADDINGS_HEIGHT = 48; // 16px top and 16px gap and 16px bottom

interface IQueries {
  isGenerating: boolean;
  user: User;
  queries: DashboardQuery[];
  aiVersion?: string;
  onSubmit: (message: string) => void;
  onResize: (newSize: number) => void;
}

function Queries({ isGenerating, queries, user, aiVersion, onSubmit, onResize }: IQueries) {
  // USE REF
  const containerRef = useRef<HTMLDivElement | null>(null);
  const queriesListContainerRef = useRef<HTMLDivElement | null>(null);
  const queriesListRef = useRef<IQueriesListRef | null>(null);
  const titleRef = useRef<HTMLDivElement | null>(null);
  const inputBarRef = useRef<HTMLDivElement | null>(null);

  // USE STATE
  const [isExpanded, setIsExpanded] = useState<boolean>(false);
  const [withBackdrop, setWithBackdrop] = useState<boolean>(false);
  const [withDownButton, setWithDownButton] = useState<boolean>(false);

  // USE EFFECT
  useEffect(() => {
    handleSizeChanges();
  }, [containerRef, queriesListContainerRef, queries, isExpanded]);

  useEffect(() => {
    if (!containerRef?.current) {
      return;
    }
    onResize(containerRef.current.offsetHeight);
  }, [isExpanded, queries]);

  useEffect(() => {
    window.addEventListener('resize', handleScreenResize);
    return () => {
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  // HANDLERS
  function handleInputHeightChange() {
    handleSizeChanges();
  }

  function handleSizeChanges() {
    if (!isExpanded) {
      setWithBackdrop(false);
      return;
    }
    if (!containerRef?.current || !queriesListContainerRef?.current || !inputBarRef.current) {
      return;
    }

    queriesListContainerRef.current.style.height = 'auto';
    const requiredQueriesHeight = queriesListContainerRef.current.scrollHeight;
    const requiredInputHeight = inputBarRef.current.offsetHeight;
    const currentHeight = containerRef.current.offsetHeight;
    const requiredTotalHeight = requiredQueriesHeight + requiredInputHeight + PADDINGS_HEIGHT;
    const availableGrowHeight = MAX_TAB_HEIGHT - currentHeight;

    if (requiredTotalHeight > currentHeight && currentHeight < MAX_TAB_HEIGHT) {
      if (requiredTotalHeight <= availableGrowHeight) {
        queriesListContainerRef.current.style.height = requiredQueriesHeight + 'px';
        setWithBackdrop(false);
      } else {
        queriesListContainerRef.current.style.height = availableGrowHeight + 'px';
        setWithBackdrop(true);
      }
    } else if (requiredTotalHeight > currentHeight && currentHeight === MAX_TAB_HEIGHT) {
      queriesListContainerRef.current.style.height = MAX_TAB_HEIGHT - requiredInputHeight - PADDINGS_HEIGHT + 'px';
      setWithBackdrop(true);
    } else if (requiredTotalHeight === currentHeight) {
      setWithBackdrop(true);
      return;
    } else {
      queriesListContainerRef.current.style.height = 'auto';
      setWithBackdrop(false);
    }
  }

  function handleToggleExpandClick() {
    setIsExpanded((prevState) => !prevState);
  }

  function handleSubmit(message: string) {
    if (!isExpanded) {
      setIsExpanded(true);
    }

    onSubmit(message);
  }

  function handleScrollToggle(didReachTop: boolean, didReachBottom: boolean) {
    setWithBackdrop(!didReachTop);
    setWithDownButton(!didReachBottom);
  }

  function handleScrollDownButtonClick() {
    queriesListRef.current?.scrollToBottom();
  }

  function handleScreenResize() {
    if (!titleRef.current || !containerRef.current || !inputBarRef.current) {
      return;
    }
    const LEFT_PADDING = 16;
    if (window.innerWidth <= BREAKING_SCREEN_SIZE) {
      titleRef.current.style.width = `${(containerRef.current.offsetWidth - inputBarRef.current.offsetWidth - LEFT_PADDING) / 2}px`;
    } else if (window.innerWidth > BREAKING_SCREEN_SIZE && titleRef.current.style.width !== 'auto') {
      titleRef.current.style.width = 'auto';
    }
  }

  return (
    <Container ref={containerRef} $isExpanded={isExpanded}>
      <Header>
        <Title ref={titleRef} title='Chat with Beehive AI Agent'>
          <GenaiIconStyled />
          Chat with Beehive AI Agent
        </Title>
        {!!queries.length && <ExpandIconStyled $isExpanded={isExpanded} onClick={handleToggleExpandClick} />}
      </Header>
      <MainElementsContainer>
        <QueriesListContainer ref={queriesListContainerRef} $isExpanded={isExpanded}>
          <Backdrop $isVisible={withBackdrop} />
          <QueriesList
            ref={queriesListRef}
            queries={queries}
            user={user}
            onScrollToggle={handleScrollToggle}
            onUserFeedbackToggle={handleScrollDownButtonClick}
          />
          <DownButton $isVisible={withDownButton} onClick={handleScrollDownButtonClick}>
            <DownIconStyled />
          </DownButton>
        </QueriesListContainer>
        <InputBar ref={inputBarRef}>
          <InputContainer onInputHeightChange={handleInputHeightChange} isGenerating={isGenerating} onSubmit={handleSubmit} />
          <Statement>
            BHive {aiVersion} may make mistakes. Your data remains solely in your environment, used only to train your model.
          </Statement>
        </InputBar>
      </MainElementsContainer>
    </Container>
  );
}

export default Queries;
