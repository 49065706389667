import styled, { keyframes } from 'styled-components';

import { ReactComponent as ReloadIcon } from '../../../../assets/icons/progress-activity-icon.svg';
import { ReactComponent as QuoteIcon } from '../../../../assets/icons/quote-icon.svg';

const fade = keyframes`
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
`;

export const StyledReloadIcon = styled(ReloadIcon)`
  height: 18px;
  width: 18px;
  fill: var(--black);
  animation-name: ${fade};
  animation-duration: 1s;
  cursor: pointer;
  &:hover {
    fill: var(--beehive-purple);
  }
`;

export const Insights = styled.ol`
  margin-top: 16px;
  margin-bottom: 16px;
  padding-left: 14px;
  list-style-type: none;
`;

export const ReactionsBlock = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

export const RelatedConcepts = styled.div`
  display: flex;
  gap: 8px;
  margin: 12px 0 4px 0;
  flex-wrap: wrap;
`;

export const HiddenCopyToClipboardContent = styled.div`
  display: none;
`;

export const KeyQuoteButton = styled.span`
  display: inline-flex;
  padding: 0 4px;
  justify-content: center;
  align-items: center;
  background-color: var(--dark-purple);
  border-radius: 100px;
  color: var(--white);
  font-weight: bold;
  font-size: 11px;
  gap: 4px;
  height: 16px;
  cursor: pointer;
  margin-left: 5px;

  &:hover {
    background-color: var(--beehive-purple);
  }
`;

export const InsightText = styled.li`
  &:not(:first-child) {
    margin-top: 8px;
  }
`;

export const QuoteIconStyled = styled(QuoteIcon)``;
