import { ComponentProps, useEffect, useMemo, useState } from 'react';

import { ResponseModel } from '../../../../../models/Response';
import { ConversationState, Participant } from '../../../../../store/reducers/interviewWidget/types';
import { IRowRenderer } from '../../../components/Responses/types';
import { Sentiment } from '../../../../../types/DataSource';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { Label } from '../../../../../types/Label';
import { assignTopicToResponse, removeTopicFromResponse } from '../../../../../store/thunks/interviewWidget';

import { IManageMenuState } from './types';
import ConversationList from './ConversationList';
import ConversationRow from './ConversationRow';

interface IConversationListContainer {
  widgetID: string;
  responses: ResponseModel[];
  responsesState: ConversationState;
  labels: Label[];
  selectedLabelItem: string | null;
  participants: Participant[];
  isRegenerating: boolean;
  onLoadMoreResponses: (startIndex: number) => void;
  onLabelClick: (label: string | null, withScroll: boolean) => void;
}

function ConversationListContainer({
  widgetID,
  responses,
  responsesState,
  selectedLabelItem,
  participants,
  labels,
  isRegenerating,
  onLoadMoreResponses,
  onLabelClick,
}: IConversationListContainer) {
  const dispatch = useAppDispatch();
  const [manageMenuState, setManageMenuState] = useState<IManageMenuState | null>(null);

  const translationUsed = useAppSelector((state) => state.app.translationUsed);
  const dashboardInfo = useAppSelector((state) => state.dashboard.dashboard);
  const responsesManagementState = useAppSelector((state) => state.interviewWidgets.responseManagementStatesByWidgetID[widgetID]);

  const rowCount = !responsesState.isLoading && !responses ? 0 : responsesState.responsesTotal;

  useEffect(() => {
    if (responsesState.isLoading || isRegenerating) {
      handleManageMenuClose();
    }
  }, [responsesState.isLoading, isRegenerating]);

  const rowLabels = useMemo(() => {
    if (!responses) {
      return [];
    }
    return responses.find((x) => x.id === manageMenuState?.responseID)?.labels ?? [];
  }, [responses, manageMenuState]);

  const isManagementLoading = useMemo(() => {
    if (!responsesManagementState) {
      return false;
    }
    if (!responses) {
      return false;
    }

    return responsesManagementState[manageMenuState?.responseID ?? '']?.isLoading ?? false;
  }, [responsesManagementState, responses, manageMenuState]);

  function handleGetRowProps(index: number, renderProps: IRowRenderer): ComponentProps<typeof ConversationRow> {
    if (!responses) {
      return {
        id: null,
        customerName: null,
        customerColor: null,
        start: null,
        displayAnswerText: '',
        hasSentiment: true,
        sentiment: null,
        sentimentScore: null,
        labels: null,
        selectedLabelItem: selectedLabelItem,
        onLabelClick: onLabelClick,
        onEditClick: handleManageMenuOpen,
        ...renderProps,
      };
    }
    const response = responses[index];
    if (!response) {
      return {
        id: null,
        customerName: null,
        customerColor: null,
        start: null,
        displayAnswerText: '',
        hasSentiment: true,
        sentiment: null,
        sentimentScore: null,
        labels: null,
        selectedLabelItem: selectedLabelItem,
        onLabelClick: onLabelClick,
        onEditClick: handleManageMenuOpen,
        ...renderProps,
      };
    }
    response.setDisplayAnswerText(translationUsed);

    const participant = response.customerID ? participants?.find((p) => p.id === response.customerID) : null;
    return {
      id: response.id,
      customerName: participant ? participant.name : 'Unknown',
      customerColor: participant?.avatarBackground ?? null,
      start: response.start,
      displayAnswerText: response.answerText,
      hasSentiment: dashboardInfo?.hasSentiment ?? false,
      sentiment: response.sentiment as Sentiment,
      sentimentScore: response.sentimentScore,
      labels: response.labels,
      selectedLabelItem: selectedLabelItem,
      onLabelClick: onLabelClick,
      onEditClick: handleManageMenuOpen,
      ...renderProps,
    };
  }

  async function handleLoadMoreRows({ startIndex }: { startIndex: number }) {
    onLoadMoreResponses(startIndex);
  }

  function handleIsRowLoaded({ index }: { index: number }) {
    return responses && responses[index] !== undefined;
  }

  function handleManageMenuOpen(ref: any, id: string | null) {
    if (!id) {
      return;
    }

    setManageMenuState({ anchorEl: ref, responseID: id });
  }

  function handleManageMenuClose() {
    setManageMenuState(null);
  }

  function handleRemoveLabel(label: string) {
    if (!manageMenuState) {
      return;
    }
    dispatch(
      removeTopicFromResponse({
        widgetID: widgetID,
        responseID: manageMenuState.responseID,
        label: label,
      }),
    );
  }

  function handleAddLabel(label: string) {
    if (!manageMenuState) {
      return;
    }
    dispatch(
      assignTopicToResponse({
        widgetID: widgetID,
        responseID: manageMenuState.responseID,
        label: label,
      }),
    );
  }

  return (
    <ConversationList
      labels={labels}
      rowLabels={rowLabels}
      rowCount={rowCount}
      manageMenuState={manageMenuState}
      isLoading={responsesState.isLoading}
      isManagementLoading={isManagementLoading}
      onGetRowProps={handleGetRowProps}
      onLoadMoreResponses={handleLoadMoreRows}
      onIsRowLoaded={handleIsRowLoaded}
      onManageMenuClose={handleManageMenuClose}
      onAddResponseLabel={handleAddLabel}
      onRemoveResponseLabel={handleRemoveLabel}
    />
  );
}

export default ConversationListContainer;
