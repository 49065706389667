import styled from 'styled-components';

import { ReactComponent as CloseIcon } from '../../../../../../assets/icons/cross-icon.svg';
import { ReactComponent as CheckIcon } from '../../../../../../assets/icons/check-circle-icon.svg';
import { ReactComponent as ErrorIcon } from '../../../../../../assets/icons/error-icon.svg';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 5px;
  flex-direction: column;
  width: 100%;
`;

export const FunctionalPart = styled.div`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 8px;
  width: 100%;
`;

export const InputContainer = styled.div<{ $isValid: boolean }>`
  display: flex;
  position: relative;
  height: 40px;
  width: 100%;
  border-radius: var(--border-radius);
  border: 1px solid ${({ $isValid }) => ($isValid ? 'var(--beehive-purple)' : 'var(--red-error)')};
  padding: 8px 32px 8px 8px;
  background-color: var(--white);
`;

export const Input = styled.input`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  padding: 2px 0px;
  width: 100%;

  &:focus {
    outline: none;
  }
`;
export const CloseIconStyled = styled(CloseIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 50%;
  transform: translate(50%, -50%);
  cursor: pointer;
`;
export const CheckIconStyled = styled(CheckIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 50%;
  transform: translate(50%, -50%);
  fill: var(--green-active);
`;
export const ErrorIconStyled = styled(ErrorIcon)`
  position: absolute;
  width: 24px;
  height: 24px;
  right: 16px;
  top: 50%;
  transform: translate(50%, -50%);
  fill: var(--red-error);
`;
export const SaveButton = styled.button`
  display: flex;
  width: 64px;
  height: 40px;
  padding: 0px 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: var(--double-border-radius);
  background: var(--beehive-purple);
  color: var(--white);
  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  border: none;
  cursor: pointer;

  &:focus {
    outline: none;
  }
`;

export const ErrorPart = styled.div<{ $isValid: boolean }>`
  display: ${({ $isValid }) => (!$isValid ? 'flex' : 'none')};
  color: var(--red-error);
  font-family: Rubik;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`;
