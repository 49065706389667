import { FILTER_ID_ALL, FILTER_ID_CURRENT } from '../../../../../constants';
import { SavedFilterModel } from '../../../../../models/Filter';
import { ConversationState, WidgetConfig } from '../../../../../store/reducers/interviewWidget/types';
import { InsightsQuery } from '../../../../../store/thunks/aiInsights';
import { Query } from '../../../../../types/Query';
import { COMBINATOR } from '../../../../FilterForm/constants';
import {
  createContainsValuesRule,
  createGroup,
  createInCustomersValuesRule,
  createInValuesRule,
  EMPTY_FILTER_QUERY,
  narrowQueryWithRule,
} from '../../../../FilterForm/lib';
import { ALL_SEGMENT, CURRENT_SEGMENT } from '../../../utils/lib';

export function convertResponsesFiltersToQuery(
  dataSourceID: string,
  selectedFilterIds: string[],
  currentDashboardFilter: Query | null,
  savedFiltersById: { [p: string]: SavedFilterModel },
  responsesState: ConversationState,
  widgetConfig: WidgetConfig,
  selectedItems: string[],
  selectedCustomerIds: string[],
  currentWidgetFilter: Query,
) {
  const queries = [];
  for (const filterId of selectedFilterIds) {
    switch (filterId) {
      case FILTER_ID_ALL:
        queries.push(EMPTY_FILTER_QUERY);
        break;
      case FILTER_ID_CURRENT:
        queries.push(currentDashboardFilter);
        break;
      default: // saved filters
        queries.push(savedFiltersById[filterId].query);
    }
  }

  let resultingQuery = queries.length === 1 ? queries[0] : createGroup(COMBINATOR.OR, queries);
  if (responsesState && responsesState.searchString.length > 0) {
    const rule = createContainsValuesRule(dataSourceID, responsesState.searchString);
    resultingQuery = narrowQueryWithRule(resultingQuery, rule);
  }

  if (widgetConfig && widgetConfig.selectedConcept !== null) {
    // add filtration by labels
    const rule = createInValuesRule(dataSourceID, [widgetConfig.selectedConcept.title]);
    resultingQuery = narrowQueryWithRule(resultingQuery, rule);
  } else if (selectedItems.length > 0) {
    const rule = createInValuesRule(dataSourceID, [...selectedItems]);
    resultingQuery = narrowQueryWithRule(resultingQuery, rule);
  }

  if (selectedCustomerIds && selectedCustomerIds.length > 0) {
    const rule = createInCustomersValuesRule(dataSourceID, [...selectedCustomerIds]);
    resultingQuery = narrowQueryWithRule(resultingQuery, rule);
  }

  if (currentWidgetFilter) {
    resultingQuery = narrowQueryWithRule(resultingQuery, currentWidgetFilter);
  }
  return resultingQuery;
}

export function convertInsightsFiltersToQuery(
  dataSourceID: string,
  selectedFilterIds: string[],
  currentDashboardFilter: Query | null,
  savedFiltersById: { [p: string]: SavedFilterModel },
  responsesState: ConversationState,
  widgetConfig: WidgetConfig,
  selectedItems: string[],
  selectedCustomerIds: string[],
  currentWidgetFilter: Query,
) {
  const result: InsightsQuery[] = [];

  const queries = [];
  for (const filterId of selectedFilterIds) {
    switch (filterId) {
      case FILTER_ID_ALL:
        queries.push({
          query: EMPTY_FILTER_QUERY,
          segmentName: ALL_SEGMENT,
        });
        break;
      case FILTER_ID_CURRENT:
        queries.push({
          query: currentDashboardFilter,
          segmentName: CURRENT_SEGMENT,
        });
        break;
      default: // saved filters
        queries.push({
          query: savedFiltersById[filterId].query,
          segmentName: savedFiltersById[filterId].name,
        });
    }
  }
  for (const { query, segmentName } of queries) {
    let resultingQuery = query;
    if (responsesState && responsesState.searchString.length > 0) {
      const rule = createContainsValuesRule(dataSourceID, responsesState.searchString);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    }

    if (widgetConfig && widgetConfig.selectedConcept !== null) {
      // add filtration by labels
      const rule = createInValuesRule(dataSourceID, [widgetConfig.selectedConcept.title]);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    } else if (selectedItems.length > 0) {
      const rule = createInValuesRule(dataSourceID, [...selectedItems]);
      resultingQuery = narrowQueryWithRule(resultingQuery, rule);
    }

    if (currentWidgetFilter) {
      resultingQuery = narrowQueryWithRule(resultingQuery, currentWidgetFilter);
    }
    result.push({
      segmentName,
      query: resultingQuery,
    });
  }

  return result;
}
