import { FETCH_STATUS_STATE } from '../../constants';
import { Company, User, UserProfile } from '../reducers/profile';

export const PROFILE_SET_USER = 'PROFILE_SET_USER';
export const PROFILE_SET_COMPANIES = 'PROFILE_SET_COMPANIES';
export const PROFILE_SET_HMAC = 'PROFILE_SET_HMAC';
export const PROFILE_SET_COMPANY = 'PROFILE_SET_COMPANY';
export const PROFILE_SET_CREATE_WORKSPACE_STATE = 'PROFILE_SET_CREATE_WORKSPACE_STATE';
export const PROFILE_SET_USER_PROFILE = 'PROFILE_SET_USER_PROFILE';
export const PROFILE_SET_USER_PROFILE_FETCH_STATE = 'PROFILE_SET_USER_PROFILE_FETCH_STATE';
export const PROFILE_SET_USER_PROFILE_UPDATE_STATE = 'PROFILE_SET_USER_PROFILE_UPDATE_STATE';

export const profileSetUser = (user: User) => ({
  type: PROFILE_SET_USER,
  payload: {
    user,
  },
});

export const profileSetCompanies = (companies: Company[]) => ({
  type: PROFILE_SET_COMPANIES,
  payload: {
    companies,
  },
});

export const profileSetHmac = (hmac: string) => ({
  type: PROFILE_SET_HMAC,
  payload: {
    hmac,
  },
});

export const profileSetCompany = (company: Company) => ({
  type: PROFILE_SET_COMPANY,
  payload: {
    company,
  },
});

export const profileSetCreateWorkspaceState = (state: FETCH_STATUS_STATE, isPasswordWrong = false) => ({
  type: PROFILE_SET_CREATE_WORKSPACE_STATE,
  payload: {
    state,
    isPasswordWrong,
  },
});

export const profileSetUserProfile = (userProfile: UserProfile) => ({
  type: PROFILE_SET_USER_PROFILE,
  payload: {
    userProfile,
  },
});

export const profileSetUserProfleFetchState = (state: FETCH_STATUS_STATE) => ({
  type: PROFILE_SET_USER_PROFILE_FETCH_STATE,
  payload: {
    state,
  },
});

export const profileSetUserProfleUpdateState = (state: FETCH_STATUS_STATE) => ({
  type: PROFILE_SET_USER_PROFILE_UPDATE_STATE,
  payload: {
    state,
  },
});
