import classnames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';

import { ReactComponent as CheckmarkIcon } from '../../assets/icons/check-icon.svg';
import { ReactComponent as ExpandIcon } from '../../assets/icons/expand-icon.svg';
import ClickAwayListener from '../UI/ClickAwayListener';

import styles from './styles.module.css';

export type DropdownOption = {
  title: string;
  value: any;
  isSelectable?: boolean;
  action?: () => void;
} & Record<string, any>;

interface IDropdown {
  value: DropdownOption | DropdownOption[] | null;
  options?: DropdownOption[];
  placeholder?: string;
  isOpen?: boolean;
  isMulti?: boolean;
  isDisabled?: boolean;
  renderValueTitle?: (option: DropdownOption | DropdownOption[] | null) => React.JSX.Element;
  renderPreOption?: () => React.JSX.Element;
  renderAfterOption?: () => React.JSX.Element;
  renderOptionTitle?: (option: DropdownOption) => React.JSX.Element;
  onChange: (value: DropdownOption) => void;
  onClose?: () => void;
  onOpen?: () => void;
}

function Dropdown({
  placeholder,
  value,
  options,
  isOpen,
  isMulti,
  isDisabled = false,
  onChange,
  onClose,
  onOpen,
  renderValueTitle,
  renderPreOption,
  renderOptionTitle,
  renderAfterOption,
}: IDropdown) {
  const dropdownRef = useRef(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(isOpen || false);
  const [valueToUse, setValueToUse] = useState(value);

  useEffect(() => {
    setValueToUse(value);
  }, [value]);

  function handleDropdownClick() {
    if (isDisabled) {
      return;
    }

    setIsDropdownOpen((prevState) => !prevState);
  }

  function handleClickAwayClick() {
    setIsDropdownOpen(false);
  }

  function handleOptionClick(event: React.MouseEvent<HTMLDivElement | undefined>) {
    if (!event?.target || !options?.length) {
      return;
    }
    const possibleValueIndex = (event.target as HTMLDivElement).getAttribute('data-option-index');
    if (possibleValueIndex === null) {
      return;
    }
    const valueIndex = parseInt(possibleValueIndex, 10);
    const optionSelected = options[valueIndex];

    if (optionSelected.action) {
      optionSelected.action();
    }

    if (optionSelected.isSelectable === false) {
      return;
    }

    if (!onChange) {
      setValueToUse(optionSelected);
    } else {
      onChange(optionSelected);
    }

    if (onClose) {
      onClose();
    } else if (!isMulti) {
      setIsDropdownOpen(false);
    }
  }

  const mainContainerClassNames = classnames({
    [styles.mainContainer]: true,
    [styles.mainContainerDisabled]: isDisabled,
  });

  const expandIconClassNames = classnames({
    [styles.expandIcon]: true,
    [styles.expandIconDisabled]: isDisabled,
  });

  return (
    <>
      <div ref={dropdownRef} className={mainContainerClassNames} onClick={handleDropdownClick}>
        {renderValueOrPlaceholder()}
        <ExpandIcon className={expandIconClassNames} />
      </div>
      {isDropdownOpen && createPortal(renderOptions(), document.body)}
    </>
  );

  function renderValueOrPlaceholder() {
    if (!valueToUse) {
      const placeholderClassNames = classnames({
        [styles.placeholder]: true,
        [styles.placeholderDisabled]: isDisabled,
      });
      return <div className={placeholderClassNames}>{placeholder || 'Please, select value'}</div>;
    }
    const dropdownClassNames = classnames({
      [styles.value]: true,
      [styles.valueDisabled]: isDisabled,
    });

    const readyValueToUse = isMulti
      ? (valueToUse as DropdownOption[]).map((v) => v.title).join(' ,')
      : (valueToUse as DropdownOption).title;
    return <div className={dropdownClassNames}>{renderValueTitle ? renderValueTitle(valueToUse) : readyValueToUse}</div>;
  }

  function renderOptions() {
    if (!dropdownRef.current) {
      return;
    }
    const { top, width, left } = (dropdownRef.current as HTMLDivElement).getBoundingClientRect();

    return (
      <ClickAwayListener onClickAway={handleClickAwayClick}>
        <div
          style={{
            top: `${top + 50}px`,
            left: `${left}px`,
            width: `${width}px`,
          }}
          className={styles.optionsContainer}
          onClick={handleOptionClick}
        >
          {renderPreOption && renderPreOption()}
          {options?.map(renderOption)}
          {renderAfterOption && renderAfterOption()}
        </div>
      </ClickAwayListener>
    );
  }

  function renderOption(option: DropdownOption, index: number) {
    const isOptionSelected =
      valueToUse && Array.isArray(valueToUse) ? (valueToUse as DropdownOption[]).includes(option) : valueToUse?.value === option.value;
    const className = classnames({
      [styles.option]: true,
      [styles.isOptionSelected]: isOptionSelected,
    });
    return (
      <div key={index} className={className} data-option-index={index}>
        {renderOptionTitle ? renderOptionTitle(option) : option.title}
        {isOptionSelected && (
          <div className={styles.checkmarkContainer}>
            <CheckmarkIcon className={styles.checkmark} />
          </div>
        )}
      </div>
    );
  }
}

export default Dropdown;
