import React, { createContext, useState, useMemo, useContext, useCallback } from 'react';
import { SavedFilterModel, SavedFilterModelFactory } from '../models/Filter';
import { useAppSelector } from '../store/hooks';
import { SavedFiltersPayload } from '../types/incoming/savedFilters';
import { preprocessSavedFilter, reportInvalidSavedFilter } from './lib';
import useFormatLogMessage from '../hooks/useFormatLogMessage';
import { getSchemeColors } from '../style/colors';
import Color from 'color';
import { FILTER_ID_ALL, FILTER_ID_CURRENT } from '../constants';
import * as api from '../components/tools/api';

export const SavedFiltersContext = createContext([]);

export const SavedFiltersProvider = (props: any) => {
  const [selectedSavedFilters, setSelectedSavedFilters] = useState<Set<string>>(new Set());
  const [savedFilters, setSavedFilters] = useState<SavedFilterModel[]>([]);
  const [isSavedFiltersLoaded, setIsSavedFiltersLoaded] = useState(false);

  const colorSchemeName = useAppSelector((state) => state.app.colorSchemeName);

  const colorByFilterId = useMemo(() => {
    const schemeColors = getSchemeColors(colorSchemeName, selectedSavedFilters.size + 2);
    const colors = schemeColors.map((color) => new Color(color).darken(0.25).hex());

    const colorsById: { [key: string]: any } = {
      [FILTER_ID_ALL]: colors.pop(),
      [FILTER_ID_CURRENT]: colors.pop(),
    };

    for (const filterId of selectedSavedFilters.values()) {
      colorsById[filterId] = colors.pop();
    }

    return colorsById;
  }, [selectedSavedFilters, colorSchemeName]);

  const value = useMemo(
    () => [
      selectedSavedFilters,
      setSelectedSavedFilters,
      savedFilters,
      setSavedFilters,
      isSavedFiltersLoaded,
      setIsSavedFiltersLoaded,
      colorByFilterId,
    ],
    [selectedSavedFilters, savedFilters, isSavedFiltersLoaded, colorByFilterId],
  );

  return (
    <SavedFiltersContext.Provider value={value} {...props}>
      {props.children}
    </SavedFiltersContext.Provider>
  );
};

export const useSavedFilters = (skipLoading = false) => {
  const context: any[] = useContext(SavedFiltersContext);
  const dashboardTitle = useAppSelector((state) => state.dashboard.dashboard?.title) as string;
  const dashboardDataSources = useAppSelector((state) => state.dashboard.dashboard?.dataSources);
  const responsesByDataSourceId = useAppSelector((state) => state.dashboard.responsesByDataSourceId);
  const formatLogMessage = useFormatLogMessage();

  if (!context) {
    throw new Error(`useCount must be used within a FiltersContext`);
  }

  const [
    selectedSavedFilters,
    _setSelectedSavedFilters,
    savedFilters,
    setSavedFilters,
    isSavedFiltersLoaded,
    setIsSavedFiltersLoaded,
    colorByFilterId,
  ] = context;
  const savedFiltersById = useMemo(
    () =>
      (savedFilters as SavedFilterModel[]).reduce(
        (acc, filter) => {
          acc[filter['id']] = filter;
          return acc;
        },
        {} as { [key: string]: SavedFilterModel },
      ),
    [savedFilters],
  );

  const fetchSavedFilters = useCallback(
    async (dashboardId: string) => {
      let models: SavedFilterModel[] = [];
      if (!skipLoading && dashboardDataSources) {
        const savedFiltersData: SavedFiltersPayload = await api.fetchSavedFilters(dashboardId);
        models = savedFiltersData.map((savedFilterData) => {
          const processedSavedFilter = preprocessSavedFilter(savedFilterData, responsesByDataSourceId);
          if (!processedSavedFilter.valid) {
            reportInvalidSavedFilter(
              savedFilterData,
              responsesByDataSourceId,
              dashboardId,
              dashboardTitle,
              dashboardDataSources,
              formatLogMessage,
            );
          }
          return SavedFilterModelFactory(processedSavedFilter);
        });
      }
      setSavedFilters(models);
      setIsSavedFiltersLoaded(true);
    },
    [
      dashboardDataSources,
      dashboardTitle,
      formatLogMessage,
      setIsSavedFiltersLoaded,
      setSavedFilters,
      skipLoading,
      responsesByDataSourceId,
    ],
  );

  async function deleteFilter(dashboardId: string, filterId: string) {
    await api.deleteSavedFilter(dashboardId, filterId);
    await fetchSavedFilters(dashboardId);
  }

  function resetSelectedSavedFilters() {
    return _setSelectedSavedFilters(new Set());
  }

  function setSelectedSavedFilters(savedFiltersIdList: string[]) {
    const newSet = new Set(savedFiltersIdList);
    return _setSelectedSavedFilters(newSet);
  }

  function toggleSelectedSavedFilter(id: string) {
    if (selectedSavedFilters.has(id)) {
      selectedSavedFilters.delete(id);
    } else {
      if (savedFiltersById[id]) {
        selectedSavedFilters.add(id);
      }
    }

    setSelectedSavedFilters(selectedSavedFilters);
  }

  const selectedSavedFiltersArray = useMemo(() => Array.from(selectedSavedFilters as Set<string>), [selectedSavedFilters]);

  // TODO: separate saved filters
  return {
    deleteFilter,
    resetSelectedSavedFilters,
    toggleSelectedSavedFilter,
    selectedSavedFilters,
    selectedSavedFiltersArray,
    setSelectedSavedFilters,
    fetchSavedFilters,
    /** @type {SavedFilterModel[]} savedFilters */
    savedFilters,
    savedFiltersNum: savedFilters.length,
    savedFiltersById,
    setSavedFilters,
    isSavedFiltersLoaded,
    colorByFilterId,
  };
};
