import Compare from '../../features/compare';
import { useContext } from 'react';
import { SidebarDashboardContext } from '../Sidebar';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import styled from 'styled-components';

const CompareContainer = styled.div<{ $marginBottom: number }>`
  margin-bottom: ${({ $marginBottom }) => ($marginBottom ? `${$marginBottom}px` : '0')};
`;

interface ICompareTab {
  bottomMargin: number;
}
const CompareTab = ({ bottomMargin }: ICompareTab) => {
  const { setActiveItemIndex, savedFiltersIndex } = useContext<any>(SidebarDashboardContext);

  function handleSelectSegmentsClick() {
    eventsTracker.track(EVENTS.COMPARE_SEGMENTS_SELECT);
    setActiveItemIndex(savedFiltersIndex);
  }

  return (
    <CompareContainer $marginBottom={bottomMargin}>
      <Compare onSelectSegmentsClick={handleSelectSegmentsClick} />
    </CompareContainer>
  );
};

export default CompareTab;
