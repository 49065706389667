import styled from 'styled-components';

export const AnswerItemTextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

interface IBaseLabel {
  $isDisabled: boolean;
  $isSelected: boolean;
}

export const BaseLabel = styled.div<IBaseLabel>`
  display: flex;
  align-items: center;
  padding: 0px 8px;
  color: var(--dark-purple);
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  height: 24px;
  border-radius: var(--double-border-radius);
  cursor: pointer;
  overflow: hidden;
`;

export const ParentLabel = styled(BaseLabel)`
  background-color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'none')};
  color: ${({ $isSelected }) => ($isSelected ? 'var(--white)' : 'var(--dark-purple)')};
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'none' : 'var(--dark-purple)')};
`;

export const ChildLabel = styled(BaseLabel)`
  margin-left: 32px;
  background-color: var(--beehive-purple-transparent-10);
  color: ${({ $isSelected }) => ($isSelected ? 'var(--dark-purple)' : 'var(--dark-purple)')};
  border: 1px solid ${({ $isSelected }) => ($isSelected ? 'var(--dark-purple)' : 'none')};
  font-weight: ${({ $isSelected }) => ($isSelected ? 'bold' : '400')};
`;

export const Container = styled.div`
  width: 160px;
  height: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
  font-family: Rubik;
  flex-shrink: 0;
`;

export const ContainerItem = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  flex-shrink: 0;
`;

export const Percent = styled.div`
  width: 65px;
  text-align: right;
  margin-right: 4px;
  font-size: 14px;
  line-height: 24px;
`;

export const PercentageBar = styled.div<{
  $percent: number;
  $color: string;
}>`
  height: 24px;
  border-radius: var(--double-border-radius);
  width: ${({ $percent }) => `${$percent}`}%;
  background-color: ${({ $color }) => `${$color}`};
`;

export const Number = styled.span`
  background-color: var(--grey-bar);
  border-radius: var(--border-radius);
  font-size: 14px;
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
`;

export const BarContainer = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;

export const Bar = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
`;
