import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';

import { NotificationsContext } from '../../../components/Notification/NotificationsContext';
import { SEVERITY } from '../../../components/Notification/types';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createWorkspace } from '../../../store/thunks/profile';

import { checkRules } from '../utils/passwordValidator';
import WelcomePage from './WelcomePage';
import { APP_PATH, FETCH_STATUS_STATE } from '../../../constants';

function WelcomePageContainer() {
  const dispatch = useAppDispatch();
  const { update: intercomUpdate } = useIntercom();
  const history = useHistory();
  const { addNotification } = useContext(NotificationsContext);
  const { isPasswordWrong, createWorkspaceState, user, companies, hmac } = useAppSelector((app) => app.profile);

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [password, setPassword] = useState('');
  const [verificationPassword, setVerificationPassword] = useState('');
  const [doPasswordsMatch, setDoPasswordsMatch] = useState(true);
  const [isPasswordChangeError, setIsPasswordChangeError] = useState(isPasswordWrong);
  const [didSubmit, setDidSubmit] = useState(false);

  useEffect(() => {
    switch (createWorkspaceState) {
      case FETCH_STATUS_STATE.LOADED:
        intercomUpdate({
          name: `${firstName} ${lastName}`,
          email: user?.email,
          userHash: hmac ?? '',
          alignment: 'left',
        });
        eventsTracker.identify(user?.id || '', {
          user_id: user?.id,
          firstName,
          lastName,
          email: user?.email,
          workspace: JSON.stringify(companies?.[0]),
        });
        eventsTracker.track(EVENTS.SIGN_UP_COMPLETED, {
          email: user?.email,
          user_id: user?.id,
          timestamp: new Date().toLocaleString(),
          workspace: JSON.stringify(companies?.[0]),
        });
        eventsTracker.trackPageView();
        history.push(APP_PATH.CONGRATULATIONS);
        break;
      case FETCH_STATUS_STATE.ERROR:
        if (isPasswordWrong) {
          setPassword('');
          setVerificationPassword('');
          setIsPasswordChangeError(true);
        } else {
          addNotification({ title: 'Error creating workspace', severity: SEVERITY.ERROR, id: 'WELCOME-PAGE_CREATE-WORKSPACE' });
        }
        break;
      default:
        break;
    }
  }, [createWorkspaceState]);

  useEffect(() => {
    if (!didSubmit) {
      return;
    }

    setDoPasswordsMatch(verificationPassword === password);
  }, [password, verificationPassword]);

  const passwordCheckInfo = useMemo(() => {
    return checkRules(password);
  }, [password]);

  const isLoading = createWorkspaceState === FETCH_STATUS_STATE.LOADING;
  const isDisabled =
    firstName.trim().length === 0 || lastName.trim().length === 0 || !passwordCheckInfo.isValid || verificationPassword.trim().length === 0;

  function handleFirstNameChange(name: string) {
    setFirstName(name);
  }

  function handleLastNameChange(name: string) {
    setLastName(name);
  }

  function handlePasswordChange(newPassword: string) {
    if (isPasswordChangeError) {
      setIsPasswordChangeError(false);
    }
    setPassword(newPassword);
  }

  function handleVerificationPasswordChange(newVerificationPassword: string) {
    setVerificationPassword(newVerificationPassword);
  }

  function handleSubmit() {
    if (isLoading) {
      return;
    }
    setDidSubmit(true);
    if (password !== verificationPassword) {
      setDoPasswordsMatch(false);
      return;
    }
    dispatch(createWorkspace({ firstName, lastName, password }));
  }

  return (
    <WelcomePage
      firstName={firstName}
      lastName={lastName}
      password={password}
      verificationPassword={verificationPassword}
      passwordCheckInfo={passwordCheckInfo}
      doPasswordsMatch={doPasswordsMatch}
      isDisabled={isDisabled}
      isPasswordChangeError={isPasswordChangeError}
      isLoading={isLoading}
      onFirstNameChange={handleFirstNameChange}
      onLastNameChange={handleLastNameChange}
      onPasswordChange={handlePasswordChange}
      onVerificationPasswordChange={handleVerificationPasswordChange}
      onSubmit={handleSubmit}
    />
  );
}

export default WelcomePageContainer;
