import { Divider } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Sentiment } from '../../../../../types/DataSource';
import { formatMilliseconds } from '../../../../../utils/helpers';

import ExpandableText from '../../../../ExpandableText/ExpandableText';
import InlineSentiment from '../../../components/InlineSentiment';
import { AnswerEditButton, ConceptPill } from '../../../components/Labels/ConceptPill';
import Avatar from '../../../../Avatar/Avatar';
import styles from '../../../components/Responses/Response.module.css';
import {
  HeaderContainer,
  HeaderRight,
  LabelsWrapper,
  LeftHeaderWrapper,
  SentimentResponseContainer,
  TimestampContainer,
  Width100,
} from '../../../components/Responses/style';
import { IRowRenderer } from '../../../components/Responses/types';
import { ResponseWrapper } from '../style';

const HeaderTimestamp = styled.div`
  margin-right: 64px;
`;

const ParticipantWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

interface IConversationRow extends IRowRenderer {
  id: string | null;
  displayAnswerText: string;
  customerName: string | null;
  customerColor: string | null;
  start: number | null;
  labels: string[] | null;
  selectedLabelItem: string | null;
  sentiment: Sentiment | null;
  sentimentScore: number | null;
  hasSentiment: boolean;
  onLabelClick: (label: string | null, withScroll: boolean) => void;
  onEditClick: (ref: any, id: string | null) => void;
}

function ConversationRow({
  style,
  isVisible,
  measurerKey,
  id,
  displayAnswerText,
  customerName,
  customerColor,
  start,
  labels,
  selectedLabelItem,
  hasSentiment,
  sentiment,
  sentimentScore,
  measure,
  registerChild,
  onLabelClick,
  onEditClick,
}: IConversationRow) {
  const editButtonRef = useRef(null);

  useEffect(() => {
    if (id || isVisible) {
      measure();
    }
  }, [id, labels, isVisible, measure]);

  const handleToggleAnswerExpand = useCallback(() => {
    if (id) {
      measure();
    }
  }, [measure, id]);

  function handleRef(ref: any) {
    registerChild?.(ref as any);
  }

  return (
    <ResponseWrapper ref={handleRef} style={style} key={measurerKey}>
      <HeaderContainer>
        {renderParticipant()}
        <HeaderRight>
          <HeaderTimestamp>{renderTimestamp()}</HeaderTimestamp>
          {renderSentiment()}
        </HeaderRight>
      </HeaderContainer>
      <Width100>
        {displayAnswerText === '' ? (
          <Skeleton width='50%' />
        ) : (
          <ExpandableText expandableButtonClassName={styles.toggleExpandAnswer} onToggleExpand={handleToggleAnswerExpand}>
            {displayAnswerText}
          </ExpandableText>
        )}
      </Width100>
      {renderLabels()}
      {/* TODO Divider from ../UI */}
      <Divider />
    </ResponseWrapper>
  );

  function renderParticipant() {
    let color;
    if (customerColor) {
      color = customerColor;
    }

    // TODO: Skeleton from ../UI
    return customerName ? (
      <LeftHeaderWrapper>
        <ParticipantWrapper>
          <Avatar name={customerName} color={color} />
          {customerName}
        </ParticipantWrapper>
      </LeftHeaderWrapper>
    ) : (
      <Skeleton width='20%' />
    );
  }

  function renderSentiment() {
    if (!hasSentiment) return null;
    if (!sentiment || sentimentScore === null) {
      // TODO: Skeleton from ../UI
      return (
        <SentimentResponseContainer>
          <Skeleton width='100%' />
        </SentimentResponseContainer>
      );
    }
    return (
      <SentimentResponseContainer>
        <InlineSentiment sentiment={sentiment} score={sentimentScore} />
      </SentimentResponseContainer>
    );
  }

  function renderTimestamp() {
    if (start === null) {
      return null;
    }

    const timestamp = formatMilliseconds(start, false);
    return <TimestampContainer>{timestamp}</TimestampContainer>;
  }

  function renderLabels() {
    if (labels === null) {
      // TODO: Skeleton from ../UI
      return <Skeleton width='50%' />;
    }

    return (
      <LabelsWrapper>
        {labels.map((label: string) => {
          return (
            <ConceptPill
              key={label}
              text={label}
              isClickable={true}
              isHierarchy={false}
              isSelected={label === selectedLabelItem}
              isDisabled={false}
              isParent={false}
              onClick={onLabelClick.bind(null, label, true)}
            />
          );
        })}
        <AnswerEditButton ref={editButtonRef} onClick={onEditClick.bind(null, editButtonRef, id)} />
      </LabelsWrapper>
    );
  }
}

export default ConversationRow;
