import styled from 'styled-components';
import ButtonMui from '@material-ui/core/Button';
import IconButtonMui from '@material-ui/core/IconButton';

export const FilledButton = styled(ButtonMui)<{ $isWarning?: boolean }>`
  &.MuiButton-root {
    background-color: ${({ $isWarning }) => ($isWarning ? 'var(--red-error)' : 'var(--beehive-purple)')};
    color: var(--white);
    border-radius: 8px;
    text-transform: none;
    box-shadow: none;

    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    padding: 10px 40px;
  }

  &.MuiButton-root:hover {
    box-shadow:
      0 2px 6px 2px rgba(15, 12, 12, 0.15),
      0 1px 2px 0 rgba(15, 12, 12, 0.3);
    background-color: ${({ $isWarning }) => ($isWarning ? 'var(--red-error)' : 'var(--beehive-purple)')};
  }

  &.Mui-disabled {
    pointer-events: auto !important;
    background-color: var(--medium-gray);
    color: var(--gray-disabled);
  }
`;

export const OutlinedButton = styled(FilledButton)`
  &.MuiButton-root {
    color: var(--dark-purple);
    background-color: var(--transparent);
    border: 1px solid var(--dark-purple);
    box-sizing: border-box;
    box-shadow: none;
    padding-top: 9px;
    padding-bottom: 9px;
  }

  &.MuiButton-root:hover {
    background-color: rgba(130, 104, 255, 0.1);
    box-shadow: none;
  }

  &.Mui-disabled {
    background-color: var(--medium-gray);
    border-color: var(--medium-gray);
    color: var(--gray-disabled);
  }
`;

export const OutlinedButtonNarrow = styled(OutlinedButton)`
  &.MuiButton-root {
    padding: 9px 15px;
  }
`;

export const SavedFilterButton = styled(ButtonMui)<{ selected: boolean }>`
  &.MuiButton-root {
    background-color: ${({ selected }) => (selected ? 'var(--beehive-purple)' : 'var(--light-purple-50-transparency)')};
    border-radius: 6px;
    box-shadow: none;
    color: ${(props) => (props.selected ? 'var(--white)' : 'var(--black)')};

    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    text-transform: none;
    padding: 8px 10px;
  }

  &.MuiButtonGroup-groupedHorizontal:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &.MuiButtonGroup-groupedHorizontal:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

export const TextButton = styled(ButtonMui)`
  &.MuiButton-root {
    color: var(--black);

    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

    text-transform: none;
    padding: 8px 10px;
  }

  &.MuiButton-root:hover {
    background-color: var(--transparent);
    color: var(--beehive-purple);
  }
`;

export const IconButton = styled(IconButtonMui)``;
