import React, { useEffect, useMemo, useState } from 'react';

import {
  fileMappingUpdateFileInfo,
  fileMappingUpdateSelectedIndex,
  fileMappingUpdateUserMapping,
  fileMappingValidateMappingContinue,
} from '../../../../store/actions/fileMapping';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { FileType, MappingState, UserMapping } from '../../../../store/reducers/fileMapping/types';
import { getFileData, getPredictedMapping, getTemplates, loadDocuments, validateMapping } from '../../../../store/thunks/fileMapping';
import { DropdownOption } from '../../../Dropdown/Dropdown';

import {
  CUSTOM_DATE_FORMAT,
  DATE_FORMATS,
  DEFAULT_SOURCE_NAME,
  GENERAL_MAPPING_VALUES,
  MAX_NUMBER_OF_ROWS,
  UNKNOWN_TYPE,
} from './constants';
import FileUploadMapping from './FileUploadMapping';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';

export type ColumnsMappingConfig = { columnName: string; placeholder: string; isMulti: boolean; action: (option: DropdownOption) => void };

export const SOURCE_COLUMN = 'source';
export const ANSWERS_START_FROM_COLUMN = 'answers_start_from';
export const DATE_FORMAT_COLUMN = 'date_format';
export const SELECT_MODERATOR_COLUMN = 'select_moderator';
export const MAPPING_COLUMNS = 'mapping_columns';

interface IFileUploadMappingContainer {
  onCloseClick: () => void;
  onMappingFinish: (fileNames: string[]) => void;
}

function FileUploadMappingContainer({ onCloseClick, onMappingFinish }: IFileUploadMappingContainer) {
  const {
    files,
    selectedIndex: currentFileNumber,
    templates,
    isLoading: isLoadingState,
    error,
    dashboardID,
    isPrivate,
  } = useAppSelector((state) => state.fileMapping);
  const dispatch = useAppDispatch();
  const currentFile = files?.[currentFileNumber];
  const isInterviewFile = currentFile?.fileType === FileType.INTERVIEW;
  const isErrorPredicting = error.PREDICTING !== null || currentFile?.isErrorPredicting;
  const isUnrecoverableError = isErrorPredicting && !Object.keys(currentFile?.userMapping ?? {}).length;
  const isLastFile = currentFileNumber === files.length - 1;

  // USE STATE
  const [source, setSource] = useState<DropdownOption | null>(null);
  const [answersStartFrom, setAnswersStartFrom] = useState<DropdownOption | null>(null);
  const [dateFormat, setDateFormat] = useState<DropdownOption | null>(null);
  const [moderator, setModerator] = useState<DropdownOption[] | null>(null);
  const [activeField, setActiveField] = useState(SOURCE_COLUMN);
  const isLoading = Boolean(isLoadingState[MappingState.LOADING_TEMPLATES] || isLoadingState[MappingState.LOADING_MAPPING_STATE]);
  const isPredictLoading = !!isLoadingState[MappingState.PREDICTING];
  const [previousFileNumber, setPreviousFileNumber] = useState(currentFileNumber);

  // USE MEMO
  const columnsMappingConfig = useMemo(() => {
    const generalConfigPreset = [
      {
        columnName: SOURCE_COLUMN,
        placeholder: 'Select template file source',
        isMulti: false,
        action: handleSourceChange,
      },
      {
        columnName: ANSWERS_START_FROM_COLUMN,
        placeholder: 'Select starting row',
        isMulti: false,
        action: handleAnswersStartFromChange,
      },
      {
        columnName: DATE_FORMAT_COLUMN,
        placeholder: 'Select format',
        isMulti: false,
        action: handleDateFormatChange,
      },
    ];

    const interviewConfigPreset = [
      {
        columnName: SOURCE_COLUMN,
        placeholder: 'Select template file source',
        isMulti: false,
        action: handleSourceChange,
      },
      {
        columnName: DATE_FORMAT_COLUMN,
        placeholder: 'Select format',
        isMulti: false,
        action: handleDateFormatChange,
      },
      {
        columnName: SELECT_MODERATOR_COLUMN,
        placeholder: 'Select moderator(s)',
        isMulti: true,
        action: handleModeratorChange,
      },
    ];

    if (isInterviewFile) {
      return interviewConfigPreset;
    }

    return generalConfigPreset;
  }, [isInterviewFile, handleAnswersStartFromChange, handleDateFormatChange, handleSourceChange]);

  const totalMappedColumns = useMemo(() => {
    if (!currentFile) {
      return 0;
    }

    return Object.values(currentFile.userMapping).reduce((acc, cf) => {
      acc += cf.columnType !== UNKNOWN_TYPE ? 1 : 0;
      return acc;
    }, 0);
  }, [currentFile?.userMapping]);

  const exampleColumns = useMemo(() => {
    if (!currentFile) {
      return [];
    }

    return Object.values(currentFile.userMapping).sort((a, b) => a.columnIndex - b.columnIndex);
  }, [currentFile?.userMapping]);

  const numberOfColumnsWithWrongDate = useMemo(() => {
    if (!currentFile || !currentFile?.dateFormatErrors?.length) {
      return 0;
    }

    return currentFile.dateFormatErrors.reduce((acc, colName) => {
      if (!currentFile.userMapping[colName].shouldSkip) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [currentFile?.userMapping, currentFile?.dateFormatErrors]);

  const availableFileSources = useMemo(() => {
    if (!templates) {
      return [];
    }
    return templates.map((t) => ({ title: t.displayName, value: t.id, name: t.name }));
  }, [templates]);

  const availableMappingValues = useMemo(() => {
    return GENERAL_MAPPING_VALUES;
  }, []);

  const availableAnswersStartFromValues = useMemo(() => {
    if (!currentFile) {
      return [];
    }
    const numberOfRowsToGenerate =
      currentFile.numberOfRows === null || currentFile.numberOfRows > MAX_NUMBER_OF_ROWS || currentFile.numberOfRows === 0
        ? MAX_NUMBER_OF_ROWS
        : currentFile.numberOfRows;
    return new Array(numberOfRowsToGenerate).fill(null).map((_, idx) => ({ title: `Row ${idx + 1}`, value: idx + 1 }));
  }, [currentFile?.numberOfRows]);

  const availableDateFormats = useMemo(() => {
    return DATE_FORMATS;
  }, []);

  // USE EFFECT
  useEffect(function loadTemplates() {
    if (templates && templates.length === 0) {
      dispatch(getTemplates());
    }
  }, []);

  useEffect(
    function initialSelectOfAISource() {
      if (!templates?.length || currentFile?.templateID) {
        return;
      }

      const defaultTemplate = availableFileSources.find((t) => t.name === DEFAULT_SOURCE_NAME);
      if (defaultTemplate) {
        handleSourceChange(defaultTemplate);
      }
    },
    [templates, currentFile?.templateID, currentFileNumber],
  );

  useEffect(
    function resetActiveFieldOnFileChange() {
      if (currentFileNumber !== previousFileNumber) {
        setPreviousFileNumber(currentFileNumber);
        if (!currentFile?.templateID) {
          // Since we automatically select the templateID aka Source
          // I exclude it so that two useEffects don't have race conditions.
          // Later it might be required to add additional check for Interview file types
          return;
        } else if (!currentFile?.dateFormat) {
          setActiveField(DATE_FORMAT_COLUMN);
        } else {
          setActiveField(MAPPING_COLUMNS);
        }
      }
    },
    [currentFileNumber],
  );

  useEffect(
    function handleFileValidationPass() {
      if (isLoadingState[MappingState.VALIDATING_MAPPING] === false && currentFile?.isMappingValid === true) {
        if (currentFileNumber + 1 < files.length) {
          dispatch(fileMappingValidateMappingContinue());
        } else {
          dispatch(loadDocuments({ files, dashboardID, isPrivate, requiresManualProcessing: false }));
        }
      }
    },
    [isLoadingState],
  );

  useEffect(
    function handleMappingFinish() {
      if (isLoadingState[MappingState.UPLOADING]) {
        onMappingFinish(files.map((file) => file.fileName));
      }
    },
    [isLoadingState],
  );

  useEffect(
    function handleFileConfigChanges() {
      // We don't call for file changes when we don't have necessary information or if its of Interview type
      if (!currentFile || !templates.length || currentFile.fileType === FileType.INTERVIEW) {
        return;
      }

      let isGettingSampleRowsRequired = false;

      if (currentFile.startRow !== answersStartFrom?.value) {
        const possibleNewAnswersStartFrom = availableAnswersStartFromValues.find(({ value }) => value === currentFile.startRow);
        setAnswersStartFrom(possibleNewAnswersStartFrom ?? null);
        isGettingSampleRowsRequired = true;
      }

      if (currentFile.dateFormat !== dateFormat?.value && !currentFile.isCustomDateFormat) {
        const possibleNewDateFormat = availableDateFormats.find(({ value }) => value === currentFile.dateFormat);
        setDateFormat(possibleNewDateFormat ?? null);
      } else if (currentFile.isCustomDateFormat) {
        const possibleNewDateFormat = availableDateFormats.find(({ value }) => value === CUSTOM_DATE_FORMAT);

        setDateFormat(possibleNewDateFormat!);
      }

      if (isGettingSampleRowsRequired) {
        dispatch(
          getFileData({
            externalID: currentFile.externalID,
            templateID: currentFile.templateID ?? '',
            dateFormat: currentFile.dateFormat ?? '',
            startRow: currentFile.startRow ?? 0,
          }),
        );
      }
    },
    [currentFile?.startRow, currentFile?.dateFormat, currentFile?.isCustomDateFormat],
  );

  useEffect(
    function handleTemplateIDChangeAndDoPredict() {
      if (!currentFile?.templateID) {
        return;
      }
      const possibleNewSource = availableFileSources.find(({ value }) => value === currentFile.templateID);
      setSource(possibleNewSource ?? null);

      // We don't want to call predict on back/forward calls and on Interview files (as of now)
      if (currentFile.hasAlreadyPredicted || currentFile.fileType === FileType.INTERVIEW) {
        return;
      }

      dispatch(
        getPredictedMapping({
          externalID: currentFile.externalID,
          templateID: currentFile.templateID ?? '',
          dateFormat: currentFile.dateFormat ?? '',
          startRow: currentFile.startRow ?? 0,
        }),
      );
    },
    [currentFile?.templateID],
  );

  // HANDLERS
  function handleSourceChange(option: DropdownOption) {
    const template = templates?.find((t) => t.id === option.value);
    dispatch(
      fileMappingUpdateFileInfo({
        fileState: {
          templateID: option.value as string,
          startRow: template?.defaultStartRow ?? 0,
          hasAlreadyPredicted: false,
        },
      }),
    );
    eventsTracker.track(EVENTS.FILE_MAPPING_SELECT_TEMPLATE, {
      'Template selected': option.title,
    });
    let nextActiveField = '';
    if (isInterviewFile) {
      nextActiveField = DATE_FORMAT_COLUMN;
    } else {
      if (!dateFormat || !currentFile?.dateFormat) {
        nextActiveField = DATE_FORMAT_COLUMN;
      } else {
        nextActiveField = MAPPING_COLUMNS;
      }
    }
    setActiveField(nextActiveField);
  }

  function handleAnswersStartFromChange(option: DropdownOption) {
    dispatch(
      fileMappingUpdateFileInfo({
        fileState: {
          startRow: option.value as number,
        },
      }),
    );
    eventsTracker.track(EVENTS.FILE_MAPPING_ANSWERS_START_FROM_SELECT, {
      'Start Row selected': option.value as number,
      'Current Mapping Template': source?.title,
    });
  }

  function handleDateFormatChange(option: DropdownOption) {
    if (option.value === CUSTOM_DATE_FORMAT) {
      dispatch(
        fileMappingUpdateFileInfo({
          fileState: {
            isCustomDateFormat: true,
            isDateFormatValid: null,
            dateFormat: null,
            isMappingValid: null,
          },
        }),
      );

      if (source && !currentFile?.hasAlreadyPredicted) {
        setActiveField(DATE_FORMAT_COLUMN);
      }

      return;
    } else {
      dispatch(
        fileMappingUpdateFileInfo({
          fileState: {
            dateFormat: option.value as string,
            isCustomDateFormat: false,
            dateFormatErrors: [],
            isMappingValid: null,
          },
        }),
      );
      eventsTracker.track(EVENTS.FILE_MAPPING_DATE_FORMAT_SELECT, {
        'Date Format selected': option.value as string,
        'Current Mapping Template': source?.title,
      });
    }

    if (activeField !== MAPPING_COLUMNS && !currentFile?.isCustomDateFormat && !currentFile?.hasAlreadyPredicted) {
      setActiveField(MAPPING_COLUMNS);
    }
  }

  function handleColumnMappingChange(columnInfo: UserMapping, option: DropdownOption) {
    const colInfoBefore = currentFile?.userMapping[columnInfo.columnName];
    dispatch(
      fileMappingUpdateUserMapping({
        byUserAction: true,
        userMapping: {
          [columnInfo.columnName]: {
            ...columnInfo,
            columnType: option.value,
            byUserAction: true,
            shouldSkip: false,
          },
        },
      }),
    );
    const template = templates?.find((t) => t.id === currentFile?.templateID);
    eventsTracker.track(EVENTS.FILE_MAPPING_COLUMN_DATA_TYPE_SELECT, {
      'Data Type selected': option.value,
      'Column Mapping state': colInfoBefore.columnType === UNKNOWN_TYPE ? 'Unmapped' : 'Mapped',
      'Column Inclusion state': colInfoBefore.shouldSkip ? 'Excluded' : 'Included',
      'Current Mapping Template': template?.displayName,
    });
  }

  function handleColumnSkipToggle(columnInfo: UserMapping) {
    const colInfoBefore = currentFile?.userMapping[columnInfo.columnName];
    if (!columnInfo.columnType) {
      return;
    }

    dispatch(
      fileMappingUpdateUserMapping({
        byUserAction: true,
        userMapping: {
          [columnInfo.columnName]: {
            ...columnInfo,
            shouldSkip: !columnInfo.shouldSkip,
            byUserAction: true,
          },
        },
      }),
    );

    const template = templates?.find((t) => t.id === currentFile?.templateID);
    const eventName = colInfoBefore.shouldSkip ? EVENTS.FILE_MAPPING_INCLUDE_COLUMN_SELECT : EVENTS.FILE_MAPPING_EXCLUDE_COLUMN_SELECT;
    eventsTracker.track(eventName, {
      'Data Type selected': colInfoBefore.columnType,
      'Column Mapping state': colInfoBefore.columnType === UNKNOWN_TYPE ? 'Unmapped' : 'Mapped',
      'Current Mapping Template': template?.displayName,
    });
  }

  function handleNextButtonClick() {
    if (isErrorPredicting) {
      if (!isLastFile) {
        dispatch(fileMappingValidateMappingContinue());
        eventsTracker.track(EVENTS.FILE_MAPPING_NEXT_BUTTON_SELECT, {
          'Next Status': 'Success',
          'Number of files uploaded': files.length,
        });
      } else {
        dispatch(loadDocuments({ files, dashboardID, isPrivate, requiresManualProcessing: false }));
      }
    } else {
      if (currentFile?.fileType === FileType.INTERVIEW) {
        if (currentFileNumber === files.length - 1) {
          dispatch(loadDocuments({ files, dashboardID, isPrivate, requiresManualProcessing: false }));
        } else {
          dispatch(fileMappingValidateMappingContinue());
          eventsTracker.track(EVENTS.FILE_MAPPING_NEXT_BUTTON_SELECT, {
            'Next Status': 'Success',
            'Number of files uploaded': files.length,
          });
        }
      } else {
        dispatch(validateMapping({ file: currentFile }));
      }
    }
  }

  function handleBackButtonClick() {
    if (currentFileNumber === 0) {
      handleCloseClick();
    } else {
      dispatch(fileMappingUpdateSelectedIndex({ index: currentFileNumber - 1 }));
    }
  }

  function handleCloseClick() {
    const template = templates?.find((t) => t.id === currentFile?.templateID);
    eventsTracker.track(EVENTS.FILE_MAPPING_CANCEL, {
      'Number of files uploaded': files.length,
      'Current file number': currentFileNumber + 1,
      'Current Mapping Template': template?.displayName,
      'Any errors present': [
        currentFile?.isErrorPredicting && 'Error predicting',
        !!currentFile?.dateFormatErrors?.length && 'Date format errors present',
        currentFile?.isCustomDateFormat && currentFile?.isDateFormatValid === false && 'Custom date format is invalid',
      ].filter(Boolean),
    });
    onCloseClick();
  }

  function handleExpertMapRequest() {
    dispatch(loadDocuments({ files, dashboardID, isPrivate, requiresManualProcessing: true }));
    const template = templates?.find((t) => t.id === currentFile?.templateID);
    eventsTracker.track(EVENTS.FILE_MAPPING_HAVE_A_BEEHIVE_EXPERT_MAP, {
      'Number of files uploaded': files.length,
      'Current file number': currentFileNumber + 1,
      'Current Mapping Template': template?.displayName,
      'Any errors present': [
        currentFile?.isErrorPredicting && 'Error predicting',
        !!currentFile?.dateFormatErrors?.length && 'Date format errors present',
        currentFile?.isCustomDateFormat && currentFile?.isDateFormatValid === false && 'Custom date format is invalid',
      ].filter(Boolean),
    });
  }

  function handleModeratorChange(option: DropdownOption) {
    if (moderator) {
      setModerator([...moderator, option]);
    } else {
      setModerator([option]);
    }

    if (activeField !== MAPPING_COLUMNS) {
      setActiveField(MAPPING_COLUMNS);
    }
  }

  if (!currentFile || !templates) {
    return null;
  }
  const isNextButtonEnabled =
    isInterviewFile ||
    isErrorPredicting ||
    (!isLoading &&
      !isPredictLoading &&
      numberOfColumnsWithWrongDate === 0 &&
      !!currentFile.templateID &&
      !!currentFile.dateFormat &&
      !!currentFile.startRow &&
      totalMappedColumns >= 1);
  return (
    <FileUploadMapping
      activeField={activeField}
      activeFileName={currentFile.fileName}
      answersStartFrom={answersStartFrom}
      availableAnswersStartFromValues={availableAnswersStartFromValues}
      availableDateFormats={availableDateFormats}
      availableMappingValues={availableMappingValues}
      columnsMappingConfig={columnsMappingConfig}
      currentFileNumber={currentFileNumber + 1}
      currentMappedNumberOfColumns={totalMappedColumns}
      dateFormat={dateFormat}
      dateFormatErrors={currentFile.dateFormatErrors}
      exampleColumns={exampleColumns}
      isBackButtonEnabled={currentFileNumber >= 1}
      isCustomDateFormat={currentFile.isCustomDateFormat}
      isErrorPredicting={isErrorPredicting}
      isInterviewFile={isInterviewFile}
      isLoading={isLoading}
      isNextButtonEnabled={isNextButtonEnabled}
      isPredictLoading={isPredictLoading}
      isUnrecoverableError={isUnrecoverableError}
      moderator={moderator}
      numberOfColumnsWithWrongDate={numberOfColumnsWithWrongDate}
      possibleFileSources={availableFileSources}
      source={source}
      totalNumberOfColumnsInActiveFile={Object.keys(currentFile.userMapping).length}
      totalNumberOfFiles={files.length}
      onBackButtonClick={handleBackButtonClick}
      onCloseButtonClick={handleCloseClick}
      onColumnMappingChange={handleColumnMappingChange}
      onColumnSkipToggle={handleColumnSkipToggle}
      onNextButtonClick={handleNextButtonClick}
      onExpertMapRequest={handleExpertMapRequest}
    />
  );
}

export default FileUploadMappingContainer;
