import styled from 'styled-components';
import { ReactComponent as ThumbsUpIcon } from '../../assets/icons/thumbs-up-icon.svg';

export const ThumbsUp = styled(ThumbsUpIcon)<{ $isSelected: boolean }>`
  cursor: pointer;
  height: 18px;
  width: 18px;
  fill: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'none')};
  stroke: var(--black);
  &:hover {
    stroke: var(--beehive-purple);
  }
`;
export const ThumbsDown = styled(ThumbsUp)`
  transform: rotateX(180deg);
`;
