import { AuthSourceEnum, OperationTypeEnum, SurveySourceEnum } from '../../types/connections';

import { TabEnum } from './types';

export const getConnectionTypeString = (type: OperationTypeEnum) => {
  switch (type) {
    case OperationTypeEnum.IMPORT:
      return 'Import';
    case OperationTypeEnum.EXPORT:
      return 'Export';
    default:
      return '';
  }
};

export const authSourceToSurveySource = (source: AuthSourceEnum) => {
  switch (source) {
    case AuthSourceEnum.AZURE_SAS:
      return SurveySourceEnum.AZURE;
    case AuthSourceEnum.QUALTRICS:
      return SurveySourceEnum.QUALTRICS;
    default:
      return '';
  }
};
export const surveySourceToAuthSource = (source: SurveySourceEnum) => {
  switch (source) {
    case SurveySourceEnum.AZURE:
      return AuthSourceEnum.AZURE_SAS;
    case SurveySourceEnum.QUALTRICS:
      return AuthSourceEnum.QUALTRICS;
    default:
      return '';
  }
};

export const TAB_TO_LABEL = {
  [TabEnum.INTEGRATIONS]: 'Apps & Integrations',
};
