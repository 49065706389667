import React from 'react';
import styled from 'styled-components';
import { Concept } from '../../../../store/reducers/aiInsights';

const PromptContainer = styled.div`
  font-weight: bold;
  min-height: 18px;
`;

const OptionalConcept = styled.i`
  color: var(--black);
  font-weight: normal;
`;

interface IInsightPrompt {
  availableFilters: string[];
  isWidgetLevelFilterApplied: boolean;
  selectedConcept?: Concept;
  customPrompt?: string;
  segmentsNames?: string[];
}

function InsightPrompt({ availableFilters, customPrompt, isWidgetLevelFilterApplied, selectedConcept, segmentsNames }: IInsightPrompt) {
  const withSegmentMention = availableFilters.length > 1 || (availableFilters.length === 1 && !selectedConcept);
  if (withSegmentMention) {
    return (
      <PromptContainer data-testid='prompt'>
        The key takeaways regarding {renderOptionalSegments()}
        {renderOptionalSelectedConcept()}
        {renderOptionalWidgetFilter()}:
      </PromptContainer>
    );
  }

  return (
    <PromptContainer data-testid='prompt'>
      The key takeaways regarding {renderOptionalSelectedConcept()}
      {isWidgetLevelFilterApplied ? '' : ' from the data are:'}
      {renderOptionalWidgetFilter()}
      {isWidgetLevelFilterApplied && ':'}
    </PromptContainer>
  );

  function renderOptionalSelectedConcept() {
    if (!selectedConcept) {
      return null;
    }

    return (
      <b>
        {withSegmentMention && ' and the concept '}
        <OptionalConcept>"{selectedConcept.title}"</OptionalConcept>
      </b>
    );
  }

  function renderOptionalWidgetFilter() {
    if (!isWidgetLevelFilterApplied) {
      return null;
    }

    const withComma = !!selectedConcept && segmentsNames && segmentsNames.length > 1;
    return (
      <b>
        {withComma ? ', ' : ' '}
        and results for the <OptionalConcept>current widget-level filter</OptionalConcept>
      </b>
    );
  }

  function renderOptionalSegments() {
    if (availableFilters.length === 1 && selectedConcept) {
      return null;
    }

    return segmentsNames!.map((segment, i) => (
      <OptionalConcept key={`${i}-${segment}`}>
        "{segment}"{i < segmentsNames!.length - 1 ? ', ' : ''}
      </OptionalConcept>
    ));
  }
}

export default InsightPrompt;
