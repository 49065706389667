import React, { ReactElement } from 'react';
import { BorderedContainer, ResultsContainer, Title } from './style';

interface IWidgetElement {
  title: string;
  isHighlighted?: boolean;
  dataTestId?: string;
  renderChild: () => ReactElement;
  renderOptionalIcon?: () => ReactElement;
}

function WidgetElement({ title, dataTestId, isHighlighted = false, renderChild, renderOptionalIcon }: IWidgetElement) {
  return (
    <BorderedContainer data-testid={dataTestId} $isHighlighted={isHighlighted}>
      <ResultsContainer>
        {!!renderOptionalIcon && renderOptionalIcon()}
        <Title>{title} </Title>
        {renderChild()}
      </ResultsContainer>
    </BorderedContainer>
  );
}

export default WidgetElement;
