import React from 'react';
import styled from 'styled-components';
import { Typography, Tabs, Tab } from '@material-ui/core';

import { Integration, SurveySourceEnum } from '../../types/connections';

import IntegrationsTabContainer from './Tabs/Integrations/IntegrationsTabContainer';
import AddConnectionDialogContainer from './AddConnection/AddConnectionDialogContainer';
import { TabEnum } from './types';
import { TAB_TO_LABEL } from './utils';

const TOP_PADDING = 40;
const BOTTOM_PADDING = 60;

const Container = styled.div`
  padding: ${TOP_PADDING}px 60px ${BOTTOM_PADDING}px 60px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const Title = styled(Typography)`
  && {
    text-align: center;
    margin-bottom: 24px;
  }
`;

const StyledTabs = styled(Tabs)``;

const Content = styled.div`
  margin-top: 20px;
  height: 100%;
`;

interface IConnectorsTab {
  selectedTab: TabEnum;
  onTabChange: (event: React.ChangeEvent<object>, value: TabEnum) => void;
  onConnectionAdd: (source: SurveySourceEnum | null) => void;
  onEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string | null) => void;
  onAuthenticationEdit: (integration: Integration) => void;
}

function ConnectorsTab({ selectedTab, onTabChange, onConnectionAdd, onEnrich, onAuthenticationEdit }: IConnectorsTab) {
  return (
    <Container>
      <Title variant='h5'>Connect with Beehive AI</Title>
      <StyledTabs value={selectedTab} onChange={onTabChange} indicatorColor='primary' textColor='primary'>
        <Tab data-testid='connectors-tab-integrations' label={TAB_TO_LABEL[TabEnum.INTEGRATIONS]} value={TabEnum.INTEGRATIONS} />
      </StyledTabs>
      <Content data-testid='connectors-tab-container'>
        {selectedTab === TabEnum.INTEGRATIONS && (
          <IntegrationsTabContainer
            handleAddConnection={onConnectionAdd}
            handleEnrich={onEnrich}
            handleEditAuthentication={onAuthenticationEdit}
          />
        )}
      </Content>
      <AddConnectionDialogContainer />
    </Container>
  );
}

export default ConnectorsTab;
