import React, { ChangeEvent, useRef } from 'react';

import { ReactComponent as CloseIcon } from '../../../../../../../assets/icons/cross-icon.svg';
import styles from './DynamicTextArea.module.css';

interface IDynamicTextArea {
  isCloseButtonVisible: boolean;
  feedback: string;
  onInputChange: (newFeedback: string) => void;
  onCloseIconClick: () => void;
}

function DynamicTextArea({ isCloseButtonVisible, feedback, onInputChange, onCloseIconClick }: IDynamicTextArea) {
  const textAreaRef = useRef<HTMLTextAreaElement | null>(null);

  function handleInputChange(event: ChangeEvent<HTMLTextAreaElement>) {
    if (!textAreaRef.current) {
      return;
    }
    textAreaRef.current.style.height = 'auto';
    // Set height to match content
    textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px';

    onInputChange(event.target.value);
  }

  function handleCloseIconClick() {
    if (!textAreaRef.current) {
      return;
    }
    textAreaRef.current.style.height = 'auto';
    onCloseIconClick();
  }

  return (
    <div className={styles.container}>
      <textarea
        rows={1}
        onChange={handleInputChange}
        ref={textAreaRef}
        className={styles.dynamicTextarea}
        placeholder='Tell us more...'
        value={feedback}
      ></textarea>
      {renderCloseButton()}
    </div>
  );

  function renderCloseButton() {
    if (!isCloseButtonVisible) {
      return;
    }

    return (
      <div className={styles.closeButtonContainer} onClick={handleCloseIconClick}>
        <CloseIcon className={styles.closeButton} />
      </div>
    );
  }
}

export default DynamicTextArea;
