import React, { useMemo } from 'react';
import { FILTER_ID_ALL } from '../../../../constants';
import { SavedFilterModel } from '../../../../models/Filter';
import { useAppSelector } from '../../../../store/hooks';
import { Filter } from '../../../../types/Config';
import { TotalsByDataSourcesAndFiltersIDS } from '../Data/OpenWidgetData';
import StatusPanel from './StatusPanel';

export type SentimentSummary = {
  positive: number;
  neutral: number;
  negative: number;
};

interface IStatusPanelContainer {
  widgetFilter: Filter;
  widgetTitle: string;
  widgetType: string;
  groupTitle: string;
  savedFilters: SavedFilterModel[];
  isWidgetFilterApplied: boolean;
  answeredCount: number;
  analyzedCount: number;
  weightedTotal: number;
  sentimentSummary: SentimentSummary;
  hasSentiment: boolean;
  totalsByFiltersIds?: TotalsByDataSourcesAndFiltersIDS;
  onFilterApply: (filter: Filter) => void;
}

function StatusPanelContainer({
  widgetFilter,
  widgetTitle,
  widgetType,
  groupTitle,
  savedFilters,
  answeredCount,
  analyzedCount,
  totalsByFiltersIds,
  sentimentSummary,
  hasSentiment,
  weightedTotal,
  onFilterApply,
}: IStatusPanelContainer) {
  const weightedMetricsUsed = useAppSelector((state) => state.app.weightedMetricsUsed);
  const percentage = answeredCount === 0 ? 0 : analyzedCount / answeredCount;
  const pct = isFinite(percentage) ? Math.round(percentage * 100) : 0;
  const filteredTotal = useMemo(() => {
    if (!totalsByFiltersIds) {
      return answeredCount;
    }
    return totalsByFiltersIds[FILTER_ID_ALL].base;
  }, [totalsByFiltersIds]);

  const isWidgetFilterApplied = useMemo(() => {
    if (widgetFilter) {
      const savedFilter = savedFilters.find((savedFilter: SavedFilterModel) => savedFilter.id === widgetFilter.filterID);
      if (widgetFilter.filterID && savedFilter) {
        return !!savedFilter.query;
      } else if (widgetFilter.query) {
        return !!widgetFilter.query;
      }
    }
    return false;
  }, [widgetFilter?.query, widgetFilter?.filterID]);

  return (
    <StatusPanel
      widgetFilter={widgetFilter}
      widgetTitle={widgetTitle}
      widgetType={widgetType}
      groupTitle={groupTitle}
      answeredCount={answeredCount}
      filteredTotal={filteredTotal}
      isWidgetFilterApplied={isWidgetFilterApplied}
      sentimentSummary={sentimentSummary}
      total={answeredCount}
      hasSentiment={hasSentiment}
      weightedMetricsUsed={weightedMetricsUsed}
      weightedTotal={weightedTotal}
      percentage={pct}
      onFilterApply={onFilterApply}
    />
  );
}

export default StatusPanelContainer;
