import React from 'react';

import ErrorMessage from '../../common/ErrorMessage/ErrorMessage';
import {
  CloseButtonContainer,
  CloseIconStyled,
  Container,
  Header,
  HeaderContainer,
  HaveExpertMapFilesButton,
} from '../FileUploadMapping/styles';
import { Body } from '../FileUploadMappingLoader/styles';
import { FooterStyled } from './styles';

interface IFileUploadMappingError {
  onCloseButtonClick: () => void;
  onReportIssueClick: () => void;
}

function FileUploadMappingError({ onCloseButtonClick, onReportIssueClick }: IFileUploadMappingError) {
  return (
    <Container>
      <HeaderContainer>
        <Header>Upload Error</Header>
        <CloseButtonContainer onClick={onCloseButtonClick}>
          <CloseIconStyled />
        </CloseButtonContainer>
      </HeaderContainer>
      <Body>
        <ErrorMessage text='There was an Error uploading your files.' />
      </Body>
      <FooterStyled>
        <HaveExpertMapFilesButton onClick={onReportIssueClick}>Report Issue</HaveExpertMapFilesButton>
      </FooterStyled>
    </Container>
  );
}

export default FileUploadMappingError;
