import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { datadogLogs } from '@datadog/browser-logs';

import { Action } from '../hooks';
import { Concept } from '../reducers/aiInsights';
import { putInterviewInsightsGenerationRequest } from '../../components/tools/api';
import {
  GeneratedInsight,
  generateInsightsRequestCancel,
  generateInsightsRequestFailure,
  generateInsightsRequestSuccess,
} from '../actions/aiInsights';
import { Query } from '../../types/Query';

const widgetIdToAbortSignal: Map<string, AbortController> = new Map();

export type InsightsFilter = {
  segmentName: string;
  query: Query;
};
interface IGenerateInsights {
  dashboardID: string;
  widgetID: string;
  dataSourceID: string;
  filter: InsightsFilter[];
  insightID: string;
  totalResponses: number;
  customPrompt?: string;
  selectedConcept?: Concept;
  isTryAgain?: boolean;
}

export const generateInsights =
  ({
    dashboardID,
    widgetID,
    dataSourceID,
    customPrompt,
    selectedConcept,
    isTryAgain = false,
    filter,
    totalResponses,
    insightID,
  }: IGenerateInsights): Action =>
  async (dispatch) => {
    const startTime = performance.now();

    const abortController = new AbortController();
    widgetIdToAbortSignal.set(widgetID, abortController);

    const triggerName = selectedConcept
      ? customPrompt
        ? `${selectedConcept}: ${customPrompt}`
        : selectedConcept.title
      : customPrompt
        ? customPrompt
        : 'Generate';

    let timeToGenerate;
    try {
      const resp = await putInterviewInsightsGenerationRequest(
        abortController.signal,
        dashboardID,
        widgetID,
        dataSourceID,
        totalResponses,
        filter,
        isTryAgain,
        selectedConcept?.title,
        customPrompt,
      );
      timeToGenerate = `${performance.now() - startTime} ms`;
      if (resp.error) {
        throw new Error(resp.error);
      } else {
        widgetIdToAbortSignal.delete(widgetID);

        eventsTracker.track(EVENTS.GEN_AI_GENERATE, {
          'Widget ID': widgetID,
          'Generated insight trigger name': triggerName,
          'Data Source ID': dataSourceID,
          'Generation status': 'Successful',
          'Generated Content': JSON.stringify(resp?.keyTakeaways),
          'Generated Related Concepts': resp.relatedConcepts?.join(' , '),
          'Is Try Again?': isTryAgain || false,
          'Time to Generate': timeToGenerate,
        });

        const keyTakeaways: GeneratedInsight[] = resp?.keyTakeaways?.map((generatedInsight: any) => ({
          insight: generatedInsight.keyTakeaway,
          keyQuotes: generatedInsight.keyQuotes ?? [],
        }));

        dispatch(
          generateInsightsRequestSuccess({
            id: insightID,
            timestamp: new Date().toISOString(),
            widgetID,
            concept: selectedConcept,
            customPrompt: customPrompt,
            isTryAgain,
            totalResponses,
            generatedInsights: keyTakeaways,
            relatedConcepts:
              resp?.relatedConcepts?.map((concept: string) => ({
                id: concept,
                title: concept,
              })) ?? [],
            numberOfResponsesUsedForGeneration: `${resp?.numberOfResponsesUsedForGeneration}`,
            generationModel: resp?.generationModel,
            generationModelLocation: resp?.generationModelLocation,
            generationTime: `${resp?.generationTime}`,
            tokensTotal: `${resp?.tokensTotal}`,
            modelParameters: {
              temperature: `${resp?.modelParameters.temperature}`,
              topK: `${resp?.modelParameters.topK}`,
              topP: `${resp?.modelParameters.topP}`,
              maxOutputTokens: `${resp?.modelParameters.maxOutputTokens}`,
            },
          }),
        );
      }
    } catch (e: any) {
      if (e.name !== 'AbortError') {
        const timeStamp = new Date().toISOString();
        eventsTracker.track(EVENTS.GEN_AI_GENERATE, {
          'Widget ID': widgetID,
          'Generated insight trigger name': triggerName,
          'Data Source ID': dataSourceID,
          'Generation status': 'Error',
          'Is Try Again?': isTryAgain,
          'Time to Generate': timeToGenerate,
        });
        const errorPayload = `
                Time Stamp: ${timeStamp};
                Workspace: ${(eventsTracker.context as any).Company};
                Dashboard ID: ${dashboardID};
                Widget ID: ${widgetID};
                Data Source ID: ${dataSourceID};
                Trigger Name: ${triggerName};
                Time to Generate: ${timeToGenerate};
                Backend error info: ${e};
            `;
        datadogLogs.logger.error('Failed to generate AI Insights.', {
          errorPayload,
        });
        widgetIdToAbortSignal.delete(widgetID);
        dispatch(
          generateInsightsRequestFailure({
            id: insightID,
            timestamp: timeStamp,
            totalResponses,
            widgetID,
          }),
        );
      } else {
        eventsTracker.track(EVENTS.GEN_AI_STOP_GENERATING, {
          'Widget ID': widgetID,
          'Generated insight trigger name': triggerName,
          'Data Source ID': dataSourceID,
          'Generation status': 'Error',
          'Is Try Again?': isTryAgain,
          'Time to Generate': timeToGenerate,
        });
      }
    }
  };

interface IStopGeneratingInsights {
  widgetID: string;
}

export const stopGeneratingInsights =
  ({ widgetID }: IStopGeneratingInsights): Action =>
  (dispatch) => {
    const abortController = widgetIdToAbortSignal.get(widgetID);
    abortController?.abort('User cancelled the generation request');
    dispatch(
      generateInsightsRequestCancel({
        timestamp: new Date().toISOString(),
        widgetID,
      }),
    );
  };
