import React, { ForwardedRef, useEffect, useState } from 'react';
import { thumbsToggle } from '../../../../../../../store/actions/dashboardQueries';
import { useAppDispatch, useAppSelector } from '../../../../../../../store/hooks';
import { ThumbStatus } from '../../../../../../../store/reducers/dashboardQueries/types';
import { submitDashboardQueriesFeedback } from '../../../../../../../store/thunks/dashboardQueries';

import { DashboardQuery } from '../../../../../../../types/DashboardQuery';
import TextResponse from './TextResponse';

interface ITextResponseContainer {
  query: DashboardQuery;
  onUserFeedbackToggle: () => void;
}

function TextResponseContainer({ query, onUserFeedbackToggle }: ITextResponseContainer, ref: ForwardedRef<HTMLDivElement>) {
  const { feedbacks } = useAppSelector((state) => state.dashboardQueries);
  const dispatch = useAppDispatch();
  const feedback = feedbacks[query.id];

  // USE STATE
  const [userFeedback, setUserFeedback] = useState('');
  const [wasLoadingPreviously, setWasLoadingPreviously] = useState(feedback.isLoading);
  const [previousThumb, setPreviousThumb] = useState(feedback.thumb);
  const [isUserFeedbackVisible, setIsUserFeedbackVisible] = useState(true);

  // USE EFFECT
  useEffect(() => {
    if (!wasLoadingPreviously && feedback.isLoading) {
      setWasLoadingPreviously(true);
    } else if (wasLoadingPreviously && !feedback.isLoading && feedback.wasSubmitted) {
      setUserFeedback('');
      setWasLoadingPreviously(false);
    } else if (wasLoadingPreviously && !feedback.isLoading && feedback.wasErrorSubmitting) {
      setWasLoadingPreviously(false);
    }
  }, [feedback.isLoading, feedback.wasSubmitted, feedback.wasErrorSubmitting]);

  useEffect(() => {
    if (previousThumb !== feedback.thumb) {
      onUserFeedbackToggle();
      setPreviousThumb(feedback.thumb);
    }
  }, [feedback.thumb]);

  // HANDLERS
  function handleFeedbackChange(newFeedback: string) {
    setUserFeedback(newFeedback);
  }

  function handleThumbsToggle(thumb: ThumbStatus) {
    dispatch(thumbsToggle({ thumb, id: query.id }));
    if (!isUserFeedbackVisible) {
      setIsUserFeedbackVisible(true);
    }
  }

  function handleSendClick() {
    dispatch(
      submitDashboardQueriesFeedback({
        userFeedback,
        queryID: query.id,
      }),
    );
  }

  function handleCloseIconClick() {
    if (userFeedback.length) {
      setUserFeedback('');
    } else {
      setIsUserFeedbackVisible(false);
    }
  }

  return (
    <TextResponse
      ref={ref}
      isUserFeedbackVisible={isUserFeedbackVisible}
      query={query}
      feedback={feedback}
      userFeedback={userFeedback}
      onFeedbackChange={handleFeedbackChange}
      onThumbsToggle={handleThumbsToggle}
      onSendClick={handleSendClick}
      onCloseIconClick={handleCloseIconClick}
    />
  );
}

export default React.forwardRef(TextResponseContainer);
