export enum ThumbStatus {
  UP = 'up',
  DOWN = 'down',
}

export type Feedback = {
  thumb: ThumbStatus | null;
  isLoading: boolean;
  wasSubmitted: boolean;
  wasErrorSubmitting: boolean;
};
