import Grid from '@material-ui/core/Grid';
import { LabelWrapper } from './style';
import { OverviewAnswerModel } from '../../../models/Overview';
import FiltersBars from '../../UI/FiltersBars/FiltersBars';
import BreakdownFiltersBars from '../../UI/FiltersBars/BreakdownFiltersBars';
import { AutoSizer, CellMeasurer, CellMeasurerCache, List } from 'react-virtualized';

const breakdownLabelSumByFilterId = (dataMap, filterId) => {
  return [...dataMap].reduce((acc, [, value]) => {
    const filterValue = value.find((v) => v.filterId === filterId);
    return acc + (filterValue ? filterValue.value : 0);
  }, 0);
};

const RowRenderer = ({
  index,
  registerChild,
  style,
  dataToShowByLabel,
  sortedLabels,
  breakdownData,
  average,
  selectedFilterIds,
  filtersById,
}) => {
  const label = sortedLabels[index];
  const percentDataByFilterId = dataToShowByLabel.get(label);

  return (
    <div ref={registerChild} data-label={label} style={style}>
      <Grid container direction='row' style={{ paddingBottom: '16px', fontSize: '16px' }}>
        <Grid item xs={3}>
          <LabelWrapper data-tooltip-id='base-tooltip' data-tooltip-content={label}>
            {label}
          </LabelWrapper>
        </Grid>
        <Grid item xs={9}>
          {breakdownData ? (
            <BreakdownFiltersBars
              breakdownData={breakdownData}
              average={average}
              label={label}
              selectedFilterIds={selectedFilterIds}
              filtersById={filtersById}
            />
          ) : (
            <FiltersBars
              dataByFilterId={percentDataByFilterId}
              average={average}
              filtersById={filtersById}
              selectedFilterIds={selectedFilterIds}
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const LabelsList = ({ dataSource, overviewDataById, selectedFilterIds, breakdownData, filtersById, average, selectedItems }) => {
  const dataByAnswerByFilterId = new Map();
  const isOrdered = dataSource.ordered;
  const measurerCache = new CellMeasurerCache({
    defaultHeight: 22,
    fixedWidth: true,
    // Required due to: https://github.com/bvaughn/react-virtualized/issues/1375
    minHeight: 10,
  });
  const selectedItemsFilter = (item) => selectedItems.length === 0 || selectedItems.includes(item.response);

  overviewDataById.forEach((overviewAPIResponse, filterId) => {
    if (
      selectedFilterIds.includes(filterId) ||
      Object.keys(filtersById).length === 1 ||
      (breakdownData && Object.keys(filtersById).length === 1)
    ) {
      if (overviewAPIResponse) {
        const model = overviewAPIResponse.items.find((item) => item.id === dataSource.dataSourceID);
        dataSource.items.filter(selectedItemsFilter).forEach((item) => {
          if (!dataByAnswerByFilterId.has(item.displayResponse)) {
            dataByAnswerByFilterId.set(item.displayResponse, {});
          }

          let answerModel = model && model.answers.find((answer) => answer.response === item.response);
          if (!answerModel) {
            answerModel = new OverviewAnswerModel(item.response, item.originalResponse, item.displayResponse);
          }

          dataByAnswerByFilterId.get(item.displayResponse)[filterId] = answerModel;
        });
      }
    }
  });

  let dataToShow = [...dataByAnswerByFilterId.entries()];

  let sortedLabels = [...dataByAnswerByFilterId.keys()].reverse(); // custom order
  if (breakdownData) {
    sortedLabels = [];
    dataToShow = [];
    dataSource.items.filter(selectedItemsFilter).forEach((item) => {
      sortedLabels.push(item.displayResponse);
      dataToShow.push([item.displayResponse]);
    });
    sortedLabels.reverse();
  }
  if (!isOrdered) {
    // should be ordered by value
    if (selectedFilterIds.length === 1 && !average) {
      // one filter is selected - use values for the filter to sort
      if (breakdownData) {
        if (breakdownData.size > 0) {
          const cache = new Map();
          sortedLabels.sort((a, b) => {
            if (!cache.has(a)) {
              cache.set(a, breakdownLabelSumByFilterId(breakdownData.get(a), selectedFilterIds[0]));
            }
            if (!cache.has(b)) {
              cache.set(b, breakdownLabelSumByFilterId(breakdownData.get(b), selectedFilterIds[0]));
            }
            return cache.get(b) - cache.get(a);
          });
        }
      } else {
        const filterIdToSort = selectedFilterIds[0];
        sortedLabels.sort(
          (a, b) => dataByAnswerByFilterId.get(b)[filterIdToSort].number - dataByAnswerByFilterId.get(a)[filterIdToSort].number,
        );
      }
    } else {
      // multiple filters are selected - use non-filtered values to sort
      sortedLabels = [...dataSource.items]
        .filter(selectedItemsFilter)
        .sort((a, b) => b.value - a.value)
        .map((item) => item.displayResponse);
    }
  }

  const dataToShowByLabel = new Map();
  for (let [label, percentDataByFilterId] of dataToShow) {
    dataToShowByLabel.set(label, percentDataByFilterId);
  }

  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          overscanRowCount={3}
          height={height}
          width={width}
          rowCount={sortedLabels.length}
          deferredMeasurementCache={measurerCache}
          rowHeight={measurerCache.rowHeight}
          rowWidth={width}
          rowRenderer={({ index, key, parent, style }) => (
            <CellMeasurer cache={measurerCache} columnIndex={0} key={key} parent={parent} rowIndex={index}>
              {({ registerChild }) => (
                <RowRenderer
                  index={index}
                  registerChild={registerChild}
                  style={style}
                  dataToShowByLabel={dataToShowByLabel}
                  sortedLabels={sortedLabels}
                  breakdownData={breakdownData}
                  average={average}
                  selectedFilterIds={selectedFilterIds}
                  filtersById={filtersById}
                />
              )}
            </CellMeasurer>
          )}
        />
      )}
    </AutoSizer>
  );
};

export default LabelsList;
