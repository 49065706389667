import styled from 'styled-components';

import { ResponseModel } from '../../../../../models/Response';
import { ConversationState, Participant } from '../../../../../store/reducers/interviewWidget/types';
import { Label } from '../../../../../types/Label';

import ConversationListContainer from './ConversationListContainer';
import ConversationFilters from './ConversationFilters';
import { Concept } from '../../../../../store/reducers/aiInsights';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: var(--transparent);
  height: 100%;
  gap: 24px;
`;

interface IConversation {
  widgetID: string;
  widgetName: string;
  responses: ResponseModel[];
  responsesState: ConversationState;
  labels: Label[];
  selectedLabelItem: Concept | null;
  participants: Participant[];
  isRegenerating: boolean;
  onLoadMoreResponses: (startIndex: number) => void;
  onLabelClick: (label: string | null, withScroll: boolean) => void;
  onSearchStringChange: (searchString: string) => void;
  onCustomersFilterChange: (ids: string[]) => void;
}
function Conversation({
  widgetID,
  widgetName,
  responses,
  responsesState,
  labels,
  selectedLabelItem,
  participants,
  isRegenerating,
  onLoadMoreResponses,
  onLabelClick,
  onCustomersFilterChange,
  onSearchStringChange,
}: IConversation) {
  return (
    <Container>
      <ConversationFilters
        participants={participants}
        widgetName={widgetName}
        selectedLabelItem={selectedLabelItem}
        searchString={responsesState.searchString}
        labels={labels}
        onSearchStringChange={onSearchStringChange}
        onLabelSelect={onLabelClick}
        onCustomersFilterChange={onCustomersFilterChange}
      />
      <ConversationListContainer
        widgetID={widgetID}
        responses={responses}
        responsesState={responsesState}
        labels={labels}
        selectedLabelItem={selectedLabelItem?.title ?? null}
        participants={participants}
        isRegenerating={isRegenerating}
        onLoadMoreResponses={onLoadMoreResponses}
        onLabelClick={onLabelClick}
      />
    </Container>
  );
}

export default Conversation;
