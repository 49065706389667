import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import LabelsAutocompleteControlContainer from './LabelsAutocompleteControlContainer';
import { TRENDS_ITEM_ALL_KEY, TRENDS_ITEM_TOP_10_KEY, TRENDS_ITEM_TOP_5_KEY } from '../../../constants';
import { prepareLabelsAutocompleteOptions } from './lib';

const SPECIAL_OPTIONS = [
  {
    label: 'ALL',
    name: TRENDS_ITEM_ALL_KEY,
    tooltip: 'All labels',
  },
  {
    label: 'TOP 5',
    name: TRENDS_ITEM_TOP_5_KEY,
    tooltip: 'Top 5 high-level items',
  },
  {
    label: 'TOP 10',
    name: TRENDS_ITEM_TOP_10_KEY,
    tooltip: 'Top 10 high-level items',
  },
];

const SPECIAL_ITEM_KEYS = [TRENDS_ITEM_ALL_KEY, TRENDS_ITEM_TOP_5_KEY, TRENDS_ITEM_TOP_10_KEY];

const WidgetLabelsAutocompleteControl = ({ onChange, value, dataSourceID, enableEmpty, disabled }) => {
  const { translationUsed, weightedMetricsUsed } = useSelector((state) => state.app);
  const dataSources = useSelector((state) => state.dashboard.dataSources);
  const dashboardDataSources = useSelector((state) => state.dashboard.dashboard.dataSources);

  const options = useMemo(() => {
    if (disabled && !dataSourceID) {
      return [];
    }
    const dashboardDataSource = dashboardDataSources.find((dashboardDataSource) => dashboardDataSource.dataSourceID === dataSourceID);
    const dataSource = dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceID);
    return prepareLabelsAutocompleteOptions(dataSource, dashboardDataSource.ordered, translationUsed, weightedMetricsUsed, SPECIAL_OPTIONS);
  }, [dataSourceID, dataSources, dashboardDataSources, translationUsed, weightedMetricsUsed, disabled]);

  const sortedItems = useMemo(() => {
    const hasHierarchy = options.some((option) => option.parent !== null);
    return options
      .filter((item) => (hasHierarchy ? item.parent !== null : item.parent === null) && !item.isSpecial)
      .sort((itemA, itemB) => itemB.value - itemA.value)
      .map((item) => item.name);
  }, [options]);

  const selectedItems = useMemo(() => {
    if (!value && enableEmpty) {
      return [];
    }
    if (!value || value.length === 0 || options.length === 1) {
      return disabled ? [] : [TRENDS_ITEM_ALL_KEY];
    }
    return value;
  }, [value, options, enableEmpty, disabled]);

  const handleOnChange = (value) => {
    const selectedCustomItem = selectedItems.find((item) => SPECIAL_ITEM_KEYS.includes(item));
    if (value.length === 0 || (value.includes(TRENDS_ITEM_ALL_KEY) && selectedCustomItem !== TRENDS_ITEM_ALL_KEY)) {
      onChange([]);
      return;
    }
    if (value.includes(TRENDS_ITEM_TOP_5_KEY)) {
      const top5Slice = sortedItems.slice(0, 5);
      onChange(top5Slice);
      return;
    }
    if (value.includes(TRENDS_ITEM_TOP_10_KEY)) {
      const top10Slice = sortedItems.slice(0, 10);
      onChange(top10Slice);
      return;
    }

    const valueWithoutCustomItems = value.filter((item) => item !== selectedCustomItem);
    onChange(valueWithoutCustomItems);
  };

  return (
    <LabelsAutocompleteControlContainer
      options={options}
      value={selectedItems}
      handleOnChange={handleOnChange}
      testID='labels-autocomplete'
      fullWidth
      disabled={disabled}
    />
  );
};

export default WidgetLabelsAutocompleteControl;
