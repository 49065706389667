import styled, { keyframes } from 'styled-components';
import { ReactComponent as ProgressActivityIcon } from '../../assets/icons/progress-activity-icon.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

export const ProgressIndicator = styled(ProgressActivityIcon)<{ $color?: string }>`
  height: 18px;
  width: 18px;
  fill: ${({ $color }) => ($color ? $color : 'var(--black)')};
  animation-name: ${spin};
  animation-duration: 1s;
  animation-iteration-count: infinite;
`;
