import styled, { keyframes } from 'styled-components';
import { ReactComponent as DownIcon } from '../../../assets/icons/down-icon.svg';

import { ReactComponent as ExpandIcon } from '../../../assets/icons/expand-icon.svg';
import { ReactComponent as GenaiIcon } from '../../../assets/icons/genai-icon.svg';

export const BREAKING_SCREEN_SIZE = 1439;
export const MAX_TAB_HEIGHT = 593;
export const Container = styled.div<{
  $isExpanded: boolean;
}>`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  max-height: ${MAX_TAB_HEIGHT}px;
  height: auto;
  right: 16px;
  left: 80px;
  overflow: hidden;
  background-color: var(--beehive-purple-transparent-30);
  border-radius: var(--double-border-radius) var(--double-border-radius) 0 0;
  box-shadow: 0 -8px 16px 2px rgba(15, 12, 12, 0.08);
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  width: calc(100% - 32px);
  position: absolute;
  left: 16px;
  top: 16px;
`;
export const Title = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  @media screen and (max-width: ${BREAKING_SCREEN_SIZE}px) {
    display: block;
    text-overflow: ellipsis;
    overflow: hidden;
    width: calc(11vw);
    height: 1.2rem;
    white-space: nowrap;
  }
`;

export const GenaiIconStyled = styled(GenaiIcon)`
  @media screen and (max-width: ${BREAKING_SCREEN_SIZE}px) {
    vertical-align: text-top;
    margin-right: 8px;
  }
`;

export const MainElementsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 799px;
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;
  height: 100%;
  gap: 16px;
`;
export const ExpandIconStyled = styled(ExpandIcon)<{
  $isExpanded: boolean;
}>`
  cursor: pointer;
  transform: rotateZ(${({ $isExpanded }) => ($isExpanded ? '0deg' : '180deg')});
`;
export const QueriesListContainer = styled.div<{
  $isExpanded: boolean;
}>`
  height: 88%;
  width: 100%;
  overflow: hidden;
  position: relative;
  display: ${({ $isExpanded }) => ($isExpanded ? 'block' : 'none')};
  background-color: var(--beehive-purple-transparent-30);
`;
export const smoothAppear = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
export const Backdrop = styled.div<{
  $isVisible: boolean;
}>`
  display: ${({ $isVisible }) => ($isVisible ? 'block' : 'none')};
  position: absolute;
  width: 100%;
  height: 63px;
  animation-name: ${smoothAppear};
  animation-duration: 0.5s;
  background: linear-gradient(180deg, var(--beehive-purple-transparent-30) 0%, rgba(218, 210, 255, 0) 100%);
`;
export const InputBar = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`;
export const Statement = styled.div`
  color: var(--dark-purple);
  text-align: center;
  font-family: Rubik;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-left: 25px;
`;
export const DownIconStyled = styled(DownIcon)`
  fill: var(--dark-purple);
`;
export const DownButton = styled.div<{ $isVisible: boolean }>`
  width: 35px;
  height: 35px;
  position: absolute;
  left: calc(50% + 30px);
  bottom: -10px;
  transform: translate(-50%, -50%);
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 50%;
  border: 1px solid var(--dark-purple);
  cursor: pointer;
`;
