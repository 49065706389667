import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import styled from 'styled-components';
import FileUploadMappingContainer from './FileUploadMappingContainer';

export const Container = styled.div`
  width: 1376px;
  height: auto;
  display: flex;
  position: relative;
`;

interface IFileUploadMappingModal {
  onDialogClose: () => void;
  onMappingFinish: (fileNames: string[]) => void;
}

function FileUploadMappingModal({ onDialogClose, onMappingFinish }: IFileUploadMappingModal) {
  return (
    <Dialog maxWidth={false} open={true}>
      <Container>
        <FileUploadMappingContainer onCloseClick={onDialogClose} onMappingFinish={onMappingFinish} />
      </Container>
    </Dialog>
  );
}

export default FileUploadMappingModal;
