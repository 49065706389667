import { combineReducers, Reducer } from 'redux';
import { AppReducer, IAppReducer } from './app';
import { dashboardQueriesReducer, IDashboardQueriesReducer } from './dashboardQueries/reducer';
import mappingReducer, { IMappingReducer } from './fileMapping/reducer';
import overviewReducer, { IOverviewReducer } from './overviewReducer';
import dashboardFilter, { IDashboardFilterReducer } from './dashboardFilter';
import settingsReducer, { ISettingsReducer } from './settingsReducer';
import profileReducer, { IProfileReducer } from './profile';
import dashboardsReducer, { IDashboardsReducer } from './dashboards/reducer';
import dashboardReducer, { IDashboardReducer } from './dashboard/reducer';
import aiInsightsReducer, { IAIInsightsReducer } from './aiInsights';
import { compareReducer } from '../../features/compare';
import connectorsReducer, { IConnectorsReducer } from './connectors/reducer';
import { brandsReducer, IBrandsReducer } from './brands';
import { ILanguagesReducer, languagesReducer } from './languages';
import { azureReducer, IAzureReducer } from './azure';
import { ICompareState } from '../../features/compare/store/reducer';
import { IQualtricsReducer, qualtricsReducer } from './qualtrics';
import OpenEndedWidgetReducer, { IOpenEndedWidgetsReducer } from './openEndedWidget';
import interviewWidgetsReducer, { IInterviewWidgetsReducer } from './interviewWidget/reducer';

export interface IApplicationState {
  app: IAppReducer;
  overview: IOverviewReducer;
  filter: IDashboardFilterReducer;
  settings: ISettingsReducer;
  profile: IProfileReducer;
  dashboards: IDashboardsReducer;
  dashboard: IDashboardReducer;
  compare: ICompareState;
  connectors: IConnectorsReducer;
  brands: IBrandsReducer;
  languages: ILanguagesReducer;
  azure: IAzureReducer;
  qualtrics: IQualtricsReducer;
  aiInsights: IAIInsightsReducer;
  openEndedWidgets: IOpenEndedWidgetsReducer;
  interviewWidgets: IInterviewWidgetsReducer;
  dashboardQueries: IDashboardQueriesReducer;
  fileMapping: IMappingReducer;
}
const rootReducer: Reducer<IApplicationState> = combineReducers<IApplicationState>({
  app: AppReducer,
  overview: overviewReducer,
  filter: dashboardFilter,
  settings: settingsReducer,
  profile: profileReducer,
  dashboards: dashboardsReducer,
  dashboard: dashboardReducer,
  compare: compareReducer,
  connectors: connectorsReducer,
  brands: brandsReducer,
  languages: languagesReducer,
  azure: azureReducer,
  qualtrics: qualtricsReducer,
  aiInsights: aiInsightsReducer,
  openEndedWidgets: OpenEndedWidgetReducer,
  interviewWidgets: interviewWidgetsReducer,
  dashboardQueries: dashboardQueriesReducer,
  fileMapping: mappingReducer,
});

export default rootReducer;
