export const EVENTS = {
  // Base
  API_SUCCESS_REQUEST: 'API Success Request',
  API_FAILED_REQUEST: 'API Failed Request',
  // Company Select & Header
  INVITE_USER: 'Invite User',
  CREATE_INVITATION: 'Create Invitation',
  // Company Hives
  VIEW_COMPANY_DASHBOARDS: 'View Company Dashboards',
  CREATE_NEW_PROGRAM: 'Create New Program',
  DASHBOARD_SELECT: 'Dashboard Select',
  DASHBOARD_VISIT: 'Dashboard Visit',
  // Dashboard Header
  SEGMENTS_TAB: 'Segments Tab',
  WEIGHTED_METRICS: 'Weighted Metrics',
  // Results Tab Header
  FILTER_APPLY: 'Filter Apply',
  FILTER_SAVE_CONFIRM: 'Filter Save Confirm',
  SAVED_SEGMENTS_APPLY: '[Compare Segments] Saved Segments Apply',
  SAVED_SEGMENTS_EDIT_SAVE: '[Compare Segments] Saved Segments Edit Save',
  ADD_NEW_WIDGET_SAVE: 'Add New Widget Save',
  ADD_MULTIPLE_WIDGETS_SAVE: 'Add Multiple Widgets Save',
  WIDGET_LABEL_SELECT: 'Widget Label Select',
  WIDGET_FILTER_CREATE: 'Widget Filter Create',
  WIDGET_FILTER_APPLY: 'Widget Filter Apply',
  WIDGET_DUPLICATE: 'Widget Duplicate',
  WIDGET_RESPONSES_SORT: 'Widget Responses Sort',
  WIDGET_RESPONSES_SORT_TOGGLE: 'Widget Responses Sort Toggle',
  WIDGET_RESPONSES_SEARCH: 'Widget Responses Search',
  WIDGET_RESPONSES_FILTER_SAVED: 'Widget Response Filter Saved',
  WIDGET_LABEL_PARENT_EXPAND: 'Widget Label Parent Expand',
  // Control panel
  SELECT_DATA_SOURCE: 'Select Data Source',
  // Compare
  COMPARE_SEGMENTS_SELECT: '[Compare Segments] Compare Segments Select',
  COMPARED_SEGMENT_SELECT: '[Compare Segments] Compared Segment Select',
  COMPARED_ALL_SELECT: '[Compare Segments] ALL Select',
  COMPARED_TABLE_SORT: '[Compare Segments] Table Sort',
  COMPARED_TABLE_PAGINATE: '[Compare Segments] Paginate Compare Segments Table',
  COMPARED_TABLE_THRESHOLDS_SELECT: '[Compare Segments] Set Thresholds Select',
  COMPARED_TABLE_THRESHOLDS_APPLY: '[Compare Segments] Set Thresholds Apply',
  SELECT_DATA_SOURCES_SELECT: '[Compare Segments] SELECT DATA SOURCES Select',
  SELECT_DATA_SOURCES_TYPE_SELECT: '[Compare Segments] SELECT DATA SOURCES Type select',
  SELECT_DATA_SOURCES_APPLY: '[Compare Segments] SELECT DATA SOURCES Apply',
  SELECT_DATA_SOURCES_SEARCH: '[Compare Segments] SELECT DATA SOURCES Search',
  WATCH_TUTORIAL_SELECT: '[Compare Segments] Watch Tutorial Select',
  SAVE_IMAGE: '[Compare Segments] Save Image',
  COMPARE_SEGMENTS_CHANGE_PAGE_VIEW: '[Compare Segments] Compare Segments Change Page View',
  VIEW_SELECTED_BUTTON_SELECT: '[Compare Segments] View Selected Button Select',
  EXPORT_TABLE: '[Compare Segments] Export Table',
  SEGMENT_DROPDOWN_FILTER_APPLIED: '[Compare Segments] Segment Dropdown Filter Applied',

  /// P2
  HIVE_FILTER: 'Hive Filter',
  HIVE_NEW_CREATE: 'Hive New Create',
  HIVE_NEW_SAVE: 'Hive New Save',
  HIVE_NEW_CANCEL: 'Hive New Cancel',
  HIVE_EDIT_VIEW: 'Hive Edit View',
  HIVE_EDIT_SAVE: 'Hive Edit Save',
  HIVE_EDIT_CANCEL: 'Hive Edit Cancel',
  HIVE_EDIT_DELETE: 'Hive Edit Delete',
  ///
  TOGGLE_TRANSLATION: 'Toggle Translation',
  OPEN_CONTROL_PANEL: 'Open Control Panel',
  ///
  FILTERS_EXPAND: 'Filters Expand',
  FILTER_SELECT_PROPERTY: 'Filter Select Property',
  FILTER_ADD_PROPERTY_GROUP: 'Filter Add Property Group',
  SAVED_SEGMENTS_VIEW: '[Compare Segments] Saved Segments View',
  SAVED_SEGMENTS_EDIT: '[Compare Segments] Saved Segments Edit',
  SAVED_SEGMENTS_DUPLICATE: '[Compare Segments] Saved Segments Duplicate',
  SAVED_SEGMENTS_CLEAR: '[Compare Segments] Saved Segments Clear',
  SAVED_SEGMENTS_CREATE_NEW: '[Compare Segments] Saved Segments Create New',
  ADD_NEW_WIDGET: 'Add New Widget',
  ADD_MULTIPLE_WIDGETS: 'Add Multiple Widgets',
  ADD_NEW_GROUP: 'Add New Group',
  BREAKDOWN_DASHBOARD: 'Breakdown Dashboard',
  DASHBOARD_RESULTS_EXPORT: 'Dashboard Results Export',
  DASHBOARD_RESULTS_SHARE: 'Dashboard Results Share',
  RESPONSES_TAB_SELECT: 'Responses Tab Select',
  TRENDS_TAB_SELECT: 'Trends Tab Select',
  COMPARE_TAB_SELECT: '[Compare Segments] Compare Tab Select',
  COMPARE_PERFORMANCE: '[Compare Segments] Compare Performance',
  ///
  WIDGET_FILTER_CANCEL: 'Widget Filter Cancel',
  WIDGET_FILTER_RESET: 'Widget Filter Reset',
  WIDGET_EXPORT: 'Widget Export',
  WIDGET_DASHBOARD_FILTERS_TOGGLE: 'Widget Dashboard Filters Toggle',
  ///
  SEGMENTS_RESULTS: 'Segments Results',
  //
  COMPARED_ROWS_PER_PAGE_SELECT: '[Compare Segments] Rows Per Page Select',
  COMPARED_TABLE_THRESHOLDS_RESET: '[Compare Segments] Set Thresholds Reset',
  SELECT_DATA_SOURCES_RESET: '[Compare Segments] SELECT DATA SOURCES Reset',
  TUTORIAL_BANNER_CLOSE: '[Compare Segments] Tutorial Banner close',

  //// P3
  MY_ACCOUNT: 'My Account',
  SELECT_COMPANY: 'Select Company',
  SEARCH_COMPANY: 'Search Company',
  LOGOUT: 'Logout',
  ///
  DASHBOARD_DATE_FILTER: 'Dashboard Date Filter',
  DASHBOARD_SORT: 'Dashboard Sort',
  RENAME_DASHBOARD: 'Rename Dashboard',
  TOGGLE_COLOR_PALETTE: 'Toggle Color Palette',
  ///
  FILTER_RESET: 'Filter Reset',
  FILTER_SAVE: 'Filter Save',
  FILTER_SAVE_CANCEL: 'Filter Save Cancel',
  FILTER_CLOSE: 'Filter Close',
  FILTER_TOGGLE_OPERATOR: 'Filter Toggle Operator',
  SAVED_SEGMENTS_EDIT_CANCEL: '[Compare Segments] Saved Segments Edit Cancel',
  SAVED_SEGMENTS_CLOSE: '[Compare Segments] Saved Segments Close',
  ADD_NEW_WIDGET_CANCEL: 'Add New Widget Cancel',
  ADD_MULTIPLE_WIDGETS_CANCEL: 'Add Multiple Widgets Cancel',
  DASHBOARD_TOTAL_RESPONSES_COLLAPSE: 'Dashboard Total Responses Collapse',
  DASHBOARD_TOTAL_RESPONSES_EXPAND: 'Dashboard Total Responses Expand',
  DASHBOARD_TOTAL_RESPONSES_VIEW_CHANGE: 'Dashboard Total Responses View Change',
  ///
  GROUP_TOGGLE: 'Group Toggle',
  WIDGET_TOGGLE: 'Widget Toggle',
  WIDGET_MAX_MIN: 'Widget Max Min',
  WIDGET_REMOVE: 'Widget Remove',
  WIDGET_CHANGE_TYPE: 'Widget Change Type',
  WIDGET_EDIT: 'Widget Edit',
  WIDGET_EDIT_SAVE: 'Widget Edit Save',
  OVERVIEW_EDIT_SAVE: 'Overview Edit Save',
  WIDGET_EDIT_CANCEL: 'Widget Edit Cancel',
  WIDGET_CHANGE_DATA_VIEW: 'Widget Change Data View',
  WIDGET_TRENDS_VIEW_TOGGLE: 'Widget Trends View Toggle',
  WIDGET_DRAG_OUTSIDE: 'Widget Drag Outside',
  ///
  TRENDS_QUESTION_CHANGE: 'Trends Question Change',
  TRENDS_ANSWER_CHANGE: 'Trends Answer Change',
  TRENDS_MOVING_AVERAGE_TOGGLE: 'Trends Moving Average Toggle',
  /// CONNECTIONS
  CONNECTIONS_TAB_SELECT: '[Connections] Connections Select',
  CONNECTIONS_APP_TAB_SELECT: '[Connections] Tab Select',
  CONNECTIONS_APP_AND_INTEGRATION_CARD_SELECT: '[Connections] App & Integration card select',
  CONNECTIONS_ADD_CONNECTION_SELECT: '[Connections] Add Connection Select',
  CONNECTIONS_AUTHENTICATION_SUCCESSFUL: '[Connections] Authentication Successful',
  CONNECTIONS_DELETE_AUTHENTICATION: '[Connections] Authentication delete',
  CONNECTIONS_KEBAB_SELECT: '[Connections] App & Integration Kebab Select',
  CONNECTIONS_KEBAB_DELETE_AUTHENTICATION: '[Connections] App & Integration Kebab - Delete Authentication',
  CONNECTIONS_KEBAB_EDIT_AUTHENTICATION: '[Connections] App & Integration Kebab - Edit Authentication',
  CONNECTIONS_DELETE_CONNECTION: '[Connections] Connection delete',
  CONNECTIONS_DELETE_CONNECTION_FLOW: '[Connections] Connection delete flow',
  CONNECTIONS_UPDATE_CONNECTION: '[Connections] Connection update',
  CONNECTIONS_EDIT_CONNECTION_INLINE: '[Connections] Edit Connection Inline',
  CONNECTIONS_CONNECTION_ESTABLISHED: '[Connections] Connection Established',
  CONNECTIONS_CSV_UPLOADS_ENRICH: '[Connections] CSV Uploads Enrich',
  CONNECTIONS_CSV_UPLOADS_HELP_PAGE_SELECT: '[Connections] CSV Uploads Help Page Select',
  CONNECTIONS_CSV_UPLOADS_CSV_TEMPLATE_SELECT: '[Connections] CSV Uploads CSV Template Select',
  CONNECTIONS_CSV_UPLOADS_SELECT_A_FILE: '[Connections] CSV Uploads Select a File',
  CONNECTIONS_FETCH_CONNECTORS: '[Connections] Fetch connectors',
  DATA_UPLOAD: 'Data Upload',
  CONNECT_DATA_COLLECTION: 'Connect Data Collection',
  FiLE_UPLOAD_BUTTON_SELECT: 'File Upload button select',
  FILE_UPLOAD_POPUP_NEXT_BUTTON_SELECT: 'Upload pop-up Next button select',
  FILE_UPLOAD_SELECT_FILE_CLICK: 'File Upload Select File Click',
  FILE_UPLOAD_ERROR: 'File Upload Error',
  FILE_UPLOAD_SUCCESS: 'File Upload Success',
  FILE_UPLOAD_SUCCESSFUL_CLOSE_POPUP: 'Upload Successful Close pop-up',
  FILE_MAPPING_SELECT_TEMPLATE: 'Upload Mapping Select a mapping template',
  FILE_MAPPING_TEMPLATE_LOADED: 'Upload Mapping Template Loaded',
  FILE_MAPPING_ANSWERS_START_FROM_SELECT: 'Upload Mapping Answers Start From select',
  FILE_MAPPING_DATE_FORMAT_SELECT: 'Upload Mapping Date Format select',
  FILE_MAPPING_COLUMN_DATA_TYPE_SELECT: 'Upload Mapping Column Data Type select',
  FILE_MAPPING_EXCLUDE_COLUMN_SELECT: 'Upload Mapping Exclude Column select',
  FILE_MAPPING_INCLUDE_COLUMN_SELECT: 'Upload Mapping Include Column select',
  FILE_MAPPING_HAVE_A_BEEHIVE_EXPERT_MAP: 'Upload Mapping Have a Beehive Expert Map Your Files',
  FILE_MAPPING_NEXT_BUTTON_SELECT: 'Upload Mapping Next button select',
  FILE_MAPPING_SUBMIT_BUTTON_SELECT: 'Upload Mapping Submit button select',
  FILE_MAPPING_CANCEL: 'Upload Mapping Cancel',
  /// AI Insights
  GEN_AI_BANNER_OPEN: "[Gen AI] 'Try Experimental AI Insights' Select",
  GEN_AI_BANNER_CLOSE: "[Gen AI] 'Try Experimental AI Insights' Hide Select",
  GEN_AI_TAB_OPEN: '[Gen AI] AI Insights Tab Select',
  GEN_AI_LEARN_MORE: '[Gen AI] Learn More Select',
  GEN_AI_GENERATE: '[Gen AI] Generate',
  GEN_AI_STOP_GENERATING: '[Gen AI] Stop Generating Select',
  GEN_AI_CLEAR_ALL: '[Gen AI] Clear All Select',
  GEN_AI_COPY_GENERATED_INSIGHTS: '[Gen AI] Copy generated insights Select',
  GEN_AI_LIKE_GENERATED_INSIGHTS: '[Gen AI] Like generated insights Select',
  GEN_AI_DISLIKE_GENERATED_INSIGHTS: '[Gen AI] Dislike generated insights Select',
  GEN_AI_USER_FEEDBACK: '[Gen AI] User Feedback',
  SIGN_UP_COMPLETED: 'Sign-Up Flow Completed',
  /// AI Agent
  AI_AGENT_QUERY_CHAT_SUBMIT: 'AI Agent Query Chat Submit',
  AI_AGENT_RESPONSE_LOADED: 'AI Agent Response Loaded',
  AI_AGENT_CONNECTION_LOST: 'AI Agent Connection Lost',
  AI_AGENT_ERROR_REQUEST_CANNOT_BE_PROCESSED: 'AI Agent Error Request Cannot be Processed',
};

export const EVENT_PROPERTY_DASHBOARD_NAME = 'Dashboard Name';
export const EVENT_PROPERTY_DASHBOARD_ID = 'Dashboard ID';
export const EVENT_PROPERTY_COMPANY_NAME = 'Company';
export const EVENT_PROPERTY_USER_ID = 'User ID';
