import Dialog from '@material-ui/core/Dialog';
import React from 'react';

import { ReactComponent as ControlPanelIcon } from '../../../../assets/icons/control-panel-icon.svg';
import { ReactComponent as FileUploadIcon } from '../../../../assets/icons/file-upload-icon.svg';
import backgroundImage from '../../../../assets/images/upload-in-dashboard-background.svg';
import { FileUploadButton } from '../../../../components/DashboardToolbar/DashboardToolbar';
import FileUploadFlow from '../../../../components/FileUploadFlow/FileUploadFlowContainer';
import { FilledButton } from '../../../../components/UI/Button';

import {
  Background,
  Container,
  Footer,
  FooterLeft,
  FooterRight,
  Header,
  LeftPart,
  ReturnToHivesButton,
  RightPart,
  StatusContainer,
  StatusContent,
  StatusHeader,
  SubTitle,
  SupportLink,
  Title,
} from './styles';

interface IUploadInDashboard {
  dashboardName: string;
  dashboardID: string;
  isCloseable: boolean;
  onAddMoreDataClick: () => void;
  onReturnToHivesClick: () => void;
  onExploreMyDataClick: () => void;
  onCloseClick: () => void;
  onGetSupportClick: () => void;
}

function UploadInDashboard({
  dashboardID,
  dashboardName,
  isCloseable,
  onAddMoreDataClick,
  onReturnToHivesClick,
  onExploreMyDataClick,
  onGetSupportClick,
  onCloseClick,
}: IUploadInDashboard) {
  function handleDialogClose(event?: object, reason?: string) {
    if (reason === 'backdropClick') {
      return;
    }

    onCloseClick();
  }

  return (
    <Dialog maxWidth={false} open={true} onClose={handleDialogClose}>
      <Container>
        <Background src={backgroundImage} />
        <Header>
          <LeftPart>
            <Title>{dashboardName}</Title>
            <SubTitle>
              Stay up-to-date on the status of your Analysis. Need assistance? Reach out to{' '}
              <SupportLink onClick={onGetSupportClick}>Beehive AI support</SupportLink> for more info!
            </SubTitle>
          </LeftPart>
          <RightPart />
        </Header>
        <StatusContainer>
          <StatusHeader>
            <ControlPanelIcon />
            Status
          </StatusHeader>
          <StatusContent>
            {dashboardName} is being analyzed and verified for accuracy and quality. You will be notified when results are ready.
          </StatusContent>
        </StatusContainer>
        <Footer>
          <FooterLeft>
            <FileUploadFlow
              isNewDashboard={false}
              dashboardName={dashboardName}
              dashboardID={dashboardID}
              fileUploadButtonTitle='Add More Data'
              onClose={handleDialogClose}
            />
          </FooterLeft>
          <FooterRight>
            <ReturnToHivesButton onClick={onReturnToHivesClick}>Return to Hives</ReturnToHivesButton>
            <FilledButton disabled={!isCloseable} onClick={onExploreMyDataClick}>
              Explore my data
            </FilledButton>
          </FooterRight>
        </Footer>
      </Container>
    </Dialog>
  );
}

export default UploadInDashboard;
