import { ButtonGroup, Fade } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import styled from 'styled-components';

import SavedFilterTooltip from './SavedFilterTooltip';
import { HtmlTooltip } from './SavedFilters';
import { SavedFilterButton } from '../UI/Button';

const ButtonBase = styled(SavedFilterButton)`
  &&& {
    ${(props) =>
      props.invalid &&
      `
            color: #f00;
            background-color: lightpink;
        `}
  }
`;

export const FilterButton = styled(ButtonBase)`
  &&& {
    max-width: 300px;
    &:last-child {
      border-bottom-right-radius: 0;
      border-top-right-radius: 0;
    }

    ${(props) => props.invalid && 'pointer-events: none;'}
    .MuiButton-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
`;

export const PlusButton = styled(ButtonBase)`
  &&& {
    max-width: 300px;

    ${(props) => props.invalid && 'pointer-events: none;'}
    .MuiButton-label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
    }
  }
`;

const Wrapper = styled.span``;
const MenuButton = styled(ButtonBase)`
  &&& {
    min-width: 0;
    padding: 0;
  }
`;

const SavedFilter = ({ savedFilter, onFilterClick, onMenuClick, selected }) => {
  return (
    <ButtonGroup variant='contained' size='small' disableElevation>
      <HtmlTooltip disableInteractive title={<SavedFilterTooltip savedFilter={savedFilter} />} TransitionComponent={Fade}>
        <Wrapper>
          <FilterButton onClick={onFilterClick} selected={selected} invalid={savedFilter.valid ? undefined : 1}>
            {savedFilter.name}
          </FilterButton>
        </Wrapper>
      </HtmlTooltip>
      <MenuButton onClick={onMenuClick} selected={selected} invalid={savedFilter.valid ? undefined : 1}>
        <ArrowDropDownIcon />
      </MenuButton>
    </ButtonGroup>
  );
};

export default SavedFilter;
