import React, { useEffect, useRef, useState } from 'react';
import palette from '../../../tools/palette';
import { TextOverflowEllipsis } from '../../../UI';
import { ReactComponent as EditIcon } from '../../../../assets/icons/edit-icon.svg';

import { ConceptPillContainer, ChildLabel, LabelCloseIcon, ParentLabel, Roundedlabel, EditTopicIconContainer } from './style';
import { isElementTruncated } from '../../../../utils/helpers';

function getTooltipProps(isTruncated: boolean, text: string) {
  if (!isTruncated) {
    return {};
  }

  return {
    'data-tooltip-id': 'base-tooltip',
    'data-tooltip-content': text,
  };
}

function useTooltip(ref: HTMLDivElement | HTMLSpanElement | null, text: string) {
  const [tooltipProps, setTooltipProps] = useState({});
  const [entered, setEntered] = useState(false);

  // isElementTruncated on mouse enter/leave or ref changes
  useEffect(() => {
    const isTruncated = isElementTruncated(ref);
    setTooltipProps(getTooltipProps(isTruncated, text));
  }, [ref?.offsetWidth, ref?.scrollWidth, entered]);

  function handleOnMouseEnter() {
    setEntered(true);
  }

  function handleOnMouseLeave() {
    setEntered(false);
  }

  return { tooltipProps, handleOnMouseEnter, handleOnMouseLeave };
}

interface IConceptPill {
  text: string;
  isSelected: boolean;
  isClickable: boolean;
  isDisabled: boolean;
  tooltipContent?: string;
  isParent?: boolean;
  className?: string;
  isHierarchy?: boolean;
  onClick: () => void;
}
export function ConceptPill({
  text,
  isSelected,
  isClickable,
  isDisabled,
  isParent = false,
  isHierarchy = true,
  className = '',
  onClick,
}: IConceptPill) {
  if (isClickable) {
    return (
      <ConceptPillClickable
        text={text}
        selected={isSelected}
        disabled={isDisabled}
        isParent={isParent}
        className={className}
        isHierarchy={isHierarchy}
        onClick={onClick}
      />
    );
  }
  return <ConceptPillNonClickable text={text} />;
}

interface IAnswerItemText {
  text: string;
}
export function ConceptPillNonClickable({ text }: IAnswerItemText) {
  const el = useRef<HTMLDivElement>(null);
  const { tooltipProps, handleOnMouseEnter, handleOnMouseLeave } = useTooltip(el.current, text);

  return (
    <ConceptPillContainer
      data-testid='concept-pill-text'
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
      {...tooltipProps}
    >
      <TextOverflowEllipsis ref={el}>{text}</TextOverflowEllipsis>
    </ConceptPillContainer>
  );
}

interface IConceptPillClickable {
  text: string;
  selected: boolean;
  disabled: boolean;
  isParent: boolean;
  isHierarchy: boolean;
  className: string;
  tooltipContent?: string;
  onClick: () => void;
}
export function ConceptPillClickable({
  text,
  onClick,
  selected,
  disabled,
  isParent,
  isHierarchy,
  className,
  tooltipContent,
}: IConceptPillClickable) {
  const el = useRef<HTMLDivElement>(null);
  const { tooltipProps, handleOnMouseEnter, handleOnMouseLeave } = useTooltip(el.current, tooltipContent ?? text);

  return (
    <ConceptPillContainer
      className={`label-button-wrapper ${className}`}
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
      {...tooltipProps}
    >
      {renderLabel()}
    </ConceptPillContainer>
  );

  function renderLabel() {
    const Component = isParent ? ParentLabel : ChildLabel;

    return (
      <Component
        data-testid='concept-pill-button'
        $isDisabled={disabled}
        $isSelected={selected}
        $isHierarchy={isHierarchy}
        onClick={onClick}
      >
        <TextOverflowEllipsis ref={el}>{text}</TextOverflowEllipsis>
      </Component>
    );
  }
}

interface IAnswerEditButton {
  className?: string;
  onClick: () => void;
}
export const AnswerEditButton = React.forwardRef<HTMLDivElement, IAnswerEditButton>(({ className, onClick }, ref) => {
  return (
    <ConceptPillContainer ref={ref} data-testid='answer-item-button-container' className={`label-button-wrapper ${className}`}>
      <Roundedlabel data-testid='answer-item-button' $isDisabled={false} $isSelected={false} onClick={onClick}>
        <EditIcon />
      </Roundedlabel>
    </ConceptPillContainer>
  );
});

interface ITopicEditButton {
  className?: string;
  onClick: () => void;
}
export const TopicEditButton = React.forwardRef<HTMLDivElement, ITopicEditButton>(({ className, onClick }, ref) => {
  return (
    <ConceptPillContainer ref={ref} data-testid='answer-item-button-container' className={`label-button-wrapper ${className}`}>
      <EditTopicIconContainer data-testid='answer-item-button' onClick={onClick}>
        <EditIcon />
      </EditTopicIconContainer>
    </ConceptPillContainer>
  );
});

interface IAnswerRemovableItem {
  text: string;
  disabled: boolean;
  onClick: () => void;
}

export function AnswerRemovableItem({ text, disabled, onClick }: IAnswerRemovableItem) {
  const el = useRef<HTMLDivElement>(null);
  const { tooltipProps, handleOnMouseEnter, handleOnMouseLeave } = useTooltip(el.current, text);

  return (
    <ConceptPillContainer
      data-testid='answer-item-button-container'
      className='label-button-wrapper'
      onMouseLeave={handleOnMouseLeave}
      onMouseEnter={handleOnMouseEnter}
      {...tooltipProps}
    >
      <ChildLabel data-testid='answer-item-button' $isDisabled={disabled} $isSelected={false}>
        <TextOverflowEllipsis ref={el}>{text}</TextOverflowEllipsis>
        <LabelCloseIcon fill={palette.beehivePurpleLight10} onClick={onClick} />
      </ChildLabel>
    </ConceptPillContainer>
  );
}
