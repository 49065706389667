import { useState } from 'react';
import Fade from '@material-ui/core/Fade';
import SvgIcon from '@material-ui/core/SvgIcon';
import styled from 'styled-components';
import capitalize from 'lodash/capitalize';

import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import palette from '../tools/palette';
import SavedFilterTooltip from '../SavedFilters/SavedFilterTooltip';
import { HtmlTooltip } from '../SavedFilters/SavedFilters';
import WidgetFilterDialog from './WidgetFilterDialog';
import { countProperties } from '../FilterForm/lib';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import { WIDGET_TYPE } from '../../constants';

const FilterAltOutlined = (props) => (
  <SvgIcon {...props}>
    <path d='M7 6h10l-5.01 6.3L7 6zm-2.75-.39C6.27 8.2 10 13 10 13v6c0 .55.45 1 1 1h2c.55 0 1-.45 1-1v-6s3.72-4.8 5.74-7.39c.51-.66.04-1.61-.79-1.61H5.04c-.83 0-1.3.95-.79 1.61z' />
  </SvgIcon>
);

const MenuFilterIcon = styled(FilterAltOutlined)`
  vertical-align: bottom;
  color: ${(props) => (props.$highlighted ? '#fff' : '#000')};
`;

const MenuFilterIconWrapper = styled.span`
  white-space: nowrap;
  display: inline-block;
  cursor: pointer;
  color: ${(props) => (props.$highlighted ? '#fff' : '#000')};
  background-color: ${({ $highlighted, $invalid }) => ($highlighted ? ($invalid ? 'lightpink' : palette.primaryColorLightHover) : '#fff')};
  border-radius: 2px;
`;

function WidgetFilterMenuIcon({ onClick, query, invalid = false }) {
  const highlighted = !!query;
  const tooltip = invalid ? (
    'Applied segment is invalid. Please select another saved segment or edit the existing one.'
  ) : (
    <SavedFilterTooltip savedFilter={{ query, valid: true }} />
  );
  return (
    <HtmlTooltip disableHoverListener={!query} title={tooltip} TransitionComponent={Fade}>
      <MenuFilterIconWrapper $highlighted={highlighted} onClick={onClick} $invalid={invalid}>
        <MenuFilterIcon $highlighted={highlighted} $invalid={invalid} data-testid='widget-filter-menu' />
      </MenuFilterIconWrapper>
    </HtmlTooltip>
  );
}

function WidgetFilterMenu({ widgetType, widgetTitle, widgetFilter, onFilterApply, groupTitle }) {
  const { savedFilters } = useSavedFilters();
  const [showWidgetFilterDialog, setShowWidgetFilterDialog] = useState(false);

  let invalid = false;
  let query = null;
  if (widgetFilter) {
    const savedFilter = savedFilters.find((savedFilter) => savedFilter.id === widgetFilter.filterID);
    if (widgetFilter.filterID && savedFilter) {
      query = savedFilter.query;
      invalid = !savedFilter.valid;
    } else if (widgetFilter.query) {
      query = widgetFilter.query;
      invalid = !widgetFilter.valid;
    }
  }

  function handleIconClick() {
    eventsTracker.track(EVENTS.WIDGET_FILTER_CREATE, {
      'Widget Name': widgetTitle,
      'Group Name': groupTitle,
      'Widget Type': widgetType !== WIDGET_TYPE.OPEN_ENDED ? capitalize(widgetType) : 'Open-ended',
    });
    setShowWidgetFilterDialog(true);
  }

  function handleFilterApply(filterID, query) {
    let propertyCount = 0;
    if (filterID) {
      const savedFilter = savedFilters.find((savedFilter) => savedFilter.id === filterID);
      propertyCount = countProperties(savedFilter.query);
    } else if (query) {
      propertyCount = countProperties(query);
    } else {
      eventsTracker.track(EVENTS.WIDGET_FILTER_RESET, {
        'Group Name': groupTitle,
        'Widget Name': widgetTitle,
      });
    }

    if (propertyCount > 0) {
      eventsTracker.track(EVENTS.WIDGET_FILTER_APPLY, {
        'Group Name': groupTitle,
        'Widget Name': widgetTitle,
        'Filter Origin': filterID ? 'Saved' : 'Customized',
        'Property Count': propertyCount,
      });
    }
    setShowWidgetFilterDialog(false);
    onFilterApply({ filterID, query });
  }

  function handleFilterCancel() {
    eventsTracker.track(EVENTS.WIDGET_FILTER_CANCEL, {
      'Group Name': groupTitle,
      'Widget Name': widgetTitle,
    });
    setShowWidgetFilterDialog(false);
  }

  return (
    <>
      <WidgetFilterMenuIcon onClick={handleIconClick} query={query} invalid={invalid} />
      {showWidgetFilterDialog && (
        <WidgetFilterDialog filter={widgetFilter} query={query} onApply={handleFilterApply} onCancel={handleFilterCancel} />
      )}
    </>
  );
}

export default WidgetFilterMenu;
