import { ListItem, ListItemIcon } from '@material-ui/core';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import styled from 'styled-components';

import palette from '../../components/tools/palette';

export const HivesListItem = styled(ListItem)`
  &&.Mui-selected,
  &&.Mui-selected:hover {
    color: ${palette.primaryColorLight};
    background-color: transparent;
  }

  &&:hover {
    color: ${palette.primaryColorLightHover};
    background-color: transparent;
  }
  .edit {
    display: none;
  }
  .edit:hover {
    fill: ${palette.primaryColorLight};
  }
  &:hover {
    .visibility {
      display: none;
    }
    .edit {
      display: block;
    }
  }
`;

const MenuListItemIcon = styled(ListItemIcon)`
  &.MuiListItemIcon-root {
    min-width: 30px;
  }
`;

interface IHivesSidebar {
  hives: any[];
  selectedHiveId: string | null;
  onClick: (hiveID: string) => void;
  onEditClick: (hive: any) => void;
}

const HivesSidebar = ({ hives, selectedHiveId, onClick, onEditClick }: IHivesSidebar) => {
  return (
    <List style={{ marginRight: '20px' }}>
      {hives.map((hive) => (
        <HivesListItem key={hive.id} selected={selectedHiveId === hive.id} button onClick={() => onClick(hive.id)}>
          <MenuListItemIcon onClick={onEditClick.bind(null, hive)}>
            {hive.private && <HttpsOutlinedIcon fontSize='small' className='visibility' />}
            {hive.id && <CreateIcon data-tooltip-id='base-tooltip' data-tooltip-content='Edit hive' className='edit' />}
          </MenuListItemIcon>
          <Typography noWrap data-tooltip-id='base-tooltip' data-tooltip-content={hive.title}>
            {hive.title}
          </Typography>
        </HivesListItem>
      ))}
      <HivesListItem button onClick={onEditClick.bind(null, null)} style={{ color: palette.linkColorLight2 }}>
        <MenuListItemIcon>
          <AddIcon htmlColor={palette.linkColorLight2} />
        </MenuListItemIcon>
        <Typography noWrap>Create hive</Typography>
      </HivesListItem>
    </List>
  );
};

export default HivesSidebar;
