export const UNKNOWN_TYPE = 'unknown';
export const UNIQUE_ID_TYPE = 'unique_id';
export const CREATED_DATE_TYPE = 'created_date';
export const DATE_TYPE = 'date';
export const GENERAL_MAPPING_VALUES = [
  { title: 'Customer ID', value: UNIQUE_ID_TYPE },
  {
    title: 'Record Date',
    value: CREATED_DATE_TYPE,
  },
  { title: 'Date', value: DATE_TYPE },
  {
    title: 'Multi Select',
    value: 'multi_choice',
  },
  { title: 'Single Select', value: 'single_choice' },
  { title: 'Free Text', value: 'open_ended' },
  // TODO: Uncomment or remove when will be working on Interviews support
  // {
  //   title: 'Speaker',
  //   value: 'speaker',
  // },
  // { title: 'Comment', value: 'comment' },
  // { title: 'Time', value: 'time' },
  { title: 'Other', value: 'other' },
];
export const UNIQUE_COLUMNS_TYPES_PER_FILE = [UNIQUE_ID_TYPE, CREATED_DATE_TYPE];
export const DATE_CONTAIN_COLUMNS_TYPES = [CREATED_DATE_TYPE, DATE_TYPE];
export const DEFAULT_SOURCE_NAME = 'ai-generated-mapping';
export const MAX_NUMBER_OF_ROWS = 5;
export const CUSTOM_DATE_FORMAT = 'custom';
export const DATE_FORMATS = [
  {
    title: 'Custom date format',
    value: CUSTOM_DATE_FORMAT,
  },
  {
    title: 'N/A',
    value: 'N/A',
  },
  {
    title: 'MM-DD-YYYY',
    value: 'MM-DD-YYYY',
  },
  {
    title: 'MM/DD/YYYY',
    value: 'MM/DD/YYYY',
  },
  {
    title: 'MM.DD.YYYY',
    value: 'MM.DD.YYYY',
  },
  {
    title: 'MM-DD-YY',
    value: 'MM-DD-YY',
  },
  {
    title: 'MM/DD/YY',
    value: 'MM/DD/YY',
  },
  {
    title: 'M-D-YYYY',
    value: 'M-D-YYYY',
  },
  {
    title: 'M/D/YYYY',
    value: 'M/D/YYYY',
  },
  {
    title: 'M/D/YYYY H:mm',
    value: 'M/D/YYYY H:mm',
  },
  {
    title: 'M-D-YY',
    value: 'M-D-YY',
  },
  {
    title: 'M/D/YY',
    value: 'M/D/YY',
  },
  {
    title: 'Month DD, YYYY',
    value: 'Month DD, YYYY',
  },
  {
    title: 'DD Month YYYY',
    value: 'DD Month YYYY',
  },
  {
    title: 'DD-MM-YYYY',
    value: 'DD-MM-YYYY',
  },
  {
    title: 'DD/MM/YYYY',
    value: 'DD/MM/YYYY',
  },
  {
    title: 'DD-MM-YY',
    value: 'DD-MM-YY',
  },
  {
    title: 'DD/MM/YY',
    value: 'DD/MM/YY',
  },
  {
    title: 'DD.MM.YYYY',
    value: 'DD.MM.YYYY',
  },
  {
    title: 'MM-DD-YYYY HH:mm:ss',
    value: 'MM-DD-YYYY HH:mm:ss',
  },
  {
    title: 'MM/DD/YYYY HH:mm:ss',
    value: 'MM/DD/YYYY HH:mm:ss',
  },
  {
    title: 'DD-MM-YYYY HH:mm:ss',
    value: 'DD-MM-YYYY HH:mm:ss',
  },
  {
    title: 'DD/MM/YYYY HH:mm:ss',
    value: 'DD/MM/YYYY HH:mm:ss',
  },
  {
    title: 'YYYY-MM-DD',
    value: 'YYYY-MM-DD',
  },
  {
    title: 'YYYY-MM-DD HH:mm:ss',
    value: 'YYYY-MM-DD HH:mm:ss',
  },
  {
    title: 'YYYY-MM-DDTHH:mm:ssZ',
    value: 'YYYY-MM-DDTHH:mm:ssZ',
  },
  {
    title: 'YYYY-MM-DDTHH:mm:ss.sssZ',
    value: 'YYYY-MM-DDTHH:mm:ss.sssZ',
  },
];

export const MEDALLIA_SOURCE = 'medallia';
export const SURVEY_MONKEY_SOURCE = 'survey_monkey';
export const TYPEFORM_SOURCE = 'typeform';
export const QUALTRICS_SOURCE = 'qualtrics';
export const AI_GENERATED_SOURCE = 'ai-generated-mapping';
export const USER_TESTING_SOURCE = 'user_testing';
