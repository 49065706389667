import { debounce, isEqual } from 'lodash';
import { MutableRefObject, Ref, SetStateAction, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { FETCH_STATUS_TYPE } from '../../../../constants';
import { useSavedFilters } from '../../../../contexts/SavedFiltersContext';
import useSelectedFilterIdsToggle from '../../../../hooks/useSelectedFilterIdsToggle';
import { generateInsightsFGRequest, initializeInsightsForWidget } from '../../../../store/actions/aiInsights';
import { dashboardWidgetReloadResponsesSuccess } from '../../../../store/actions/dashboard';
import { initialize, selectConcept, updateWidgetQueries } from '../../../../store/actions/interviewWidget';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { Concept } from '../../../../store/reducers/aiInsights';
import {
  CurrentDashboardDataSource,
  CurrentDashboardInfo,
  DashboardSentimentSummariesSentiment,
  DataSourceRegeneratingStatus,
} from '../../../../store/reducers/dashboard/types';
import { stopGeneratingInsights } from '../../../../store/thunks/interviewAIInsights';
import { loadResponses } from '../../../../store/thunks/interviewWidget';
import { Config } from '../../../../types/Config';
import { WidgetDataSource } from '../../../../types/DataSource';
import { Query } from '../../../../types/Query';
import { SortingQuery } from '../../../../types/SortingQuery';
import { ResponseListItem, useDataBySelectedSavedFilterId } from '../../../overview/lib';
import { getAppliedFiltersCount } from '../../../UI/lib';
import { TOPICS_SORT_FIELDS } from '../../utils/types';
import { WidgetTotalsContext } from '../../WidgetTotalsContext';

import { useDataFilters, useLabels } from './dataHooks';
import InterviewWidgetData from './InterviewWidgetData';
import { convertInsightsFiltersToQuery, convertResponsesFiltersToQuery } from './utils/filterQueries';

export type CurrentDashboardDataSourceInfo = {
  sentimentSummary: DashboardSentimentSummariesSentiment;
  usersNum: number;
} & CurrentDashboardDataSource;

interface IInterviewWidgetDataContainer {
  currentDashboardDataSourceInfo: CurrentDashboardDataSourceInfo;
  overviewAPIOutput: ResponseListItem[];
  config: Config;
  onExpand: () => void;
  onFeedbackClick: (title: { title: string }) => void;
  refCsvExport: Ref<() => void>;
  onResetAllFilters: () => void;
  refFiltersCount: MutableRefObject<number>;
  selectedItems: string[];
  query: Query;
  loading: FETCH_STATUS_TYPE;
  isPreview: boolean;
  refContent?: MutableRefObject<HTMLDivElement> | null;
  onLabelSelectionTrack: (label: string, isParent: boolean, count: number) => void;
}

function InterviewWidgetDataContainer({
  currentDashboardDataSourceInfo,
  overviewAPIOutput,
  config,
  onExpand,
  onFeedbackClick,
  // TODO do we need?
  refCsvExport,
  refContent,
  onResetAllFilters,
  refFiltersCount,
  selectedItems,
  query: currentWidgetFilter,
  loading,
  onLabelSelectionTrack,
  isPreview,
}: IInterviewWidgetDataContainer) {
  const widgetID = config.base.id as string;
  const dispatch = useAppDispatch();
  const { filters, filtersById } = useDataFilters(isPreview);
  const { savedFiltersById } = useSavedFilters();
  const [selectedFilterIds, setSelectedFilterIds] = useSelectedFilterIdsToggle(filters);
  const overviewDataById = useDataBySelectedSavedFilterId(overviewAPIOutput, filters);
  const { loading: totalsLoading, totalsByDataSourcesAndFiltersIds } = useContext(WidgetTotalsContext);
  const { hasSentiment, dataSources: dashboardDataSources } = useAppSelector((state) => state.dashboard.dashboard) as CurrentDashboardInfo;
  const { translationUsed, weightedMetricsUsed } = useAppSelector((state) => state.app);

  const dataSourceID = currentDashboardDataSourceInfo.dataSourceID;

  const currentDashboardFilter = useAppSelector((state) => state.filter.query);
  const dataSources = useAppSelector((state) => state.dashboard.dataSources);
  const regenerationState = useAppSelector((state) => state.dashboard.regeneratingStatesByDataSourceID[dataSourceID]);
  const shouldResponsesBeReloaded = useAppSelector((state) => state.dashboard.shouldBeReloadedByWidgetID[widgetID]);

  const widgetConfig = useAppSelector((state) => state.interviewWidgets.configsByWidgetID[widgetID]);
  const responsesState = useAppSelector((state) => state.interviewWidgets.conversationsStatesByWidgetID[widgetID]);
  const insightsState = useAppSelector((state) => state.aiInsights.insightsByWidgetID).find((insights) => insights.widgetID === widgetID);

  const [areInitialInsightsBeingGenerated, setAreInitialInsightsBeingGenerated] = useState(!insightsState?.insights?.length);
  const [prevInsightsQuery, setPrevInsightsQuery] = useState(widgetConfig?.insightsQuery);

  const [topicsSorting, setTopicsSorting] = useState<SortingQuery>({
    sortBy: TOPICS_SORT_FIELDS.FREQUENCY,
    desc: false,
  });
  const [selectedCustomerIds, setSelectedCustomerIds] = useState<string[]>([]);

  const generateInsightsDebounce = useCallback(
    debounce((params) => {
      dispatch(generateInsightsFGRequest(params));
    }, 1000),
    [],
  );

  const loadResponsesDebounce = useCallback(
    debounce((params) => {
      dispatch(loadResponses(params));
    }, 1000),
    [],
  );

  refFiltersCount.current = selectedFilterIds.length;

  const hasResponses = responsesState?.responsesTotal > 0 || currentDashboardDataSourceInfo.usersNum > 0;
  const showResponses = config.settings.showResponses as boolean;
  const average = config.settings.merge && getAppliedFiltersCount(filters) > 0;

  const dataSource = useMemo(() => {
    const dataSource = dataSources.find((ds) => ds.dataSourceID === dataSourceID);
    const dataSourceInfo = dashboardDataSources.find((ds) => ds.dataSourceID === dataSourceID);
    const valueType = weightedMetricsUsed ? 'weighted' : 'base';
    const items = dataSourceInfo?.isEmpty
      ? []
      : dataSource?.items
          .map((item) => ({
            ...item,
            displayResponse: translationUsed ? item.response : item.originalResponse,
          }))
          .sort((a, b) => (dataSourceInfo?.ordered ? 1 : b[valueType].value - a[valueType].value));

    return {
      ...dataSourceInfo,
      ...dataSource,
      items,
    } as WidgetDataSource;
  }, [currentDashboardDataSourceInfo, dataSources, dashboardDataSources, weightedMetricsUsed, translationUsed]);

  useEffect(() => {
    if (!widgetConfig) {
      dispatch(
        initialize({
          widgetID,
          dataSourceID,
        }),
      );

      // TODO initialize FG insights
      dispatch(
        initializeInsightsForWidget({
          widgetID,
          selectedConcept: undefined,
          dataSource,
          filteredConcepts: selectedItems,
        }),
      );
    }

    return () => {
      dispatch(stopGeneratingInsights({ widgetID }));
      loadResponsesDebounce.cancel();
      generateInsightsDebounce.cancel();
    };
  }, []);

  // TOPICS
  const { labels } = useLabels(overviewDataById, dataSource, filtersById, selectedFilterIds, selectedItems, topicsSorting);

  useEffect(() => {
    if (!widgetConfig) {
      return;
    }
    const responsesQuery = convertResponsesFiltersToQuery(
      dataSourceID,
      selectedFilterIds,
      currentDashboardFilter,
      savedFiltersById,
      responsesState,
      widgetConfig,
      selectedItems,
      selectedCustomerIds,
      currentWidgetFilter,
    );

    const insightsQuery = convertInsightsFiltersToQuery(
      dataSourceID,
      selectedFilterIds,
      currentDashboardFilter,
      savedFiltersById,
      responsesState,
      widgetConfig,
      selectedItems,
      selectedCustomerIds,
      currentWidgetFilter,
    );
    if (!isEqual(widgetConfig.query, responsesQuery) && !isEqual(widgetConfig.insightsQuery, insightsQuery)) {
      dispatch(updateWidgetQueries({ widgetID, insightsQuery, responsesQuery }));
    }
  }, [
    selectedFilterIds,
    currentDashboardFilter,
    currentWidgetFilter,
    dataSourceID,
    selectedItems,
    responsesState?.searchString,
    savedFiltersById,
    widgetConfig?.selectedConcept,
    selectedCustomerIds,
  ]);

  const appliedSegmentsNames = useMemo(() => {
    return selectedFilterIds.map((filterID) => filters.find((filter) => filterID === filter.id)?.name || '') ?? [];
  }, [selectedFilterIds, filters]);

  // LOAD RESPONSES WITH FILTER
  useEffect(() => {
    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: 0,
    });
  }, [widgetConfig?.query]);

  // RELOAD RESPONSES
  useEffect(() => {
    if (!shouldResponsesBeReloaded) {
      return;
    }

    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: 0,
    });
    dispatch(dashboardWidgetReloadResponsesSuccess({ widgetID }));
  }, [shouldResponsesBeReloaded]);

  // LOAD INSIGHTS WITH FILTER
  useEffect(() => {
    if (!areInitialInsightsBeingGenerated && !isEqual(prevInsightsQuery, widgetConfig?.insightsQuery)) {
      setPrevInsightsQuery(widgetConfig?.insightsQuery);
      generateInsightsDebounce({
        widgetID,
        dashboardID: config.base.dashboardID,
        dataSourceID: dataSource.dataSourceID,
        insightsQuery: widgetConfig?.insightsQuery,
        responsesQuery: widgetConfig?.query,
        selectedConcept: widgetConfig?.selectedConcept ?? undefined,
        availableFilters: filters.map((f) => f.name),
        isTryAgain: false,
        segmentsNames: appliedSegmentsNames,
      });
    }
  }, [widgetConfig?.insightsQuery]);

  // HANDLE OPERATIONS
  const handleLabelClick = useCallback(
    (title: string | null, withScroll: boolean) => {
      if (title) {
        const item = dataSource?.items?.find((item) => item.response === title);
        if (item) {
          onLabelSelectionTrack(title, !!item?.children, item[weightedMetricsUsed ? 'weighted' : 'base'].value);
        }
      }
      dispatch(
        selectConcept({
          title,
          widgetID,
          options: {
            withScroll,
          },
        }),
      );
    },
    [dataSource, onLabelSelectionTrack, weightedMetricsUsed],
  );

  const handleFilterButtonChange = useCallback(
    (ids: SetStateAction<string[]>) => {
      (setSelectedFilterIds as React.Dispatch<React.SetStateAction<string[]>>)(ids);
    },
    [setSelectedFilterIds],
  );

  const handleGenerateInsightsClick = (selectedConcept?: Concept, isTryAgain?: boolean, insightID?: string) => {
    if (selectedConcept?.title && !isTryAgain) {
      dispatch(
        selectConcept({
          title: selectedConcept.title,
          widgetID,
          options: {
            withScroll: true,
          },
        }),
      );
    } else {
      setAreInitialInsightsBeingGenerated(false);
      generateInsightsDebounce({
        dashboardID: config.base.dashboardID,
        widgetID,
        dataSourceID: dataSource.dataSourceID,
        selectedConcept,
        isTryAgain,
        insightsQuery: widgetConfig?.insightsQuery,
        availableFilters: filters.map((f) => f.name),
        responsesQuery: widgetConfig?.query,
        segmentsNames: appliedSegmentsNames,
        insightID,
      });
    }
  };

  function handleResponsesLoad() {
    if (responsesState.isLoading) {
      return;
    }

    loadResponsesDebounce({
      widgetID,
      filterQuery: widgetConfig?.query,
      offset: responsesState?.offset ?? 0,
    });
  }

  function handleTopicsSortingChange(sorting: SortingQuery) {
    setTopicsSorting(sorting);
  }

  if (!widgetConfig?.dataSourceID) {
    return null;
  }

  // TODO: do we need this?
  // (refCsvExport as MutableRefObject<Function>).current = () => {
  //   const titleField = translationUsed ? 'title' : 'originalTitle';
  //   const title = dataSource[titleField];
  //   const timestampString = getTimestampString();
  //   const filename = `${title} - ${timestampString}`;
  //   const labelToParent = dataSource?.items?.filter(item => item.children)
  //     .reduce((labelToParent, item) => {
  //       const relations = item.children?.reduce((acc, child) => ({
  //         ...acc,
  //         [child]: item.displayResponse,
  //       }), {});
  //       return { ...labelToParent, ...relations };
  //     }, {} as {
  //       [key: string]: string
  //     });
  //   if (isBreakdownView) {
  //     const breakdownDataSource = dataSources.find(dataSource => dataSource.dataSourceID === breakdownFilter?.dataSourceID);
  //     const breakdownItems = breakdownFilter?.values.map(value => breakdownDataSource?.items.find(item => item.response === value));
  //     let breakdownData = dashboardDataSources?.find(dashboardDataSource => dashboardDataSource.dataSourceID === breakdownFilter?.dataSourceID);
  //     let breakdownTitle = '';
  //     if (breakdownData) {
  //       breakdownTitle = breakdownData[titleField];
  //     }
  //     const filename = `${title} (breakdown by ${breakdownTitle}) - ${timestampString}`;
  //     exportOpenEndedBreakdown(breakdownData, filters, selectedFilterIds, breakdownItems, dataSource, translationUsed, labelToParent, filename);
  //   } else {
  //     exportOpenEndedPlain(overviewDataById, dataSource, filters, selectedFilterIds, filtersById, filename, labelToParent);
  //   }
  // };

  return (
    <InterviewWidgetData
      analyzedAnswers={currentDashboardDataSourceInfo.analyzed}
      areFiltersEmpty={currentDashboardDataSourceInfo.isEmpty}
      average={average}
      config={config}
      dataSource={dataSource}
      dataSourceID={dataSourceID}
      widgetID={widgetID}
      filters={filters}
      filtersById={filtersById}
      hasResponses={hasResponses}
      hasSentiment={hasSentiment}
      isPreview={isPreview}
      isRegenerating={regenerationState?.status === DataSourceRegeneratingStatus.PendingRegenerating}
      labels={labels}
      loading={loading}
      responsesCount={responsesState?.responsesTotal}
      responsesLoading={responsesState?.isLoading}
      selectedFilterIds={selectedFilterIds}
      selectedItems={selectedItems}
      selectedLabelItem={widgetConfig?.selectedConcept}
      sentimentSummary={currentDashboardDataSourceInfo.sentimentSummary}
      showResponses={showResponses}
      totalAnswers={currentDashboardDataSourceInfo.total}
      totalsByDataSourcesAndFiltersIds={totalsByDataSourcesAndFiltersIds}
      totalsLoading={totalsLoading}
      totalWeightedAnswers={dataSource.weightedTotal}
      duration={dataSource.duration ?? 0}
      topicsSorting={topicsSorting}
      onExpand={onExpand}
      onFeedbackClick={onFeedbackClick}
      onFilterButtonChange={handleFilterButtonChange}
      onLabelClick={handleLabelClick}
      onLoadMoreResponses={handleResponsesLoad}
      onRefContent={refContent}
      onWidgetFiltersClearClick={onResetAllFilters}
      onGenerateInsightsClick={handleGenerateInsightsClick}
      onTopicsSortingChange={handleTopicsSortingChange}
      onCustomersFilterChange={setSelectedCustomerIds}
    />
  );
}

export default InterviewWidgetDataContainer;
