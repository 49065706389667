import { useContext } from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import styled from 'styled-components';

import { numberWithCommas } from '../tools';
import { WidgetTotalsContext } from './WidgetTotalsContext';
import { FILTER_ID_ALL } from '../../constants';

const AccountIconContainer = styled('span')`
  font-size: 14px;
  padding-right: 2px;
`;

const AccountIcon = styled(AccountCircleIcon)`
  vertical-align: bottom;
`;

const WidgetTotal = ({ config }) => {
  const { totalsByDataSourcesAndFiltersIds } = useContext(WidgetTotalsContext);
  const dataSourceID = config.settings.dataSources[0].id;
  return totalsByDataSourcesAndFiltersIds[dataSourceID] ? (
    <span>
      <AccountIconContainer>
        <AccountIcon fontSize='inherit' />
      </AccountIconContainer>
      {numberWithCommas(totalsByDataSourcesAndFiltersIds[dataSourceID][FILTER_ID_ALL].base)}
    </span>
  ) : null;
};

export default WidgetTotal;
