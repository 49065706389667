import { createContext, useEffect, useState } from 'react';

import { TESTS_URL_PARAMS } from '../constants';

export const TestsContext = createContext(null);

function getParams(search) {
  const searchParams = new URLSearchParams(search);

  const isUnsafe = searchParams.has(TESTS_URL_PARAMS.UNSAFE) && searchParams.get(TESTS_URL_PARAMS.UNSAFE) === 'true';
  const isDebug = searchParams.has(TESTS_URL_PARAMS.DEBUG) && searchParams.get(TESTS_URL_PARAMS.DEBUG) === 'true';
  const validation = searchParams.has(TESTS_URL_PARAMS.VALIDATION) && searchParams.get(TESTS_URL_PARAMS.VALIDATION) === 'true';
  const isChatAI = searchParams.has(TESTS_URL_PARAMS.CHAT_AI) && searchParams.get(TESTS_URL_PARAMS.CHAT_AI) === 'true';

  return {
    isUnsafe,
    isDebug,
    validation,
    isChatAI,
  };
}

export const TestsProvider = ({ children }) => {
  const params = getParams(window.location.search);

  const [isUnsafe, setIsUnsafe] = useState(params.isUnsafe);
  const [isDebug, setIsDebug] = useState(params.isDebug);
  const [validation, setValidation] = useState(params.validation);
  const [isChatAI, setIsChatAI] = useState(params.isChatAI);

  useEffect(() => {
    const rs = window.history.replaceState;
    window.history.replaceState = function () {
      rs.apply(window.history, arguments); // preserve normal functionality
      const params = getParams(window.location.search);
      if (params.isUnsafe) {
        setIsUnsafe(true);
      }
      if (params.isDebug) {
        setIsDebug(true);
      }
      if (params.validation) {
        setValidation(true);
      }
      if (params.isChatAI) {
        setIsChatAI(true);
      }
    };
    return () => {
      window.history.replaceState = rs;
    };
  }, []);

  const value = {
    isUnsafe,
    isDebug,
    validation,
    isChatAI,
  };

  return <TestsContext.Provider value={value}>{children}</TestsContext.Provider>;
};
