import { TESTS_URL_PARAMS } from '../../constants';

export const numberWithCommas = (x) => {
  if (x === null) {
    return x;
  }
  return x.toLocaleString('en-US');
};

export const getTestsLinks = () => {
  let unsafe = window.location.href;
  let debug = window.location.href;
  let validation = window.location.href;
  let chatAI = window.location.href;
  if (window.location.search) {
    if (!window.location.search.includes(`${TESTS_URL_PARAMS.UNSAFE}=true`)) {
      unsafe += `&${TESTS_URL_PARAMS.UNSAFE}=true`;
    }
    if (!window.location.search.includes(`${TESTS_URL_PARAMS.DEBUG}=true`)) {
      debug += `&${TESTS_URL_PARAMS.DEBUG}=true`;
    }
    if (!window.location.search.includes(`${TESTS_URL_PARAMS.VALIDATION}=true`)) {
      validation += `&${TESTS_URL_PARAMS.VALIDATION}=true`;
    }
    if (!window.location.search.includes(`${TESTS_URL_PARAMS.CHAT_AI}=true`)) {
      chatAI += `&${TESTS_URL_PARAMS.CHAT_AI}=true`;
    }
  } else {
    unsafe += `?${TESTS_URL_PARAMS.UNSAFE}=true`;
    debug += `?${TESTS_URL_PARAMS.DEBUG}=true`;
    validation += `?${TESTS_URL_PARAMS.VALIDATION}=true`;
    chatAI += `?${TESTS_URL_PARAMS.CHAT_AI}=true`;
  }

  return { unsafe, debug, validation, chatAI };
};

export const withValidation = () => {
  return window.location.search && window.location.search.includes(`${TESTS_URL_PARAMS.VALIDATION}=true`);
};

export const withChatAI = () => {
  return !!window.location.search && window.location.search.includes(`${TESTS_URL_PARAMS.CHAT_AI}=true`);
};

export const escapeQuery = (query) => query.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
