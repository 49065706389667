import { chartColors, NUMBER_OF_DISTINCT_COLORS } from '../../../UI/lib';
import { AVERAGE_POSTFIX } from '../../../../constants';
import { VALUE_FIELD } from '../lib';

export const VIEW = {
  BOTH: 'BOTH',
  AVERAGE: 'AVERAGE',
  ORIGINAL: 'ORIGINAL',
};

export function getAverageName(name) {
  return `${name}${AVERAGE_POSTFIX}`;
}

export function prepareData(trends, isPercentage, view, windowSize, showFilters = false, showBreakdown = false) {
  const valueField = isPercentage ? VALUE_FIELD.PERCENTAGE : VALUE_FIELD.VALUE;
  const series = [];
  const legendData = [];

  const transformers = [];
  const source = [];

  let index = 1;
  let previousTitle = null;
  const showAverages = view !== VIEW.ORIGINAL && !!windowSize;
  for (let trend of trends) {
    const title = trend.bucket === null ? trend.filterName : trend.bucket;
    const name = `${title}---${trend.color}---${trend.name}`;

    for (let i = 0; i < trend.data.length; i++) {
      source.push([
        trend.data[i].date,
        trend.data[i][valueField],
        trend.data[i].value,
        trend.data[i].percentage,
        trend.name,
        trend.filterName,
        trend.bucket,
        name,
      ]);
    }

    const colorIndex = ((index - 1) / 2) % NUMBER_OF_DISTINCT_COLORS;

    series.push({
      datasetIndex: index,
      id: name,
      type: 'line',
      lineStyle: {
        color: chartColors[colorIndex],
      },
      itemStyle: {
        color: chartColors[colorIndex],
      },
      name: name,
    });
    transformers.push({
      transform: {
        type: 'filter',
        config: {
          dimension: 'id',
          '=': view !== VIEW.AVERAGE ? name : '_',
        },
      },
    });

    const averageName = view === VIEW.AVERAGE ? name : getAverageName(name);

    series.push({
      datasetIndex: index + 1,
      type: 'line',
      lineStyle: {
        type: 'dotted',
        color: chartColors[colorIndex],
      },
      itemStyle: {
        color: chartColors[colorIndex],
      },
      name: averageName,
    });

    transformers.push({
      transform: [
        {
          type: 'filter',
          config: {
            dimension: 'id',
            '=': showAverages ? name : '_',
          },
        },
        {
          type: 'transformers:movingAverage',
          config: {
            windowSize,
            dimension: 'number',
          },
        },
      ],
    });

    if ((showFilters || showBreakdown) && previousTitle !== title) {
      // insert fake series to use it as a trends group in the chart legend
      // when group title is changing
      const groupName = `${title}---${trend.color}`;
      series.push({
        data: [],
        type: 'line',
        name: groupName,
      });
      legendData.push({
        name: groupName,
        icon: 'roundRect',
        itemStyle: {
          color: trend.color,
        },
        textStyle: {
          fontSize: 16,
          backgroundColor: 'white',
        },
      });
      previousTitle = title;
    }

    legendData.push({
      name,
    });
    index += 2;
  }

  return { source, transformers, series, legendData };
}
