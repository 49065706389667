import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { MappingState } from '../../../../../../store/reducers/fileMapping/types';
import { validateDateFormat } from '../../../../../../store/thunks/fileMapping';

import CustomDateFormat from './CustomDateFormat';
import { EVENTS, eventsTracker } from '../../../../../../services/EventTrackerService';

function CustomDateFormatContainer() {
  const { files, selectedIndex: currentFileNumber, isLoading: isLoadingState, templates } = useAppSelector((state) => state.fileMapping);
  const dispatch = useAppDispatch();
  const currentFile = files[currentFileNumber];
  const wasFormatValidated = currentFile?.isDateFormatValid === true || currentFile?.isDateFormatValid === false;

  const [isPristine, setIsPristine] = useState(true);
  const isLoading = !!isLoadingState[MappingState.VALIDATING_DATE_FORMAT];
  const [tempDateFormat, setTempDateFormat] = useState(currentFile?.dateFormat ?? '');

  useEffect(
    function updatePristineFlageBasedOnBEResponse() {
      if (currentFile && wasFormatValidated && currentFile.isDateFormatValid === true) {
        setIsPristine(true);
      }
    },
    [wasFormatValidated],
  );

  useEffect(
    function watchFileChanges() {
      if (!currentFile) {
        return;
      }

      if (currentFile.dateFormat !== tempDateFormat) {
        setTempDateFormat(currentFile.dateFormat ?? '');
      }
    },
    [currentFile?.dateFormat],
  );

  function handleDateFormatChange(newFormat: string) {
    if (isPristine && currentFile.dateFormat !== newFormat) {
      setIsPristine(false);
    }
    setTempDateFormat(newFormat);
  }

  function handleSaveButtonClick() {
    dispatch(
      validateDateFormat({
        dateFormat: tempDateFormat,
      }),
    );
    setIsPristine(false);
    const file = files[currentFileNumber];
    const template = templates.find((t) => t.id === file.templateID);
    eventsTracker.track(EVENTS.FILE_MAPPING_DATE_FORMAT_SELECT, {
      'Date Format selected': 'Custom Date Format',
      'Current Mapping Template': template?.displayName,
      'Custom date format text': tempDateFormat,
    });
  }

  return (
    <CustomDateFormat
      isFormatValid={currentFile.isDateFormatValid === true}
      wasFormatValidated={wasFormatValidated}
      isLoading={isLoading}
      isPristine={isPristine}
      dateFormat={tempDateFormat}
      onDateFormatChange={handleDateFormatChange}
      onSaveButtonClick={handleSaveButtonClick}
    />
  );
}

export default CustomDateFormatContainer;
