import { Action } from '../hooks';
import { feedbackSubmitError, feedbackSubmitStart, feedbackSubmitSuccess } from '../actions/aiInsights';
import { InsightType } from '../reducers/aiInsights';
import { getBrowserDetails, getOSName } from '../../utils/browserAndOSDetails';
import { ThumbStatus } from '../../components/Widgets/components/GeneratedInsights/GeneratedInsightsContainer';
import { postAIInsightsFeedback } from '../../components/tools/api';

interface ISubmitInsightsFeedback {
  feedback: string;
}

export const submitInsightsFeedback =
  ({ feedback }: ISubmitInsightsFeedback): Action =>
  async (dispatch, getState) => {
    dispatch(feedbackSubmitStart());
    const { insightsByWidgetID, feedbackModal } = getState().aiInsights;
    const { dashboard } = getState().dashboard;
    const { user } = getState().profile;
    if (!dashboard) {
      dispatch(feedbackSubmitError());
      return;
    }
    if (feedbackModal?.widgetID) {
      const insightsData = insightsByWidgetID.find((insights) => insights.widgetID === feedbackModal.widgetID);
      if (insightsData) {
        const insightsPlain = insightsData.insights
          .map((i) => {
            switch (i.type) {
              case InsightType.INTRO_PROMPT:
                return `Intro Prompt \nSuggested concepts: ${i.relatedConcepts?.map((rc) => rc.title).join(' , ')}`;
              case InsightType.SELECTED_CONCEPT:
                return `Selected Concept: ${i.concept?.title}`;
              case InsightType.AI_GENERATED:
                return `Generated Insights: ${i.concept?.title ? `For selected concept: ${i.concept?.title}` : ''}\nInsights:\n${i.generatedInsights?.map(({ insight }) => insight).join('\n')}\nRelated Concepts: ${i.relatedConcepts?.map((rc) => rc.title).join(',')}`;
              case InsightType.GENERATING:
                return `Generating: ${i.generationStatuses?.[i.generationStatuses?.length - 1]}`;
              default:
                return `Unknown status: ${JSON.stringify(i)}`;
            }
          })
          .join('\n');
        const insightForFeedback = insightsData.insights.find((i) => i.id === feedbackModal.relatedInsightID);
        const requestBody = {
          insightsThread: insightsPlain,
          userFeedback: feedback,
          timeToGenerate: insightForFeedback?.generationTime,
          modelName: insightForFeedback?.generationModel,
          tokensTotal: insightForFeedback?.tokensTotal,
          modelLocation: insightForFeedback?.generationModelLocation,
          numberOfResponsesUsed: insightForFeedback?.numberOfResponsesUsedForGeneration,
          browserOS: `${getOSName()} using ${getBrowserDetails()}`,
          dataSourceID: feedbackModal.dataSourceID,
          thumbStatus: feedbackModal.thumbStatus === ThumbStatus.UP ? 'Liked' : 'Disliked',
          messageID: feedbackModal.relatedInsightID,
          userName: user?.fullname,
          ...insightForFeedback?.modelParameters,
        };
        try {
          const resp = await postAIInsightsFeedback(dashboard.id, feedbackModal.widgetID, requestBody);
          if (resp.error) {
            dispatch(feedbackSubmitError());
          }
          dispatch(feedbackSubmitSuccess());
        } catch {
          dispatch(feedbackSubmitError());
        }
      }
    } else {
      dispatch(feedbackSubmitError());
    }
  };
