import html2canvas from 'html2canvas';
import capitalize from 'lodash/capitalize';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { FIXED_SIZE_WIDGETS, WIDGET_HEIGHT, WIDGET_TYPE } from '../../constants';
import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import { SavedFilterModel } from '../../models/Filter';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Widget } from '../../store/reducers/dashboard/types';
import { updateWidget } from '../../store/thunks/dashboard';
import { Config, Filter } from '../../types/Config';
import { Query } from '../../types/Query';
import { RGL_MARGIN_VALUE, RGL_ROW_HEIGHT } from '../overview/lib';
import { SAVED_FILTERS_URL_ARG_NAME } from '../SavedFilters/SavedFilters';

import { savePng } from '../tools/export';
import BaseWidget from './BaseWidget';
import { getHeight, getWidth } from './lib';

interface IBaseWidgetContainer {
  config: Config;
  SettingsComponent: React.ElementType;
  MenuComponent: React.ElementType;
  ContentComponent: React.ElementType;
  groupTitle: string;
  onDimensionsChange: (width: number, height: number, save?: boolean) => void;
}

function BaseWidgetContainer({
  config,
  SettingsComponent,
  MenuComponent,
  ContentComponent,
  groupTitle,
  onDimensionsChange,
}: IBaseWidgetContainer) {
  const dataSourceId = config.settings.dataSources[0].id;

  const history = useHistory();
  const { savedFilters, setSelectedSavedFilters } = useSavedFilters();
  const dispatch = useAppDispatch();
  const { translationUsed } = useAppSelector((state) => state.app);
  const dashboardDataSource = useAppSelector((state) =>
    state.dashboard.dashboard?.dataSources.find((dataSource) => dataSource.dataSourceID === dataSourceId),
  );

  if (!dashboardDataSource) {
    return null;
  }

  const [areSettingsVisible, setAreSettingsVisible] = useState(false);
  const refContent = useRef<HTMLElement>();
  const refCsvExport = useRef<() => void>();
  const refSettings = useRef<HTMLElement>();

  const title = translationUsed ? config.base.title : config.base.originalTitle;

  const isMaximizable =
    !FIXED_SIZE_WIDGETS.includes(config.base.type) &&
    !FIXED_SIZE_WIDGETS.map((x) => x.toLowerCase()).includes(dashboardDataSource?.type?.toLowerCase());
  const isUnfixable = !config.validation?.valid && !!config.validation?.errors?.missedDataSourceIds; // user cannot fix incorrect data source
  const width = getWidth(config.base.maximized);
  const height = getHeight(config.base.collapsed, config.base.expanded, config.base.type);

  const setQuery = useCallback(
    (name: string, value: string) => {
      const params = new URLSearchParams();
      if (name && value) {
        params.append(name, value);
      }
      history.push({ search: params.toString() });
    },
    [history],
  );

  const query: Query | null = useMemo(() => {
    if (config.base.filter?.query || config.base.filter?.filterID) {
      if (config.base.filter.query) {
        if (!config.base.filter.valid) {
          return null;
        }
        return config.base.filter.query;
      } else {
        const savedFilter = (savedFilters as SavedFilterModel[]).find((savedFilter) => savedFilter.id === config.base.filter.filterID);
        return savedFilter && savedFilter.valid ? savedFilter.query : null;
      }
    } else {
      return null;
    }
  }, [config, savedFilters]);

  useEffect(() => {
    if (!refSettings?.current || !areSettingsVisible) {
      return;
    }
    const observer = new ResizeObserver(([entry]) => {
      const customHeight = Math.ceil((entry.target.clientHeight + RGL_MARGIN_VALUE) / (RGL_ROW_HEIGHT + RGL_MARGIN_VALUE));
      onDimensionsChange(width, customHeight);
    });
    observer.observe(refSettings.current);

    return () => {
      observer.disconnect();
    };
  }, [refSettings.current, areSettingsVisible, width, onDimensionsChange]);

  function handleWidgetStateChange(expanded: boolean, collapsed: boolean, maximized: boolean) {
    if (!config.base.id) {
      console.error(`No config.base.id was provided for widget: ${config}`);
      return;
    }
    const updatedConfig = {
      ...config,
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      base: { ...config.base, expanded, collapsed, maximized, id: config.base.id! },
      settings: { ...config.settings, merge: !!config.settings.merge },
      validation: { ...config.validation },
    } as Widget;
    dispatch(updateWidget(updatedConfig));
  }

  function handleShowSettingsClick() {
    onDimensionsChange(width, WIDGET_HEIGHT.DEFAULT);
    setAreSettingsVisible(true);
  }

  function handleHideSettingsClick() {
    onDimensionsChange(width, height);
    setAreSettingsVisible(false);
  }

  function handleCollapseClick() {
    eventsTracker.track(EVENTS.WIDGET_TOGGLE, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      Visible: !config.base.collapsed,
    });
    handleWidgetStateChange(config.base.expanded, !config.base.collapsed, config.base.maximized);
    onDimensionsChange(width, getHeight(!config.base.collapsed, config.base.expanded, config.base.type), true);
  }

  function handleMaximizeClick() {
    eventsTracker.track(EVENTS.WIDGET_MAX_MIN, {
      'Group Name': groupTitle,
      'Widget Name': config.base.title,
      'Widget Type': config.base.type !== WIDGET_TYPE.OPEN_ENDED ? capitalize(config.base.type) : 'Open-ended',
      Maximized: !config.base.maximized,
    });
    handleWidgetStateChange(config.base.expanded, config.base.collapsed, !config.base.maximized);
    onDimensionsChange(getWidth(!config.base.maximized), height, true);
  }

  function handleExpand() {
    handleWidgetStateChange(!config.base.expanded, config.base.collapsed, config.base.maximized);
    onDimensionsChange(width, getHeight(config.base.collapsed, !config.base.expanded, config.base.type), true);
  }

  const handleUpdateAutoSizeHeight = useCallback(
    (calculatedHeight: number) => {
      onDimensionsChange(width, calculatedHeight);
    },
    [onDimensionsChange, width],
  );

  function resetWidgetHeight(type: string) {
    const lType = type?.toLowerCase();
    let height = WIDGET_HEIGHT.DEFAULT;
    switch (lType) {
      case WIDGET_TYPE.OPEN_ENDED.toLowerCase():
        height = WIDGET_HEIGHT.DEFAULT_OPEN_ENDED;
        break;
      case WIDGET_TYPE.INTERVIEW.toLowerCase():
        height = WIDGET_HEIGHT.DEFAULT_INTERVIEW;
        break;
      default:
        height = WIDGET_HEIGHT.DEFAULT;
    }
    onDimensionsChange(width, height);
  }

  function handleCsvClick() {
    if (!refCsvExport.current) {
      return;
    }
    refCsvExport.current();
  }

  async function handlePngClick() {
    if (!refContent.current) {
      return;
    }
    const canvas = await html2canvas(refContent.current);
    const image = canvas.toDataURL('image/png', 1.0);
    savePng(image, title);
  }

  function handleFilterApply(filter: Filter) {
    const updatedConfig = { ...config, base: { ...config.base, filter } } as Widget;
    dispatch(updateWidget(updatedConfig));
  }

  function handleResetAllFilters() {
    handleFilterApply({ filterID: undefined, query: undefined, valid: true });
    const filterIds: string[] = [];
    setQuery(SAVED_FILTERS_URL_ARG_NAME, filterIds.join(','));
    setSelectedSavedFilters(filterIds);
  }

  return (
    <BaseWidget
      isMaximizable={isMaximizable}
      isUnfixable={isUnfixable}
      areSettingsVisible={areSettingsVisible}
      config={config}
      groupTitle={groupTitle}
      query={query}
      refContent={refContent}
      refCsvExport={refCsvExport}
      refSettings={refSettings}
      SettingsComponent={SettingsComponent}
      MenuComponent={MenuComponent}
      ContentComponent={ContentComponent}
      onShowSettingsClick={handleShowSettingsClick}
      onHideSettingsClick={handleHideSettingsClick}
      onCollapseClick={handleCollapseClick}
      onMaximizeClick={handleMaximizeClick}
      onExpand={handleExpand}
      onAutoSizeHeightUpdate={handleUpdateAutoSizeHeight}
      onWidgetHeightReset={resetWidgetHeight}
      onCSVClick={handleCsvClick}
      onPNGClick={handlePngClick}
      onAllFiltersReset={handleResetAllFilters}
      onFilterApply={handleFilterApply}
    />
  );
}

export default BaseWidgetContainer;
