import BaseWidgetContainer from '../BaseWidgetContainer';
import WidgetSettings from './Settings/WidgetSettings';
import WidgetMenu from '../../WidgetMenu/WidgetMenu';
import InterviewWidgetContent from './InterviewWidgetContent';

const InterviewWidgetWidgetSettings = (props) => {
  return <WidgetSettings {...props} hideConfig={true} />;
};

const InterviewWidget = ({ config, height, onDimensionsChange, groupTitle }) => {
  return (
    <BaseWidgetContainer
      config={config}
      height={height}
      groupTitle={groupTitle}
      onDimensionsChange={onDimensionsChange}
      SettingsComponent={InterviewWidgetWidgetSettings}
      MenuComponent={WidgetMenu}
      ContentComponent={InterviewWidgetContent}
    />
  );
};

export default InterviewWidget;
