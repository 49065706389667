import { FETCH_STATUS } from '../../../constants';
import { DASHBOARD_UPDATE_PRIVACY, DASHBOARD_UPDATE_TITLE, updateDashboardPrivacy, updateDashboardTitle } from '../../actions/dashboard';
import {
  DASHBOARDS_INITIALIZE_FAIL,
  DASHBOARDS_INITIALIZE_SUCCESS,
  DASHBOARDS_RESET,
  DASHBOARDS_SET_ABORT_CONTROLLER,
  DASHBOARDS_SET_DASHBOARDS,
  DASHBOARDS_SET_DATE_RANGE,
  DASHBOARDS_SET_STATUS,
  DASHBOARDS_SET_VIEWS,
  DASHBOARDS_UPLOAD_MODAL_LOAD_DASHBOARDS_FAILURE,
  DASHBOARDS_UPLOAD_MODAL_LOAD_DASHBOARDS_START,
  DASHBOARDS_UPLOAD_MODAL_LOAD_DASHBOARDS_SUCCESS,
  DASHBOARDS_UPLOAD_MODAL_TOGGLE,
  dashboardsInitializeFail,
  dashboardsInitializeSuccess,
  dashboardsReset,
  dashboardsSetAbortController,
  dashboardsSetDashboards,
  dashboardsSetDateRange,
  dashboardsSetViews,
  dashboardsUploadModalLoadDashboardsFailure,
  dashboardsUploadModalLoadDashboardsStart,
  dashboardsUploadModalLoadDashboardsSuccess,
  dashboardsUploadModalToggle,
  setDashboardsStatus,
} from '../../actions/dashboards';
import { Dashboard, DashboardFilterDateRange, DashboardsUploadModal, View } from './types';

type dashboardActionTypes = ReturnType<typeof setDashboardsStatus> &
  ReturnType<typeof dashboardsInitializeSuccess> &
  ReturnType<typeof dashboardsInitializeFail> &
  ReturnType<typeof dashboardsSetViews> &
  ReturnType<typeof dashboardsSetDashboards> &
  ReturnType<typeof dashboardsSetDateRange> &
  ReturnType<typeof dashboardsReset> &
  ReturnType<typeof updateDashboardTitle> &
  ReturnType<typeof updateDashboardPrivacy> &
  ReturnType<typeof dashboardsSetAbortController> &
  ReturnType<typeof dashboardsUploadModalLoadDashboardsStart> &
  ReturnType<typeof dashboardsUploadModalLoadDashboardsSuccess> &
  ReturnType<typeof dashboardsUploadModalLoadDashboardsFailure> &
  ReturnType<typeof dashboardsUploadModalToggle>;

export interface IDashboardsReducer {
  initialized: boolean;
  dashboards: Dashboard[];
  views: View[];
  status: string;
  abortController: AbortController;
  dateRange: DashboardFilterDateRange | null;
  uploadModal: DashboardsUploadModal;
  error?: any;
}

const initialState: IDashboardsReducer = {
  initialized: false,
  error: null,
  dateRange: null,
  dashboards: [],
  views: [],
  status: FETCH_STATUS.IDLE,
  uploadModal: {
    isOpen: false,
    isLoading: false,
  },
  abortController: new AbortController(),
};

const dashboardsReducer = (state = initialState, action: dashboardActionTypes) => {
  switch (action.type) {
    case DASHBOARDS_INITIALIZE_SUCCESS:
      return handleDashboardInitializeSuccess(state, action);
    case DASHBOARDS_INITIALIZE_FAIL:
      return handleDashboardInitializeFail(state, action);
    case DASHBOARDS_RESET:
      return handleDashboardReset(state);
    case DASHBOARDS_SET_DASHBOARDS:
      return handleSetDashboards(state, action);
    case DASHBOARDS_SET_VIEWS:
      return handleSetViews(state, action);
    case DASHBOARDS_SET_DATE_RANGE:
      return handleSetDateRange(state, action);
    case DASHBOARD_UPDATE_TITLE:
      return handleUpdateTitle(state, action);
    case DASHBOARD_UPDATE_PRIVACY:
      return handleUpdatePrivacy(state, action);
    case DASHBOARDS_SET_STATUS:
      return handleSetStatus(state, action);
    case DASHBOARDS_SET_ABORT_CONTROLLER:
      return setAbortController(state, action);
    case DASHBOARDS_UPLOAD_MODAL_TOGGLE:
      return handleUploadModalToggle(state);
    case DASHBOARDS_UPLOAD_MODAL_LOAD_DASHBOARDS_START:
      return handleUploadModalLoadDashboardsStart(state);
    case DASHBOARDS_UPLOAD_MODAL_LOAD_DASHBOARDS_SUCCESS:
      return handleUploadModalLoadDashboardsSuccess(state, action);
    case DASHBOARDS_UPLOAD_MODAL_LOAD_DASHBOARDS_FAILURE:
      return handleUploadModalLoadDashboardsFailure(state, action);
    default:
      return state;
  }
};

function handleDashboardInitializeSuccess(
  state: IDashboardsReducer,
  action: ReturnType<typeof dashboardsInitializeSuccess>,
): IDashboardsReducer {
  return {
    ...state,
    error: null,
    initialized: true,
    dashboards: action.payload.dashboards,
    views: action.payload.views,
  };
}

function handleDashboardInitializeFail(state: IDashboardsReducer, action: ReturnType<typeof dashboardsInitializeFail>): IDashboardsReducer {
  return {
    ...initialState,
    error: action.payload.error,
  };
}

function handleDashboardReset(state: IDashboardsReducer): IDashboardsReducer {
  return {
    ...initialState,
  };
}

function handleSetDashboards(state: IDashboardsReducer, action: ReturnType<typeof dashboardsSetDashboards>): IDashboardsReducer {
  return {
    ...state,
    dashboards: action.payload.dashboards,
  };
}

function handleSetViews(state: IDashboardsReducer, action: ReturnType<typeof dashboardsSetViews>): IDashboardsReducer {
  return {
    ...state,
    views: action.payload.views,
  };
}

function handleSetDateRange(state: IDashboardsReducer, action: ReturnType<typeof dashboardsSetDateRange>): IDashboardsReducer {
  return {
    ...state,
    dateRange: action.payload.dateRange,
  };
}

function handleUpdateTitle(state: IDashboardsReducer, action: ReturnType<typeof updateDashboardTitle>): IDashboardsReducer {
  const dashboards = state.dashboards.map((dashboard) => {
    if (dashboard.id !== action.payload.id) {
      return dashboard;
    }
    return { ...dashboard, title: action.payload.title };
  });
  return {
    ...state,
    dashboards,
  };
}

function handleUpdatePrivacy(state: IDashboardsReducer, action: ReturnType<typeof updateDashboardPrivacy>): IDashboardsReducer {
  const dashboards = state.dashboards.map((dashboard) => {
    if (dashboard.id !== action.payload.dashboardID) {
      return dashboard;
    }
    return { ...dashboard, isPrivate: action.payload.isPrivate };
  });
  return {
    ...state,
    dashboards,
  };
}

function handleSetStatus(state: IDashboardsReducer, action: ReturnType<typeof setDashboardsStatus>): IDashboardsReducer {
  return {
    ...state,
    status: action.payload.status,
  };
}

function setAbortController(state: IDashboardsReducer, action: ReturnType<typeof dashboardsSetAbortController>): IDashboardsReducer {
  return {
    ...state,
    abortController: action.payload.abortController,
  };
}

function handleUploadModalToggle(state: IDashboardsReducer): IDashboardsReducer {
  return {
    ...state,
    uploadModal: {
      ...state.uploadModal,
      isOpen: !state.uploadModal.isOpen,
      isLoading: !state.uploadModal.isOpen ? false : state.uploadModal.isLoading,
      dashboards: state.uploadModal.isOpen ? undefined : state.uploadModal.dashboards,
      error: !state.uploadModal.isOpen ? undefined : state.uploadModal.error,
    },
  };
}

function handleUploadModalLoadDashboardsStart(state: IDashboardsReducer): IDashboardsReducer {
  return {
    ...state,
    uploadModal: {
      ...state.uploadModal,
      isLoading: true,
    },
  };
}

function handleUploadModalLoadDashboardsSuccess(
  state: IDashboardsReducer,
  action: ReturnType<typeof dashboardsUploadModalLoadDashboardsSuccess>,
): IDashboardsReducer {
  return {
    ...state,
    uploadModal: {
      ...state.uploadModal,
      isLoading: false,
      error: undefined,
      dashboards: action.payload.dashboards,
    },
  };
}

function handleUploadModalLoadDashboardsFailure(
  state: IDashboardsReducer,
  action: ReturnType<typeof dashboardsUploadModalLoadDashboardsFailure>,
): IDashboardsReducer {
  return {
    ...state,
    uploadModal: {
      ...state.uploadModal,
      isLoading: false,
      error: action.payload.error,
    },
  };
}

export default dashboardsReducer;
