import React, { useContext, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation, useParams, useRouteMatch } from 'react-router-dom';
import CompareTab from '../../components/CompareTab/CompareTab';
import TrendsTab from '../../components/dashboard/results/TrendsTab';

import ResponsesTab from '../../components/ResultsTab/ResponsesTab/ResponsesTab';
import SegmentsTab from '../../components/SegmentsTab/SegmentsTab';
import { getURLForCompany, SURVEY_STATUS_CONFIG } from '../../constants';
import { TestsContext } from '../../contexts/TestsContext';

import { EVENTS, eventsTracker } from '../../services/EventTrackerService';

import { toggleWeightedMetricsUsage } from '../../store/actions/actionsCreators';

import { dashboardClearWatcher, dashboardInitWidgetWatcher } from '../../store/actions/dashboard';
import { useAppSelector } from '../../store/hooks';
import { deinitialize as dashboardQueriesDeinit, initialize as dashboardQueriesInit } from '../../store/actions/dashboardQueries';

import DashboardContent from './DashboardContent';

export const TAB_ID = {
  RESPONSES: 'responses-tab',
  TRENDS: 'trends-tab',
  COMPARE: 'compare-tab',
  SEGMENTS: 'segments-tab',
};

const DEFAULT_QUERIES_HEIGHT = 72;

export type DashboardTab = {
  event: string;
  id: string;
  props: {
    label: string;
    to: string;
  };
  render: (routeProps?: any) => JSX.Element;
  hidden?: boolean;
  label?: string;
};

function DashboardContentContainer() {
  const { url } = useRouteMatch();
  const { pathname, search } = useLocation();
  const history = useHistory();
  const { company: selectedCompany } = useParams<{
    company: string;
  }>();
  const dispatch = useDispatch();
  // @ts-ignore
  const { isUnsafe } = useContext(TestsContext);
  const dashboard = useAppSelector((state) => state.dashboard.dashboard);
  const { company, user } = useAppSelector((state) => state.profile);
  const { weightedMetricsUsed, withChatAI } = useAppSelector((state) => state.app);

  const [shouldShowUploadInDashboard, setShouldShowUploadInDashboard] = useState(false);
  const [queriesHeight, setQueriesHeight] = useState(withChatAI ? DEFAULT_QUERIES_HEIGHT : 0);

  const isDashboardUnavailable = !!dashboard?.underMaintenance && !isUnsafe;

  useEffect(() => {
    if (!dashboard?.id) {
      console.error('Dashboard could not be initialized');
      return;
    }

    if (company) {
      dispatch(
        dashboardQueriesInit({
          companyName: company.name,
          dashboardID: dashboard.id,
        }),
      );
    } else {
      console.error('Company is not set');
    }

    dispatch(dashboardInitWidgetWatcher({ dashboardID: dashboard.id }));

    return () => {
      dispatch(dashboardQueriesDeinit());
      dispatch(dashboardClearWatcher({ dashboardID: dashboard.id }));
    };
  }, [dispatch]);
  useEffect(() => {
    setShouldShowUploadInDashboard(!!dashboard?.underMaintenance);
  }, [dashboard?.underMaintenance]);
  useEffect(() => {
    if (!dashboard?.hasWeights) {
      dispatch(toggleWeightedMetricsUsage(false));
    }
  }, [dashboard?.hasWeights, dispatch]);

  const contentTabsToRender: DashboardTab[] = [
    {
      event: EVENTS.RESPONSES_TAB_SELECT,
      id: TAB_ID.RESPONSES,
      props: {
        label: 'Responses',
        to: `${url}/responses`,
      },
      render: () => <ResponsesTab bottomMargin={queriesHeight} />,
    },
    {
      event: EVENTS.COMPARE_TAB_SELECT,
      id: TAB_ID.COMPARE,
      props: {
        label: 'Compare',
        to: `${url}/compare`,
      },
      render: () => <CompareTab bottomMargin={queriesHeight} />,
    },
    {
      event: EVENTS.TRENDS_TAB_SELECT,
      id: TAB_ID.TRENDS,
      props: {
        label: 'Trends',
        to: `${url}/trends`,
      },
      render: () => <TrendsTab bottomMargin={queriesHeight} />,
    },
    {
      event: EVENTS.SEGMENTS_TAB,
      id: TAB_ID.SEGMENTS,
      props: {
        label: 'Segments',
        to: `${url}/segments`,
      },
      render: (routeProps: any) => <SegmentsTab routeProps={routeProps} responsesURL={`${url}/responses`} bottomMargin={queriesHeight} />,
      hidden: true, // TODO: Uncomment when logic is fixed
    },
  ].filter((tab) => !tab.hidden || tab.props.to === pathname);

  let selectedTabIndex = contentTabsToRender.findIndex((t) => t.props.to === pathname);
  selectedTabIndex = selectedTabIndex === -1 ? 0 : selectedTabIndex;
  const selectedTab = contentTabsToRender[selectedTabIndex];

  function handleTabClick(tab: DashboardTab) {
    const prevTabLabel = typeof selectedTab.props.label === 'string' ? selectedTab.props.label : selectedTab.label;
    eventsTracker.track(tab.event, {
      Origin: prevTabLabel,
      'Weighted Metrics': weightedMetricsUsed,
    });
  }

  function handleControlPanelIconClick() {
    if (!dashboard) {
      return;
    }
    eventsTracker.track(EVENTS.OPEN_CONTROL_PANEL, {
      Status: dashboard.status in SURVEY_STATUS_CONFIG ? SURVEY_STATUS_CONFIG[dashboard.status].title : dashboard.status,
      'Dashboard Access': !dashboard.underMaintenance,
    });
    setShouldShowUploadInDashboard(true);
  }

  function handleStatusDialogClose() {
    if (isDashboardUnavailable) {
      // prevent closing the dialog when the dashboard isn't ready
      return;
    }
    setShouldShowUploadInDashboard(false);
  }

  function handleReturnToHivesClick() {
    history.push(getURLForCompany(selectedCompany));
  }

  function handleQueriesHeightChange(newHeight: number) {
    setQueriesHeight(newHeight);
  }

  if (!dashboard || !company) {
    return null;
  }

  return (
    <DashboardContent
      url={url}
      search={search}
      hasWeights={dashboard.hasWeights}
      isUserAdminOfCompany={company.admin}
      isDashboardUnavailable={isDashboardUnavailable}
      dashboardID={dashboard.id}
      dashboardTitle={dashboard.title}
      selectedTabID={selectedTab.id}
      selectedTabIndex={selectedTabIndex}
      contentTabsToRender={contentTabsToRender}
      shouldShowUploadInDashboard={shouldShowUploadInDashboard}
      isPrivate={dashboard.isPrivate}
      // TODO: rework dashboard privacy
      // shouldShowPrivacyMenu={user?.id === dashboard.createdBy}
      shouldShowPrivacyMenu={false}
      shouldShowChatAI={withChatAI}
      onStatusDialogClose={handleStatusDialogClose}
      onReturnToHivesClick={handleReturnToHivesClick}
      onControlPanelIconClick={handleControlPanelIconClick}
      onTabClick={handleTabClick}
      onQueriesHeightChange={handleQueriesHeightChange}
    />
  );
}

export default DashboardContentContainer;
