export type OutgoingMessage = {
  type: OutgoingMessageTypes;
  payload?: {
    question: string;
  };
};

export enum OutgoingMessageTypes {
  Ping = 'ping',
  Question = 'question',
  Cancel = 'cancel',
}

export enum IncomingMessageTypes {
  Pong = 'pong',
  Cancel = 'cancel',
  Answer = 'answer',
}

export type IncomingMessage = {
  type: IncomingMessageTypes;
  payload: IncomingMessagePayload;
};

export type IncomingMessagePayload = {
  status: AnswerStatus;
  answer?: {
    message: string;
  };
  error?: string;
  version?: string;
};

export enum AnswerStatus {
  COLLECTING_DATA_STATUS = 'collecting_data',
  ANALYZING_DATA_STATUS = 'analyzing_data',
  GENERATING_ANSWER_STATUS = 'generating_answer',
  SUCCESS_STATUS = 'success',
  FAILURE_STATUS = 'error',
  CANCEL_STATUS = 'canceled',
}
