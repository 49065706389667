import { API_BASE_URL } from '../constants';

let selectedCompany: string;
let csrfToken = localStorage.getItem('csrfToken');

export function setCsrfToken(token: string) {
  csrfToken = token;
  localStorage.setItem('csrfToken', token);
}

export function getCsrfToken() {
  return csrfToken;
}

export const setSelectedCompany = (value: string) => (selectedCompany = value);
export const getSelectedCompany = () => selectedCompany;

export const defaultFetchSettings: RequestInit = {
  method: 'GET',
  credentials: 'include',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
  },
};

export const getFetchSettings = (method = 'GET', data: any = null) => {
  const settings = {
    ...defaultFetchSettings,
    headers: { ...defaultFetchSettings.headers, 'X-CSRF-Token': csrfToken ?? '' },
    method: method,
  };
  if (data) {
    if (data instanceof FormData) {
      settings.body = data;
      // let the browser set the content type to ensure the boundary is set correctly
      if ('Content-Type' in settings.headers) {
        delete settings.headers['Content-Type'];
      }
    } else {
      Object.defineProperty(settings, 'body', {
        enumerable: true,
        configurable: false,
        value: JSON.stringify(data),
      });
    }
  }
  return settings;
};

export const getLabelsUrl = (dashboardId: string, answerId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/extend-labels/?answerID=${answerId}`;
export const getCreateFilterUrl = (dashboardId: string, filterName: string, description: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filter/?name=${encodeURIComponent(filterName)}&description=${encodeURIComponent(description)}`;
export const getSavedFiltersUrl = (dashboardId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filters`;
export const getSavedFilterUrl = (dashboardId: string, filterId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filter/${filterId}`;
export const getUpdateFilterUrl = (dashboardId: string, filterId: string, filterName: string, description: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/saved-filter/${filterId}?name=${encodeURIComponent(filterName)}&description=${encodeURIComponent(description)}`;
export const getSegmentsUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/segments`;
export const getExportUrl = (dashboardId: string, isTranslated: boolean) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/export/?isTranslated=${isTranslated}`;
export const getTotalUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/total`;
export const getFullRecord = (dashboardId: string, answerId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/full-record/?answerID=${answerId}`;
export const getWidgetGroupUrl = (dashboardId: string, groupId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget-group/${groupId}`;
export const getWidgetUrl = (dashboardId: string, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}`;
export const putAIInsightsRequestUrl = (dashboardId: string, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}/ai-insights`;
export const putInterviewInsightsRequestUrl = (dashboardId: string, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}/interview-insights`;
export const postAIInsightsFeedbackUrl = (dashboardId: string, widgetId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/widget/${widgetId}/ai-insights/feedback`;
export const getDashboardsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/dashboards`;
export const getDashboardViewsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard-views`;
export const getDashboardViewUrl = (viewId?: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard-view/${viewId ? viewId : ''}`;
export const getDashboardUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}`;
export const getDashboardQueriesFeedbackUrl = (dashboardId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/queries/feedback`;
export const getSummaryUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/summary`;
export const getSentimentSummaryUrl = (dashboardId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/sentiment`;
export const getBreakdownUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/breakdown`;
export const getTotalTrendsUrl = (dashboardId: string, groupBy: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/total-trends/${groupBy}`;
export const getBookmarkResponseUrl = (dashboardId: string, answerId = '') =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/bookmarked-response/${answerId}`;
export const getProfileUrl = () => `${API_BASE_URL}/api/profile`;

export const postAuthUrl = `${API_BASE_URL}/api/post-auth`;

export const getTrendsUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/trends`;
export const getTrendsBreakDownUrl = (dashboardId: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/trends-breakdown`;
export const getResponsesUrl = (dashboardId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardId}/responses`;
export const getSurveysUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/surveys`;
export const getUploadFilesUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/document/upload-files`;
export const getUserFinishedUploadUrl = (dashboardID: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/user-finished-upload/${dashboardID}`;
export const getConnectionsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/connections`;
export const getConnectionUrl = (connectionId: string) => `${API_BASE_URL}/api/company/${selectedCompany}/connections/${connectionId}`;
export const getAzureFilesUrl = (authID: string) => `${API_BASE_URL}/api/company/${selectedCompany}/azure-files?authID=${authID}`;
export const getQualtricsSurveysUrl = (authID: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/qualtrics-surveys?authID=${authID}`;
export const getProviderAuthsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/provider-auth`;
export const getProviderAuthUrl = (authID: string) => `${API_BASE_URL}/api/company/${selectedCompany}/provider-auth/${authID}`;
export const getBrandsUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/brands`;
export const getLanguagesUrl = () => `${API_BASE_URL}/api/languages`;
export const getAuthProvidersUrl = () => `${API_BASE_URL}/api/auth-provider`;
export const getCustomersUrl = (dashboardID: string, datasourceID: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${datasourceID}/customers`;
export const getLabelsManagementUrl = (dashboardID: string, datasourceID: string, label?: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${datasourceID}/labels${label ? `/${label}` : ''}`;
export const getRegenerationStatusUrl = (dashboardID: string, dataSourcesIDsQuery: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/regenerate?${dataSourcesIDsQuery}`;
export const putRegeneratingUrl = (dashboardID: string, dataSourceID: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${dataSourceID}/regenerate`;
export const getReposnonseLabelsManagementUrl = (dashboardID: string, datasourceID: string, responseID: string) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/dashboard/${dashboardID}/dataSource/${datasourceID}/answer/${responseID}/labels`;
export const getSignupWorkspaceUrl = () => `${API_BASE_URL}/api/signup/workspace`;

export const getRequestTemplateUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/request-template`;
export const getTemplatesUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/templates`;
export const getFileMappingReportIssueUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/document/report-issue`;
export const getFileMappingStateUrl = (shouldPredictTypes: boolean) =>
  `${API_BASE_URL}/api/company/${selectedCompany}/document/get-file-mapping-state?predict_types=${shouldPredictTypes}`;
export const getDocumentLoadUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/document/load`;
export const getValidateDateFormatUrl = () => `${API_BASE_URL}/api/validate-date-format`;
export const getDocumentValidateMappingUrl = () => `${API_BASE_URL}/api/company/${selectedCompany}/document/validate-mapping`;
