import styled from 'styled-components';

import DashboardToolbar from '../../components/DashboardToolbar/DashboardToolbarContainer';
import LoadingLabel from '../../components/LoadingLabel/LoadingLabel';
import { UserProfile } from '../../store/reducers/profile';

import ProfileAccountDetailsContainer from './ProfileAccountDetailsContainer';

const Container = styled.div`
  padding: 64px;
`;

interface IProfile {
  profile: UserProfile | null;
  isLoading: boolean;

  onChangeProfile: (profile: UserProfile) => void;
  onBackClick: () => void;
  onSubmit: () => void;
}

function Profile({ profile, isLoading, onChangeProfile, onBackClick, onSubmit }: IProfile) {
  return (
    <Container>
      <DashboardToolbar
        exitText
        shouldShowAccountLink={false}
        shouldShowInviteLink={false}
        disableInviteLink={false}
        onBackClick={onBackClick}
        shouldShowPrivacyMenu={false}
      />
      {!profile && <LoadingLabel loading={true} />}
      {!!profile && (
        <ProfileAccountDetailsContainer profile={profile} isLoading={isLoading} onChangeProfile={onChangeProfile} onSubmit={onSubmit} />
      )}
    </Container>
  );
}

export default Profile;
