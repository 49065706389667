import { v4 } from 'uuid';
import { DashboardQuery, Feedbacks, QueryStatus, QueryType } from '../../../types/DashboardQuery';
import {
  DASHBOARD_QUERIES_DEINITIALIZE,
  DASHBOARD_QUERIES_FEEDBACK_SUBMIT_ERROR,
  DASHBOARD_QUERIES_FEEDBACK_SUBMIT_START,
  DASHBOARD_QUERIES_FEEDBACK_SUBMIT_SUCCESS,
  DASHBOARD_QUERIES_INITIALIZE,
  DASHBOARD_QUERIES_RECEIVE_PONG,
  DASHBOARD_QUERIES_RECEIVE_QUERY_UPDATE,
  DASHBOARD_QUERIES_SEND_QUERY,
  DASHBOARD_QUERIES_STOP_QUERY,
  DASHBOARD_QUERIES_THUMBS_TOGGLE,
  deinitialize,
  feedbackSubmitError,
  feedbackSubmitStart,
  feedbackSubmitSuccess,
  initialize,
  receivePong,
  receiveQueryUpdate,
  sendQuery,
  stopQuery,
  thumbsToggle,
} from '../../actions/dashboardQueries';

export const FINISHED_STATUSES = [QueryStatus.SUCCESS_STATUS, QueryStatus.CANCEL_STATUS, QueryStatus.FAILURE_STATUS];
export const FAILURE_STATUSES = [QueryStatus.CANCEL_STATUS, QueryStatus.FAILURE_STATUS];
const AI_RESPONSES = [QueryType.TEXT_RESPONSE];

export interface IDashboardQueriesReducer {
  queries: DashboardQuery[];
  feedbacks: Feedbacks;
  isGenerating: boolean;
  wasInitialPongReceived: boolean;
  aiVersion: string;
  sessionID: string;
}

const initialState: IDashboardQueriesReducer = {
  queries: [],
  feedbacks: {},
  isGenerating: false,
  wasInitialPongReceived: false,
  aiVersion: '',
  sessionID: '',
};

type dashboardQueriesActionTypes = ReturnType<typeof initialize> &
  ReturnType<typeof sendQuery> &
  ReturnType<typeof receiveQueryUpdate> &
  ReturnType<typeof stopQuery> &
  ReturnType<typeof receivePong> &
  ReturnType<typeof thumbsToggle> &
  ReturnType<typeof feedbackSubmitStart> &
  ReturnType<typeof feedbackSubmitSuccess> &
  ReturnType<typeof feedbackSubmitError> &
  ReturnType<typeof deinitialize>;

export const dashboardQueriesReducer = (state = initialState, action: dashboardQueriesActionTypes) => {
  switch (action.type) {
    case DASHBOARD_QUERIES_INITIALIZE:
      return state;
    case DASHBOARD_QUERIES_SEND_QUERY:
      return handleSendQuery(state, action);
    case DASHBOARD_QUERIES_STOP_QUERY:
      return handleStopQuery(state);
    case DASHBOARD_QUERIES_RECEIVE_QUERY_UPDATE:
      return handleReceiveQueryUpdate(state, action);
    case DASHBOARD_QUERIES_RECEIVE_PONG:
      return handleReceivePong(state, action);
    case DASHBOARD_QUERIES_THUMBS_TOGGLE:
      return handleThumbsToggle(state, action);
    case DASHBOARD_QUERIES_DEINITIALIZE:
      return initialState;
    case DASHBOARD_QUERIES_FEEDBACK_SUBMIT_START:
      return handleFeedbackSubmitStart(state, action);
    case DASHBOARD_QUERIES_FEEDBACK_SUBMIT_SUCCESS:
      return handleFeedbackSubmitSuccess(state, action);
    case DASHBOARD_QUERIES_FEEDBACK_SUBMIT_ERROR:
      return handleFeedbackSubmitError(state, action);
    default:
      return state;
  }
};

function handleSendQuery(state: IDashboardQueriesReducer, action: ReturnType<typeof sendQuery>): IDashboardQueriesReducer {
  const { query } = action.payload;
  return {
    ...state,
    isGenerating: true,
    queries: [
      {
        id: v4(),
        date: new Date().toISOString(),
        queryType: QueryType.TEXT_QUESTION,
        data: {
          message: query,
        },
        status: QueryStatus.SUCCESS_STATUS,
      },
      ...state.queries,
    ],
  };
}

function handleStopQuery(state: IDashboardQueriesReducer): IDashboardQueriesReducer {
  if (state.queries.length === 0) {
    return state;
  }
  const latestQuery = state.queries[0];
  if (FINISHED_STATUSES.includes(latestQuery.status) || FAILURE_STATUSES.includes(latestQuery.status)) {
    return state;
  }

  latestQuery.status = QueryStatus.CANCELING_STATUS;

  const queries = state.queries.toSpliced(0, 1, latestQuery);
  return {
    ...state,
    queries,
  };
}

function handleReceiveQueryUpdate(
  state: IDashboardQueriesReducer,
  action: ReturnType<typeof receiveQueryUpdate>,
): IDashboardQueriesReducer {
  const { status, answer, error } = action.payload;

  const typedStatus = status as unknown as QueryStatus;
  const latestQuery = state.queries[0];
  const wasQueryResponseAlreadyUpdated =
    latestQuery && AI_RESPONSES.includes(latestQuery.queryType) && latestQuery.status !== QueryStatus.SUCCESS_STATUS;
  let query: DashboardQuery;
  if (wasQueryResponseAlreadyUpdated) {
    query = { ...latestQuery };
    query.status = typedStatus;
    query.error = error;
  } else {
    query = {
      data: null,
      status: typedStatus,
      id: v4(),
      date: new Date().toISOString(),
      queryType: QueryType.TEXT_RESPONSE,
      error,
    };
  }

  const clonedFeedbacks = { ...state.feedbacks };
  let sessionIDToUse = '';
  if (answer?.message) {
    query.data = {
      message: answer.message,
    };

    clonedFeedbacks[query.id] = {
      thumb: null,
      isLoading: false,
      wasSubmitted: false,
      wasErrorSubmitting: false,
    };

    sessionIDToUse = answer.message.split('session ID:* ')[1];
  }
  let queries = [...state.queries];
  if (wasQueryResponseAlreadyUpdated) {
    queries = state.queries.toSpliced(0, 1, query);
  } else {
    queries.unshift(query);
  }

  const isGenerating = !FINISHED_STATUSES.includes(typedStatus);

  return {
    ...state,
    isGenerating,
    queries,
    feedbacks: clonedFeedbacks,
    sessionID: state.sessionID === sessionIDToUse ? state.sessionID : sessionIDToUse,
  };
}

function handleReceivePong(state: IDashboardQueriesReducer, action: ReturnType<typeof receivePong>): IDashboardQueriesReducer {
  const { version } = action.payload;

  return {
    ...state,
    wasInitialPongReceived: true,
    aiVersion: version ? version : state.aiVersion,
  };
}

function handleThumbsToggle(state: IDashboardQueriesReducer, action: ReturnType<typeof thumbsToggle>): IDashboardQueriesReducer {
  const { thumb, id } = action.payload;

  return {
    ...state,
    feedbacks: {
      ...state.feedbacks,
      [id]: {
        thumb,
        isLoading: false,
        wasSubmitted: false,
        wasErrorSubmitting: false,
      },
    },
  };
}

function handleFeedbackSubmitStart(
  state: IDashboardQueriesReducer,
  action: ReturnType<typeof feedbackSubmitStart>,
): IDashboardQueriesReducer {
  const { queryID } = action.payload;

  return {
    ...state,
    feedbacks: {
      ...state.feedbacks,
      [queryID]: {
        ...state.feedbacks[queryID],
        isLoading: true,
        wasErrorSubmitting: false,
        wasSubmitted: false,
      },
    },
  };
}
function handleFeedbackSubmitSuccess(
  state: IDashboardQueriesReducer,
  action: ReturnType<typeof feedbackSubmitSuccess>,
): IDashboardQueriesReducer {
  const { queryID } = action.payload;

  return {
    ...state,
    feedbacks: {
      ...state.feedbacks,
      [queryID]: {
        ...state.feedbacks[queryID],
        isLoading: false,
        wasSubmitted: true,
        wasErrorSubmitting: false,
      },
    },
  };
}
function handleFeedbackSubmitError(
  state: IDashboardQueriesReducer,
  action: ReturnType<typeof feedbackSubmitError>,
): IDashboardQueriesReducer {
  const { queryID } = action.payload;

  return {
    ...state,
    feedbacks: {
      ...state.feedbacks,
      [queryID]: {
        ...state.feedbacks[queryID],
        isLoading: false,
        wasSubmitted: false,
        wasErrorSubmitting: true,
      },
    },
  };
}
