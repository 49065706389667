import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useLocation, useRouteMatch } from 'react-router-dom';
import { Box } from '@material-ui/core';

import DashboardToolbar from '../../components/DashboardToolbar/DashboardToolbarContainer';
import { Sidebar } from '../../components/Sidebar';
import SidebarItemConnections from '../../components/Sidebar/SidebarItems/SidebarItemConnections';
import SidebarItemHives from '../../components/Sidebar/SidebarItems/SidebarItemHives';
import ConnectorsTabContainer from '../../components/ConnectorsTab/ConnectorsTabContainer';
import HivesTabContainer from './HivesTabContainer';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';

const TAB_ID = {
  HIVES: 'hives',
  CONNECTIONS: 'connections',
};

const Programs = () => {
  const { company } = useSelector<any, any>((state) => state.profile);
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  const tabs = [
    {
      id: TAB_ID.HIVES,
      label: 'Hives',
      to: `${url}/hives`,
      sidebarItem: SidebarItemHives,
      render: () => <HivesTabContainer />,
    },
    {
      event: EVENTS.CONNECTIONS_TAB_SELECT,
      id: TAB_ID.CONNECTIONS,
      label: 'Connections',
      to: `${url}/connections`,
      sidebarItem: SidebarItemConnections,
      render: () => <ConnectorsTabContainer />,
    },
  ];
  let selectedTabIndex = tabs.findIndex((t) => t.to === pathname);
  selectedTabIndex = selectedTabIndex === -1 ? 0 : selectedTabIndex;
  const selectedTab = tabs[selectedTabIndex];

  const handleTabClick = (event: string) => {
    const prevTabLabel = selectedTab.label;
    if (event) {
      eventsTracker.track(event, {
        Origin: prevTabLabel,
      });
    }
  };

  return (
    <Box>
      <DashboardToolbar
        title={company.displayName}
        exitText
        shouldShowInviteLink
        shouldShowAccountLink
        disableInviteLink={!company.admin}
        shouldShowPrivacyMenu={false}
      />
      <Box display='flex'>
        <Sidebar>
          {tabs.map((tab) => (
            <tab.sidebarItem
              isSelected={selectedTab.id === tab.id}
              onClick={() => handleTabClick(tab.event ?? '')}
              to={tab.to}
              key={tab.id}
            />
          ))}
        </Sidebar>
        <Switch>
          {tabs.map((tab) => (
            <Route key={tab.id} path={tab.to} render={tab.render} />
          ))}
          <Redirect from={url} to={tabs[0].to} />
        </Switch>
      </Box>
    </Box>
  );
};

export default Programs;
