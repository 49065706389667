/**
 * Actions types
 */
export const TURN_WEIGHTED_METRICS_ON = 'TURN_WEIGHTED_METRICS_ON';
export const TURN_WEIGHTED_METRICS_OFF = 'TURN_WEIGHTED_METRICS_OFF';
export const TURN_TRANSLATION_ON = 'TURN_TRANSLATION_ON';
export const TURN_TRANSLATION_OFF = 'TURN_TRANSLATION_OFF';
export const SET_COLOR_SCHEME_NAME = 'SET_COLOR_SCHEME_NAME';
export const APP_BLOCKER_SHOW = 'APP_BLOCKER_SHOW';
export const APP_BLOCKER_HIDE = 'APP_BLOCKER_HIDE';
export const APP_WITH_CHAT_AI = 'APP_WITH_CHAT_AI';

export const toggleWeightedMetricsUsage = (state: boolean) => {
  return {
    type: state ? TURN_WEIGHTED_METRICS_ON : TURN_WEIGHTED_METRICS_OFF,
  };
};

export const toggleTranslationUsage = (state: boolean) => {
  return {
    type: state ? TURN_TRANSLATION_ON : TURN_TRANSLATION_OFF,
  };
};

export const setColorSchemeName = (colorSchemeName: string) => {
  return {
    type: SET_COLOR_SCHEME_NAME,
    colorSchemeName,
  };
};

export const showBlocker = () => {
  return {
    type: APP_BLOCKER_SHOW,
  };
};

export const hideBlocker = () => {
  return {
    type: APP_BLOCKER_HIDE,
  };
};

interface IEnableChatAI {
  isEnabled: boolean;
}
export const enableChatAI = ({ isEnabled }: IEnableChatAI) => {
  return {
    type: APP_WITH_CHAT_AI,
    payload: {
      isEnabled,
    },
  };
};
