import styled from 'styled-components';

import { ReactComponent as HyperLinkIcon } from '../../../assets/icons/hyperlink-icon.svg';
import { BeehiveLogoStyled as BeehiveLogo, Info, PageContainer } from '../styles';

export const PageContainerStyled = styled(PageContainer)`
  height: 100vh;
`;

export const BeehiveLogoStyled = styled(BeehiveLogo)`
  position: static;
  width: 74px;
  height: 68px;
`;

export const Container = styled.div`
  position: relative;

  display: flex;
  width: 800px;
  padding: 40px 0px;
  flex-direction: column;
  align-items: center;

  margin: 15vh auto 100px;
  background-color: var(--white);

  gap: 24px;

  border-radius: 8px;
  box-shadow: 0px 2px 6px 2px #0f0c0c26;

  @media screen and (min-width: 1024px) and (max-width: 1279px) {
    padding: 32px 24px;
    width: 440px;
  }
  @media screen and (max-width: 1023px) {
    padding: 32px 24px;
    width: 328px;
  }
`;

export const InfoStyled = styled(Info)`
  font-size: 20px;
  max-width: 463px;
  margin-top: 0;
`;

export const Link = styled.a`
  color: var(--beehive-purple);
  font-family: Rubik;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;
`;

export const AboutLink = styled(Link)`
  color: var(--dark-purple);
  font-family: Rubik;
  font-size: 16px;
  margin-top: 64px;
  margin-left: 32px;
  align-self: flex-start;
  display: flex;
  gap: 4px;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    margin-top: 32px;
    margin-left: 0;
  }
`;

export const HyperLinkIconStyled = styled(HyperLinkIcon)`
  fill: var(--dark-purple);
`;
