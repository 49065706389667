import BaseWidgetContainer from '../BaseWidgetContainer';
import WidgetSettings from '../OpenEndedWidget/Settings/WidgetSettings';
import { WidgetMenuWithTrendsOption } from '../../WidgetMenu/WidgetMenu';
import BarsWidgetContent from './BarsWidgetContent';

const BarsWidgetSettings = (props) => {
  return <WidgetSettings {...props} hideConfig={true} />;
};

const BarsWidget = ({ config, height, onDimensionsChange, groupTitle }) => {
  return (
    <BaseWidgetContainer
      config={config}
      height={height}
      groupTitle={groupTitle}
      onDimensionsChange={onDimensionsChange}
      SettingsComponent={BarsWidgetSettings}
      MenuComponent={WidgetMenuWithTrendsOption}
      ContentComponent={BarsWidgetContent}
    />
  );
};

export default BarsWidget;
