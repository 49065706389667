import { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import InputLabel from '@material-ui/core/InputLabel';

import { EMPTY_QUERY, isQueryValid, transformFilterToQuery, transformQueryToFilter } from '../FilterForm/lib';
import CustomQueryBuilder from '../FilterForm/CustomQueryBuilder';
import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import { CancelButton, SaveButton } from '../UI';
import { DeleteButton } from '../../pages/Programs/styles';

function WidgetFilterDialog({ filter, query, onApply, onCancel }) {
  const { savedFilters } = useSavedFilters();
  const savedFilterId = filter?.filterID && savedFilters.find((savedFilter) => savedFilter.id === filter?.filterID) ? filter?.filterID : '';
  const [filterID, setFilterID] = useState(savedFilterId);
  const [customQuery, setCustomQuery] = useState(query ? transformFilterToQuery(query) : EMPTY_QUERY);
  const [isCustom, setIsCustom] = useState(!filterID);
  const [isTouched, setIsTouched] = useState(isCustom && customQuery !== EMPTY_QUERY);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const disabledApply = (isCustom && !isQueryValid(transformQueryToFilter(customQuery))) || (!isCustom && !filterID);

  function handleApplyClick() {
    onApply(isCustom ? null : filterID, isCustom ? transformQueryToFilter(customQuery) : null);
  }

  function handleResetClick() {
    onApply(null, null);
  }

  function handleSavedFilterSelect(event) {
    const id = event.target.value;
    setFilterID(id);
    if (id) {
      const savedFilter = savedFilters.find((savedFilter) => savedFilter.id === id);
      if (!isCustom || !isTouched || customQuery.rules.length === 0) {
        setCustomQuery(transformFilterToQuery(savedFilter.query));
      } else {
        setShowConfirmation(true);
      }
    }
  }

  function handleOverride() {
    const savedFilter = savedFilters.find((savedFilter) => savedFilter.id === filterID);
    setCustomQuery(transformFilterToQuery(savedFilter.query));
    setIsTouched(false);
    setShowConfirmation(false);
  }

  function handleQueryChange(query) {
    setCustomQuery(query);
    setIsTouched(true);
  }

  return (
    <>
      <Dialog maxWidth='lg' fullWidth open={true} data-testid='confirmation-dialog'>
        <DialogTitle>Widget filter</DialogTitle>
        <DialogContent dividers>
          <FormGroup row>
            <FormControl variant='outlined' size='small' style={{ minWidth: '200px' }}>
              <InputLabel id='widget-saved-filter-label'>Saved segment</InputLabel>
              <Select labelId='widget-saved-filter-label' value={filterID} onChange={handleSavedFilterSelect} label='Saved segment'>
                <MenuItem value=''>
                  <em>None</em>
                </MenuItem>
                {savedFilters.map((savedFilter) => (
                  <MenuItem key={savedFilter.id} value={savedFilter.id} disabled={!savedFilter.valid}>
                    {savedFilter.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControlLabel
              style={{ marginLeft: '5px' }}
              control={<Checkbox checked={isCustom} onChange={() => setIsCustom((value) => !value)} />}
              label='Customize filter'
            />
          </FormGroup>
        </DialogContent>
        <DialogContent>
          <CustomQueryBuilder value={customQuery} onChange={handleQueryChange} disabled={!isCustom} enableMountQueryChange={false} />
        </DialogContent>
        <DialogActions>
          <DeleteButton onClick={handleResetClick}>Reset</DeleteButton>
          <div style={{ flex: '1 0 0' }} />
          <CancelButton onClick={onCancel}>Cancel</CancelButton>
          <SaveButton onClick={handleApplyClick} disabled={disabledApply} variant='outlined'>
            Apply
          </SaveButton>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        onCancel={() => setShowConfirmation(false)}
        onConfirm={handleOverride}
        open={showConfirmation}
        title='Override query'
        content={'Saved segment will override existing query.'}
      />
    </>
  );
}

export default WidgetFilterDialog;
