import styled from 'styled-components';

import background1x from '../../assets/images/welcome-background/welcome-background@1x.png';
import background2x from '../../assets/images/welcome-background/welcome-background@2x.png';
import background3x from '../../assets/images/welcome-background/welcome-background@3x.png';

import { ReactComponent as BeehiveLogo } from '../../assets/icons/beehive-icon.svg';

export const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 1264.588px;
    height: 826.568px;
    left: 50%;
    top: 40%;
    transform: translate(-50%, -50%);

    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    background-image: image-set(url(${background1x}) 1x, url(${background2x}) 2x, url(${background3x}) 3x);

    @media screen and (min-width: 1280px) and (max-width: 1439px) {
      width: 1103.254px;
      height: 720.078px;
    }

    @media screen and (min-width: 1024px) and (max-width: 1279px) {
      width: 768.023px;
      height: 502px;
    }
  }
`;

export const BeehiveLogoStyled = styled(BeehiveLogo)`
  position: absolute;
  left: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
`;

export const Header = styled.div`
  color: var(--black);

  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  text-align: center;
`;
export const Info = styled.div`
  color: var(--black);

  text-align: center;
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  margin-top: 24px;
`;
