import Dialog from '@material-ui/core/Dialog';
import React from 'react';

import { ReactComponent as CloseIcon } from '../../../assets/icons/cross-icon.svg';
import { FilledButton, OutlinedButton } from '../../UI/Button';

import styles from './styles.module.css';

interface IWarningModal {
  headerText: string;
  bodyText: string;
  cancelButtonText: string;
  mainActionButtonText: string;
  onCancelButtonClick: () => void;
  onMainActionButtonClick: () => void;
}

function WarningModal({
  headerText,
  bodyText,
  cancelButtonText,
  mainActionButtonText,
  onCancelButtonClick,
  onMainActionButtonClick,
}: IWarningModal) {
  return (
    <Dialog maxWidth={false} open={true} onClose={onCancelButtonClick}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.headerText}>{headerText}</div>
          <CloseIcon className={styles.closeIcon} onClick={onCancelButtonClick} />
        </div>
        <div className={styles.body}>{bodyText}</div>
        <div className={styles.footer}>
          <OutlinedButton onClick={onCancelButtonClick}>{cancelButtonText}</OutlinedButton>
          <FilledButton $isWarning={true} onClick={onMainActionButtonClick}>
            {mainActionButtonText}
          </FilledButton>
        </div>
      </div>
    </Dialog>
  );
}

export default WarningModal;
