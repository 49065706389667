import React, { useEffect } from 'react';
import { CircularProgress, Dialog } from '@material-ui/core';
import styled from 'styled-components';

import { ReactComponent as CheckIcon } from '../../../../assets/icons/check-circle-icon.svg';
import backgroundImage from '../../../../assets/images/file-upload-success-background.png';
import { FilledButton } from '../../../UI/Button';

import { Container, DialogTitle, StyledTextField } from '../../../ConnectorsTab/AddCSV/styles';

const StyledFilledButton = styled(FilledButton)`
  width: 15%;
  align-self: flex-end;
  && {
    margin-top: 40px;
  }
`;

const StyledContainer = styled(Container)`
  align-items: center;
  justify-content: center;
  text-align: left;
`;

const StyledDialogTitle = styled(DialogTitle)`
  align-self: flex-start;
  margin-bottom: 10px;
`;

const DialogSubtext1 = styled.div`
  font-size: 20px;
  align-self: flex-start;
  margin-bottom: 16px;

  strong {
    font-weight: 1000;
  }
`;

const DialogSubtext2 = styled.div`
  font-size: 16px;
  align-self: flex-start;
  margin-bottom: 76px;
`;

const DialogSubtext3 = styled.div`
  font-size: 16px;
  align-self: flex-start;
  margin-bottom: 8px;
`;

const StyledCheckIcon = styled(CheckIcon)`
  fill: var(--sentiment-green);
`;

const StyledCheckIconGray = styled(CheckIcon)`
  fill: var(--medium-gray);
`;
const Background = styled.img`
  position: absolute;
  right: 0;
  top: 0;
  z-index: 0;
  width: 100%;
`;

interface ISuccessfulUploadDialog {
  fileNames: string[];
  shouldShowRenameDashboard: boolean;
  dashboardRenameSubmitting: boolean;
  dashboardRenameSuccess: boolean;
  requestedManualProcessing: boolean;
  dashboardName: string;
  onClose: () => void;
  onDashboardNameChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function SuccessfulUploadDialog({
  fileNames,
  shouldShowRenameDashboard,
  dashboardRenameSubmitting,
  dashboardRenameSuccess,
  dashboardName,
  requestedManualProcessing,
  onClose,
  onDashboardNameChange,
}: ISuccessfulUploadDialog) {
  const isMultipleFiles = fileNames.length > 1;
  const doneDisabled = shouldShowRenameDashboard && (dashboardRenameSubmitting || !dashboardRenameSuccess);
  const [endAdornment, setEndAdornment] = React.useState<React.ReactNode>(null);
  useEffect(() => {
    if (dashboardRenameSubmitting) {
      setEndAdornment(<CircularProgress size={24} />);
    } else if (dashboardRenameSuccess) {
      setEndAdornment(<StyledCheckIcon />);
    } else {
      setEndAdornment(<StyledCheckIconGray />);
    }
  }, [dashboardRenameSubmitting, dashboardRenameSuccess]);

  return (
    <div>
      <Dialog data-testid='successful-upload-dialog' open={true} onClose={onClose} fullWidth={true} maxWidth={'md'}>
        <StyledContainer>
          <Background src={backgroundImage} />
          <StyledDialogTitle>File upload successful!</StyledDialogTitle>
          <DialogSubtext1 data-testid='successful-upload-dialog-subtext'>
            <strong>{fileNames.length}</strong> of <strong>{fileNames.length}</strong> file{isMultipleFiles ? 's have ' : ' has '}
            been uploaded successfully!
          </DialogSubtext1>
          <DialogSubtext2 data-testid='successful-upload-dialog-subtext2'>
            {requestedManualProcessing
              ? 'A Beehive AI Expert will map your file(s) to prepare your data for analysis. You’ll receive an email notification once the AI analysis and model training is underway.'
              : "Now, the AI is analyzing your data and the model training is underway. You'll receive an email notification when your data is ready"}
          </DialogSubtext2>
          {shouldShowRenameDashboard && (
            <>
              <DialogSubtext3>Next, name your analysis dashboard</DialogSubtext3>
              <StyledTextField
                value={dashboardName}
                placeholder='e.g. Customer Insights 2024'
                InputProps={{ endAdornment }}
                onChange={onDashboardNameChange}
                variant='outlined'
                data-testid='successful-upload-dialog-dashboard-rename'
              />
            </>
          )}
          <StyledFilledButton data-testid='successful-upload-dialog-done' disabled={doneDisabled} onClick={onClose}>
            DONE
          </StyledFilledButton>
        </StyledContainer>
      </Dialog>
    </div>
  );
}

export default SuccessfulUploadDialog;
