import { useCallback, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Tooltip, Grid } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';
import debounce from 'lodash/debounce';
import capitalize from 'lodash/capitalize';
import styled from 'styled-components';

import { useSavedFilters } from '../../contexts/SavedFiltersContext';
import { eventsTracker, EVENTS } from '../../services/EventTrackerService';
import ConfirmationDialog from '../ConfirmationDialog/ConfirmationDialog';
import SavedFilterMenu from './SavedFilterMenu';
import SavedFilter, { PlusButton } from './SavedFilter';
import { TextButton, FilledButton } from '../UI/Button';
import { SidebarDashboardContext } from '../Sidebar';

export const SAVED_FILTERS_URL_ARG_NAME = 'savedFilters';

const DEBOUNCE_PERIOD = 500;

export const HtmlTooltip = withStyles(() => ({
  tooltip: {
    maxWidth: 700,
    fontSize: '12px',
    fontWeight: 'bold',
  },
}))(Tooltip);

const SegmentButtonWrapper = styled.div`
  display: inline-block;
  margin: 8px 8px 0 0;
`;

const toggle = (filterIds, filterId) => {
  if (filterIds.includes(filterId)) {
    return filterIds.filter((id) => id !== filterId);
  } else {
    return [...filterIds, filterId];
  }
};

export const SavedFilters = ({ onEditFilterClick, onCreateNewClick }) => {
  const history = useHistory();
  const widgets = useSelector((state) => state.dashboard.widgets);
  const { deleteFilter, savedFilters, selectedSavedFiltersArray, setSelectedSavedFilters, savedFiltersById } = useSavedFilters();
  const dashboardId = useSelector((state) => state.dashboard.dashboard.id);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [confirmationContent, setConfirmationContent] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeFilterId, setActiveFilterId] = useState(null);
  const [selectedFilterIds, setSelectedFilterIds] = useState(selectedSavedFiltersArray);
  const { setActiveItemIndex } = useContext(SidebarDashboardContext);

  const setQuery = useCallback(
    (name, value) => {
      const params = new URLSearchParams();
      if (name && value) {
        params.append(name, value);
      }
      history.push({ search: params.toString() });
    },
    [history],
  );

  const debouncedFiltersChange = useCallback(
    debounce((filterIds, isApply = false) => {
      if (isApply) {
        const tab = window.location.pathname.split('/').pop();
        eventsTracker.track(EVENTS.SAVED_SEGMENTS_APPLY, {
          Tab: capitalize(tab),
          'Filter Names': filterIds.map((id) => savedFiltersById[id].name),
          'Filter Count': filterIds.length,
        });
      }
      setQuery(SAVED_FILTERS_URL_ARG_NAME, filterIds.join(','));
      setSelectedSavedFilters(filterIds);
    }, DEBOUNCE_PERIOD),
    [setQuery, setSelectedFilterIds, savedFiltersById],
  );

  const applyFilterChanges = (filterIds, isApply) => {
    setSelectedFilterIds(filterIds);
    debouncedFiltersChange(filterIds, isApply);
  };

  const handleDeleteClick = () => {
    const filter = savedFilters.find((savedFilter) => savedFilter.id === activeFilterId);
    const usageCount = widgets.filter((widget) => widget.base.filter?.filterID === activeFilterId).length;
    let widgetsWarning = '';
    let confirmation = `Are you sure want to remove segment ${filter.name}?`;
    if (usageCount) {
      widgetsWarning = `This segment is used by ${usageCount === 1 ? 'a widget' : usageCount + ' widgets'}. `;
    }
    setConfirmationContent(`${widgetsWarning}${confirmation}`);
    setShowConfirmation(true);
  };

  const handleRemoveClick = () => {
    deleteFilter(dashboardId, activeFilterId);

    const newFilterIds = selectedFilterIds.filter((filterId) => filterId !== activeFilterId);
    applyFilterChanges(newFilterIds);

    setShowConfirmation(false);
    setConfirmationContent(null);
    setActiveFilterId(null);
  };

  const handleFilterClick = (id) => {
    const newFilterIds = toggle(selectedFilterIds, id);
    applyFilterChanges(newFilterIds, true);
  };

  const handleEditClick = () => {
    const filter = savedFilters.find((savedFilter) => savedFilter.id === activeFilterId);
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_EDIT, {
      'Filter Name': filter.name,
    });
    onEditFilterClick(filter);
  };

  const handleDuplicateClick = () => {
    const filter = savedFilters.find((savedFilter) => savedFilter.id === activeFilterId);
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_DUPLICATE, {
      'Filter Name': filter.name,
    });
    const clone = filter.clone();
    clone.id = null;
    clone.name = filter.name + ' - copy';
    onEditFilterClick(clone);
  };

  const handleMenuClick = (event, id) => {
    if (anchorEl) {
      setAnchorEl(null);
      setActiveFilterId(null);
    } else {
      setAnchorEl(event.currentTarget);
      setActiveFilterId(id);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    setActiveFilterId(null);
  };

  const handleResetFiltersClick = () => {
    eventsTracker.track(EVENTS.SAVED_SEGMENTS_CLEAR, {
      'Filter Count': selectedFilterIds.length,
    });
    applyFilterChanges([]);
  };

  function handleDoneClick() {
    setActiveItemIndex(null);
  }

  return (
    <Grid container direction='column' style={{ flex: 1 }}>
      <Grid item>
        <strong>Saved Segments</strong>
      </Grid>
      <Grid item style={{ flexGrow: 1 }}>
        {savedFilters.length === 0 ? (
          <p>No segments saved</p>
        ) : (
          savedFilters.map((savedFilter) => (
            <SegmentButtonWrapper key={savedFilter.id}>
              <SavedFilter
                savedFilter={savedFilter}
                selected={selectedFilterIds.includes(savedFilter.id)}
                onFilterClick={() => handleFilterClick(savedFilter.id)}
                onMenuClick={(event) => handleMenuClick(event, savedFilter.id)}
              />
            </SegmentButtonWrapper>
          ))
        )}
        <PlusButton selected={false} data-tooltip-id='base-tooltip' data-tooltip-content='Create New' onClick={onCreateNewClick}>
          +
        </PlusButton>

        <SavedFilterMenu
          anchorEl={anchorEl}
          onClose={handleClose}
          onEditClick={handleEditClick}
          onDuplicateClick={handleDuplicateClick}
          onDeleteClick={handleDeleteClick}
        />

        <ConfirmationDialog
          onCancel={() => setShowConfirmation(false)}
          onConfirm={handleRemoveClick}
          open={showConfirmation}
          title='Remove Saved Segment'
          content={confirmationContent}
        />
      </Grid>

      <Grid item style={{ marginTop: '10px', textAlign: 'right' }}>
        {selectedSavedFiltersArray.length > 0 && (
          <TextButton onClick={handleResetFiltersClick} style={{ marginRight: '16px' }}>
            Reset Segments
          </TextButton>
        )}
        <FilledButton onClick={handleDoneClick}>Done</FilledButton>
      </Grid>
    </Grid>
  );
};
