import styled from 'styled-components';
import AddIcon from '@material-ui/icons/Add';

import connect from '../../../assets/images/connect.png';
import { Integration, SurveySourceEnum } from '../../../types/connections';

import { StyledIntegrationAddButton, StyledIntegrationTitle } from '../style';
import { EDITABLE_SURVEY_SOURCES, SURVEY_SOURCE_TO_INTEGRATION_INSTRUCTIONS } from '../constants';
import NoDataView from './NoData/NoDataView';
import ConnectionsList from './ConnectionsList';

const Container = styled.div`
  flex: 0.85 0 400px;
  border-radius: 16px;
  background-color: var(--white);
`;

const EmptyViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const ViewContainer = styled.div`
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 48px;
`;

function EmptyView() {
  return (
    <EmptyViewContainer>
      <img src={connect} alt='Select an app to see something here' style={{ width: '130px' }} />
      <div>Select an App to see something here...</div>
    </EmptyViewContainer>
  );
}

interface IIntegrationView {
  integration?: Integration;
  handleAddConnection: (source: SurveySourceEnum) => void;
  handleEnrich: (source: SurveySourceEnum, title: string, existingEnrichment?: string) => void;
}

function IntegrationView({ handleAddConnection, integration }: IIntegrationView) {
  if (!integration) {
    return (
      <Container>
        <EmptyView />
      </Container>
    );
  }

  const isShowInstruction =
    !integration.authentication?.active &&
    integration.connections.length === 0 &&
    SURVEY_SOURCE_TO_INTEGRATION_INSTRUCTIONS[integration.source] !== undefined;

  return (
    <Container>
      <ViewContainer>
        {isShowInstruction ? (
          <NoDataView
            title={integration.displayName}
            handleAdd={handleAddConnection.bind(null, integration.source)}
            instructionsView={SURVEY_SOURCE_TO_INTEGRATION_INSTRUCTIONS[integration.source]}
          />
        ) : (
          <>
            <Header>
              <StyledIntegrationTitle>{integration.displayName}</StyledIntegrationTitle>
              <StyledIntegrationAddButton
                data-testid='integration-view-addconnection'
                startIcon={<AddIcon />}
                onClick={handleAddConnection.bind(null, integration.source)}
              >
                Add Connection
              </StyledIntegrationAddButton>
            </Header>
            <ConnectionsList
              authentication={integration.authentication}
              connections={integration.connections}
              isEditEnabled={EDITABLE_SURVEY_SOURCES.includes(integration.source)}
              source={integration.source}
            />
          </>
        )}
      </ViewContainer>
    </Container>
  );
}

export default IntegrationView;
