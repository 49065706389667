import { useCallback, useState } from 'react';
import { v4 } from 'uuid';

import useQualtricsRedirect from '../../../../hooks/oauth/useQualtricsRedirect';
import useOAuthRedirect from '../../../../hooks/oauth/useOAuthRedirect';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { createQualtricsAuth } from '../../../../store/thunks/connectors';
import { Integration, SurveySourceEnum } from '../../../../types/connections';

import AuthQualtrics from './AuthQualtrics';
import { SURVEY_SOURCE_TO_SCOPES } from '../../constants';
import { useIntercom } from 'react-use-intercom';

const validateDatacenterID = (value: string) => {
  if (!value) {
    return 'Please enter a Datacenter ID.';
  }
  return '';
};

const validateClientID = (value: string) => {
  if (!value) {
    return 'Please enter a Client ID.';
  }
  return '';
};

const validateSecretID = (value: string) => {
  if (!value) {
    return 'Please enter a Client Secret.';
  }
  return '';
};

const getRandomState = () => v4().replaceAll('-', '');

interface IAuthQualtricsContainer {
  integration: Integration;
  onUpdateError: (err: string) => void;
}

function AuthQualtricsContainer({ integration, onUpdateError }: IAuthQualtricsContainer) {
  const dispatch = useAppDispatch();
  const isSubmitting = useAppSelector((state) => state.connectors).addConnection.submittingAuth;

  const [datacenterID, setDatacenterID] = useState<string>('');
  const [clientID, setClientID] = useState<string>('');
  const [datacenterError, setDatacenterError] = useState<string>('');
  const [clientError, setClientError] = useState<string>('');
  const [secretID, setSecretID] = useState<string>('');
  const [secretError, setSecretError] = useState<string>('');

  const { showNewMessages } = useIntercom();

  const { redirect, redirectUri } = useQualtricsRedirect(datacenterID, clientID, SURVEY_SOURCE_TO_SCOPES[SurveySourceEnum.QUALTRICS]);

  const { isOpen, redirectTo } = useOAuthRedirect(redirect, 'Qualtrics');

  // Uncomment with using popup window instead redirect
  //const { isOpen, open, close } = useOAuthRedirect(redirect, 'Qualtrics');

  // Uncomment with using popup window instead redirect
  // useEffect(() => {
  //   return () => {
  //     close();
  //   };
  // }, []);

  const validateForm = useCallback(() => {
    const datacenterError = validateDatacenterID(datacenterID);
    const clientError = validateClientID(clientID);
    const secretError = validateSecretID(secretID);
    setDatacenterError(datacenterError);
    setClientError(clientError);
    setSecretError(secretError);

    if (datacenterError || clientError || secretError) {
      return false;
    }

    return true;
  }, [datacenterID, clientID, secretID]);

  const handleSubmitRedirect = async () => {
    if (!integration.authProviderID) {
      return;
    }

    if (!validateForm()) {
      return;
    }

    const state = getRandomState();
    const auth = await dispatch(
      createQualtricsAuth({
        integration: integration,
        auth: {
          datacenterId: datacenterID,
          clientId: clientID,
          secretId: secretID,
          state: state,
          scopes: SURVEY_SOURCE_TO_SCOPES[SurveySourceEnum.QUALTRICS].join(' '),
          redirectUri: redirectUri(),
        },
        authProviderID: integration.authProviderID,
      }),
    );
    if (!auth) {
      onUpdateError('AUTHENTICATION ERROR');
      return;
    }

    redirectTo(state);
  };

  // Uncomment with using popup window instead redirect
  // const handleSubmitPopup = async () => {
  //   if (!integration.authProviderID) {
  //     return;
  //   }

  //   if (!validateForm()) {
  //     return;
  //   }

  //   const state = getRandomState();
  //   const auth = await dispatch(
  //     createQualtricsAuth({
  //       integration: integration,
  //       auth: {
  //         datacenterId: datacenterID,
  //         clientId: clientID,
  //         secretId: secretID,
  //         state: state,
  //         scopes: SURVEY_SOURCE_TO_SCOPES[SurveySourceEnum.QUALTRICS].join(' '),
  //         redirectUri: redirectUri(),
  //       },
  //       authProviderID: integration.authProviderID,
  //     }),
  //   );
  //   if (!auth) {
  //     onUpdateError('AUTHENTICATION ERROR');
  //     // be sure we close the popup window
  //     close();
  //     return;
  //   }
  //   await open(state);
  //   const success = await open(state);

  //   if (success) {
  //     dispatch(
  //       submitQualtricsAuth({
  //         auth: auth,
  //       }),
  //     );

  //     // be sure we close the popup window
  //     close();
  //     return;
  //   }

  //   dispatch(updateAuthError({ source: SurveySourceEnum.QUALTRICS, error: 'auth_client_failed' }));
  //   onUpdateError('AUTHENTICATION ERROR');

  //   // be sure we close the popup window
  //   close();
  // };

  const isSubmitDisabled = Boolean(clientError || datacenterError || secretError || isOpen || isSubmitting);

  const handleBlurDatacenterID = () => {
    setDatacenterError(validateDatacenterID(datacenterID));
  };

  const handleBlurClientID = () => {
    setClientError(validateClientID(clientID));
  };

  const handleBlurSecretID = () => {
    setSecretError(validateSecretID(secretID));
  };

  const handleChangeDatacenterID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDatacenterID(e.target.value);
    setDatacenterError('');
  };

  const handleChangeClientID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientID(e.target.value);
    setClientError('');
  };

  const handleChangeSecretID = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSecretID(e.target.value);
    setSecretError('');
  };

  function sendHelpMessage() {
    showNewMessages();
  }

  return (
    <AuthQualtrics
      datacenterID={datacenterID}
      clientID={clientID}
      secretID={secretID}
      datacenterError={datacenterError}
      clientError={clientError}
      secretError={secretError}
      isSubmitDisabled={isSubmitDisabled}
      isSubmitting={isSubmitting ?? false}
      onChangeDatacenterID={handleChangeDatacenterID}
      onChangeClientID={handleChangeClientID}
      onChangeSecretID={handleChangeSecretID}
      onBlurDatacenterID={handleBlurDatacenterID}
      onBlurClientID={handleBlurClientID}
      onBlurSecretID={handleBlurSecretID}
      onSendHelpMessage={sendHelpMessage}
      onSubmit={handleSubmitRedirect}
    />
  );
}

export default AuthQualtricsContainer;
