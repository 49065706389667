import React from 'react';

import FileUploadSurvey from './FileUploadSurvey';
import { IFile } from '../types';

interface IFileUploadSurveyContainer {
  fileName: string;
  files: { [fileName: string]: IFile };
  filesRef: React.MutableRefObject<{ [fileName: string]: IFile }>;
  handleFilesChange: (files: { [fileName: string]: IFile }) => void;
}

function FileUploadSurveyContainer({ fileName, files, filesRef, handleFilesChange }: IFileUploadSurveyContainer) {
  const handleFileDelete = (fileName: string) => {
    const newFiles = { ...filesRef.current };
    delete newFiles[fileName];
    handleFilesChange(newFiles);
  };

  if (!files[fileName]) {
    // handle the case when fileName is not found in files. Necessary for cypress test.
    return null;
  }

  return <FileUploadSurvey fileName={fileName} status={files[fileName].status} onDeleteFile={handleFileDelete} />;
}

export default FileUploadSurveyContainer;
