import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DateRangeOutlinedIcon from '@material-ui/icons/DateRangeOutlined';
import { addDays, format } from 'date-fns';
import { DateRangePicker } from 'materialui-daterange-picker2';
import React, { useState } from 'react';
import styled from 'styled-components';
import LoadingLabel from '../../components/LoadingLabel/LoadingLabel';

import palette from '../../components/tools/palette';
import { FETCH_STATUS } from '../../constants';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';

const OUTPUT_FORMAT = 'yyyy-MM-dd';
const DISPLAY_FORMAT = 'LLL d, yyyy';

const Container = styled(Box)`
  background-color: #fff;
  border: 1px solid ${palette.lightGray};
  border-radius: 8px;
  padding: 8px 16px;
`;

const DateRangeButton = styled(Button)<{ selected: boolean }>`
  &.MuiButton-root {
    text-transform: none;
    font-family:
      'Rubik',
      -apple-system,
      BlinkMacSystemFont,
      'Segoe UI',
      'Roboto',
      'Oxygen',
      'Ubuntu',
      'Cantarell',
      'Fira Sans',
      'Droid Sans',
      'Helvetica Neue',
      sans-serif;
    font-size: 16px;
    color: ${(props) => (props.selected ? '#fff' : palette.black)};
    background-color: ${(props) => (props.selected ? palette.beehivePurple : '#fff')};
    border-color: ${palette.lightGray};
  }
`;
const CustomLoadingLabel = styled(LoadingLabel)`
  &&& {
    align-items: center;
    margin-left: 10px;
    width: fit-content;
  }
`;

const useStyles = makeStyles(() => ({
  wrapper: {
    position: 'absolute',
    width: 'max-content',
  },
}));

const TODAY = new Date();

export const DATE_RANGE_ID = {
  CUSTOM: 'CUSTOM',
  TODAY: 'TODAY',
  YESTERDAY: 'YESTERDAY',
  PERIOD_7D: 'PERIOD_7D',
  PERIOD_30D: 'PERIOD_30D',
  ALL: 'ALL',
};
export const DATE_RANGE = {
  [DATE_RANGE_ID.CUSTOM]: {
    label: 'Custom',
    icon: <DateRangeOutlinedIcon />,
  },
  [DATE_RANGE_ID.TODAY]: {
    label: 'Today',
    startDate: TODAY,
    endDate: TODAY,
  },
  [DATE_RANGE_ID.YESTERDAY]: {
    label: 'Yesterday',
    startDate: addDays(TODAY, -1),
    endDate: addDays(TODAY, -1),
  },
  [DATE_RANGE_ID.PERIOD_7D]: {
    label: '7D',
    startDate: addDays(TODAY, -7),
    endDate: TODAY,
  },
  [DATE_RANGE_ID.PERIOD_30D]: {
    label: '30D',
    startDate: addDays(TODAY, -30),
    endDate: TODAY,
  },
  [DATE_RANGE_ID.ALL]: {
    label: 'All',
  },
};

const DATE_RANGE_LIST = [
  DATE_RANGE_ID.CUSTOM,
  DATE_RANGE_ID.TODAY,
  DATE_RANGE_ID.YESTERDAY,
  DATE_RANGE_ID.PERIOD_7D,
  DATE_RANGE_ID.PERIOD_30D,
  DATE_RANGE_ID.ALL,
];

const detectSelectedId = (value: any) => {
  if (!value) {
    return DATE_RANGE_ID.ALL;
  }

  for (const id of DATE_RANGE_LIST) {
    if (id === DATE_RANGE_ID.CUSTOM || id === DATE_RANGE_ID.ALL) {
      continue;
    }
    const dateRange = DATE_RANGE[id];
    if (dateRange.startDate !== undefined && dateRange.endDate !== undefined) {
      if (format(dateRange.startDate, OUTPUT_FORMAT) === value.startDate && format(dateRange.endDate, OUTPUT_FORMAT) === value.endDate) {
        return id;
      }
    }
  }
  return DATE_RANGE_ID.CUSTOM;
};

const formatDateRange = (value: any) => {
  if (!value) {
    return '';
  }
  const startDate = format(new Date(value.startDate), DISPLAY_FORMAT);
  const endDate = format(new Date(value.endDate), DISPLAY_FORMAT);
  return `${startDate} - ${endDate}`;
};

interface IDateRangeFilter {
  onChange: (value: any, dataRangeId: string) => void;
  value: null | { startDate: string; endDate: string };
  status: string;
}

const DateRangeFilter = ({ onChange, value, status }: IDateRangeFilter) => {
  const [selectedId, setSelectedId] = useState(detectSelectedId(value));
  const [dateRange, setDateRange] = useState(formatDateRange(value));
  const [isOpen, setIsOpen] = useState(false);
  const classes = useStyles();

  const track = (dateRange: any, start = null, end = null) => {
    eventsTracker.track(EVENTS.DASHBOARD_DATE_FILTER, {
      'Date Range': dateRange,
      'Custom Start': start,
      'Custom End': end,
    });
  };

  const handleChange = (value: any, dataRangeId = DATE_RANGE_ID.CUSTOM) => {
    setDateRange(formatDateRange(value));
    if (value) {
      const startDate = format(value.startDate, OUTPUT_FORMAT);
      const endDate = format(value.endDate, OUTPUT_FORMAT);
      onChange({ startDate, endDate }, value ? value : null);
      track(DATE_RANGE[dataRangeId].label);
    } else {
      onChange(null, DATE_RANGE[DATE_RANGE_ID.ALL].label);
      track(DATE_RANGE[dataRangeId].label);
    }
  };

  const handleClick = (id: string) => {
    setSelectedId(id);
    switch (id) {
      case DATE_RANGE_ID.CUSTOM:
        setIsOpen(true);
        break;
      case DATE_RANGE_ID.ALL:
        handleChange(null, id);
        break;
      default:
        handleChange(DATE_RANGE[id], id);
    }
  };

  const handleToggle = () => {
    setIsOpen(false);
  };
  const initialDateRange = value ? { startDate: new Date(value.startDate), endDate: new Date(value.endDate) } : {};
  return (
    <Container>
      <Box display='flex'>
        <ButtonGroup size='small'>
          {DATE_RANGE_LIST.map((dateRangeId) => (
            <DateRangeButton
              key={dateRangeId}
              selected={selectedId === dateRangeId}
              startIcon={DATE_RANGE[dateRangeId].icon}
              onClick={() => handleClick(dateRangeId)}
            >
              {dateRangeId === DATE_RANGE_ID.CUSTOM && dateRange ? dateRange : DATE_RANGE[dateRangeId].label}
            </DateRangeButton>
          ))}
        </ButtonGroup>
        <CustomLoadingLabel loading={status === FETCH_STATUS.LOADING} showText={false} />
      </Box>
      {isOpen && (
        <DateRangePicker
          minDate={new Date('1900/1/1')}
          initialDateRange={initialDateRange}
          onChange={handleChange}
          open={isOpen}
          toggle={handleToggle}
          wrapperClassName={classes.wrapper}
        />
      )}
    </Container>
  );
};

export default DateRangeFilter;
