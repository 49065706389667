import { Middleware } from 'redux';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import { QueryStatus } from '../../../types/DashboardQuery';
import {
  DASHBOARD_QUERIES_DEINITIALIZE,
  DASHBOARD_QUERIES_INITIALIZE,
  DASHBOARD_QUERIES_SEND_QUERY,
  DASHBOARD_QUERIES_STOP_QUERY,
  initialize,
  receivePong,
  receiveQueryUpdate,
  sendQuery,
} from '../../actions/dashboardQueries';
import { TypedDispatch } from '../../hooks';
import { IApplicationState } from '../../reducers';
import {
  AnswerStatus,
  IncomingMessage,
  IncomingMessagePayload,
  IncomingMessageTypes,
  OutgoingMessage,
  OutgoingMessageTypes,
} from './types';

let socketConnection: WS | null = null;

const middleware: Middleware<object, IApplicationState, TypedDispatch> =
  ({ dispatch, getState }) =>
  (next) =>
  (action) => {
    if (!action) {
      return;
    }
    let startTimeStamp: number | undefined;
    if (action?.type === DASHBOARD_QUERIES_INITIALIZE) {
      const typedAction = action as ReturnType<typeof initialize>;
      const { companyName, dashboardID } = typedAction.payload;

      if (socketConnection) {
        console.error('Existing connection is present');
      }
      socketConnection = new WS(
        companyName,
        dashboardID,
        (type: IncomingMessageTypes, message: IncomingMessagePayload) => {
          switch (type) {
            case IncomingMessageTypes.Answer:
            case IncomingMessageTypes.Cancel:
              const sessionID = getState().dashboardQueries.sessionID;

              if (message.answer?.message.length) {
                eventsTracker.track(EVENTS.AI_AGENT_RESPONSE_LOADED, {
                  'Debug Session ID': sessionID.length ? sessionID : message.answer.message.split('session ID:* ')[1],
                  'Time to Response Loaded': `${(performance.now() - (startTimeStamp ?? 0)) / 1000} seconds`,
                });
              }
              startTimeStamp = undefined;
              dispatch(receiveQueryUpdate(message));
              break;
            case IncomingMessageTypes.Pong:
              const { aiVersion: dashboardQueriesVersion, wasInitialPongReceived } = getState().dashboardQueries;
              if (dashboardQueriesVersion !== message.version || !wasInitialPongReceived) {
                dispatch(receivePong({ version: message.version || '' }));
              }
              break;
          }
        },
        (error) => {
          const sessionID = getState().dashboardQueries.sessionID;
          const latestQuery = getState().dashboardQueries.queries[0];
          const companyName = getState().profile.company?.name ?? '';
          const userID = getState().profile.user?.id ?? '';
          const dashboardID = getState().dashboard.dashboard?.id ?? '';

          eventsTracker.track(EVENTS.AI_AGENT_CONNECTION_LOST, {
            'Dashboard ID': dashboardID,
            'Workspace Name': companyName,
            'Debug Session ID': sessionID,
            'User ID': userID,
            'Reason for Lost Connection': error,
          });

          if (latestQuery?.status !== QueryStatus.FAILURE_STATUS) {
            dispatch(
              receiveQueryUpdate({
                status: AnswerStatus.FAILURE_STATUS,
                error,
              }),
            );
          }
        },
      );
    }

    if (action?.type === DASHBOARD_QUERIES_SEND_QUERY) {
      const typedAction = action as ReturnType<typeof sendQuery>;
      const { query } = typedAction.payload;

      if (!socketConnection) {
        console.error('No existing connection is present');
      } else {
        startTimeStamp = performance.now();
        socketConnection.sendMessage({
          type: OutgoingMessageTypes.Question,
          payload: {
            question: query,
          },
        });
      }
    }

    if (action?.type === DASHBOARD_QUERIES_STOP_QUERY) {
      if (!socketConnection) {
        console.error('No existing connection is present');
      } else {
        startTimeStamp = undefined;
        socketConnection.sendMessage({
          type: OutgoingMessageTypes.Cancel,
        });
      }
    }

    if (action?.type === DASHBOARD_QUERIES_DEINITIALIZE) {
      if (!socketConnection) {
        console.error('No existing connection is present');
      } else {
        socketConnection.close();
        socketConnection = null;
      }
    }

    return next(action);
  };

type HandleMessageReceive = (type: IncomingMessageTypes, message: IncomingMessagePayload) => void;

class WS {
  _connection?: WebSocket;
  _isReady = false;

  _reconnectInterval = 5000;
  _reconnectIntervalID?: NodeJS.Timeout;

  _pingInterval = 10000;
  _pingIntervalID?: NodeJS.Timeout;

  _companyName: string;
  _dashboardID: string;

  _handleMessageReceive: HandleMessageReceive;
  _handleError: (error?: string) => void;

  constructor(companyName: string, dashboardID: string, handleMessageReceive: HandleMessageReceive, handleError: (error?: string) => void) {
    this._companyName = companyName;
    this._dashboardID = dashboardID;
    this._handleMessageReceive = handleMessageReceive;
    this._handleError = handleError;

    this._connectWebSocket();
  }

  _connectWebSocket() {
    const protocol = document.location.protocol === 'https:' ? 'wss:' : 'ws:';
    const url = `${protocol}//${document.location.host}/ws/company/${this._companyName}/dashboard/${this._dashboardID}/chat`;
    this._connection = new WebSocket(url);

    this._connection.onopen = () => {
      this._isReady = true;
      this.sendMessage({
        type: OutgoingMessageTypes.Ping,
      });
      this._pingIntervalID = setInterval(() => {
        this.sendMessage({
          type: OutgoingMessageTypes.Ping,
        });
      }, this._pingInterval);
    };

    this._connection.onclose = () => {
      this._isReady = false;
      if (this._pingIntervalID) {
        clearInterval(this._pingIntervalID);
        this._pingIntervalID = undefined;

        this._handleError('ws_connection_closed');
        this._tryReconnect();
      }
    };

    this._connection.onmessage = (event) => {
      const message = JSON.parse(event.data);
      const payload = JSON.parse(message.payload);

      this._handleIncomingMessage({ ...message, payload: { ...payload } }, this._handleMessageReceive, this._handleError);
    };

    this._connection.onerror = (error) => {
      this._handleError('ws_connection_error');
      this._tryReconnect();
      console.error('WebSocket error:', error);
    };
  }

  _tryReconnect() {
    if (this._connection && this._isReady) {
      return;
    }
    this._reconnectIntervalID = setTimeout(() => {
      this._connectWebSocket();
      this._reconnectIntervalID = undefined;
    }, this._reconnectInterval);
  }

  _handleIncomingMessage(messageData: IncomingMessage, handleMessageReceive: HandleMessageReceive, handleError: (error?: string) => void) {
    switch (messageData.type) {
      case IncomingMessageTypes.Pong:
        handleMessageReceive(messageData.type, messageData.payload);
        break;
      case IncomingMessageTypes.Cancel:
      case IncomingMessageTypes.Answer:
        switch (messageData.payload.status) {
          case AnswerStatus.ANALYZING_DATA_STATUS:
          case AnswerStatus.COLLECTING_DATA_STATUS:
          case AnswerStatus.GENERATING_ANSWER_STATUS:
          case AnswerStatus.SUCCESS_STATUS:
          case AnswerStatus.CANCEL_STATUS:
            handleMessageReceive(messageData.type, messageData.payload);
            break;
          case AnswerStatus.FAILURE_STATUS:
            handleError(messageData.payload.error);
            break;
          default:
            handleError('Unexpected error happened during response generation');
        }
        break;
    }
  }

  sendMessage(messageData: OutgoingMessage) {
    if (this._isReady && this._connection) {
      try {
        this._connection.send(JSON.stringify(messageData));
      } catch (e) {
        console.debug(JSON.stringify(e));
        this._handleError(e as string);
      }
    } else {
      console.error('Cannot send message while there is no socket connection');
      this._handleError('ws_connection_not_ready');
    }
  }

  close() {
    this._connection?.close();
    this._connection = undefined;
    if (this._reconnectIntervalID) {
      clearTimeout(this._reconnectIntervalID);
      this._reconnectIntervalID = undefined;
    }
    if (this._pingIntervalID) {
      clearInterval(this._pingIntervalID);
      this._pingIntervalID = undefined;
    }
  }
}

export default middleware;
