import SidebarItem from './SidebarItem';
import SvgIcon from '@material-ui/core/SvgIcon';
import { useHistory } from 'react-router-dom';
import { itemColors } from './style';

const ConnectionsIcon = (props) => (
  <SvgIcon viewBox='0 0 17 20' {...props}>
    <path
      d='M7.64054 9.01763C6.37869 10.0379 5.96929 10.8345 6.11072 11.9599C6.20781 12.7326 6.612 13.4252 7.05628 14.0647L8.84414 16.6384C9.38728 17.4203 10.1909 18.2682 11.0769 18.6166V18.6166C12.4717 19.165 13.9201 18.5056 14.8677 17.3443C15.1982 16.9392 15.4262 16.5196 15.5622 16.0418C15.863 14.9849 15.4756 13.8685 14.9173 12.9221C14.3929 12.0331 13.903 11.4322 13 10.5'
      strokeWidth='2'
      strokeLinecap='round'
      fill='transparent'
    />
    <path
      d='M9.14452 10.818C10.3961 9.78517 10.7975 8.9845 10.6447 7.8605C10.5399 7.08885 10.1288 6.40036 9.67812 5.76529L7.86453 3.20966C7.31357 2.43326 6.50151 1.59345 5.61206 1.254V1.254C4.21176 0.719593 2.77007 1.39355 1.83419 2.56426C1.50773 2.97264 1.28401 3.3945 1.15279 3.87367C0.862565 4.93348 1.26122 6.04595 1.82895 6.98674C2.36221 7.87041 2.85818 8.46642 3.77046 9.38947'
      strokeWidth='2'
      strokeLinecap='round'
      fill='transparent'
    />
  </SvgIcon>
);

const SidebarItemConnections = ({ isSelected, onClick, to }) => {
  const history = useHistory();
  const handleClick = () => {
    onClick();
    history.push(to);
  };

  return (
    <SidebarItem
      icon={<ConnectionsIcon style={{ stroke: isSelected ? itemColors.selectedColor : itemColors.color }} />}
      onClick={handleClick}
      selected={isSelected}
      tooltip='Connections'
    />
  );
};

export default SidebarItemConnections;
