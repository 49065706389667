import { FETCH_STATUS_STATE } from '../../constants';
import {
  PROFILE_SET_COMPANIES,
  PROFILE_SET_COMPANY,
  PROFILE_SET_CREATE_WORKSPACE_STATE,
  PROFILE_SET_HMAC,
  PROFILE_SET_USER,
  PROFILE_SET_USER_PROFILE,
  PROFILE_SET_USER_PROFILE_FETCH_STATE,
  PROFILE_SET_USER_PROFILE_UPDATE_STATE,
  profileSetCompanies,
  profileSetCompany,
  profileSetCreateWorkspaceState,
  profileSetHmac,
  profileSetUser,
  profileSetUserProfile,
  profileSetUserProfleFetchState,
  profileSetUserProfleUpdateState,
} from '../actions/profile';

const initialState: IProfileReducer = {
  user: null,
  userProfile: null,
  companies: null,
  company: null,
  hmac: null,
  isPasswordWrong: false,
  userProfileFetchState: FETCH_STATUS_STATE.IDLE,
  userProfileUpdateState: FETCH_STATUS_STATE.IDLE,
  createWorkspaceState: FETCH_STATUS_STATE.IDLE,
};

export type User = {
  email: string;
  fullname: string;
  emailVerified: boolean;
  isAIAgentEnabled: boolean;
  id: string;
  isInternal: boolean;
  avatarURL?: string;
};

export type UserProfile = {
  firstName: string;
  lastName: string;
  country: string;
  position: string;
  requiredOTP: boolean;
  notifications: string[];
};

export type Company = {
  name: string;
  displayName: string;
  admin: boolean;
};

export interface IProfileReducer {
  user: null | User;
  userProfile: null | UserProfile;
  companies: null | Company[];
  company: null | Company;
  hmac: null | string;
  isPasswordWrong: boolean;
  userProfileFetchState: FETCH_STATUS_STATE;
  userProfileUpdateState: FETCH_STATUS_STATE;
  createWorkspaceState: FETCH_STATUS_STATE;
}

type overviewReducerActionTypes = ReturnType<typeof profileSetUser> &
  ReturnType<typeof profileSetCompanies> &
  ReturnType<typeof profileSetHmac> &
  ReturnType<typeof profileSetCompany> &
  ReturnType<typeof profileSetCreateWorkspaceState> &
  ReturnType<typeof profileSetUserProfile> &
  ReturnType<typeof profileSetUserProfleFetchState> &
  ReturnType<typeof profileSetUserProfleUpdateState>;

const profileReducer = (state = initialState, action: overviewReducerActionTypes) => {
  switch (action.type) {
    case PROFILE_SET_USER:
      return {
        ...state,
        user: action.payload.user,
      };
    case PROFILE_SET_COMPANIES:
      return {
        ...state,
        companies: action.payload.companies,
      };
    case PROFILE_SET_HMAC:
      return {
        ...state,
        hmac: action.payload.hmac,
      };
    case PROFILE_SET_COMPANY:
      return {
        ...state,
        company: action.payload.company,
      };
    case PROFILE_SET_CREATE_WORKSPACE_STATE:
      return {
        ...state,
        createWorkspaceState: action.payload.state,
        isPasswordWrong: action.payload.isPasswordWrong,
      };
    case PROFILE_SET_USER_PROFILE:
      return {
        ...state,
        userProfile: action.payload.userProfile,
      };
    case PROFILE_SET_USER_PROFILE_FETCH_STATE:
      return {
        ...state,
        userProfileFetchState: action.payload.state,
      };
    case PROFILE_SET_USER_PROFILE_UPDATE_STATE:
      return {
        ...state,
        userProfileUpdateState: action.payload.state,
      };
    default:
      return state;
  }
};

export default profileReducer;
