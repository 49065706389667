import { Property } from 'csstype';

export type CONNECTORS_COLUMN = {
  width: Property.Width;
  align: Property.TextAlign;
};

export type CONNECTORS_COLUMN_STYLE = {
  width: Property.Width;
  maxWidth: Property.MaxWidth;
  minWidth: Property.MinWidth;
  textAlign: Property.TextAlign;
};

export interface IFile {
  file: File;
  status: FileStatus;
  source: string;
  error: string | null;
}

export enum FileUploadSystemError {
  UNKNOWN = 'Unknown error',
  INVALID_FILE_TYPE = 'Wrong file type',
}

export enum FileStatus {
  UPLOADED = 'Uploaded',
  ERROR = 'Error',
  DEFAULT = 'Default',
}

export enum ScheduleEnum {
  HOURLY = 60 * 60,
  DAILY = 60 * 60 * 24,
  WEEKLY = 60 * 60 * 24 * 7,
  ONE_TIME = 0,
}

export const ScheduleToReadable = {
  [ScheduleEnum.HOURLY]: 'Hourly',
  [ScheduleEnum.DAILY]: 'Daily',
  [ScheduleEnum.WEEKLY]: 'Weekly',
  [ScheduleEnum.ONE_TIME]: 'One Time',
};

export enum ExportTypeEnum {
  Standard = 'standard',
  Flat = 'flat',
  NotAggregate = 'notAggregate',
}

export enum TabEnum {
  INTEGRATIONS = 'integrations',
}

export const DashboardVisibility = {
  WORKSPACE: 'workspace',
  PRIVATE: 'private',
};
