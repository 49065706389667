import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import React, { useState } from 'react';
import { createPortal } from 'react-dom';

import Loading from '../../components/LoadingLabel/LoadingLabel';
import { Dashboard, DashboardFilterDateRange } from '../../store/reducers/dashboards/types';
import FileUploadFlow from '../../components/FileUploadFlow/FileUploadFlowContainer';
import { Survey } from '../../store/reducers/dashboard/types';

import DateRangeFilter from './DateRangeFilter';
import HiveDeleteConfirmationDialog from './HiveDeleteConfirmationDialog';
import HiveEditDialog from './HiveEditDialog';
import Hives from './Hives';
import HivesBannerContainer from './HivesBannerContainer';
import HivesSidebar from './HivesSidebar';
import { Hive } from './HivesTabContainer';
import { HiveStatusDetailedView } from './HiveStatus';

interface IHivesTab {
  isHiveEditDialogOpen: boolean;
  isDeleteConfirmationOpen: boolean;
  isInitialized: boolean;
  dashboards: Dashboard[];
  hives: Hive[];
  status: string;
  selectedHiveId: string | null;
  dateRange: DashboardFilterDateRange | null;
  selectedHive: Hive | null;
  onDateRangeChange: (value: any, dr: any) => void;
  onEditClick: (hive: Hive) => void;
  onHiveClick: (hiveId: string) => void;
  onSort: (field: string, order: string) => void;
  onCancelDelete: () => void;
  onRemoveConfirmation: () => void;
  onDialogClose: () => void;
  onDialogSaveClick: (hive: Hive) => void;
  onDialogDeleteClick: () => void;
}

function HivesTab({
  isHiveEditDialogOpen,
  isDeleteConfirmationOpen,
  isInitialized,
  dashboards,
  selectedHiveId,
  hives,
  selectedHive,
  dateRange,
  status,
  onHiveClick,
  onDateRangeChange,
  onEditClick,
  onSort,
  onCancelDelete,
  onRemoveConfirmation,
  onDialogClose,
  onDialogSaveClick,
  onDialogDeleteClick,
}: IHivesTab) {
  const [isDetailedStatusViewVisible, setIsDetailedStatusViewVisible] = useState(false);
  const [detailedStatusViewCoords, setDetailedStatusViewCoords] = useState({ top: 0, left: 0 });
  const [detailedStatusViewSurveys, setDetailedStatusViewSurveys] = useState<Survey[]>([]);

  return (
    <Box flexGrow={1}>
      {renderContent()}

      {isHiveEditDialogOpen && (
        <HiveEditDialog
          hive={selectedHive}
          dashboards={dashboards}
          onClose={onDialogClose}
          onSave={onDialogSaveClick}
          onDelete={onDialogDeleteClick}
        />
      )}
      {isDeleteConfirmationOpen && (
        <HiveDeleteConfirmationDialog
          onCancel={onCancelDelete}
          onConfirm={onRemoveConfirmation}
          open={isDeleteConfirmationOpen}
          title={selectedHive?.title}
        />
      )}
    </Box>
  );

  function handleToggleDetailedStatusView(shouldShowDetailedView: boolean, surveys: Survey[], top: number, left: number) {
    setIsDetailedStatusViewVisible(shouldShowDetailedView);
    setDetailedStatusViewCoords({ top, left });
    setDetailedStatusViewSurveys(surveys);
  }

  function renderDetailedView() {
    const { top, left } = detailedStatusViewCoords;
    return createPortal(
      <HiveStatusDetailedView
        surveys={detailedStatusViewSurveys}
        top={top + window.scrollY}
        left={left + window.scrollX}
        onMouseLeave={handleToggleDetailedStatusView.bind(null, false, [], 0, 0)}
      />,
      document.body,
    );
  }

  function renderContent() {
    if (!isInitialized) {
      return <Loading loading={true} style={{ marginTop: '20px' }} />;
    }
    return (
      <Grid container direction='row' style={{ padding: '20px' }}>
        <Grid item xs={2}>
          <HivesSidebar selectedHiveId={selectedHiveId} hives={hives} onClick={onHiveClick} onEditClick={onEditClick} />
        </Grid>
        <Grid item xs={10}>
          <HivesBannerContainer dashboardsLength={dashboards.length} />
          <Box display='flex' flexDirection='row'>
            <Box flexGrow={1}>
              {dashboards.length > 0 && <DateRangeFilter onChange={onDateRangeChange} value={dateRange} status={status} />}
            </Box>
            <FileUploadFlow fileUploadButtonTitle='Upload Data' />
          </Box>
          <Hives
            selectedHiveId={selectedHiveId}
            hives={hives}
            onEditClick={onEditClick}
            onSort={onSort}
            onToggleDetailedStatusView={handleToggleDetailedStatusView}
          />
          {isDetailedStatusViewVisible && renderDetailedView()}
        </Grid>
      </Grid>
    );
  }
}

export default HivesTab;
