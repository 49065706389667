import { LoadIndicator, LoadIndicatorContainer } from './styles';

function HorizontalLoader() {
  return (
    <LoadIndicatorContainer>
      <LoadIndicator />
    </LoadIndicatorContainer>
  );
}

export default HorizontalLoader;
