import React from 'react';
import styled from 'styled-components';
import { LABEL_NOT_APPLICABLE } from '../lib';
import { ReactComponent as ExpandIcon } from '../../../../../assets/icons/expand-icon.svg';
import createLabelsListComponent from './createLabesList';
import { ConceptPill } from '../../../components/Labels/ConceptPill';
import { Filters, Label } from '../../../../../types/Label';
import { Filter } from '../../../../../types/Query';
import PercentageBars from './PercentageBars';
import styles from './styles.module.css';

const ExpandIconStyled = styled(ExpandIcon)<{
  $isExpanded: boolean;
}>`
  width: 24px;
  height: 24px;
  cursor: pointer;
  transform: ${({ $isExpanded }) => ($isExpanded ? 'rotateX(180deg)' : 'none')};
  flex-shrink: 0;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: space-between;
`;

const RowLeft = styled.div`
  display: flex;
  height: 100%;
  gap: 8px;
  align-items: flex-start;
  flex-shrink: 1;
  max-width: calc(100% - 160px);
`;

const RowRight = styled.div`
  display: flex;
  height: 100%;
  flex-shrink: 0;
  max-width: 160px;
`;

interface IHierarchyLabelRenderer {
  label: Label;
  selected: boolean;
  opened: boolean;
  clickable: boolean;
  disabled: boolean;
  filtersById: {
    [p: string]: Filter;
  };
  selectedFilterIds: string[];
  isParent: boolean;
  average?: boolean;
  onToggleClick: () => void;
  onLabelClick: () => void;
}

function HierarchyLabelRenderer({
  label,
  selected,
  opened,
  clickable,
  disabled,
  average,
  filtersById,
  selectedFilterIds,
  isParent,
  onLabelClick,
  onToggleClick,
}: IHierarchyLabelRenderer) {
  const labelText = label.title === LABEL_NOT_APPLICABLE ? 'Not Applicable' : label.displayTitle;
  const onlyActiveSegments = selectedFilterIds.reduce((result, filterID) => {
    result[filterID] = label.filters[filterID];
    return result;
  }, {} as Filters);
  return (
    <Row data-label={labelText}>
      <RowLeft>
        {label.hasChild && <ExpandIconStyled $isExpanded={opened} onClick={onToggleClick} />}
        <ConceptPill
          text={labelText}
          className={isParent ? styles.reducedPillContainer : ''}
          onClick={onLabelClick}
          isClickable={clickable}
          isDisabled={disabled}
          isSelected={selected}
          isParent={isParent}
        />
      </RowLeft>
      <RowRight>
        <PercentageBars
          dataByFilterId={onlyActiveSegments}
          average={average}
          filtersById={filtersById}
          selectedFilterIds={selectedFilterIds}
        />
      </RowRight>
    </Row>
  );
}

export default createLabelsListComponent(HierarchyLabelRenderer);
