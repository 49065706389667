import React from 'react';
import StatusPanel from './StatusPanel';
import { DashboardSentimentSummariesSentiment } from '../../../../store/reducers/dashboard/types';
import { useAppSelector } from '../../../../store/hooks';

interface IStatusPanelContainer {
  dataSourceID: string;
  duration: number;
  topicsCount: number;
  total: number;
  hasSentiment: boolean;
  sentimentSummary: DashboardSentimentSummariesSentiment;
}

function StatusPanelContainer({ dataSourceID, duration, topicsCount, total, hasSentiment, sentimentSummary }: IStatusPanelContainer) {
  const participants = useAppSelector((state) => state.interviewWidgets.participantsByDataSourceID[dataSourceID]);
  const participantsState = useAppSelector((state) => state.interviewWidgets.participantsStatesByDataSourceID[dataSourceID]);

  const isParticipantsLoading = participantsState?.isLoading ?? false;

  return (
    <StatusPanel
      duration={duration}
      isParticipantsLoading={isParticipantsLoading}
      participantsCount={participants?.length ?? 0}
      participants={participants}
      topicsCount={topicsCount}
      total={total}
      hasSentiment={hasSentiment}
      sentimentSummary={sentimentSummary}
    />
  );
}

export default StatusPanelContainer;
