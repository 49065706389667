import React, { useMemo } from 'react';

import { Concept, GenerationStatus as CurrentGenerationStatus } from '../../../../store/reducers/aiInsights';
import { ProgressIndicator } from '../../../ProgressIndicator/ProgressIndicator';
import InsightsButton from '../../OpenEndedWidget/Data/AIInsights/Components/InsightsButton';
import {
  AIInsightContainer,
  FailureIcon,
  HeaderContainer,
  HeaderContainerLeftPart,
  StyledBeehiveLogo,
} from '../../OpenEndedWidget/Data/AIInsights/Components/styles';
import InsightPrompt from '../InsightPrompt/InsightPrompt';
import { ActionsContainer, LoadingContainer, StyledReloadIcon } from './styles';

interface IGenerationStatus {
  id: string;
  statuses: CurrentGenerationStatus[];
  availableFilters: string[];
  isWidgetLevelFilterApplied: boolean;
  selectedConcept?: Concept;
  customPrompt?: string;
  segmentsNames?: string[];
  onTryAgainClick: () => void;
}

function GenerationStatus({
  id,
  availableFilters,
  isWidgetLevelFilterApplied,
  customPrompt,
  selectedConcept,
  statuses,
  segmentsNames,
  onTryAgainClick,
}: IGenerationStatus) {
  const lastStatus = useMemo(() => statuses[statuses.length - 1], [statuses]);

  return (
    <AIInsightContainer data-testid={`generation-status-${id}`}>
      <HeaderContainer>
        <HeaderContainerLeftPart>
          {renderStatusIcon()}
          {renderPrompt()}
        </HeaderContainerLeftPart>
      </HeaderContainer>
      <ActionsContainer>{renderActionButton()}</ActionsContainer>
    </AIInsightContainer>
  );

  function renderStatusIcon() {
    if (lastStatus === CurrentGenerationStatus.FAILURE) {
      return <FailureIcon data-testid='failure-icon' />;
    }

    return <StyledBeehiveLogo data-testid='success-icon' />;
  }

  function renderPrompt() {
    if (lastStatus === CurrentGenerationStatus.FAILURE) {
      return <div data-testid='prompt'>Insights cannot be generated at this time. Please try again later.</div>;
    }

    return (
      <InsightPrompt
        data-testid='prompt'
        isWidgetLevelFilterApplied={isWidgetLevelFilterApplied}
        availableFilters={availableFilters}
        customPrompt={customPrompt}
        selectedConcept={selectedConcept}
        segmentsNames={segmentsNames}
      />
    );
  }

  function renderActionButton() {
    if (lastStatus === CurrentGenerationStatus.FAILURE) {
      return (
        <InsightsButton onClick={onTryAgainClick}>
          <LoadingContainer data-testid='generate-button'>
            <StyledReloadIcon /> Generate
          </LoadingContainer>
        </InsightsButton>
      );
    }

    return (
      <LoadingContainer data-testid='loading-indicator'>
        <ProgressIndicator /> Analyzing
      </LoadingContainer>
    );
  }
}

export default GenerationStatus;
