import React, { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../../store/hooks';
import { MappingState } from '../../../../../../store/reducers/fileMapping/types';
import { reportIssue, requestTemplate } from '../../../../../../store/thunks/fileMapping';
import RequestBeehive from './RequestBeehive';

export enum FLOW_STATES {
  IDLE = 'IDLE',
  PROMPT = 'PROMPT',
  FINISH = 'FINISH',
}

function RequestBeehiveContainer() {
  const dispatch = useAppDispatch();
  const { isLoading: isLoadingState } = useAppSelector((state) => state.fileMapping);

  const [previousState, setPreviousState] = useState(MappingState.DEFAULT);
  const isLoading = !!isLoadingState[MappingState.REQUESTING_TEMPLATE];
  const [flowState, setFlowState] = useState<FLOW_STATES>(FLOW_STATES.IDLE);

  useEffect(
    function updateFlowState() {
      if (!isLoading && previousState === MappingState.REQUESTING_TEMPLATE) {
        setFlowState(FLOW_STATES.FINISH);
        setPreviousState(MappingState.DEFAULT);
      } else if (isLoading) {
        setPreviousState(MappingState.REQUESTING_TEMPLATE);
      }
    },
    [isLoading],
  );

  function handleReportIssueButtonClick() {
    setFlowState(FLOW_STATES.PROMPT);
  }

  function handleCancelButtonClick() {
    setFlowState(FLOW_STATES.IDLE);
  }

  function handleSendButtonClick(message: string) {
    if (isLoading || message.trim() === '') {
      return;
    }
    dispatch(requestTemplate(message));
  }

  return (
    <RequestBeehive
      flowState={flowState}
      onReportIssueButtonClick={handleReportIssueButtonClick}
      onCancelButtonClick={handleCancelButtonClick}
      onSendButtonClick={handleSendButtonClick}
    />
  );
}

export default RequestBeehiveContainer;
