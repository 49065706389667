import { useContext, useEffect, useState } from 'react';

import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import useQualtricsRedirect from '../../../../hooks/oauth/useQualtricsRedirect';
import useOAuthRedirect from '../../../../hooks/oauth/useOAuthRedirect';
import { SurveySourceEnum } from '../../../../types/connections';
import { NotificationsContext } from '../../../Notification/NotificationsContext';
import { NOTIFICATIONS } from '../../../Notification/constants';
import { SEVERITY } from '../../../Notification/types';
import { SURVEY_SOURCE_TO_SCOPES } from '../../constants';

interface IConfig {
  datacenterId: string;
  clientId: string;
  state: string;
}

interface IReAuthQualtricsContainer {
  onClose: () => void;
}

function ReAuthQualtricsContainer({ onClose }: IReAuthQualtricsContainer) {
  const dispatch = useAppDispatch();

  const { addNotification } = useContext(NotificationsContext);
  const integration = useAppSelector((state) => state.connectors).selectedIntegration;

  // Uncomment with using popup window instead redirect
  // const [isPrimaryInitialized, setIsPrimaryInitialized] = useState(false);

  const [datacenterID, setDatacenterID] = useState('');
  const [clientID, setClientID] = useState('');
  const [state, setState] = useState('');

  const { redirect } = useQualtricsRedirect(datacenterID, clientID, SURVEY_SOURCE_TO_SCOPES[SurveySourceEnum.QUALTRICS]);

  // Uncomment with using popup window instead redirect
  //const { open } = useOAuthRedirect(redirect, 'Qualtrics');

  const { redirectTo } = useOAuthRedirect(redirect, 'Qualtrics');

  useEffect(() => {
    if (integration?.source !== SurveySourceEnum.QUALTRICS || !integration.authentication) {
      onClose();
      return;
    }

    const config: IConfig = JSON.parse(integration.authentication.config);
    if (!config.datacenterId || !config.clientId || !config.state) {
      addNotification({
        id: NOTIFICATIONS.ConnectorsReAuthError,
        title: `Failed to reauthorize Qualtrics.`,
        severity: SEVERITY.ERROR,
      });
      onClose();
      return;
    }

    setDatacenterID(config.datacenterId);
    setClientID(config.clientId);
    setState(config.state);
  }, [integration, onClose, addNotification]);

  useEffect(() => {
    if (!datacenterID || !clientID || !state) {
      return;
    }
    redirectTo(state);
  }, [datacenterID, clientID, state, integration, addNotification, dispatch]);

  // Uncomment with using popup window instead redirect
  // useEffect(() => {
  //   if (!datacenterID || !clientID || !state) {
  //     return;
  //   }
  //   if (isPrimaryInitialized) {
  //     return;
  //   }

  //   setIsPrimaryInitialized(true);
  //   open(state)
  //     .then((success) => {
  //       if (!success) {
  //         addNotification({
  //           id: NOTIFICATIONS.ConnectorsReAuthError,
  //           title: `Failed to reauthorize Qualtrics.`,
  //           severity: SEVERITY.ERROR,
  //         });
  //         return;
  //       }
  //       dispatch(
  //         submitQualtricsAuth({
  //           auth: integration!.authentication!,
  //         }),
  //       );
  //       addNotification({
  //         id: NOTIFICATIONS.ConnectorsReAuthSuccess,
  //         title: `Qualtrics successfully reauthorized.`,
  //         severity: SEVERITY.SUCCESS,
  //       });
  //     })
  //     .finally(() => {
  //       onClose();
  //     });
  // }, [datacenterID, clientID, state, isPrimaryInitialized, integration, redirect, open, onClose, addNotification, dispatch]);

  return <></>;
}

export default ReAuthQualtricsContainer;
