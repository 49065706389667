import { v4 } from 'uuid';

import { FileStatus, IFile, ScheduleEnum, ScheduleToReadable } from '../../../components/ConnectorsTab/types';
import * as api from '../../../components/tools/api';
import { EVENTS, eventsTracker } from '../../../services/EventTrackerService';
import {
  AuthProvider,
  AuthSourceEnum,
  BaseConnection,
  BaseProviderAuthentication,
  BaseSurvey,
  ConnectionUpdate,
  CsvUploadErrorEnum,
  Integration,
  OperationTypeEnum,
  ProviderAuthentication,
  SurveySourceEnum,
} from '../../../types/connections';
import {
  closeConnectionsModal,
  closeDeleteAuthModal,
  closeDeleteConnectionModal,
  removeAuth,
  removeConnection,
  resetUploadResult,
  setAuth,
  updateConnection as updateConnectionAction,
  updateConnectionUpdateResult,
  updateConnectors,
  updateConnectorsLoading,
  updateLastAddedConnection,
  updateLastDeletedAuth,
  updateLastDeletedConnection,
  updateLastEditedAuth,
  updateSubmittingAddConnection,
  updateSubmittingAuth,
  updateSubmittingConnectionUpdate,
  updateSubmittingDeleteAuth,
  updateSubmittingDeleteConnection,
  updateSubmittingUpload,
  updateSurveysLoading,
  updateUploadResult,
} from '../../actions/connectors';
import { AppDispatch } from '../../hooks';
import { IApplicationState } from '../../reducers';
import { IConnectorsReducer } from '../../reducers/connectors/reducer';
import { UploadSuccessResult } from '../../reducers/connectors/types';
import store from '../../store';

import { assembleIntegrations, groupSurveysBySource, UnsupportedConnectorsSources } from './utils';
import { fileMappingInit } from '../../actions/fileMapping';

export const fetchSurveys = () => async (dispatch: AppDispatch) => {
  const startTime = performance.now();
  dispatch(updateSurveysLoading({ loading: true }));
  const surveysRes: BaseSurvey[] | undefined = await api.fetchSurveys();
  if (!surveysRes) {
    dispatch(updateSurveysLoading({ loading: false, error: true }));
    const endTime = performance.now();
    eventsTracker.track(EVENTS.CONNECTIONS_FETCH_CONNECTORS, {
      'Processing Time': endTime - startTime,
      'Is Success': false,
    });
    return;
  }
  const surveyGroups = groupSurveysBySource(surveysRes);

  const newIntegrations = assembleIntegrations([], [], surveyGroups, []).filter(({ source }) =>
    UnsupportedConnectorsSources.includes(source),
  );
  dispatch(
    updateConnectors({
      integrations: newIntegrations,
    }),
  );
  dispatch(updateSurveysLoading({ loading: false }));
};
export const fetchConnectors = () => async (dispatch: AppDispatch) => {
  const startTime = performance.now();
  dispatch(updateConnectorsLoading({ loading: true }));
  const [connectionsRes, authProviderRes, authRes]: [
    BaseConnection[] | undefined,
    AuthProvider[] | undefined,
    BaseProviderAuthentication[] | undefined,
  ] = await Promise.all([api.fetchConnections(), api.fetchAuthProviders(), api.fetchProviderAuths()]);
  if (!connectionsRes || !authRes || !authProviderRes) {
    dispatch(updateConnectorsLoading({ loading: false, error: true }));
    return;
  }
  const surveyGroups = groupSurveysBySource();

  const newIntegrations = assembleIntegrations(authProviderRes, authRes, surveyGroups, connectionsRes).filter(
    ({ source }) => !UnsupportedConnectorsSources.includes(source),
  );

  dispatch(updateConnectors({ integrations: newIntegrations }));
  dispatch(updateConnectorsLoading({ loading: false }));
  const endTime = performance.now();
  eventsTracker.track(EVENTS.CONNECTIONS_FETCH_CONNECTORS, {
    'Processing Time': endTime - startTime,
    'Is Success': true,
  });
};

export const resetConnectors = () => (dispatch: AppDispatch) => {
  dispatch(updateConnectors({ integrations: assembleIntegrations() }));
};

interface ICreateConnection {
  operation: OperationTypeEnum;
  source: SurveySourceEnum;
  config: Record<string, any>;
  startDate: Date;
}

export const createConnection =
  ({ operation, source, config, startDate }: ICreateConnection) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();

    dispatch(updateSubmittingAddConnection({ submitting: true }));
    const res = await api.createConnection(operation, source, config);
    dispatch(updateSubmittingAddConnection({ submitting: false }));
    if (res) {
      dispatch(fetchConnectors());
      dispatch(updateLastAddedConnection({ success: true, source, type: operation }));
    } else {
      dispatch(updateLastAddedConnection({ success: false, source, type: operation }));
    }
    const endTime = performance.now();
    eventsTracker.track(EVENTS.CONNECTIONS_CONNECTION_ESTABLISHED, {
      'Processing Time': endTime - startTime,
      'App/Integration name': source,
      Operation: operation,
      'Is Success': !!res,
      'File/Dashboard Selected': config.fileName || config.dashboardName,
      'Selected Language': config.language,
      'Selected Brand': config.brandID,
      'Selected Export Type': config.exportType,
      'Schedule Operation': startDate.toDateString(),
      'Update Interval': ScheduleToReadable[config.updateInterval as ScheduleEnum],
    });
  };

interface ICreateQualtricsAuth {
  auth: {
    datacenterId: string;
    clientId: string;
    secretId: string;
    state: string;
    scopes: string;
    redirectUri: string;
  };
  integration: Integration;
  authProviderID: string;
}

export const createQualtricsAuth =
  ({ auth, integration, authProviderID }: ICreateQualtricsAuth) =>
  async (dispatch: AppDispatch) => {
    dispatch(updateSubmittingAuth({ submitting: true }));
    // Do not allow to start new auth process if has active
    if (integration.authentication) {
      if (integration.authentication.active) {
        dispatch(updateSubmittingAuth({ submitting: false }));
        dispatch(
          updateLastEditedAuth({
            success: false,
            source: AuthSourceEnum.QUALTRICS,
          }),
        );
        return null;
      }
    }

    const res = await api.authenticateProvider(AuthSourceEnum.QUALTRICS, {
      datacenterId: auth.datacenterId,
      clientId: auth.clientId,
      secretId: auth.secretId,
      state: auth.state,
      scopes: auth.scopes,
      redirectUri: auth.redirectUri,
      providerID: authProviderID,
    });

    if (res) {
      const newAuth = res as ProviderAuthentication;
      dispatch(setAuth({ auth: newAuth, source: SurveySourceEnum.QUALTRICS }));
      return newAuth;
    } else {
      dispatch(updateSubmittingAuth({ submitting: false }));
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: AuthSourceEnum.QUALTRICS,
        }),
      );
      return null;
    }
  };

interface ISubmitQualtricsAuth {
  auth: ProviderAuthentication;
}

export const submitQualtricsAuth =
  ({ auth }: ISubmitQualtricsAuth) =>
  async (dispatch: AppDispatch) => {
    const newAuth = { ...auth, active: true };
    dispatch(setAuth({ auth: newAuth, source: SurveySourceEnum.QUALTRICS }));
    dispatch(updateLastEditedAuth({ success: true, source: AuthSourceEnum.QUALTRICS }));
    eventsTracker.track(EVENTS.CONNECTIONS_AUTHENTICATION_SUCCESSFUL, {
      'App/Integration name': AuthSourceEnum.QUALTRICS,
      'Processing Time': 0,
    });

    dispatch(updateSubmittingAuth({ submitting: false }));
  };

interface ISubmitAzureAuth {
  auth: {
    accountName: string;
    containerName: string;
    sasToken: string;
  };
  authProviderID?: string;
}

export const submitAzureAuth =
  ({ auth, authProviderID }: ISubmitAzureAuth) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingAuth({ submitting: true }));
    const res = await api.authenticateProvider(AuthSourceEnum.AZURE_SAS, {
      accountName: auth.accountName,
      container: auth.containerName,
      token: auth.sasToken,
      providerID: authProviderID,
    });
    dispatch(updateSubmittingAuth({ submitting: false }));
    if (res) {
      dispatch(setAuth({ auth: res, source: SurveySourceEnum.AZURE }));
      const endTime = performance.now();
      dispatch(
        updateLastEditedAuth({
          success: true,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
      eventsTracker.track(EVENTS.CONNECTIONS_AUTHENTICATION_SUCCESSFUL, {
        'App/Integration name': AuthSourceEnum.AZURE_SAS,
        'Account Name': auth.accountName,
        'Processing Time': endTime - startTime,
      });
    } else {
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
    }
  };

interface IUpdateAzureAuth {
  auth: {
    accountName: string;
    containerName: string;
    sasToken: string;
  };
  id: string;
}

export const updateAzureAuth =
  ({ auth, id }: IUpdateAzureAuth) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingAuth({ submitting: true }));
    const res = await api.updateProviderAuth(id, AuthSourceEnum.AZURE_SAS, {
      accountName: auth.accountName,
      container: auth.containerName,
      token: auth.sasToken,
    });
    dispatch(updateSubmittingAuth({ submitting: false }));
    if (res) {
      dispatch(setAuth({ auth: res, source: SurveySourceEnum.AZURE }));
      const endTime = performance.now();
      dispatch(
        updateLastEditedAuth({
          success: true,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
      dispatch(fetchConnectors());
      dispatch(closeConnectionsModal());
      eventsTracker.track(EVENTS.CONNECTIONS_AUTHENTICATION_SUCCESSFUL, {
        'App/Integration name': AuthSourceEnum.AZURE_SAS,
        'Account Name': auth.accountName,
        'Processing Time': endTime - startTime,
      });
    } else {
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: AuthSourceEnum.AZURE_SAS,
        }),
      );
    }
  };

interface IUpdateAuthError {
  source: SurveySourceEnum;
  error: string;
}

export const updateAuthError =
  ({ source, error }: IUpdateAuthError) =>
  (dispatch: AppDispatch, getState: () => IApplicationState) => {
    const state = getState();
    const integration = state.connectors.integrations.find((x) => x.source === source);
    if (!integration) {
      return;
    }
    const auth = integration.authentication;
    if (!auth) {
      return;
    }

    dispatch(updateSubmittingAuth({ submitting: true }));
    try {
      api.updateProviderAuth(auth.id, auth.systemType, {
        error: error,
      });
      dispatch(setAuth({ auth: { ...auth, error: error }, source }));
      dispatch(
        updateLastEditedAuth({
          success: false,
          source: auth.systemType,
        }),
      );
    } catch (e) {
      console.error('updateAuthError: authID ', auth.id, ' error ', e);
    }
    dispatch(updateSubmittingAuth({ submitting: false }));
  };

export const deleteAuthById =
  ({ id }: { id: string }) =>
  async (dispatch: AppDispatch) => {
    const res = await api.deleteProviderAuth(id);
    if (res) {
      dispatch(removeAuth({ id }));
    }
    dispatch(updateSubmittingAuth({ submitting: false }));
  };

export const deleteAuth = () => async (dispatch: AppDispatch, getState: () => IApplicationState) => {
  const state = getState();
  const { id, source } = state.connectors.deleteAuth;
  if (!id) {
    return;
  }
  if (!source) {
    return;
  }

  const startTime = performance.now();
  dispatch(updateSubmittingDeleteAuth({ submitting: true }));
  const res = await api.cleanProviderAuth(source);
  dispatch(updateSubmittingDeleteAuth({ submitting: false }));
  if (res) {
    dispatch(removeAuth({ id }));
    dispatch(updateLastDeletedAuth({ success: true }));
  } else {
    dispatch(updateLastDeletedAuth({ success: false }));
  }
  dispatch(closeDeleteAuthModal());
  const endTime = performance.now();
  eventsTracker.track(EVENTS.CONNECTIONS_DELETE_AUTHENTICATION, {
    'Provider ID': id,
    'Is Success': !!res,
    'Processing Time': endTime - startTime,
  });
};

export const deleteConnection = () => async (dispatch: AppDispatch, getState: typeof store.getState) => {
  const { id, source } = (getState().connectors as IConnectorsReducer).deleteConnection;
  if (!id || !source) {
    return;
  }
  const startTime = performance.now();
  dispatch(updateSubmittingDeleteConnection({ submitting: true }));
  const res = await api.deleteConnection(id);
  dispatch(updateSubmittingDeleteConnection({ submitting: false }));
  if (res) {
    dispatch(removeConnection({ id, source }));
    dispatch(updateLastDeletedConnection({ success: true }));
  } else {
    dispatch(updateLastDeletedConnection({ success: false }));
  }
  dispatch(closeDeleteConnectionModal());
  const endTime = performance.now();
  eventsTracker.track(EVENTS.CONNECTIONS_DELETE_CONNECTION_FLOW, {
    'Connection ID': id,
    'Is Success': !!res,
    'App/Integration name': source,
    'Processing Time': endTime - startTime,
  });
};

interface IUpdateConnection {
  update: ConnectionUpdate;
}

export const updateConnection =
  ({ update }: IUpdateConnection) =>
  async (dispatch: AppDispatch) => {
    const startTime = performance.now();
    dispatch(updateSubmittingConnectionUpdate({ id: update.id, submitting: true }));
    const res = await api.updateConnection({
      ...update,
      interval: update.interval ? update.interval.toString() : undefined,
    });
    dispatch(updateSubmittingConnectionUpdate({ id: update.id, submitting: false }));
    if (res) {
      dispatch(updateConnectionAction({ connectionUpdate: update }));
      dispatch(updateConnectionUpdateResult({ id: update.id, success: true }));
    } else {
      dispatch(updateConnectionUpdateResult({ id: update.id, success: false }));
    }

    const endTime = performance.now();
    eventsTracker.track(EVENTS.CONNECTIONS_UPDATE_CONNECTION, {
      'Connection ID': update.id,
      'Is Success': !!res,
      'Processing Time': endTime - startTime,
    });
  };

interface IUploadFiles {
  files: { [fileName: string]: IFile };
  dashboardName?: string;
  dashboardID?: string;
  isDashboardPrivate?: boolean;
}

export const uploadFiles =
  ({ files, dashboardName, dashboardID, isDashboardPrivate }: IUploadFiles) =>
  async (dispatch: AppDispatch) => {
    let res: UploadSuccessResult | null = null;
    try {
      const fileBuffers: { fileName: string; arrayBuffer: ArrayBuffer }[] = [];
      for (const fileObject of Object.values(files)) {
        // Only upload the files which aren't already uploaded
        if (fileObject.status === FileStatus.ERROR || fileObject.status === FileStatus.DEFAULT) {
          const buffer = await fileObject.file.arrayBuffer();
          fileBuffers.push({ fileName: fileObject.file.name, arrayBuffer: buffer });
        }
      }

      dispatch(updateSubmittingUpload({ submitting: true }));
      res = await api.uploadFiles(fileBuffers, {
        dashboardName,
        dashboardID,
        numberOfFiles: fileBuffers.length,
        fileSizes: fileBuffers.map(({ fileName, arrayBuffer }) => ({ fileName, fileSize: arrayBuffer.byteLength })),
        isDashboardPrivate,
      });
    } catch (e) {
      console.error('uploadFiles: error ', e);
      eventsTracker.track(EVENTS.FILE_UPLOAD_ERROR, {
        'Error text': 'Something went wrong. Please make a different file selection to try again.',
      });
      dispatch(updateSubmittingUpload({ submitting: false }));
      dispatch(
        updateUploadResult({
          success: false,
          uploadErrorResults: [],
          error: CsvUploadErrorEnum.UNKNOWN_ERROR,
        }),
      );
      return;
    }
    dispatch(updateSubmittingUpload({ submitting: false }));
    // handle success
    for (const fileName of Object.keys(files)) {
      eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_SELECT_A_FILE, {
        'File Name': fileName,
      });
    }
    dispatch(
      updateUploadResult({
        uploadErrorResults: Object.keys(files).map((fileName) => ({ fileName, success: true, error: '' })),
        success: true,
      }),
    );
    if (res) {
      dispatch(fileMappingInit({ files: res.files, isPrivate: isDashboardPrivate ?? null, dashboardID: dashboardID ?? null }));
    }
  };

export const resetCSVUploadStatus = () => (dispatch: AppDispatch) => {
  dispatch(resetUploadResult());
};
