import { useMemo } from 'react';
import Grid, { GridProps } from '@material-ui/core/Grid';
import HttpsOutlinedIcon from '@material-ui/icons/HttpsOutlined';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import styled from 'styled-components';

import palette from '../../components/tools/palette';
import { DASHBOARD_SORT_FIELD_NAME, DASHBOARD_SORT_FIELDS, SORT_ORDER } from '../../constants';
import HiveRow from './HiveRow';
import HiveEmptyRow from './HiveEmptyRow';
import { Survey } from '../../store/reducers/dashboard/types';

const HeadColumnGrid = styled(Grid)`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
`;

const DashboardTitle = styled('strong')`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const PrivateIcon = styled(HttpsOutlinedIcon)<{ $isPrivate: boolean }>`
  vertical-align: sub;
  visibility: ${(props) => (props.$isPrivate ? 'inherit' : 'hidden')};
`;

interface IHeaderTableCell {
  title: string;
  selected: boolean;
  sortOrder: string;
  onClick: () => void;
  xs?: GridProps['xs'];
}

const HeaderTableCell = ({ title, selected, sortOrder, onClick, xs = 2 }: IHeaderTableCell) => {
  const OrderIcon = sortOrder === SORT_ORDER.ASC ? ArrowDownwardIcon : ArrowUpwardIcon;
  return (
    <HeadColumnGrid item xs={xs} style={{ cursor: 'pointer', userSelect: 'none' }} onClick={onClick}>
      {title}
      <OrderIcon
        fontSize='small'
        style={{
          color: palette.linkColorLight,
          verticalAlign: 'middle',
          visibility: selected ? 'visible' : 'hidden',
        }}
      />
    </HeadColumnGrid>
  );
};
// "2022-11-18 02:50" -> "2022-11-18"
const getDate = (datetime: any) => {
  if (!datetime) {
    return '';
  }
  return datetime.substring(0, 10);
};

interface IHive {
  hive: any;
  showSorting: boolean;
  sort: any;
  onEditClick: (hive: any) => void;
  onSortClick: (field: string) => void;
  onToggleDetailedStatusView: (shouldShowDetailedView: boolean, surveys: Survey[], top: number, left: number) => void;
}

const Hive = ({ hive, showSorting, sort, onEditClick, onSortClick, onToggleDetailedStatusView }: IHive) => {
  let dashboards;
  const hiveDashboards = hive.dashboards;
  const dashboardsList = useMemo(
    () =>
      [...hiveDashboards].sort((a, b) => {
        switch (sort.field) {
          case DASHBOARD_SORT_FIELDS.DATE_CREATED:
            return sort.order === SORT_ORDER.ASC ? a[sort.field].localeCompare(b[sort.field]) : b[sort.field].localeCompare(a[sort.field]);
          case DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE:
            return sort.order === SORT_ORDER.ASC
              ? getDate(a[sort.field]).localeCompare(getDate(b[sort.field]))
              : getDate(b[sort.field]).localeCompare(getDate(a[sort.field]));
          case DASHBOARD_SORT_FIELDS.FILTERED_TOTAL:
            return sort.order === SORT_ORDER.ASC ? a[sort.field] - b[sort.field] : b[sort.field] - a[sort.field];
          default:
            return 1;
        }
      }),
    [hiveDashboards, sort],
  );

  if (hive.dashboards.length === 0) {
    dashboards = (
      <Grid item style={{ marginBottom: '20px' }}>
        <HiveEmptyRow onClick={() => onEditClick(hive)} />
      </Grid>
    );
  } else {
    dashboards = dashboardsList.map((dashboard) => (
      <Grid item style={{ marginBottom: '20px' }} key={dashboard.id}>
        <HiveRow dashboard={dashboard} onToggleDetailedStatusView={onToggleDetailedStatusView} />
      </Grid>
    ));
  }

  return (
    <>
      <Grid container direction='column'>
        <Grid item>
          <Grid container direction='row' style={{ height: '58px' }}>
            <Grid item xs={4} style={{ display: 'flex', alignItems: 'center' }}>
              {hive.id !== null && (
                <DashboardTitle>
                  {<PrivateIcon fontSize='small' $isPrivate={hive.private} />}
                  {hive.title}
                </DashboardTitle>
              )}
            </Grid>
            {showSorting && (
              <>
                <HeadColumnGrid item xs={2} style={{ justifyContent: 'left' }}>
                  Status
                </HeadColumnGrid>
                <HeaderTableCell
                  title={DASHBOARD_SORT_FIELD_NAME[DASHBOARD_SORT_FIELDS.FILTERED_TOTAL]}
                  selected={sort.field === DASHBOARD_SORT_FIELDS.FILTERED_TOTAL}
                  sortOrder={sort.order}
                  xs={2}
                  onClick={() => onSortClick(DASHBOARD_SORT_FIELDS.FILTERED_TOTAL)}
                />
                <HeaderTableCell
                  title={DASHBOARD_SORT_FIELD_NAME[DASHBOARD_SORT_FIELDS.DATE_CREATED]}
                  selected={sort.field === DASHBOARD_SORT_FIELDS.DATE_CREATED}
                  sortOrder={sort.order}
                  xs={1}
                  onClick={() => onSortClick(DASHBOARD_SORT_FIELDS.DATE_CREATED)}
                />
                <HeaderTableCell
                  title={DASHBOARD_SORT_FIELD_NAME[DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE]}
                  selected={sort.field === DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE}
                  sortOrder={sort.order}
                  xs={1}
                  onClick={() => onSortClick(DASHBOARD_SORT_FIELDS.LATEST_UPDATED_RESPONSE)}
                />
                <HeadColumnGrid item xs={1}>
                  Countries
                </HeadColumnGrid>
              </>
            )}
          </Grid>
        </Grid>
        {dashboards}
      </Grid>
    </>
  );
};

export default Hive;
