import Dialog from '@material-ui/core/Dialog';
import React from 'react';
import styled from 'styled-components';
import { ReactComponent as CrossIcon } from '../../../../assets/icons/cross-icon.svg';

import { KeyQuote } from '../../../../store/reducers/aiInsights';

const Content = styled.div`
  width: 800px;
  min-height: 600px;
  padding: 32px;
  overflow: hidden;
`;
const Header = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const Title = styled.div`
  color: var(--black);
  font-family: Rubik;
  font-size: 32px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%;
  max-width: 650px;
`;

const Body = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 32px;
`;

const QuotesList = styled.div`
  display: flex;
  padding: 0px 8px;

  flex-direction: column;
  align-items: flex-start;
  gap: 18px;
  overflow: scroll;
  max-height: 400px;
`;

const QuoteRow = styled.div`
  font-family: Rubik;
  font-size: 16px;
  font-style: normal;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  &:not(:last-child) {
    border-bottom: 1px solid var(--medium-gray);
  }
`;

const QuoteHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 120%;
`;

const QuoteDate = styled.div`
  font-weight: bold;
`;

const QuoteBody = styled.div`
  margin-bottom: 10px;
`;

const CrossIconStyled = styled(CrossIcon)`
  width: 24px;
  height: 24px;
  cursor: pointer;
`;

interface IKeyQuotes {
  keyTakeaway: string;
  keyQuotes: KeyQuote[];
  isOpen: boolean;
  onClose: () => void;
}
function KeyQuotes({ isOpen, keyTakeaway, keyQuotes, onClose }: IKeyQuotes) {
  return (
    <Dialog maxWidth={false} open={isOpen} onClose={onClose}>
      <Content>
        <Header>
          <Title>Top Supporting Sources for "{keyTakeaway}"</Title>
          <CrossIconStyled onClick={onClose} />
        </Header>
        <Body>
          <QuotesList>{keyQuotes.map(renderKeyQuote)}</QuotesList>
        </Body>
      </Content>
    </Dialog>
  );

  function renderKeyQuote(keyQuote: KeyQuote) {
    return (
      <QuoteRow key={keyQuote.quoteID}>
        <QuoteHeader>
          <QuoteDate>{keyQuote.createdAt}</QuoteDate>
        </QuoteHeader>
        <QuoteBody>{keyQuote.quote}</QuoteBody>
      </QuoteRow>
    );
  }
}

export default KeyQuotes;
