import React, { useState } from 'react';
import { useAppDispatch } from '../../store/hooks';
import { useAppParams } from '../../types/Router';
import { EVENTS, eventsTracker } from '../../services/EventTrackerService';
import { dashboardUpdatePrivacy, dashboardUpdateTitle } from '../../store/thunks/dashboard';
import DashboardToolbar from './DashboardToolbar';

interface IDashboardToolbarContainer {
  exitText: boolean;
  shouldShowAccountLink: boolean;
  shouldShowInviteLink: boolean;
  disableInviteLink: boolean;
  shouldShowPrivacyMenu: boolean;
  dashboardName?: string;
  title?: string;
  dashboardID?: string;
  shouldShowUploadButton?: boolean;
  isPrivate?: boolean;
  onBackClick?: () => void;
}
function DashboardToolbarContainer({
  dashboardID,
  dashboardName,
  title,
  exitText,
  shouldShowAccountLink,
  shouldShowInviteLink,
  disableInviteLink,
  shouldShowUploadButton,
  isPrivate,
  shouldShowPrivacyMenu,
  onBackClick,
}: IDashboardToolbarContainer) {
  const dispatch = useAppDispatch();
  const { company: selectedCompany } = useAppParams();
  const [isPrivacyMenuOpen, setIsPrivacyMenuOpen] = useState(false);

  function handleDashboardRename(name: string) {
    if (dashboardID && name && title) {
      eventsTracker.track(EVENTS.RENAME_DASHBOARD, {
        'Old Name': title,
        'New Name': name,
      });
      dispatch(
        dashboardUpdateTitle({
          id: dashboardID,
          title: name,
        }),
      );
    }
  }

  function handleUploadClick() {
    // TODO: Fix callback to FileUploadFlow
    eventsTracker.track(EVENTS.FiLE_UPLOAD_BUTTON_SELECT, {
      'Button location': 'Dashboard Header',
    });
  }

  function handleChangeDashboardPrivacy(newIsPrivate: boolean) {
    if (!dashboardID || isPrivate === newIsPrivate) {
      return;
    }
    dispatch(dashboardUpdatePrivacy({ id: dashboardID, isPrivate: newIsPrivate }));
  }

  function handleTogglePrivacyMenu() {
    setIsPrivacyMenuOpen(!isPrivacyMenuOpen);
  }

  return (
    <DashboardToolbar
      dashboardID={dashboardID}
      dashboardName={dashboardName}
      exitText={exitText}
      selectedCompany={selectedCompany}
      title={title}
      disableInviteLink={disableInviteLink}
      shouldShowInviteLink={shouldShowInviteLink}
      shouldShowAccountLink={shouldShowAccountLink}
      shouldShowUploadButton={shouldShowUploadButton}
      isPrivacyMenuOpen={isPrivacyMenuOpen}
      isPrivate={isPrivate}
      shouldShowPrivacyMenu={shouldShowPrivacyMenu}
      onBackClick={onBackClick}
      onDashboardRename={handleDashboardRename}
      onTogglePrivacyMenu={handleTogglePrivacyMenu}
      onChangeDashboardPrivacy={handleChangeDashboardPrivacy}
    />
  );
}

export default DashboardToolbarContainer;
