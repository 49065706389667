import React from 'react';
import styled from 'styled-components';
import { Dialog, MenuItem, Tooltip } from '@material-ui/core';

import { ReactComponent as FileUploadIcon } from '../../../../assets/icons/file-upload-icon.svg';
import { ReactComponent as UserLockIcon } from '../../../../assets/icons/auth-icon.svg';
import { EVENTS, eventsTracker } from '../../../../services/EventTrackerService';
import { FilledButton, OutlinedButton } from '../../../UI/Button';
import BeehiveBackground from '../../../../assets/images/beehive-background.png';
import LoadingLabel from '../../../LoadingLabel/LoadingLabel';
import { ReactComponent as ErrorIcon } from '../../../../assets/icons/error-icon.svg';

import {
  CloseButtonContainer,
  CloseIcon,
  ContactUsText,
  Container,
  DialogSubtext,
  DialogTitle,
  UploadErrorChip,
  UploadErrorChipLine,
  UploadErrorContactUsText,
  UploadErrorText,
} from '../../../ConnectorsTab/AddCSV/styles';
import Dropzone from '../../../ConnectorsTab/AddCSV/Dropzone';
import FileUploadSurveyContainer from '../../../ConnectorsTab/AddCSV/FileUploadSurveyContainer';
import { DashboardVisibility, FileUploadSystemError, IFile } from '../../../ConnectorsTab/types';
import { CsvUploadErrorEnum } from '../../../../types/connections';
import { Select } from '../../../UI/Select';

const StyledFileUploadIcon = styled(FileUploadIcon)`
  width: 64px;
  height: 64px;
  fill: var(--beehive-purple);
`;

const StyledErrorIcon = styled(ErrorIcon)`
  width: 20px;
  height: 20px;
  margin-right: 8px;
  fill: var(--dark-red);
`;

const UploadContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 24px;
  border: 2px dashed var(--beehive-purple);
  border-radius: var(--double-border-radius);
  box-sizing: border-box;
  background-color: var(--light-gray);
`;

const DropzoneText = styled.div`
  font-size: 14px;
  color: var(--dark-gray);
  margin-top: 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 24px;
`;

const FileTitle = styled.div`
  margin: 24px 0;
  font-size: 24px;
`;

const BeehiveBackgroundImage = styled.img`
  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;
`;

const StyledLink = styled.a`
  color: var(--dark-purple);
  text-decoration: underline;
`;

const StyledFilledButton = styled(FilledButton)`
  width: 150px;
`;

const UploadedFilesContainer = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 16px;
  flex-wrap: wrap;
`;

const TooltipTitle = styled.div`
  font-size: 14px;
  padding: 12px;
`;

const VisibilityHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  > div {
    margin-left: 16px;
    color: var(--text-primary);
    font-size: 16px;
    font-weight: 500;
  }
`;

const VisibilityAssurance = styled.div`
  color: var(--dark-gray);
  font-size: 14px;
`;

const VisibilitySelect = styled(Select)`
  width: 368px;
  margin-bottom: 8px;
  .MuiSelect-outlined.MuiSelect-outlined {
    padding: 8px;
  }
`;

const UserLockIconStyled = styled(UserLockIcon)`
  width: 24px;
  height: 24px;
  fill: var(--sentiment-yellow);
`;

export const UPLOAD_BUTTON_TEXT = 'Next';

interface IFileUploadDialog {
  isSubmitDisabled: boolean;
  uploadError: FileUploadSystemError | null;
  filesRef: React.MutableRefObject<{ [fileName: string]: IFile }>;
  files: { [fileName: string]: IFile };
  isUploading: boolean;
  visibility: string;
  shouldShowVisibilityMenu: boolean;
  handleFilesChange: (files: { [fileName: string]: IFile }) => void;
  handleClose: () => void;
  handleSubmit: () => void;
  handleUploadErrorSet: (error: FileUploadSystemError | null) => void;
  handleSelectFile: (e: any) => void;
  handleChangeVisibility: (v: string) => void;
  handleContactUsClick: () => void;
}

function FileUploadDialog({
  isSubmitDisabled,
  uploadError,
  filesRef,
  files,
  isUploading,
  visibility,
  shouldShowVisibilityMenu,
  handleFilesChange,
  handleClose,
  handleSubmit,
  handleUploadErrorSet,
  handleSelectFile,
  handleChangeVisibility,
  handleContactUsClick,
}: IFileUploadDialog) {
  function handleHelpPageClick() {
    eventsTracker.track(EVENTS.CONNECTIONS_CSV_UPLOADS_HELP_PAGE_SELECT);
  }
  function onChangeVisibility(e: React.ChangeEvent<{ value: unknown }>) {
    handleChangeVisibility(e.target.value as string);
  }
  return (
    <div>
      <Dialog open={true} maxWidth={'md'}>
        <BeehiveBackgroundImage src={BeehiveBackground} />
        <CloseButtonContainer data-testid='file-upload-dialog-close' onClick={handleClose}>
          <CloseIcon />
        </CloseButtonContainer>
        <Container>
          <DialogTitle>Upload data to analyze with Beehive</DialogTitle>
          <DialogSubtext>
            We support data source types from surveys to interviews, and everything in between.{' '}
            <StyledLink
              href='https://help.beehive.ai/en/articles/8511249-uploading-data-file-s-to-the-beehive-ai-platform'
              target='_blank'
              rel='noopener noreferrer'
              onClick={handleHelpPageClick}
            >
              Learn more
            </StyledLink>
            .
          </DialogSubtext>
          {renderDropzone()}
          {renderSystemError()}
          {Object.values(files).map((file) => renderFileError(file))}
          {shouldShowVisibilityMenu && renderVisibilitySection()}
          {renderButtonContainer()}
        </Container>
      </Dialog>
    </div>
  );

  function renderVisibilitySection() {
    const visibilityAssuranceText = () => {
      if (visibility === DashboardVisibility.WORKSPACE) {
        return 'Your data is protected and available to only users in this workspace.';
      }
      return 'Your data is protected and available only to you.';
    };
    return (
      <>
        <VisibilityHeader>
          <UserLockIconStyled />
          <div>Visibility</div>
        </VisibilityHeader>
        <VisibilitySelect variant='outlined' value={visibility} data-testid='file-upload-visibility-select' onChange={onChangeVisibility}>
          <MenuItem value={DashboardVisibility.WORKSPACE} data-testid='file-upload-visibility-select-workspace'>
            Workspace
          </MenuItem>
          <MenuItem value={DashboardVisibility.PRIVATE} data-testid='file-upload-visibility-select-private'>
            Private
          </MenuItem>
        </VisibilitySelect>
        <VisibilityAssurance>{visibilityAssuranceText()}</VisibilityAssurance>
      </>
    );
  }

  function renderSystemError() {
    if (uploadError) {
      let errorText = <span>Something went wrong. Please make a different file selection to try again.</span>;
      switch (uploadError) {
        case FileUploadSystemError.INVALID_FILE_TYPE:
          errorText = <span>Please select only supported file types CSV, VTT or TXT.</span>;
          break;
        default:
          errorText = <span>Something went wrong. Please make a different file selection to try again.</span>;
          break;
      }
      return renderUploadError(errorText);
    }
    return null;
  }

  function renderFileError(file: IFile) {
    if (file.error) {
      switch (file.error) {
        case CsvUploadErrorEnum.DUPLICATE_NAME:
          return renderUploadError(
            <span>
              A file with the name <strong>"{file.file.name}"</strong> already exists. Please select a different file, or change the file
              name and try again.
            </span>,
            file.file.name,
          );
        default:
          return null;
      }
    }
    return null;
  }

  function renderUploadError(err: JSX.Element, key?: string) {
    return (
      <UploadErrorChip $boxColor='var(--red-transparent-10)' data-testid={`chip-upload-error`} key={key}>
        <UploadErrorChipLine>
          <StyledErrorIcon />
          <UploadErrorText>{err}</UploadErrorText>
          <UploadErrorContactUsText>
            Need help? <ContactUsText onClick={handleContactUsClick.bind(null)}>Contact us</ContactUsText>
          </UploadErrorContactUsText>
        </UploadErrorChipLine>
      </UploadErrorChip>
    );
  }

  function renderDropzone() {
    return (
      <Dropzone
        rootContainer={UploadContainer}
        files={filesRef}
        handleFilesChange={handleFilesChange}
        handleUploadErrorSet={handleUploadErrorSet}
        handleSelectFile={handleSelectFile}
        options={{
          className: 'fw',
          accept: {
            'text/csv': ['.csv'],
            'text/vtt': ['.vtt'],
            'text/plain': ['.txt'],
          },
          noClick: true,
        }}
      >
        {({ handleSelectFile: handleSelectFileInternal }) => renderFileContent(handleSelectFileInternal)}
      </Dropzone>
    );
  }

  function renderFileContent(handleSelectFileInternal: (e: any) => void) {
    return (
      <>
        <StyledFileUploadIcon />
        <FileTitle data-testid='file-upload-dialog-file'>Select file(s) or drag here</FileTitle>
        <OutlinedButton data-testid='file-upload-dialog-upload' onClick={handleSelectFileInternal}>
          Select File
        </OutlinedButton>
        <DropzoneText>(CSV, VTT, TXT)</DropzoneText>
        <UploadedFilesContainer>{renderFiles()}</UploadedFilesContainer>
      </>
    );
  }

  function renderFiles() {
    return Object.values(files).map((fileObject) => (
      <FileUploadSurveyContainer
        fileName={fileObject.file.name}
        files={files}
        filesRef={filesRef}
        handleFilesChange={handleFilesChange}
        key={fileObject.file.name}
      />
    ));
  }

  function renderButtonContainer() {
    return (
      <ButtonContainer>
        <OutlinedButton data-testid='file-upload-dialog-cancel' onClick={handleClose}>
          Cancel
        </OutlinedButton>
        <Tooltip title={isSubmitDisabled ? <TooltipTitle>Please select file(s) before continuing</TooltipTitle> : ''} placement='top' arrow>
          <span>
            <StyledFilledButton
              data-testid='file-upload-dialog-submit'
              disabled={isSubmitDisabled}
              variant='contained'
              onClick={handleSubmit}
            >
              {renderUploadButton()}
            </StyledFilledButton>
          </span>
        </Tooltip>
      </ButtonContainer>
    );
  }

  function renderUploadButton() {
    if (isUploading) {
      return <LoadingLabel loading='true' textContent={''} />;
    } else {
      return UPLOAD_BUTTON_TEXT;
    }
  }
}

export default FileUploadDialog;
