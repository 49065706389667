import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useRef } from 'react';
import {
  CheckIconStyled,
  CloseIconStyled,
  Container,
  ErrorIconStyled,
  ErrorPart,
  FunctionalPart,
  Input,
  InputContainer,
  SaveButton,
} from './styles';

interface ICustomDateFormat {
  isFormatValid: boolean;
  isPristine: boolean;
  wasFormatValidated: boolean;
  isLoading: boolean;
  dateFormat: string;
  onDateFormatChange: (newFormat: string) => void;
  onSaveButtonClick: () => void;
}

function CustomDateFormat({
  isFormatValid,
  isPristine,
  isLoading,
  dateFormat,
  wasFormatValidated,
  onDateFormatChange,
  onSaveButtonClick,
}: ICustomDateFormat) {
  const dateFormatInputRef = useRef<HTMLInputElement | null>(null);

  function handleSaveButtonClick() {
    if (isLoading) {
      return;
    }

    if ((wasFormatValidated && !isFormatValid) || (isPristine && !wasFormatValidated)) {
      onSaveButtonClick();
      return;
    }

    if (wasFormatValidated && isFormatValid && isPristine) {
      dateFormatInputRef?.current?.focus();
      return;
    }

    return onSaveButtonClick();
  }

  function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
    onDateFormatChange(e.target.value);
  }

  function handleClearInputIcon() {
    onDateFormatChange('');
  }

  return (
    <Container>
      <FunctionalPart>
        <InputContainer $isValid={!wasFormatValidated || (wasFormatValidated && isFormatValid)}>
          <Input ref={dateFormatInputRef} value={dateFormat} onChange={handleInputChange} placeholder='MM-DD-YYYY, etc.' />
          {renderStateIcon()}
        </InputContainer>
        <SaveButton onClick={handleSaveButtonClick}>{renderSaveButtonTitle()}</SaveButton>
      </FunctionalPart>
      <ErrorPart $isValid={!wasFormatValidated || (wasFormatValidated && isFormatValid)}>Please enter a valid date format...</ErrorPart>
    </Container>
  );

  function renderStateIcon() {
    if (isLoading || (isPristine && !wasFormatValidated)) {
      return <CloseIconStyled onClick={handleClearInputIcon} />;
    }

    if (wasFormatValidated && isFormatValid && isPristine) {
      return <CheckIconStyled />;
    }

    if (wasFormatValidated && !isFormatValid) {
      return <ErrorIconStyled />;
    }

    return <CloseIconStyled onClick={handleClearInputIcon} />;
  }

  function renderSaveButtonTitle() {
    if (isLoading) {
      return <CircularProgress size={20} color='inherit' />;
    }

    if ((wasFormatValidated && !isFormatValid) || (isPristine && !wasFormatValidated)) {
      return 'Save';
    }

    if (wasFormatValidated && isFormatValid && isPristine) {
      return 'Edit';
    }

    return 'Save';
  }
}

export default CustomDateFormat;
