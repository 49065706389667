import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { AppBar, Box, Button, Menu, MenuItem } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import { ExpandLess } from '@material-ui/icons';

import { ReactComponent as LockIcon } from '../../assets/icons/lock-icon.svg';
import { ReactComponent as LockOutlinedIcon } from '../../assets/icons/lock-outlined-icon.svg';
import { ReactComponent as UsersIcon } from '../../assets/icons/users-icon.svg';
import { ReactComponent as CheckIcon } from '../../assets/icons/check-circle-filled-icon.svg';
import FileUploadFlow from '../FileUploadFlow/FileUploadFlowContainer';
import palette from '../tools/palette';
import LogoSvg from '../UI/LogoSvg';
import { FilledButton } from '../UI/Button';
import Divider from '../Divider/Divider';
import { APP_PATH, getURLForCompany } from '../../constants';

import RenameField from './RenameField';
import { sharedStyles } from './styles';
import { AccountLink, ExitLink, InviteLink, PseudoLink } from './Link';

const AppBarStyled = styled(AppBar)`
  &&& {
    z-index: 1;
  }
`;

const DashboardTitle = styled.span`
  ${sharedStyles};
  font-size: 24px;
  display: flex;
  align-items: center;
`;

const ToolbarStyled = styled(Toolbar)`
  background-color: ${palette.toolbarBackgroundColor};
  padding: 0 16px;
`;

export const FileUploadButton = styled(FilledButton)`
  && {
    padding: 4px 16px;
    font-size: 14px;
  }

  svg {
    fill: white;
  }
`;

const StyledDivider = styled(Divider)`
  && {
    height: 32px;
    margin: 0 16px;
  }
`;

const PrivacyButton = styled(Button)<{ $isOpen: boolean }>`
  && {
    margin-left: 16px;
    padding: 4px 8px;
    background-color: var(${({ $isOpen }) => ($isOpen ? '--beehive-purple-light-30' : '--beehive-purple-light-10')});
    border-radius: 50px;
  }
`;

const StyledMenuItem = styled(MenuItem)<{ $isSelected: boolean }>`
  && {
    width: 200px;
    display: flex;

    > span {
      margin-left: 8px;
      flex-grow: 1;
      color: ${({ $isSelected }) => ($isSelected ? 'var(--beehive-purple)' : 'inherit')};
    }
  }
`;

const LockIconStyled = styled(LockIcon)`
  width: 16px;
  height: 16px;
  margin-right: 4px;
`;

const LockOutlinedStyled = styled(LockOutlinedIcon)<{ $isSelected: boolean }>`
  fill: var(${({ $isSelected }) => ($isSelected ? '--beehive-purple' : '--black')});
  width: 16px;
  height: 16px;
`;

const UsersIconStyled = styled(UsersIcon)<{ $isSelected: boolean }>`
  stroke: var(${({ $isSelected }) => ($isSelected ? '--beehive-purple' : '--black')});
  width: 16px;
  height: 16px;
`;

const CheckIconStyled = styled(CheckIcon)`
  fill: var(--beehive-purple);
  width: 20px;
  height: 20px;
  align-self: end;
`;

interface IDashboardToolbar {
  exitText: boolean;
  shouldShowAccountLink: boolean;
  shouldShowInviteLink: boolean;
  disableInviteLink: boolean;
  isPrivacyMenuOpen: boolean;
  shouldShowPrivacyMenu: boolean;
  dashboardName?: string;
  title?: string;
  selectedCompany?: string;
  dashboardID?: string;
  shouldShowUploadButton?: boolean;
  isPrivate?: boolean;
  onBackClick?: () => void;
  onDashboardRename: (name: string) => void;
  onTogglePrivacyMenu?: () => void;
  onChangeDashboardPrivacy?: (isPrivate: boolean) => void;
}

function DashboardToolbar({
  dashboardName,
  dashboardID,
  title,
  selectedCompany,
  exitText,
  shouldShowAccountLink,
  shouldShowInviteLink,
  isPrivacyMenuOpen,
  shouldShowPrivacyMenu,
  disableInviteLink,
  shouldShowUploadButton,
  isPrivate = false,
  onBackClick,
  onDashboardRename,
  onTogglePrivacyMenu,
  onChangeDashboardPrivacy,
}: IDashboardToolbar) {
  const anchorRef = React.useRef<HTMLButtonElement>(null);

  return (
    <>
      <AppBarStyled position='fixed' elevation={0}>
        <ToolbarStyled variant='dense' disableGutters>
          <Box display='flex' alignItems='center' width='100%'>
            <Box display='flex' flexGrow={1} alignItems='center'>
              <Box>
                <Link href='#' to={selectedCompany && dashboardID ? getURLForCompany(selectedCompany) : APP_PATH.COMPANIES}>
                  <LogoSvg />
                </Link>
              </Box>
              <Box display='flex' style={{ paddingLeft: '16px' }}>
                <DashboardTitle data-testid='dashboard-toolbar-title'>{renderTitle()}</DashboardTitle>
                {shouldShowPrivacyMenu && (
                  <>
                    <PrivacyButton
                      $isOpen={isPrivacyMenuOpen}
                      ref={anchorRef}
                      data-testid='dashboard-toolbar-privacy-menu-button'
                      onClick={onTogglePrivacyMenu}
                    >
                      {isPrivate ? (
                        <LockIconStyled data-testid='dashboard-toolbar-privacy-private-icon' />
                      ) : (
                        <UsersIconStyled $isSelected data-testid='dashboard-toolbar-privacy-workspace-icon' />
                      )}
                      <ExpandLess />
                    </PrivacyButton>
                    <Menu
                      open={isPrivacyMenuOpen}
                      getContentAnchorEl={null}
                      anchorEl={anchorRef.current}
                      onClose={onTogglePrivacyMenu}
                      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
                    >
                      <StyledMenuItem
                        $isSelected={isPrivate}
                        data-testid='dashboard-toolbar-privacy-menu-item-private'
                        onClick={onChangeDashboardPrivacy?.bind(null, true)}
                      >
                        <LockOutlinedStyled $isSelected={isPrivate} />
                        <span>Private</span>
                        {isPrivate ? <CheckIconStyled /> : null}
                      </StyledMenuItem>
                      <StyledMenuItem
                        $isSelected={!isPrivate}
                        data-testid='dashboard-toolbar-privacy-menu-item-workspace'
                        onClick={onChangeDashboardPrivacy?.bind(null, false)}
                      >
                        <UsersIconStyled $isSelected={!isPrivate} />
                        <span>Workspace</span>
                        {!isPrivate ? <CheckIconStyled /> : null}
                      </StyledMenuItem>
                    </Menu>
                  </>
                )}
              </Box>
              {shouldShowUploadButton && (
                <>
                  <Box>
                    <StyledDivider orientation='vertical' />
                  </Box>
                  <Box>
                    <FileUploadFlow
                      isNewDashboard={false}
                      dashboardName={dashboardName}
                      dashboardID={dashboardID}
                      fileUploadButtonTitle='Add More Data'
                    />
                  </Box>
                </>
              )}
            </Box>
            <Box display='flex' flexWrap='nowrap' alignItems='center'>
              {shouldShowAccountLink && <AccountLink />}
              {shouldShowInviteLink && <InviteLink company={selectedCompany} disabled={disableInviteLink} />}
              {onBackClick && (
                <PseudoLink onClick={onBackClick} style={{ marginRight: '20px' }}>
                  &lt; Back
                </PseudoLink>
              )}
              {exitText && <ExitLink />}
            </Box>
          </Box>
        </ToolbarStyled>
      </AppBarStyled>
      <Toolbar variant='dense' />
    </>
  );

  function renderTitle() {
    if (title === undefined) {
      return null;
    }

    return <RenameField label={title ? title : 'No title'} onSaveData={onDashboardRename} />;
  }
}

export default DashboardToolbar;
