import { RegenerationStatus } from '../../types/DataSource';
import { DashboardPayload } from '../../types/incoming/dashboard';
import {
  DataSource,
  PendingRefreshDataSource,
  ResponsesByDataSourceId,
  SentimentSummary,
  Widget,
  WidgetGroup,
} from '../reducers/dashboard/types';

export const DASHBOARD_FETCH_START = 'DASHBOARD_FETCH_START';
export const DASHBOARD_FETCH_SUCCESS = 'DASHBOARD_FETCH_SUCCESS';
export const DASHBOARD_FETCH_FAIL = 'DASHBOARD_FETCH_FAIL';
export const DASHBOARD_UPDATE_TITLE = 'DASHBOARD_UPDATE_TITLE';
export const DASHBOARD_UPDATE_PRIVACY = 'DASHBOARD_UPDATE_PRIVACY';
export const DASHBOARD_SET_DATA_SOURCES = 'DASHBOARD_SET_DATA_SOURCES';
export const DASHBOARD_SET_SENTIMENT_SUMMARIES = 'DASHBOARD_SET_SENTIMENTS';
export const DASHBOARD_SET_WIDGET_GROUPS = 'DASHBOARD_SET_WIDGET_GROUPS';
export const DASHBOARD_SET_WIDGETS = 'DASHBOARD_SET_WIDGETS';
export const DASHBOARD_UPDATE_WIDGET = 'DASHBOARD_UPDATE_WIDGET';
export const DASHBOARD_ADD_WIDGET = 'DASHBOARD_ADD_WIDGET';
export const DASHBOARD_REMOVE_WIDGET = 'DASHBOARD_REMOVE_WIDGET';
export const DASHBOARD_RESET = 'DASHBOARD_RESET';

export const DASHBOARD_CREATE_LABEL_START = 'DASHBOARD_CREATE_LABEL_START';
export const DASHBOARD_CREATE_LABEL_FAILURE = 'DASHBOARD_CREATE_LABEL_FAILURE';
export const DASHBOARD_CREATE_LABEL_SUCCESS = 'DASHBOARD_CREATE_LABEL_SUCCESS';

export const DASHBOARD_RENAME_LABEL_START = 'DASHBOARD_RENAME_LABEL_START';
export const DASHBOARD_RENAME_LABEL_FAILURE = 'DASHBOARD_RENAME_LABEL_FAILURE';
export const DASHBOARD_RENAME_LABEL_SUCCESS = 'DASHBOARD_RENAME_LABEL_SUCCESS';

export const DASHBOARD_DELETE_LABEL_START = 'DASHBOARD_DELETE_LABEL_START';
export const DASHBOARD_DELETE_LABEL_FAILURE = 'DASHBOARD_DELETE_LABEL_FAILURE';
export const DASHBOARD_DELETE_LABEL_SUCCESS = 'DASHBOARD_DELETE_LABEL_SUCCESS';

export const DASHBOARD_LABEL_STATE_RESET = 'DASHBOARD_LABEL_STATE_RESET';

export const DASHBOARD_SHOULD_RELOAD_RESPONSES = 'DASHBOARD_SHOULD_RELOAD_RESPONSES';
export const DASHBOARD_WIDGET_RELOAD_RESPONSES_SUCCESS = 'DASHBOARD_WIDGET_RELOAD_RESPONSES_SUCCESS';

export const DASHBOARD_FETCH_SUMMARY_START = 'DASHBOARD_FETCH_SUMMARY_START';
export const DASHBOARD_FETCH_SUMMARY_SUCCESS = 'DASHBOARD_FETCH_SUMMARY_SUCCESS';
export const DASHBOARD_FETCH_SUMMARY_FAILURE = 'DASHBOARD_FETCH_SUMMARY_FAILURE';

export const DASHBOARD_FETCH_SENTIMENT_START = 'DASHBOARD_FETCH_SENTIMENT_START';
export const DASHBOARD_FETCH_SENTIMENT_SUCCESS = 'DASHBOARD_FETCH_SENTIMENT_SUCCESS';
export const DASHBOARD_FETCH_SENTIMENT_FAILURE = 'DASHBOARD_FETCH_SENTIMENT_FAILURE';

export const DASHBOARD_WATCHER_INIT = 'DASHBOARD_WATCHER_INIT';
export const DASHBOARD_REGENERATION_IN_PROGRESS = 'DASHBOARD_REGENERATION_IN_PROGRESS';
export const DASHBOARD_SET_DATA_SOURCES_PENDING_REFRESH = 'DASHBOARD_SET_DATA_SOURCES_PENDING_REFRESH';
export const DASHBOARD_GET_REGENERATION_STATUS_START = 'DASHBOARD_GET_REGENERATION_STATUS_START';
export const DASHBOARD_GET_REGENERATION_STATUS_SUCCESS = 'DASHBOARD_GET_REGENERATION_STATUS_SUCCESS';
export const DASHBOARD_GET_REGENERATION_STATUS_FAILURE = 'DASHBOARD_GET_REGENERATION_STATUS_FAILURE';
export const DASHBOARD_WATCHER_CLEAR = 'DASHBOARD_WATCHER_CLEAR';

export const dashboardReset = () => ({
  type: DASHBOARD_RESET,
});

interface ISetDataSources {
  dataSources: DataSource[];
}
export const setDataSources = ({ dataSources }: ISetDataSources) => ({
  type: DASHBOARD_SET_DATA_SOURCES,
  payload: {
    dataSources,
  },
});

interface IRemoveWidget {
  widgetId: string;
}
export const removeWidget = ({ widgetId }: IRemoveWidget) => ({
  type: DASHBOARD_REMOVE_WIDGET,
  payload: {
    widgetId,
  },
});

export const fetchStart = () => ({ type: DASHBOARD_FETCH_START });

export const dashboardUpdateWidget = (widget: Widget) => ({
  type: DASHBOARD_UPDATE_WIDGET,
  payload: {
    widget,
  },
});

interface ISetSentimentSummaries {
  sentimentSummaries: SentimentSummary[];
}
export const setSentimentSummaries = ({ sentimentSummaries }: ISetSentimentSummaries) => ({
  type: DASHBOARD_SET_SENTIMENT_SUMMARIES,
  payload: {
    sentimentSummaries,
  },
});

interface IFetchSuccess {
  dashboard: DashboardPayload;
  responsesByDataSourceId: ResponsesByDataSourceId;
  isInternal: boolean;
}
export const fetchSuccess = ({ dashboard, responsesByDataSourceId, isInternal }: IFetchSuccess) => ({
  type: DASHBOARD_FETCH_SUCCESS,
  payload: {
    dashboard,
    responsesByDataSourceId,
    isInternal,
  },
});

interface IFetchFail {
  error: string;
}
export const fetchFail = ({ error }: IFetchFail) => ({
  type: DASHBOARD_FETCH_FAIL,
  payload: {
    error,
  },
});

interface IAddWidget {
  widget: Widget;
}
export const addWidget = ({ widget }: IAddWidget) => ({
  type: DASHBOARD_ADD_WIDGET,
  payload: {
    widget,
  },
});

interface IUpdateDashboardTitle {
  id: string;
  title: string;
}
export const updateDashboardTitle = ({ id, title }: IUpdateDashboardTitle) => ({
  type: DASHBOARD_UPDATE_TITLE,
  payload: {
    id,
    title,
  },
});

interface IUpdateDashboardPrivacy {
  id: string;
  isPrivate: boolean;
}
export const updateDashboardPrivacy = ({ id, isPrivate }: IUpdateDashboardPrivacy) => ({
  type: DASHBOARD_UPDATE_PRIVACY,
  payload: {
    dashboardID: id,
    isPrivate,
  },
});

interface ISetWidgetGroups {
  widgetGroups: WidgetGroup[];
}
export const setWidgetGroups = ({ widgetGroups }: ISetWidgetGroups) => ({
  type: DASHBOARD_SET_WIDGET_GROUPS,
  payload: {
    widgetGroups,
  },
});

export const dashboardSetWidgets = (widgets: Widget[]) => ({
  type: DASHBOARD_SET_WIDGETS,
  payload: {
    widgets,
  },
});

interface ICreateLabelStart {
  dataSourceID: string;
  label: string;
}
export const dashboardCreateLabelStart = ({ dataSourceID, label }: ICreateLabelStart) => ({
  type: DASHBOARD_CREATE_LABEL_START,
  payload: {
    dataSourceID,
    label,
  },
});

interface ICreateLabelFailure {
  dataSourceID: string;
  label: string;
}
export const dashboardCreateLabelFailure = ({ dataSourceID, label }: ICreateLabelFailure) => ({
  type: DASHBOARD_CREATE_LABEL_FAILURE,
  payload: {
    dataSourceID,
    label,
  },
});

interface ICreateLabelSuccess {
  dataSourceID: string;
  label: string;
}
export const dashboardCreateLabelSuccess = ({ dataSourceID, label }: ICreateLabelSuccess) => ({
  type: DASHBOARD_CREATE_LABEL_SUCCESS,
  payload: {
    dataSourceID,
    label,
  },
});

interface IRenameLabelStart {
  dataSourceID: string;
  label: string;
}
export const dashboardRenameLabelStart = ({ dataSourceID, label }: IRenameLabelStart) => ({
  type: DASHBOARD_RENAME_LABEL_START,
  payload: {
    dataSourceID,
    label,
  },
});

interface IRenameLabelFailure {
  dataSourceID: string;
  label: string;
}
export const dashboardRenameLabelFailure = ({ dataSourceID, label }: IRenameLabelFailure) => ({
  type: DASHBOARD_RENAME_LABEL_FAILURE,
  payload: {
    dataSourceID,
    label,
  },
});

interface IRenameLabelSuccess {
  dataSourceID: string;
  originalLabel: string;
  newLabel: string;
}
export const dashboardRenameLabelSuccess = ({ dataSourceID, originalLabel, newLabel }: IRenameLabelSuccess) => ({
  type: DASHBOARD_RENAME_LABEL_SUCCESS,
  payload: {
    dataSourceID,
    originalLabel,
    newLabel,
  },
});

interface IDeleteLabelStart {
  dataSourceID: string;
  label: string;
}
export const dashboardDeleteLabelStart = ({ dataSourceID, label }: IDeleteLabelStart) => ({
  type: DASHBOARD_DELETE_LABEL_START,
  payload: {
    dataSourceID,
    label,
  },
});

interface IDeleteLabelFailure {
  dataSourceID: string;
  label: string;
}
export const dashboardDeleteLabelFailure = ({ dataSourceID, label }: IDeleteLabelFailure) => ({
  type: DASHBOARD_DELETE_LABEL_FAILURE,
  payload: {
    dataSourceID,
    label,
  },
});

interface IDeleteLabelSuccess {
  dataSourceID: string;
  label: string;
}
export const dashboardDeleteLabelSuccess = ({ dataSourceID, label }: IDeleteLabelSuccess) => ({
  type: DASHBOARD_DELETE_LABEL_SUCCESS,
  payload: {
    dataSourceID,
    label,
  },
});

interface IDashboardLabelStateReset {
  dataSourceID: string;
  label: string;
}
export const dashboardLabelStateReset = ({ dataSourceID, label }: IDashboardLabelStateReset) => ({
  type: DASHBOARD_LABEL_STATE_RESET,
  payload: {
    dataSourceID,
    label,
  },
});

interface IIDashboardShouldReload {
  dataSourceID: string;
}
export const dashboardShouldReloadResponses = ({ dataSourceID }: IIDashboardShouldReload) => ({
  type: DASHBOARD_SHOULD_RELOAD_RESPONSES,
  payload: {
    dataSourceID,
  },
});

interface IDashboardReloadResponsesSuccess {
  widgetID: string;
}
export const dashboardWidgetReloadResponsesSuccess = ({ widgetID }: IDashboardReloadResponsesSuccess) => ({
  type: DASHBOARD_WIDGET_RELOAD_RESPONSES_SUCCESS,
  payload: {
    widgetID,
  },
});

interface IDashboardFetchSummaryStart {
  dataSourceID: string;
}
export const dashboardFetchSummaryStart = ({ dataSourceID }: IDashboardFetchSummaryStart) => ({
  type: DASHBOARD_FETCH_SUMMARY_START,
  payload: {
    dataSourceID,
  },
});

interface IDashboardFetchSummarySuccess {
  dataSourceID: string;
  dataSource: DataSource;
}

export const dashboardFetchSummarySuccess = ({ dataSourceID, dataSource }: IDashboardFetchSummarySuccess) => ({
  type: DASHBOARD_FETCH_SUMMARY_SUCCESS,
  payload: {
    dataSourceID,
    dataSource,
  },
});

interface IDashboardFetchSummaryFailure {
  dataSourceID: string;
}
export const dashboardFetchSummaryFailure = ({ dataSourceID }: IDashboardFetchSummaryFailure) => ({
  type: DASHBOARD_FETCH_SUMMARY_FAILURE,
  payload: {
    dataSourceID,
  },
});

interface IDashboardFetchSentimentStart {
  dataSourceID: string;
}
export const dashboardFetchSentimentStart = ({ dataSourceID }: IDashboardFetchSentimentStart) => ({
  type: DASHBOARD_FETCH_SENTIMENT_START,
  payload: {
    dataSourceID,
  },
});

interface IDashboardFetchSentimentSuccess {
  dataSourceID: string;
  sentiment: SentimentSummary;
}
export const dashboardFetchSentimentSuccess = ({ dataSourceID, sentiment }: IDashboardFetchSentimentSuccess) => ({
  type: DASHBOARD_FETCH_SENTIMENT_SUCCESS,
  payload: {
    dataSourceID,
    sentiment,
  },
});

interface IDashboardFetchSentimentFailure {
  dataSourceID: string;
}
export const dashboardFetchSentimentFailure = ({ dataSourceID }: IDashboardFetchSentimentFailure) => ({
  type: DASHBOARD_FETCH_SENTIMENT_FAILURE,
  payload: {
    dataSourceID,
  },
});

interface IInitWidgetWatcher {
  dashboardID: string;
}
export const dashboardInitWidgetWatcher = ({ dashboardID }: IInitWidgetWatcher) => ({
  type: DASHBOARD_WATCHER_INIT,
  payload: {
    dashboardID,
  },
});

interface IDashboardSetDataSourcesPendingRefresh {
  pendingRefreshDataSources: PendingRefreshDataSource[];
}
export const dashboardSetDataSourcesPendingRefresh = ({ pendingRefreshDataSources }: IDashboardSetDataSourcesPendingRefresh) => ({
  type: DASHBOARD_SET_DATA_SOURCES_PENDING_REFRESH,
  payload: {
    pendingRefreshDataSources,
  },
});

interface IRegenerationInProgress {
  dataSourceIDs: string[];
}
export const dashboardRegenerationInProgress = ({ dataSourceIDs }: IRegenerationInProgress) => ({
  type: DASHBOARD_REGENERATION_IN_PROGRESS,
  payload: {
    dataSourceIDs,
  },
});

interface IClearWatcher {
  dashboardID: string;
}
export const dashboardClearWatcher = ({ dashboardID }: IClearWatcher) => ({
  type: DASHBOARD_WATCHER_CLEAR,
  payload: {
    dashboardID,
  },
});

interface IGetRegenerationStatusStart {
  dataSourceIDs: string[];
}
export const dashboardGetRegenerationStatusStart = ({ dataSourceIDs }: IGetRegenerationStatusStart) => ({
  type: DASHBOARD_GET_REGENERATION_STATUS_START,
  payload: {
    dataSourceIDs,
  },
});

interface IGetRegenerationStatusSuccess {
  items: {
    dashboardID: string;
    dataSourceID: string;
    status: RegenerationStatus;
  }[];
}
export const dashboardGetRegenerationStatusSuccess = ({ items }: IGetRegenerationStatusSuccess) => ({
  type: DASHBOARD_GET_REGENERATION_STATUS_SUCCESS,
  payload: {
    items,
  },
});

interface IGetRegenerationStatusFailure {
  dataSourceIDs: string[];
}
export const dashboardGetRegenerationStatusFailure = ({ dataSourceIDs }: IGetRegenerationStatusFailure) => ({
  type: DASHBOARD_GET_REGENERATION_STATUS_FAILURE,
  payload: {
    dataSourceIDs,
  },
});
